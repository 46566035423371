import styled from 'styled-components';

export const StyledPage = styled.div`
  font-family: ${(props) => props.theme.fontText} !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .layout {
    display: flex;
    flex-direction: column;
  }

  .ant-layout-content {
    width: initial !important;
    min-height: 100vh;

    @media (max-width: 440px) {
      min-height: auto;
    }
  }

  thead .ant-table-cell {
    background-color: ${(props) => props.theme.veryLightGray} !important;
    border-bottom: 1px ${(props) => props.theme.headerBottom} !important;
  }

  .ant-table-row:nth-child(even) {
    background-color: ${(props) => props.theme.veryLightGray};
  }

  .pagination ul {
    .ant-pagination-item,
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev,
    .ant-pagination-prev,
    .ant-pagination-next {
      width: 40px;
      height: 40px;
      padding-top: 5px;
    }

    .ant-pagination-item-active {
      background-color: ${(props) => props.theme.bgColorHover};
      border: none;

      a {
        color: ${(props) => props.theme.white};
      }
    }

    li {
      button span {
        color: ${(props) => props.theme.bgColorHover};
      }
    }
  }
`;
