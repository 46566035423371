import styled from 'styled-components';

export const StyledCartWarning = styled.div`
  .cart-warning-container {
    font-size: 16px;
    font-weight: 300px;
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.cartWarningBackground};
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    font-family: ${(props) => props.theme.fontText};

    @media only screen and (max-width: 576px) {
    }
  }
`;
