import { StyledOrdersDrawer } from '../style';
import { TransactionsProps } from '../../../../interfaces/BackendModels';
import { OrderStatus } from './OrderStatus/OrderStatus';
import { OrderItemsTable } from './OrderItemsTable/OrderItemsTable';
import { OrderInvoiceBase } from './OrderInvoiceBase/OrderInvoiceBase';

interface OrdersViewOrderProps {
  transaction?: TransactionsProps | null;
  open?: boolean;
  onClose?: () => void;
}
export const OrdersViewOrder = ({
  transaction,
  onClose,
  open,
}: OrdersViewOrderProps) => {
  return (
    <StyledOrdersDrawer open={open} onClose={onClose} width={923}>
      <OrderStatus transactionStatus={transaction?.status ?? 'new'} />
      <OrderItemsTable products={transaction?.items ?? []} />
      <div className={'content-invoice'}>
        {transaction?.transactions?.map((trans) => {
          return <OrderInvoiceBase transaction={trans} order={transaction} />;
        })}
      </div>
    </StyledOrdersDrawer>
  );
};
