import styled from 'styled-components';

export const StyledNewProductForm = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .ant-checkbox-group.create-product-delivery-check-box {
    width: 100%;
    .ant-table-wrapper {
      width: 100%;

      .ant-table-row:nth-child(n + 1) .ant-table-cell {
        padding: 24px 100px 24px 24px;
      }

      .ant-table-cell:nth-child(1) {
        text-align: left;
      }
      .ant-table-cell:nth-child(2) {
        text-align: right;
      }
    }
  }

  .text-area {
    height: 90px;
  }

  .availability {
    .ant-form-item {
      margin-bottom: 30px;
    }
  }

  .ant-select-selector {
    border-radius: 3px !important;
  }

  .ant-input-number {
    border-radius: 3px !important;
  }

  .ant-input-number-affix-wrapper {
    border-radius: 3px !important;
  }

  .ant-picker {
    border-radius: 3px !important;
  }

  .ant-form-item-explain-error {
    position: absolute;
    font-size: 10px;
    z-index: 40;
  }
  .gr-cat-typ {
    margin-top: 24px;
  }

  .desc {
    .ant-input {
      height: 152px;
    }
  }

  .ant-upload-list {
    display: flex;
    padding-top: 30px;
    gap: 20px;
  }
  .social-icons {
    display: flex;
    gap: 20px;

    img {
      cursor: pointer;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 5px;
  }

  .ant-input {
    border-radius: 5px;
  }

  .time {
    display: flex;
    flex-direction: column;
  }

  .ant-input-number-suffix {
    .unit-suffix {
      height: 100%;
      position: absolute;
      right: 0;
      display: flex;
      min-width: 57px;
      align-items: center;
      justify-content: center;
      border-left: 1px solid ${(props) => props.theme.inputAddonBg};
      background-color: ${(props) => props.theme.inputAddonBg};
    }

    .unit-suffix.mala {
      padding: 0 10px;
      font-size: 14px;
    }
  }

  .suffix {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .image-upload {
    min-height: 98px;

    .ant-upload-drag,
    .ant-upload-btn,
    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      width: 431px;
      height: 100%;
      align-items: center;
      justify-content: center;

      .ant-upload-drag-icon {
        margin: 0;
      }
    }
  }

  .requirements {
    .reqs {
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 15px;
      gap: 0;
      p {
        margin: 0;
      }
    }
  }

  .scnd-tr {
    min-width: 407px;
    min-height: 38px;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
  }

  .flex {
    min-width: 261px;
    flex-grow: 1;
    min-height: 38px;
  }

  .col-djfgr {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    gap: 30px;

    .min {
      width: 100%;
      .ant-form-item {
        margin: 0;
        .ant-input-number {
          width: 100%;
        }
      }
    }
    .price {
      width: 100%;
      .ant-form-item {
        margin: 0;
        .ant-input-number {
          width: 100%;
        }
      }
    }
  }

  .checkboxes {
    margin-bottom: 20px;
  }

  .prod-availability {
    border-top: 1px solid ${(props) => props.theme.colorBorder};
    padding-top: 30px;
    border-bottom: 1px solid ${(props) => props.theme.colorBorder};
    padding-bottom: 30px;
  }

  .additional-info {
    padding-top: 30px;

    .checkboxes {
      display: flex;
      flex-direction: row;
      gap: 400px;
      padding-top: 10px;

      .ant-checkbox-group {
        display: flex;
        gap: 10px;
        flex-direction: column;
      }
    }
  }
`;
