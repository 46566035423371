import { styled } from 'styled-components'

export const StyledProcessPurchaseForm = styled('div')`
  width: 100%;
  .purchase-form-item .ant-form-item-label {
    font-family: ${(props) => props.theme.fontText};
  }

  span {
    font-family: ${(props) => props.theme.fontText};
  }

  .purchase-form-title {
    padding-top: 15px;
  }

  @media only screen and (max-width: 900px) {
    .purchase-form-item {
      margin-bottom: 8px;
    }

    .purchase-form-item .ant-form-item-label {
      padding-bottom: 3px !important;
    }

    .purchase-form-checkbox-item {
      margin-bottom: 23px;
    }
  }

  @media only screen and (max-width: 576px) {
    .ant-form {
      margin-bottom: 10px;
    }
    .ant-form-item {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
`
