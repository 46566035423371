enum RoutesPath {
  MAP = '/map',
  PRODUCTS_ALL = '/products/all',
  PRODUCTS_ALL_NEW = '/products/all/new',
  PRODUCTS_ALL_DISCOUNTED = '/products/all/discounted',
  FARMS_ALL = '/farms/all',
  FARM_PAGE = '/farm/:id',
  TEST_PAGE = '/test',
  PRODUCT_PAGE = '/product/:id',
  HOME = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  CONFIRMATION = '/confirmation',
  SUCCESSFUK_REGISTRATION = '/successful-registration',
  REGISTRATION_COMPANY = '/registration-company',
  TERMS_OF_USE = '/terms-of-use',
  ICONS_PAGE = '/icons',
  CART_PAGE = '/cart',
  PROFILE = '/profile/:tab',
  NOT_FOUND = '*',
  PAYMENT_FAILED = '/cart/failed',
  USER_ORDER = '/user/order',
}

export default RoutesPath;
