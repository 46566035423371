import styled from 'styled-components'

export const StyledPage = styled.div`
  padding: 0 50px;

  .ant-breadcrumb {
    padding: 21px 0;
  }

  .table-currency {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
`
