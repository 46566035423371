import styled from 'styled-components';

export const StyledBusinessForm = styled('div')`
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.fontText};

  .new-btn {
    max-width: 264px;
  }
  
  .input-filelist{
    display: flex;
    gap: 100px;
  }
    
    .yt{
      padding-bottom: 50px;
      max-width: 430px;
      label {
          bottom: -10px;
      }
    }
  
    .gallery, .video{
      padding-top: 100px;
    }
    
    h3{
        margin-bottom: 5px;
    }
  
  .upload-content{
    display: flex;
  }

  .content {
    min-height: 100vh;
    .section {
      max-width: 844px;
      min-height: 200px;

      .double-column-checkbox {
        .ant-checkbox-group {
          display: flex;
          flex-direction: column;
          max-height: 84px;
          justify-content: space-between;
            
        }
      }

        .double-column-checkbox-two-sixteen {
          .ant-checkbox-group {
              display: flex;
              flex-direction: column;
              max-height: 228px;!important;
              justify-content: space-between;
              gap: 10px;
          }
        }
    }

    textarea {
      height: 80px;
      border-radius: 5px;
      resize: none;
    }

    .text-area {
      label {
        position: relative;
        top: 8px;
      }

      .ant-input-data-count {
        position: relative;
        right: 5px;
      }
    }

    .bottom-border-wrapper {
      position: relative;
      width: 100vw;

      .bottom-border {
        border-bottom: 1px solid ${(props) => props.theme.borderInput};
        padding-top: 20px;
        width: 100%;
      }
    }

    .label-fix {
      .ant-col {
        padding-bottom: 0;
      }
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-form-item-control-input-content {
      input {
        border-color: ${(props) => props.theme.borderInput};
      }
    }
  }

  .double-checkbox {
    display: flex;
    gap: 133px;
    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .double-input {
    display: flex;
    justify-content: space-between;

    .ant-form-item-control-input {
      min-width: 407px;
    }
  }
`;

export const StyledBusinessInformation = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledBusinessDraggerGallery = styled('div')`
  display: flex;
  gap: 100px;

  .action {
    cursor: pointer;
    text-decoration: underline;
    color: ${(props) => props.theme.colorPrimary};
  }

  .upload {
    display: flex;
    flex-direction: column;

    .ant-upload-wrapper {
      .ant-upload-btn {
        padding: 0;

        .ant-upload-drag-icon {
          margin-bottom: 5px;
        }
      }
      .ant-upload {
        min-height: 98px;
        background-color: ${(props) => props.theme.white};

        .ant-upload-text {
          max-height: 98px;
          a {
            color: ${(props) => props.theme.blue};
            font-weight: 600;
          }
        }
      }
    }
  }
`;
