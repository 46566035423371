import styled from 'styled-components'

export const StyledPage = styled.div`
  .layout {
    min-height: 100vh;
  }

  .pointer {
    cursor: pointer;
  }

  .pagination {
    margin-left: auto;
  }

  .ant-layout-content {
    width: initial !important;
  }

  .action {
    color: ${(props) => props.theme.actionCollor};
    background-color: transparent !important;
    border: none;
  }

  .ant-pagination-item-active {
    border-color: ${(props) => props.theme.colorPaginationActiveBorder};
    border-width: 1px;
  }
`
