import { StyledTopCategoriesPage } from './style';
import UseQueryApiClient from '../../../utils/useQueryApiClient';
import React, { useCallback, useMemo, useState } from 'react';
import DefaultLayout from '../../components/DefaultLayout';
import { CategoryData } from '../../../types/CategoryData';
import TopCategorySelector, { FormDataType } from '../../../ui/Select/Selectors/TopCategorySelector';
import { Form, FormProps, message } from 'antd';
import { useIntl } from 'react-intl';
import { ImageProps } from '../../../types/BannerData';
import { Button, Tabs } from "../../../ui";

const TopCategories = () => {
  const [categories, setCategories] = useState<CategoryData[]>([])
  const [popular, setPopular] = useState<CategoryData[]>([])
  const [images, setImages] = useState<ImageProps[]>([])
  const [formData, setFormData] = useState<Record<any, FormDataType>>({})
  const [clearFile, setClearFile] = useState<boolean>(false)
  const intl = useIntl()

  const [form] = Form.useForm()

  const { appendData: uploadImage } = UseQueryApiClient({
    request: {
      url: '/api/v2/categories/upload',
      method: 'POST',
      multipart: true,
    },
    onSuccess: () => {
    },
  })

  const { appendData } = UseQueryApiClient({
    request: {
      url: 'api/v2/categories/update',
      method: 'POST',
    },
    onSuccess: () => {
      message.success(
        intl.formatMessage({ id: 'message.success_category_update' })
      )
    },
  })

  const { refetch: refetchImages } = UseQueryApiClient({
    request: {
      url: '/api/v2/categories/popular/images',
    },
    onSuccess: (response) => {
      setImages(response)
    },
  })

  const { refetch } = UseQueryApiClient({
    request: {
      url: 'api/v2/categories/menu/desktop',
    },
    onSuccess: (response) => {
      setCategories(response)
    },
  })

  const { refetch: refetchPopular } = UseQueryApiClient({
    request: {
      url: 'api/v2/categories/popular',
    },
    onSuccess: (response) => {
      setPopular(response)
    },
  })

  const handleCategoryChange = (position: number, categoryId: number) => {
    const updatedCategory = {
      top_category_position: position,
      category_id: categoryId,
    }
    appendData(updatedCategory)
  }

  const breadCrumbs = [
    {
      title: intl.formatMessage({ id: 'navigation.beginning' }),
    },
    {
      title: intl.formatMessage({ id: 'navigation.top_categories' }),
    },
  ]

  const orderedCategories = useMemo(()=> Array.from({ length: 6 }).map((_, index) => {
    const cat =
      popular.find((cat) => cat.top_category_position === index + 1) || null
    let img = images.find((img) => img.table_row_id === index + 1) || null
    const formDataObj = formData?.[index + 1];
    if (formDataObj && formDataObj?.file) {
      img = {
        ...img,
        url_original: formDataObj?.file ? URL.createObjectURL(formDataObj?.file) : ''
      }
    }
    return { cat: cat, img: img }
  }), [images, popular, formData])

  const refetchData = async () => {
    await refetchPopular()
    await refetchImages()
  }

  const onFinish: FormProps['onFinish'] = useCallback(async () => {
    try {
      setClearFile(true)
      const formDataList = Object.values(formData)
      for (let i = 0; i < formDataList.length; i++) {
        const formData = new FormData();
        Object.entries(formDataList[i]).map(([key, value]) => formData.append(key, value))
        await uploadImage(formData)
      }
      await refetchData()
      setFormData({})
      setClearFile(false)
      message.success(intl.formatMessage({id: 'message.success_save_top_category'}))
    } catch (e) {
      message.error(String(e));
    }
  }, [formData, refetchData, uploadImage, intl])

  const items = [
    {
      key: 'top_categories',
      label: intl.formatMessage({ id: 'navigation.top_categories' }),
      children: (
        <Form form={form} onFinish={onFinish}>
          <div className={'levels'}>
            {orderedCategories.map(({ cat, img }, index) => (
              <TopCategorySelector
                key={index}
                categories={categories}
                name={`${index + 1}`}
                label={`${index + 1}_category`}
                initialValue={cat ? cat : undefined}
                onCategoryChange={handleCategoryChange}
                image={img ? img : undefined}
                setFormData={setFormData}
                clearFile={clearFile}
              />
            ))}
          </div>
          <div className={'buttons'}>
            <Button
              type={'primary'}
              label={intl.formatMessage({ id: 'general.save' })}
              htmlType={'submit'}
            />
          </div>
        </Form>
      ),
    },
  ];

  return (
    <DefaultLayout.PageLayout breadcrumbs={breadCrumbs}>
      <DefaultLayout.PageContent>
        <StyledTopCategoriesPage>
          <Tabs
            items={items}
          />
        </StyledTopCategoriesPage>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default TopCategories
