import {CategoryData} from "../../../types/CategoryData";
import {DragEndEvent, useDroppable} from "@dnd-kit/core";
import React, {useState} from "react";
import {StyledDroppable} from "./style";
import DraggableCategory from "./DraggableCategory";

interface DroppableAreaProps {
  category: CategoryData
  onDrop: (event: DragEndEvent) => void
  isOver?: boolean
  onEdit: (category: CategoryData) => void
}

const DroppableArea = ({ category, onDrop, onEdit }: DroppableAreaProps) => {
  const [isOpenSubCategories, setIsOpenSubCategories] = useState(true)

  const { isOver, setNodeRef } = useDroppable({
    id: category ? category.id.toString() : 'undefined',
  })

  const handleToggleSubCategories = () => {
    setIsOpenSubCategories((prevState) => !prevState)
  }

  return (
    <StyledDroppable>
      <div className={isOver ? 'isOver' : ''} ref={setNodeRef}>
        <DraggableCategory
          key={category.id}
          category={category}
          isDragging={false}
          onEdit={onEdit}
          handleOpen={handleToggleSubCategories}
          isOpen={isOpenSubCategories}
        />
        {category.has_subcategories && (
          <>
            <div
              className="toggle-subcat"
              onClick={handleToggleSubCategories}
            ></div>
            {isOpenSubCategories && (
              <div className="sub-cat">
                {category.subcategories?.map((sub) => (
                  <DroppableArea
                    key={sub.id}
                    category={sub}
                    onDrop={onDrop}
                    onEdit={onEdit}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </StyledDroppable>
  )
}

export default DroppableArea
