import { Steps } from 'antd';
import styled from 'styled-components';

export const StyledAntdCartSteps = styled(Steps)`
  padding-left: 20px;
  padding-right: 5px;
  height: 34px;
  padding-top: 0 !important;
  margin-bottom: 15px;

  .ant-steps-item {
    .ant-steps-item-container {
      padding-bottom: 0;
      align-items: center;
      display: flex;
      .ant-steps-item-icon {
        margin-right: 20px;
        height: 34px;
        width: 34px;
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          font-size: 16px;
          font-family: ${({ theme }) => theme.fontText};
        }
      }
    }
  }
  .ant-steps-item::before {
    display: none !important;
  }

  .ant-steps-item::after {
    display: none !important;
  }
`;
