import React, { CSSProperties } from 'react';
import { Form } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import useFormValidation from '../../utils/useFormValidation';
import { Validations } from '../../interfaces';
import { useIntl } from 'react-intl';
import { AntdSelect } from './style';
import { FormItemLayout } from 'antd/es/form/Form';

export interface SelectOptionsValueStringNumber {
  label: string;
  value: number;
}
export interface SelectOptionsValueString {
  label: string;
  value: string;
}

export interface SelectProps extends Validations {
  placeholder?: React.ReactNode;
  children?: React.ReactNode;
  defaultValue?: string | number | React.ReactText[];
  style?: React.CSSProperties;
  onChange?: any;
  size?: 'large' | 'middle' | 'small';
  mode?: 'multiple' | 'tags';
  value?: number | string | string[];
  showSearch?: boolean;
  maxTagCount?: number | 'responsive';
  allowClear?: boolean;
  loading?: boolean;
  optionLabelProp?: string;
  label?: string;
  name?: (string | number)[] | string | number;
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
  tagRender?: (props: CustomTagProps) => React.ReactElement;
  rules?: Rule[];
  initialValue?: string | string[] | number | number[];
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  noStyle?: boolean;
  disabled?: boolean;
  className?: string;
  variant?: 'outlined' | 'borderless' | 'filled';
  suffixIcon?: React.ReactNode;
  onDropdownVisibleChange?: (open: boolean) => void;
  dropdownStyle?: CSSProperties;
  required?: boolean;
  layout?: FormItemLayout;
}

const { Option } = AntdSelect;

export const SelectOption = Option;

export const Select = ({
  disabled,
  placeholder = 'Izvēlēties',
  className,
  children,
  onChange,
  defaultValue,
  style,
  size = 'large',
  value,
  mode,
  showSearch = true,
  maxTagCount,
  allowClear,
  loading,
  optionLabelProp,
  label,
  name,
  dropdownRender,
  tagRender,
  initialValue,
  placement,
  noStyle,
  validations,
  variant,
  suffixIcon,
  onDropdownVisibleChange,
  dropdownStyle,
  required,
  layout,
  rules = [],
}: SelectProps) => {
  const { formValidations } = useFormValidation();
  const intl = useIntl();
  const combinedRules = validations ? formValidations(validations) : rules;

  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={initialValue}
      noStyle={noStyle}
      rules={combinedRules}
      layout={layout}
    >
      <AntdSelect
        disabled={disabled}
        dropdownStyle={dropdownStyle}
        onDropdownVisibleChange={onDropdownVisibleChange}
        placeholder={placeholder}
        className={className}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        onChange={onChange}
        defaultValue={defaultValue}
        style={style}
        size={size}
        value={value}
        mode={mode}
        suffixIcon={suffixIcon}
        showSearch={showSearch}
        maxTagCount={maxTagCount}
        allowClear={allowClear}
        variant={variant}
        filterOption={(input: string, option: any) => {
          if (mode === 'tags') {
            return true;
          }

          if (React.isValidElement(option.children?.[0])) {
            return (
              option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }
          return (
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        loading={loading}
        optionLabelProp={optionLabelProp}
        dropdownRender={dropdownRender}
        tagRender={tagRender}
        placement={placement}
        notFoundContent={intl.formatMessage({ id: 'general.select_no_data' })}
      >
        {children}
      </AntdSelect>
    </Form.Item>
  );
};
