import React, { useEffect, useState } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import {
  Button,
  Tabs,
  Table,
  DatePicker,
  Dropdown,
  Select,
  SelectOption,
  Modal,
  Input,
} from 'ui';
import Filter from 'admin/components/Filter';
import { StyledButtonList } from 'styles/buttonList';
import dayjs from 'dayjs';
import useQueryApiClient from '../../../../utils/useQueryApiClient';
import FileDownload from 'js-file-download';
import { MenuProps } from 'antd/es/menu';
import { Form, message } from 'antd';
import FarmActivityStatusSelect from '../../../Selects/FarmActivityStatusSelect';
import { FarmCreate } from '../../../../ui/Form/FarmCreate';

interface Record {
  id: number;
  website: string;
  confirmed: boolean;
}

const FarmListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState({});
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [reloadNumber, setReloadNumber] = useState(0);

  const intl = useIntl();
  const [form] = Form.useForm();

  const { appendData } = useQueryApiClient({
    request: {
      url: `api/v2/farms`,
      method: 'POST',
    },
    onSuccess: (response) => {
      FileDownload(response, `Fidavista_${dayjs().format('YYYY-MM-DD')}.xml`);
    },
  });

  const { appendData: syncProductRemainders, isLoading } = useQueryApiClient({
    request: {
      url: `api/v2/farms/syncProductRemainders`,
      method: 'POST',
    },
  });

  const { appendData: exportData } = useQueryApiClient({
    request: {
      url: `api/v2/farms/export`,
      data: {
        filter: advancedSearch,
      },
      multipart: true,
      method: 'POST',
    },
    onSuccess: (response, format) => {
      FileDownload(
        response,
        `${format}_${dayjs().format('YYYY-MM-DD')}.${format}`
      );
    },
  });

  const { appendData: deleteContract } = useQueryApiClient({
    request: {
      url: `api/v2/farms/:id`,
      method: 'DELETE',
    },
    onSuccess: () => {
      setReloadNumber((prevState) => prevState + 1);
      message.success(
        intl.formatMessage({ id: 'message.successful_delete_farm' })
      );
    },
  });

  const handleEdit = (record: Record) => {};

  const handleDelete = (record: Record) => {
    deleteContract(null, { id: record.id });
  };

  const handleOpenWeb = (record: Record) => {
    window.open(record.website, '_blank');
  };

  const handleOpenProfile = (record: Record) => {
    window.open(`${window.runConfig.frontendUrl}/farm/${record.id}`, '_blank');
  };

  function onActionSelect(action: string, record: Record) {
    switch (action) {
      case 'edit':
        handleEdit(record);
        break;
      case 'delete':
        handleDelete(record);
        break;
      case 'open_web':
        handleOpenWeb(record);
        break;
      case 'open_profile':
        handleOpenProfile(record);
        break;
      case 'open_activities':
        handleOpenProfile(record);
        break;
    }
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'farms.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'farms.reg_nr' }),
      dataIndex: 'reg_nr',
    },
    {
      title: intl.formatMessage({ id: 'farms.pvd_nr' }),
      dataIndex: 'pvd_nr',
    },
    {
      title: intl.formatMessage({ id: 'farms.contact_name' }),
      dataIndex: 'contact_name',
    },
    {
      title: intl.formatMessage({ id: 'farms.contact_phone' }),
      dataIndex: 'contact_phone',
    },
    {
      title: intl.formatMessage({ id: 'farms.status' }),
      dataIndex: 'public',
      render: (value: boolean, record: Record) => {
        if (!record.confirmed) {
          return intl.formatMessage({ id: 'farms.confirmed' });
        }

        if (value) {
          return intl.formatMessage({ id: 'farms.public' });
        } else {
          return intl.formatMessage({ id: 'farms.hidden' });
        }
      },
    },
    {
      title: intl.formatMessage({ id: 'farms.owner_name' }),
      dataIndex: 'owner_name',
    },
    {
      title: intl.formatMessage({ id: 'farms.owner_email' }),
      dataIndex: 'owner_email',
    },
    {
      title: intl.formatMessage({ id: 'farms.activitie_status' }),
      dataIndex: 'activitie_status',
    },
    {
      title: intl.formatMessage({ id: 'farms.progres' }),
      dataIndex: 'progress',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      render: (record: Record) => {
        const items: MenuProps['items'] = [
          {
            key: 'edit',
            label: intl.formatMessage({ id: 'general.edit' }),
          },
          {
            key: 'delete',
            label: intl.formatMessage({ id: 'general.delete' }),
          },
          ...(record.website
            ? [
                {
                  key: 'open_web',
                  label: intl.formatMessage({ id: 'general.open_web' }),
                },
              ]
            : []),
          {
            key: 'open_profile',
            label: intl.formatMessage({ id: 'general.open_profile' }),
          },
          {
            key: 'open_activities',
            label: intl.formatMessage({ id: 'general.open_activities' }),
          },
        ];
        const onMenuClick: MenuProps['onClick'] = (e) => {
          onActionSelect(e.key, record);
        };

        return (
          <Dropdown menu={{ items, onClick: onMenuClick }} trigger={['click']}>
            <Button
              label={intl.formatMessage({ id: 'general.select_action' })}
            />
          </Dropdown>
        );
      },
    },
  ];

  const items = [
    {
      key: 'active_farms',
      label: intl.formatMessage({ id: 'navigation.farms' }),
      children: (
        <>
          <Filter onFinish={setAdvancedSearch}>
            <DatePicker
              label={intl.formatMessage({ id: 'general.date_from' })}
              name="date_from"
            />
            <DatePicker
              label={intl.formatMessage({ id: 'general.date_to' })}
              name="date_to"
            />
            <Input
              label={intl.formatMessage({ id: 'general.search' })}
              name="search"
            />
            <Select
              label={intl.formatMessage({ id: 'farms.status' })}
              name="public"
            >
              <SelectOption key={1} value={1}>
                {intl.formatMessage({ id: 'farms.public' })}
              </SelectOption>
              <SelectOption key={2} value={0}>
                {intl.formatMessage({ id: 'farms.hidden' })}
              </SelectOption>
            </Select>
            <FarmActivityStatusSelect
              name="activitie_status"
              label={intl.formatMessage({ id: 'farms.activitie_status' })}
            />
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />
          </Filter>
          <Table
            url="/api/v2/farms"
            columns={columns}
            filter={advancedSearch}
            options={{ onlyFarms: '1' }}
            reload={reloadNumber}
          />
        </>
      ),
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'xlsx' || e.key === 'csv') {
      exportData({ format: e.key }, [], e.key);
    }
  };

  const dropdownItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'general.print' }),
      key: '1',
    },
    {
      label: intl.formatMessage({ id: 'general.copy' }),
      key: '2',
    },
    {
      label: intl.formatMessage({ id: 'general.excel' }),
      key: 'xlsx',
    },
    {
      label: intl.formatMessage({ id: 'general.csv' }),
      key: 'csv',
    },
  ];

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  };

  const onCloseModal = () => {
    setShowCreateModal(false);
    form.resetFields();
  };

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.farms' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Dropdown menu={menuProps}>
                <Button
                  label={intl.formatMessage({ id: 'general.export' })}
                  type="primary"
                />
              </Dropdown>
              <Button
                label={intl.formatMessage({ id: 'general.create' })}
                type="primary"
                onClick={() => setShowCreateModal(true)}
                ability={'farm_add'}
              />
              <Button
                label={intl.formatMessage({
                  id: 'farms.sync_product_remainders',
                })}
                type="primary"
                onClick={() => syncProductRemainders()}
                loading={isLoading}
                ability={'farm_edit'}
              />
            </StyledButtonList>
          }
          items={items}
        />
        <Form form={form}>
          <FarmCreate
            showCreateModal={showCreateModal}
            onCloseModal={onCloseModal}
            setShowCreateModal={setShowCreateModal}
          />
        </Form>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default FarmListPage;
