import styled from 'styled-components';

export const StyledSearchAndFilter = styled.div`
  .filter-open-btn {
    display: flex;
    height: auto;
    padding: 11px 14px !important;
    border-radius: 5px;
  }

  .filter-open-btn:hover {
    background: ${({ theme }) => theme.bgColorHover} !important;
    border-color: ${({ theme }) => theme.colorBorder} !important;
    color: ${({ theme }) => theme.white} !important;
  }

  .search-section {
    width: 100%;
    padding-top: 9px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    background-color: ${({ theme }) => theme.inputGrey};

    .ant-input-affix-wrapper {
      border: 1px solid ${({ theme }) => theme.bgColorHover};

      .ant-input-prefix {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .search-section {
      padding: 9px 20px 25px 20px;
    }

    .search-section-fixed {
      position: fixed !important;
      top: 0px !important;
      z-index: 100;
    }
  }

  @media only screen and (max-height: 870px) {
    .mobile-search-section-box {
      margin-right: 10px;
    }

    .mobile-serach-fixed {
      display: none !important;
    }
  }

  @media only screen and (max-height: 870px) {
    .mobile-search-section-box {
      margin-right: 10px;
    }

    &.mobile-search-fixed .search-section {
      position: fixed !important;
      top: 238px;
      z-index: 1;
    }
  }

  @media only screen and (max-height: 750px) {
    &.mobile-search-fixed .search-section {
      top: 170px;
    }
  }
`;
