import styled from 'styled-components';

export const StyledOrderInvoiceBase = styled('div')`
  background-color: ${(props) => props.theme.borderTable};
  width: 845px;
  display: flex;
  flex-direction: column;

  .footer {
    display: flex;
    align-items: center;
    justify-content: right;
    padding-bottom: 35px;
    padding-right: 20px;
    text-decoration: underline;
  }

  .invoice-data {
    display: flex;
    justify-content: space-between;
    .first,
    .second {
      display: flex;
      flex-direction: column;
      min-width: 200px;
      padding: 35px 20px;

      .field {
        display: flex;
        line-height: 30px;
        gap: 70px;
        width: 300px;
        .label {
          color: ${(props) => props.theme.stepInactiveColor};
          min-width: 140px;
        }
        .value {
          min-width: 120px;
        }
      }
    }
  }
`;
