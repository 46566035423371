import styled from 'styled-components';

export const StyledDeliveryForm = styled.div`
  max-width: 500px;
  margin-bottom: 10px;

  .delivery-options-container {
    margin-bottom: 24px;
    .pick-delivery-header {
      height: fit-content;
      padding: 16px 0 14px 21px;
      font-size: 18px;
      font-weight: 500;
      border-bottom: 1px solid ${(props) => props.theme.borderBottom};
    }

    .delivery-options-wrapper {
      margin: 22px 22px 0 22px;
      .delivery-radio-group {
        .ant-form-item {
          padding-bottom: 15px;
          border-bottom: 1px solid ${(props) => props.theme.borderBottom};
          .ant-radio-group {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .ant-radio-wrapper:hover {
        .ant-radio-inner {
          border-color: ${(props) => props.theme.radioCheckGreen};
        }
      }
      .ant-radio-wrapper {
        span {
          font-weight: 300;
        }
        .ant-radio {
          width: 16px;
          height: 16px;

          .ant-radio-inner {
            height: 16px;
            width: 16px;
          }
        }
        .ant-radio.ant-radio-checked {
          .ant-radio-inner {
            transition: none !important;
            background-color: transparent;
            border: 1px solid ${(props) => props.theme.radioCheckGreen};
          }

          .ant-radio-inner::after {
            transition: none !important;
            background-color: ${(props) => props.theme.radioCheckGreen};
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 111%;
            left: 111%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .delivery-options-container {
      margin-bottom: 0;
      .pick-delivery-header {
        padding: 0 0 16px 0;
        border-bottom: none;
      }
      .delivery-options-wrapper {
        margin: 0;
        .delivery-radio-group {
          .ant-form-item {
            margin-bottom: 10px;
            padding-bottom: 0;
            border-bottom: none;
          }
        }
      }
    }
  }
`;
