import React, { useState, useEffect } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import {
  Tabs,
  Table,
  Button,
  CustomGrid,
  Input,
  TextArea,
  Modal,
  InputNumber,
  Select,
  SelectOption
} from 'ui';
import dayjs from 'dayjs';
import { StyledButtonList } from 'styles/buttonList';
import { Form, FormInstance } from 'antd';
import useQueryApiClient from 'utils/useQueryApiClient';
import RichTextInput from '../../../../ui/Input/ReactQuillInput';
import parse from 'html-react-parser';

interface notificationTypeProps {
  id: number;
  name: string;
}

const SettingListPage = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<number>(0);
  const [reload, setReload] = useState<number>(0);
  const [isModalVisiblePage, setIsModalVisiblePage] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>('global_settings');
  const [isModalVisibleNotificationType, setIsModalVisibleNotificationType] = useState<boolean>(false);
  const [isModalVisibleNotificationSubject, setIsModalVisibleNotificationSubject] = useState<boolean>(false);
  const [selectedNotificationTypeEntry, setSelectedNotificationTypeEntry] = useState<number>(0);
  const [selectedNotificationSubjectEntry, setSelectedNotificationSubjectEntry] = useState<number>(0);
  const [notificationTypes, setNotificationTypes] = useState<notificationTypeProps[]>([]);

  const [form] = Form.useForm();
  const [formSecond] = Form.useForm();
  const [formNotificationType] = Form.useForm();
  const [formNotificationSubject] = Form.useForm();


  const intl = useIntl();

  const { } = useQueryApiClient({
    request: {
      url: `api/v2/settings/${selectedEntry}`,
      disableOnMount: !selectedEntry && !isModalVisible,
    },
    onSuccess: (response) => form.setFieldsValue(response),
  });

  const getNotificationTypeName = (id: number) => {
    const type = notificationTypes.find((type: notificationTypeProps) => type.id === id);
    return type ? type.name : '';
  };

  const { appendData } = useQueryApiClient({
    request: {
      url: selectedEntry
        ? `api/v2/settings/${selectedEntry}`
        : `api/v2/settings`,
      method: selectedEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const { } = useQueryApiClient({
    request: {
      url: `api/v2/pages-category/${selectedEntry}`,
      disableOnMount: !selectedEntry && !isModalVisiblePage,
    },
    onSuccess: (response) => formSecond.setFieldsValue(response),
  });

  const { appendData: deletePageCategory } = useQueryApiClient({
    request: {
      url: 'api/v2/pages-category/:id',
      method: 'DELETE',
    },
    onSuccess: () => setReload((old) => old + 1),
  });

  const { appendData: appendDataPage } = useQueryApiClient({
    request: {
      url: selectedEntry
        ? `api/v2/pages-category/${selectedEntry}`
        : `api/v2/pages-category`,
      method: selectedEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const { data: notificationTypesData } = useQueryApiClient({
    request: {
      url: '/api/v2/notification-types',
    },
    onSuccess: (response) => setNotificationTypes(response),
  });

  const { } = useQueryApiClient({
    request: {
      url: `api/v2/notification-types/${selectedNotificationTypeEntry}`,
      disableOnMount: !selectedNotificationTypeEntry && !isModalVisibleNotificationType,
    },
    onSuccess: (response) => formNotificationType.setFieldsValue(response),
  });

  const { appendData: appendDataNotificationType } = useQueryApiClient({
    request: {
      url: selectedNotificationTypeEntry
        ? `api/v2/notification-types/${selectedNotificationTypeEntry}`
        : `api/v2/notification-types`,
      method: selectedNotificationTypeEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const { appendData: deleteNotificationType } = useQueryApiClient({
    request: {
      url: 'api/v2/notification-types/:id',
      method: 'DELETE',
    },
    onSuccess: () => setReload((old) => old + 1),
  });

  const { } = useQueryApiClient({
    request: {
      url: `api/v2/notification-subjects/${selectedNotificationSubjectEntry}`,
      disableOnMount: !selectedNotificationSubjectEntry && !isModalVisibleNotificationSubject,
    },
    onSuccess: (response) => formNotificationSubject.setFieldsValue(response),
  });

  const { appendData: appendDataNotificationSubject } = useQueryApiClient({
    request: {
      url: selectedNotificationSubjectEntry
        ? `api/v2/notification-subjects/${selectedNotificationSubjectEntry}`
        : `api/v2/notification-subjects`,
      method: selectedNotificationSubjectEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const { appendData: deleteNotificationSubject } = useQueryApiClient({
    request: {
      url: 'api/v2/notification-subjects/:id',
      method: 'DELETE',
    },
    onSuccess: () => setReload((old) => old + 1),
  });

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'settings.key_name' }),
      dataIndex: 'key_name',
    },
    {
      title: intl.formatMessage({ id: 'settings.value' }),
      dataIndex: 'value',
      render: (value: string) => {
        return parse(`${value}`);
      },
    },
    {
      title: intl.formatMessage({ id: 'settings.description' }),
      dataIndex: 'description',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value);
              setIsModalVisible(true);
            }}
          />
        </>
      ),
    },
  ];

  const pageColumns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'title',
    },
    {
      title: intl.formatMessage({ id: 'general.created_by' }),
      dataIndex: 'created_by',
    },
    {
      title: intl.formatMessage({ id: 'general.published' }),
      dataIndex: 'published',
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value);
              setIsModalVisiblePage(true);
            }}
          />
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.delete' })}
            onClick={() => deletePageCategory([], { id: value })}
          />
        </>
      ),
    },
  ];

  const notificationTypeColumns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'notifications.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedNotificationTypeEntry(value);
              setIsModalVisibleNotificationType(true);
            }}
          />
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.delete' })}
            onClick={() => deleteNotificationType([], { id: value })}
          />
        </>
      ),
    },
  ];

  const notificationSubjectColumns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'notifications.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'notifications.notification_type_id' }),
      dataIndex: 'notification_type_id',
      render: (value: number) => getNotificationTypeName(value),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedNotificationSubjectEntry(value);
              setIsModalVisibleNotificationSubject(true);
            }}
          />
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.delete' })}
            onClick={() => deleteNotificationSubject([], { id: value })}
          />
        </>
      ),
    },
  ];

  const items = [
    {
      key: 'global_settings',
      label: intl.formatMessage({ id: 'settings.global_settings' }),
      children: (
        <>
          <Table
            url="/api/v2/settings"
            columns={columns}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
    {
      key: 'article_categories',
      label: intl.formatMessage({ id: 'settings.article_categories' }),
      children: (
        <>
          <Table
            url="/api/v2/pages-category"
            columns={pageColumns}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
    {
      key: 'notification_types',
      label: intl.formatMessage({ id: 'settings.notification_types' }),
      children: (
        <>
          <Table
            url="/api/v2/notification-types"
            columns={notificationTypeColumns}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
    {
      key: 'notification_subjects',
      label: intl.formatMessage({ id: 'settings.notification_subjects' }),
      children: (
        <>
          <Table
            url="/api/v2/notification-subjects"
            columns={notificationSubjectColumns}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
  ];

  const onModalClose = () => {
    setIsModalVisible(false);
    setIsModalVisiblePage(false);
    setIsModalVisibleNotificationType(false);
    setIsModalVisibleNotificationSubject(false);
    setSelectedEntry(0);
    setSelectedNotificationTypeEntry(0);
    setSelectedNotificationSubjectEntry(0);
    form.resetFields();
    formSecond.resetFields();
    formNotificationType.resetFields();
    formNotificationSubject.resetFields();
    setReload((old) => old + 1);
  };

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.global_settings' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Button
                label={intl.formatMessage({ id: 'general.create_new' })}
                type="primary"
                onClick={() =>
                  activeKey === 'global_settings'
                    ? setIsModalVisible(true)
                    : activeKey === 'article_categories'
                      ? setIsModalVisiblePage(true)
                      : activeKey === 'notification_types'
                        ? setIsModalVisibleNotificationType(true)
                        : setIsModalVisibleNotificationSubject(true)
                }
              />
            </StyledButtonList>
          }
          items={items}
          onChange={setActiveKey}
        />

        <Modal
          open={isModalVisible}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'navigation.global_settings' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => form.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={form} onFinish={appendData} layout="vertical">
            <Input
              name="key_name"
              label={intl.formatMessage({ id: 'settings.key_name' })}
              validations={'required'}
            />
            <RichTextInput
              name={'value'}
              label={intl.formatMessage({ id: 'settings.value' })}
              rules={'required'}
            />
            <TextArea
              name="description"
              label={intl.formatMessage({ id: 'settings.description' })}
            />
          </Form>
        </Modal>

        <Modal
          open={isModalVisiblePage}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'navigation.global_settings' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => formSecond.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={formSecond} onFinish={appendDataPage} layout="vertical">
            <Input
              name="title"
              label={intl.formatMessage({ id: 'general.name' })}
              validations={'required'}
            />
            <InputNumber
              name="published"
              label={intl.formatMessage({ id: 'general.published' })}
              validations={'required'}
            />
          </Form>
        </Modal>

        <Modal
          open={isModalVisibleNotificationType}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'notifications.edit_type' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => formNotificationType.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={formNotificationType} onFinish={appendDataNotificationType} layout="vertical">
            <Input
              name="name"
              label={intl.formatMessage({ id: 'notifications.name' })}
            />
          </Form>
        </Modal>

        <Modal
          open={isModalVisibleNotificationSubject}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'notifications.edit_subject' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => formNotificationSubject.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={formNotificationSubject} onFinish={appendDataNotificationSubject} layout="vertical">
            <Input
              name="name"
              label={intl.formatMessage({ id: 'notifications.name' })}
            />
            <Select
              name="notification_type_id"
              label={intl.formatMessage({ id: 'notifications.notification_type_id' })}
            >
              {notificationTypesData.map((type: notificationTypeProps) => (
                <SelectOption value={type.id}>
                  {type.name}
                </SelectOption>
              ))}
            </Select>
          </Form>
        </Modal>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default SettingListPage;
