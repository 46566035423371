import styled from 'styled-components';
import { Modal } from './Modal';

export const StyledCartDeliveryModal = styled(Modal)`
  .ant-modal-content {
    margin: 0 20px 100px 20px !important;
    overflow-y: auto;
    padding: 16px;
    border-radius: 8px;

    .ant-modal-close {
      position: absolute;
      top: 1px;
      right: 1px;
      svg {
        height: 14px;
        color: ${(props) => props.theme.black};
      }
    }
  }
`;

export const StyledFarmCreateModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 5px;
    padding: 0;
    font-family: ${(props) => props.theme.fontText};
  }

  .blue {
    background-color: ${(props) => props.theme.blueColor};
  }

  .radius {
    border-radius: 5px;
  }

  .actions {
    display: flex;
    justify-content: right;
    gap: 10px;
  }

  .ant-modal-header {
    border-radius: 5px;
    position: relative;
    .title-wrapper {
      position: relative;
      border-bottom: 1px solid ${(props) => props.theme.silverGray};
      width: 100%;
      .title {
        position: relative;
        padding: 20px;
      }
    }
  }

  .ant-modal-body {
    padding: 20px 20px 0 20px;
  }

  .ant-modal-footer {
    border-top: 1px solid ${(props) => props.theme.silverGray};
    padding: 20px;
  }
`;
