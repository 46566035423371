import { ProductData } from 'types/ProductData'
import { StyledDiscountItems } from './style'
import { Button, Carousel } from '../../../../ui'
import { useIntl } from 'react-intl'
import {ReactNode} from "react";

interface DiscountItemsProps {
  products?: ProductData[]
  slidesPerRow?: number
  children?: ReactNode
}

const DiscountItems = ({ products, slidesPerRow, children }: DiscountItemsProps) => {
  const intl = useIntl()

  const responsive = [
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1150,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1484,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1760,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 5,
      },
    },
  ];

  return (
    <StyledDiscountItems>
      <div className={'container'}>
        {children}
        <div className={'title'}>
          <h1>{intl.formatMessage({ id: 'general.buy_cheaper' })}</h1>
        </div>
        <div className={'row'}>
          <Carousel
            responsive={responsive}
            products={products}
            dots={true}
            adaptiveHeight={true}
            slidesPerRow={slidesPerRow || 5}
            draggable={true}
          />
        </div>
      </div>

      <div className={'view-all-items-button'}>
        <Button
          type="primary"
          size={'large'}
          label={intl.formatMessage({ id: 'discount_items.view_items_button' })}
          href={'/products/all/discounted'}
        />
      </div>
    </StyledDiscountItems>
  );
};

export default DiscountItems;
