import React from 'react';
import { Form, ConfigProvider } from 'antd';
import { StyledWorkingHours } from './style';
import { useIntl } from 'react-intl';
import lvLV from 'antd/es/locale/lv_LV';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import dayjs from 'dayjs';
import { TimePicker } from '../TimePicker/TimePicker';
import { Checkbox } from '../Checkbox';

export const WorkingHours = () => {
  const form = useFormInstance();
  const intl = useIntl();

  const DAYS = [
    { index: 1, label: intl.formatMessage({ id: 'week.monday' }) },
    { index: 2, label: intl.formatMessage({ id: 'week.tuesday' }) },
    { index: 3, label: intl.formatMessage({ id: 'week.wednesday' }) },
    { index: 4, label: intl.formatMessage({ id: 'week.thursday' }) },
    { index: 5, label: intl.formatMessage({ id: 'week.friday' }) },
    { index: 6, label: intl.formatMessage({ id: 'week.saturday' }) },
    { index: 7, label: intl.formatMessage({ id: 'week.sunday' }) },
  ];

  const handleClosedChange = (dayIndex: number, checked: boolean) => {
    if (!checked) {
      form.setFieldValue(
        ['open_hours', dayIndex, 'no'],
        dayjs('00:00', 'HH:mm')
      );
      form.setFieldValue(
        ['open_hours', dayIndex, 'lidz'],
        dayjs('00:00', 'HH:mm')
      );
    } else {
      form.setFieldValue(['open_hours', dayIndex, 'no'], null);
      form.setFieldValue(['open_hours', dayIndex, 'lidz'], null);
    }
  };

  return (
    <ConfigProvider locale={lvLV}>
      <StyledWorkingHours>
        {DAYS.map((day) => (
          <div key={day.index - 1} className="selector">
            <div className="label">
              <div>{day.label}</div>
            </div>
            <div className="timers">
              <div className="picker">
                <TimePicker
                  dependencies={[['open_hours', day.index - 1, 'closed']]}
                  name={['open_hours', day.index - 1, 'no']}
                  allowClear={false}
                  prefix={intl.formatMessage({ id: 'time.from' })}
                  noStyle
                />
              </div>

              <div className="picker">
                <TimePicker
                  dependencies={[['open_hours', day.index - 1, 'closed']]}
                  name={['open_hours', day.index - 1, 'lidz']}
                  allowClear={false}
                  prefix={intl.formatMessage({ id: 'time.to' })}
                  noStyle
                />
              </div>

              <div className="checkbox">
                <Checkbox
                  onChange={(e) =>
                    handleClosedChange(day.index - 1, e.target.checked)
                  }
                  name={['open_hours', day.index - 1, 'closed']}
                />
                <div className="label">
                  {intl.formatMessage({ id: 'time.closed' })}
                </div>
              </div>
            </div>
          </div>
        ))}
      </StyledWorkingHours>
    </ConfigProvider>
  );
};
