import { ProductData } from 'types/ProductData';

type ProductValidationProps = {
  product: ProductData;
  count: number;
};

type ValidationResult = {
  stock_count: number;
  stock_total: number;
};

export const validateProduct = ({
  product,
  count,
}: ProductValidationProps): ValidationResult => {
  let price = product.storage_stock_price || 0;
  const step = product.step;
  count = Math.round(count / step) * step;

  const storageLimitMin = Math.max(product.storage_limit_min ?? step, step);

  if (storageLimitMin > count) {
    count = storageLimitMin;
  }

  if (product.storage_limit_max && product.storage_limit_max < count) {
    count = product.storage_limit_max;
  }

  if (
    product.storage_stock_count != null &&
    product.storage_stock_count < count
  ) {
    count = product.storage_stock_count;
  }

  if (
    product.storage_limit_max &&
    product.storage_limit_max < storageLimitMin
  ) {
    count = 0;
  }

  if (
    product.storage_stock_count != null &&
    product.storage_stock_count < storageLimitMin
  ) {
    count = 0;
  }

  if (
    product.storage_stock_discount_price &&
    product.storage_stock_discount_price < price
  ) {
    price = product.storage_stock_discount_price;
  }

  if (
    product.storage_wholesale_limit &&
    product.storage_wholesale_price &&
    product.storage_wholesale_price < price &&
    count >= product.storage_wholesale_limit
  ) {
    price = product.storage_wholesale_price;
  }

  return {
    stock_count: count,
    stock_total: parseFloat((count * (price * step)).toFixed(2)),
  };
};
