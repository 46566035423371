import React from 'react';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useFormValidation from '../../utils/useFormValidation';
import { options } from '../../config/config';

interface RichTextInputProps {
  name: string;
  label: string;
  rules: string;
}

const RichTextInput = ({ name, label, rules }: RichTextInputProps) => {
  const { formValidations } = useFormValidation();

  return (
    <Form.Item
      name={name}
      label={label}
      rules={formValidations(rules)}
      valuePropName="value"
      getValueFromEvent={(value) => value}
    >
      <ReactQuill theme="snow" modules={{ toolbar: options.toolbarOptions }} />
    </Form.Item>
  );
};

export default RichTextInput;
