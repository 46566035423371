import styled from 'styled-components';

export const StyledSiderHeader = styled.div`
  display: flex;
  gap: 19px;
  padding: 25px 0 22px 43px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};

  label {
    word-spacing: 100vw;
  }
`;

export const StyledBlock = styled.div`
  padding: 23px 0 60px 0;
  margin: 0 43px 0 47px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};

  .role {
    color: ${({ theme }) => theme.white};
    font-size: 24px;

    margin-bottom: 43px;
  }
`;

export const CustomMenu = styled.div`
  .custom-menu-item {
    display: flex;
    gap: 20px;
    align-items: center;

    padding: 0 0 15px 0;
    color: ${({ theme }) => theme.siderColor};
    cursor: pointer;

    .start-page-icon {
      color: ${({ theme }) => theme.siderColor};
      .home {
        display: flex;
        justify-content: center;
        min-width: 24px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &.active {
      color: ${({ theme }) => theme.white};
      font-weight: 500;
    }

    label {
      cursor: pointer;
    }
  }
`;
