import { StyledAllFarmsFarmCard } from './style';
import { QualityMarkData } from '../../types/ProductData';
import { useIntl } from 'react-intl';
import { HeartOutlined } from '@ant-design/icons';
import { Button } from 'ui/Button';
import { FallbackImage } from '../UploadImage/fallBack/FallBackImage';

export interface props {
  name?: string;
  qmarks?: QualityMarkData[];
  registeredFullAddress?: string;
  imageUrl?: string;
  is_top_farm?: boolean;
}

export const AllFarmsFarmCard = ({
  name,
  qmarks,
  registeredFullAddress,
  imageUrl,
  is_top_farm,
}: props) => {
  const intl = useIntl();

  const coverImg = (
    <FallbackImage
      src={imageUrl ? imageUrl : ''}
      type={'FarmCard'}
      preview={false}
    />
  );

  const findQmarkImage = (logo?: string) => {
    return logo && logo?.startsWith('/storage/uploads') ? window.runConfig.backendUrl + logo : logo
  }
  return (
    <StyledAllFarmsFarmCard cover={coverImg}>
      <div className={'overlay-wrapper'}>
        <div className={'overlay'}>
          {is_top_farm && (
            <div className={'top-farm'}>
              {intl.formatMessage({ id: 'all_farm_card.favorite' })}
            </div>
          )}
          <div className={'favorite'}>
            <HeartOutlined />
          </div>
        </div>
      </div>
      <div className={'farms-card-content'}>
        <div className={'farms-card-title'}>{name}</div>
        <div className={'registered-full-address'}>{registeredFullAddress}</div>
        <div className={'qmarks-wrapper'}>
          <div className={'qmarks'}>
            {qmarks?.splice(0, 8).map((qmark, index) => (
              <div
                className={'qmark-fm'}
                key={`frm-cr${index}-d`}
                id={qmark.slug}
              >
                <img src={findQmarkImage(qmark.logo)} alt={qmark.name} />
              </div>
            ))}
          </div>
          <span className={'farms-card-inspect-button'}>
            {intl.formatMessage({ id: 'farm_card.view' })}
          </span>
        </div>
      </div>
    </StyledAllFarmsFarmCard>
  );
};
