import React, {useEffect, useMemo, useState} from 'react'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  UniqueIdentifier
} from '@dnd-kit/core'
import { CategoryData } from '../../../types/CategoryData'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { StyledCategoryAdmin } from './style'
import { Form, message } from 'antd'
import { useIntl } from 'react-intl'
import Modal from 'antd/lib/modal'
import {Button, Input, Tabs, TextArea} from '../../../ui'
import { ArrowUpOutlined } from '@ant-design/icons'
import DefaultLayout from "../../components/DefaultLayout";
import Filter from "../../components/Filter";
import {AdvancedSearchState} from "../Contracts/ContractListPage";
import DroppableArea from "./DroppableArea";
import DraggableCategory from "./DraggableCategory";

const flattenCategories = (
  categories: CategoryData[],
  parentId: number = 0
) => {
  let result: { id: number; sub_id: number; sort: number }[] = []
  categories.forEach((category, index) => {
    result.push({
      id: category.id,
      sub_id: parentId,
      sort: index + 1,
    })

    if (category.subcategories) {
      result = result.concat(
        flattenCategories(category.subcategories, category.id)
      )
    }
  })
  return result
}

const CategoryPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState<AdvancedSearchState>({})
  const [categories, setCategories] = useState<CategoryData[]>([])
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [activeData, setActiveData] = useState<CategoryData | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showScroll, setShowScroll] = useState(false)

  const [form] = Form.useForm()

  const intl = useIntl()

  const { appendData: saveCategorySort } = useQueryApiClient({
    request: {
      url: `/api/v2/category/sort`,
      method: 'POST',
    },
    onSuccess: () => {
      message.success(
        intl.formatMessage({ id: 'message.success_save_category' })
      )
      refetch()
    },
    onError: () => {
      message.error(intl.formatMessage({ id: 'message.error_save_category' }))
    },
  })

  const buildCategoryTree = (
    categories: CategoryData[]
  ): { id: number; children: any[] }[] => {
    return categories.map((category) => ({
      id: category.id,
      children: category.subcategories
        ? buildCategoryTree(category.subcategories)
        : [],
    }))
  }

  const saveSortedCategories = (sortedCategories: CategoryData[]) => {
    const categoryTree = buildCategoryTree(sortedCategories)
    saveCategorySort({ list: JSON.stringify(categoryTree) })
  }

  const { appendData: updateCategory, isLoading } = useQueryApiClient({
    request: {
      url: `/api/v2/category/${activeData?.id}`,
      method: 'PATCH',
    },
    onSuccess: (response) => {
      message.success(intl.formatMessage({ id: 'category_updated_success' }))
      setCategories((prevCategories) =>
        prevCategories.map((cat) =>
          cat.id === activeData?.id ? { ...cat, ...response } : cat
        )
      )
      setIsModalOpen(false)
      refetch()
    },
    onError: (error) => {
      message.error(intl.formatMessage({ id: 'category_update_failed' }))
    },
  })

  const { refetch } = useQueryApiClient({
    request: {
      url: `/api/v2/categories/menu/desktop`,
      data: {
        filter: {
          ...advancedSearch
        }
      },
      disableOnMount: true,
    },
    onSuccess: (response) => {
      if (Array.isArray(response)) {
        setCategories(response)
      } else {
        setCategories([])
      }
    },
  })

  useEffect(() => {
    refetch();
  }, [advancedSearch])

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over) return

    const activeCategory = active.data.current
      ? JSON.parse(active.data.current['category'])
      : ''
    const targetCategoryId = parseInt(over.id as string)

    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories]

      const findAndRemoveCategory = (
        categoriesList: CategoryData[],
        categoryId: number
      ): CategoryData | null => {
        for (let i = 0; i < categoriesList.length; i++) {
          const category = categoriesList[i]
          if (category.id === categoryId) {
            categoriesList.splice(i, 1)
            return category
          }
          if (category.subcategories) {
            const removedCategory = findAndRemoveCategory(
              category.subcategories,
              categoryId
            )
            if (removedCategory) return removedCategory
          }
        }
        return null
      }
      const findAndInsertCategory = (
        categoriesList: CategoryData[],
        targetId: number,
        categoryToInsert: CategoryData
      ) => {
        for (const category of categoriesList) {
          if (category.id === targetId) {
            if (!category.subcategories) category.subcategories = []
            category.subcategories.push(categoryToInsert)
            return
          }
          if (category.subcategories) {
            findAndInsertCategory(
              category.subcategories,
              targetId,
              categoryToInsert
            )
          }
        }
      }

      const removedCategory = findAndRemoveCategory(
        updatedCategories,
        activeCategory.id
      )

      if (removedCategory) {
        findAndInsertCategory(
          updatedCategories,
          targetCategoryId,
          removedCategory
        )

        saveSortedCategories(updatedCategories)
      }

      return updatedCategories
    })

    setActiveId(null)
    setActiveData(null)
  }

  const handleEditCategory = (category: CategoryData) => {
    form.setFieldsValue({
      name: category.name,
      vat: category.vat,
      description: category.description
    })
    setActiveData(category)
    setIsModalOpen(true)
  }

  const handleSave = () => {
    form.validateFields().then((values) => {
      updateCategory(values)
    })
  }

  const { appendData: createCategory } = useQueryApiClient({
    request: {
      url: '/api/v2/category',
      method: 'POST',
    },
    onSuccess: (newCategory) => {
      setCategories((prev) => [...prev, newCategory])
      refetch()
    },
    onError: () => {},
  })

  const handleCreateCategory = (values: { categoryName: string }) => {
    const { categoryName } = values
    createCategory({ name: categoryName })
  }

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)
    return () => {
      window.removeEventListener('scroll', checkScrollTop)
    }
  }, [showScroll])

  const breadCrumbs = [
    {
      title: intl.formatMessage({ id: 'navigation.beginning' }),
    },
    {
      title: intl.formatMessage({ id: 'navigation.category' }),
    },
  ]

  const items = useMemo(() => [
    {
      key: 'category',
      label: intl.formatMessage({ id: 'navigation.category' }),
      children: (
        <>
          <Filter
            onFinish={setAdvancedSearch}
            isPagination={false}
          >
            <Input
              label={intl.formatMessage({ id: 'general.search' })}
              name="search"
            />
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />
          </Filter>
          <DndContext
            onDragEnd={handleDragEnd}
            onDragStart={({ active }) => {
              setActiveId(active.id)
              if (active.data.current) {
                setActiveData(JSON.parse(active.data.current['category']))
              }
            }}
          >
            <StyledCategoryAdmin>
              {showScroll && (
                <Button
                  type="primary"
                  icon={<ArrowUpOutlined />}
                  onClick={scrollTop}
                  className={'scrolltop'}
                />
              )}
              <div className={'add-new'}>
                <Form onFinish={handleCreateCategory}>
                  <Input type={'text'} required name={'categoryName'} />
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    label={intl.formatMessage({ id: 'general.add' })}
                  />
                </Form>
              </div>

              {categories.map((category) => (
                <DroppableArea
                  key={category.id}
                  category={category}
                  onDrop={handleDragEnd}
                  onEdit={handleEditCategory}
                />
              ))}
              <DragOverlay>
                {activeId && activeData && (
                  <DraggableCategory
                    category={activeData}
                    isDragging={true}
                    onEdit={handleEditCategory}
                  />
                )}
              </DragOverlay>
            </StyledCategoryAdmin>
          </DndContext>

          <Modal
            title={intl.formatMessage({ id: 'category.edit_category' })}
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            onOk={handleSave}
            confirmLoading={isLoading}
          >
            <Form form={form} layout="vertical">
              <Input
                name={'name'}
                label={intl.formatMessage({ id: 'category.category_name' })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'error.needs_name' }),
                  },
                ]}
              />
              <Input
                type="number"
                name={'vat'}
                label={intl.formatMessage({ id: 'category.vat' })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'error.needs_vat' }),
                  },
                ]}
              />
              <TextArea
                name="description"
                label={intl.formatMessage({ id: 'qmark.description' })}
              />
            </Form>
          </Modal>
        </>
      ),
    },
  ], [
    activeData,
    activeId,
    categories,
    form,
    intl,
    isLoading,
    isModalOpen,
    showScroll
  ])

  return (
    <DefaultLayout.PageLayout breadcrumbs={breadCrumbs}>
      <DefaultLayout.PageContent>
        <Tabs
          items={items}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default CategoryPage
