import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Drawer, Icon } from 'ui';
import { useNavigate } from 'react-router-dom';
import { useCartState } from 'public/context/CartContext';
import { CartProductCard } from 'ui/Card/CartProductCard';
import { StyledCartDrawer, StyledCartDrawerFooter } from './style';
import CartWarning from 'public/components/DefaultLayout/CartWarning';

interface CartDrawerProps {
  id?: number;
  isCartDrawerOpen: boolean;
  onCartClose: Function;
}

const CartDrawer = ({ id, isCartDrawerOpen, onCartClose }: CartDrawerProps) => {
  const intl = useIntl();
  const { farmProducts, priceTotal } = useCartState();
  const navigate = useNavigate();

  const innerOnClose = () => {
    onCartClose();
  };

  const onGoToCart = () => {
    navigate(`cart`);
    onCartClose();
  };

  return (
    <StyledCartDrawer
      id={'cart-drawer'}
      width={502}
      closeIcon={
        <Icon name={'close-basket-drawer-icon'} type={'cartDrawerClose'} />
      }
      open={isCartDrawerOpen}
      onClose={() => innerOnClose()}
      title={intl.formatMessage({ id: 'general.basket' })}
      footer={
        <StyledCartDrawerFooter>
          <CartWarning />
          <div className={'cart-drawer-sum-wrapper'}>
            <div className={'cart-drawer-sum-title'}>
              <p>{intl.formatMessage({ id: 'cart.sum' })}</p>
            </div>
            <div className={'cart-drawer-sum'}>
              {priceTotal.toFixed(2) + ' €'}
            </div>
          </div>
          <Button
            label={intl.formatMessage({ id: 'general.choose_delivery' })}
            type="primary"
            htmlType="submit"
            onClick={() => {
              onGoToCart();
            }}
          />
        </StyledCartDrawerFooter>
      }
    >
      {farmProducts.map((farm, farmIndex) => (
        <div key={farmIndex} className="farm-section">
          {farm.products.map((product, productIndex) => (
            <CartProductCard
              amountCart={product.amount}
              key={productIndex}
              product={product}
            />
          ))}
        </div>
      ))}
    </StyledCartDrawer>
  );
};

export default CartDrawer;
