import styled from 'styled-components';

export const StyledWorkingHours = styled('div')`
  display: flex;
  flex-direction: column;

  .label {
    padding-bottom: 0;
  }

  .timers {
    display: flex;
    gap: 15px;
    flex-direction: row;
    align-items: center;
    padding-bottom: 24px;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-picker-input {
    min-height: 40px;

    input {
      bottom: -1px;
    }

    span {
      position: relative;
      bottom: 1px;
    }
  }

  .ant-picker {
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 0 10px;
    .ant-picker-prefix {
      font-size: 14px;
    }
  }

  .picker {
    max-width: 126px;

    .ant-picker-input {
      bottom: 2px;
      font-size: 14px;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .desc {
    min-height: 110px;
    border-radius: 3px;
  }
`;
