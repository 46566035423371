import { StyledCartSummary } from './style';
import { Button, Checkbox, Icon, Input } from 'ui';
import { Form, FormInstance } from 'antd';
import { useIntl } from 'react-intl';
import { FarmProduct, useCartDispatch } from 'public/context/CartContext';
import useWindowSize from 'utils/useWindowSize';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import FileDownload from 'js-file-download';

interface CartSummaryProps {
  farmProducts: FarmProduct[];
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  form?: FormInstance;
  current: number;
  isView?: boolean | undefined;
  userForm?: FormInstance<any>;
  priceTotal: number;
}

const CartSummary = ({
  farmProducts,
  setCurrent,
  form,
  current,
  isView,
  userForm,
  priceTotal,
}: CartSummaryProps) => {
  const dispatchCart = useCartDispatch();
  const intl = useIntl();
  const [_, windowWidth] = useWindowSize();
  const productIds: number[] = [];
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { appendData: pdfData } = useQueryApiClient({
    request: {
      url: '/api/v2/generate-pdf',
      method: 'POST',
      multipart: true,
    },
    onSuccess: (response) => {
      FileDownload(
        response,
        `${intl.formatMessage({ id: 'cart_summary.invoice' })} ${dayjs().format('YYYY-MM-DD')}.pdf`
      );
    },
    onError: (error) => {
      console.error('Error downloading PDF:', error);
    },
  });

  const { appendData: cartPay } = useQueryApiClient({
    request: {
      url: `/api/v2/emarket/pay`,
      method: 'POST',
    },
    onSuccess: (response) => {
      window.location.href = response;
    },
  });

  useEffect(() => {
    const status = searchParams.get('status');

    if (status) {
      if (status === 'success') {
        setCurrent(2);
      } else if (status === 'failed') {
        navigate('/cart/failed');
      }
    }

    return () => {
      if (searchParams.get('status') === 'success' && isView) {
        localStorage.removeItem('farmProducts');
        localStorage.removeItem('priceTotal');
        dispatchCart({
          type: 'CLEAR_CART',
        });
      }
    };
  }, [searchParams, navigate]);

  const priceDiscountTotal = farmProducts.reduce((total, farm) => {
    const productsTotal = farm.products.reduce((productTotal, product) => {
      const {
        storage_stock_price = 0,
        storage_stock_discount_price = 0,
        step = 0,
      } = product.cartProduct || {};
      if (storage_stock_discount_price === 0) return 0;
      return (
        productTotal +
        (storage_stock_price * step - storage_stock_discount_price * step) *
          product.amount
      );
    }, 0);

    return total + productsTotal;
  }, 0);

  const priceNoDiscountTotal = farmProducts.reduce((total, farm) => {
    const productsTotal = farm.products.reduce((productTotal, product) => {
      const { storage_stock_price = 0, step = 0 } = product.cartProduct || {};
      return productTotal + storage_stock_price * step * product.amount;
    }, 0);

    return total + productsTotal;
  }, 0);

  const groupedVAT = farmProducts.reduce<
    Record<number, { vatAmount: number; baseAmount: number }>
  >((pvnGroup, farm) => {
    farm.products.forEach((product) => {
      const vatRate = product.vat || 0;

      const storageStockPrice = product.cartProduct
        ?.storage_stock_discount_price
        ? product.cartProduct?.storage_stock_discount_price
        : (product.cartProduct?.storage_stock_price ?? 0);

      const basePricePerUnit = storageStockPrice / ((vatRate + 100) / 100);
      const vatPerUnit = storageStockPrice - basePricePerUnit;

      const quantity = product.amount * (product.cartProduct?.step || 1);

      const baseAmount = basePricePerUnit * quantity;
      const vatAmount = vatPerUnit * quantity;

      if (!pvnGroup[vatRate]) {
        pvnGroup[vatRate] = { vatAmount: 0, baseAmount: 0 };
      }

      pvnGroup[vatRate].vatAmount += vatAmount;
      pvnGroup[vatRate].baseAmount += baseAmount;
    });
    return pvnGroup;
  }, {});

  const vatSummaryItems = Object.entries(groupedVAT)
    .filter(([rate]) => parseFloat(rate) !== 0)
    .map(([rate, data]) => ({
      id: `cart_summary.vat_${rate}`,
      label: intl.formatMessage({ id: `cart_summary.PVN` }) + `${rate}%`,
      value: `${data.vatAmount.toFixed(2)} €`,
    }));

  const productPriceWithoutVatRate = Object.values(groupedVAT).reduce(
    (sum, { baseAmount }) => sum + baseAmount,
    0
  );

  const vatRateTotal = Object.values(groupedVAT).reduce(
    (sum, { vatAmount }) => sum + vatAmount,
    0
  );

  const deliveryTotal = farmProducts.reduce(
    (total, farm) =>
      farm.selectedDelivery?.price
        ? total + farm.selectedDelivery?.price
        : total,
    0
  );

  farmProducts.forEach((farmProduct) => {
    farmProduct.products.forEach((cartProduct) => {
      if (cartProduct.cartProduct?.id !== undefined) {
        productIds.push(cartProduct.cartProduct.id);
      }
    });
  });

  const isAllDeliveriesSelected = farmProducts.reduce(
    (total, farm) => (farm.selectedDelivery ? total + 1 : total),
    0
  );

  const handleOrderAgain = () => {
    dispatchCart({
      type: 'CLEAR_CART',
    });

    dispatchCart({
      type: 'REFETCH_CART_PRODUCTS',
      farmProducts: farmProducts,
      priceTotal: priceTotal,
    });

    dispatchCart({
      type: 'REMOVE_ALL_SELECTED_DELIVERIES',
    });

    navigate('/cart');
  };

  const getFarmSummaryItems = (farm: FarmProduct) => {
    const productDiscount = farm.products.reduce((total, product) => {
      const productsTotal =
        (product.cartProduct?.storage_stock_price || 0) * product.amount;
      const productsDiscountsTotal =
        (product.cartProduct?.storage_stock_discount_price || 0) *
        product.amount;
      return total + productsTotal - productsDiscountsTotal;
    }, 0);

    return [
      {
        id: 'delivery.price',
        label: intl.formatMessage({ id: 'delivery.price' }),
        value: `${farm.farmProductsActualPrice.toFixed(2)} €`,
      },
      {
        id: 'general.discount',
        label: intl.formatMessage({ id: 'general.discount' }),
        value: `${productDiscount.toFixed(2)} €`,
      },
      {
        id: 'product.delivery',
        label: intl.formatMessage({ id: 'product.delivery' }),
        value:
          farm.selectedDelivery?.price !== undefined &&
          farm.selectedDelivery?.price !== null
            ? `${farm.selectedDelivery.price.toFixed(2)} €`
            : intl.formatMessage({ id: 'delivery.free_delivery' }),
      },
    ];
  };

  const summaryItems = [
    {
      id: 'cart_summary.price_no_discount',
      label: intl.formatMessage({ id: 'cart_summary.price_no_discount' }),
      value: `${priceNoDiscountTotal.toFixed(2)} €`,
    },
    {
      id: 'general.discount',
      label: intl.formatMessage({ id: 'general.discount' }),
      value: `${priceDiscountTotal.toFixed(2)} €`,
    },
    {
      id: 'delivery.price',
      label: intl.formatMessage({ id: 'cart_summary.PVN' }),
      value: `${vatRateTotal.toFixed(2)} €`,
    },
    ...vatSummaryItems,
    {
      id: 'cart_summary.sum_without_PVN',
      label: intl.formatMessage({ id: 'cart_summary.sum_without_PVN' }),
      value: `${productPriceWithoutVatRate.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.delivery_price',
      label: intl.formatMessage({ id: 'cart_summary.delivery_price' }),
      value: `${deliveryTotal.toFixed(2)} €`,
    },
  ];

  const summaryMobileItems = [
    {
      id: 'cart_summary.price_no_discount',
      label: intl.formatMessage({ id: 'cart_summary.price_no_discount' }),
      value: `${priceNoDiscountTotal.toFixed(2)} €`,
    },
    {
      id: 'general.discount',
      label: intl.formatMessage({ id: 'general.discount' }),
      value: `${priceDiscountTotal.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.sum_without_PVN',
      label: intl.formatMessage({ id: 'cart_summary.sum_without_PVN' }),
      value: `${productPriceWithoutVatRate.toFixed(2)} €`,
    },
    {
      id: 'delivery.price',
      label: intl.formatMessage({ id: 'cart_summary.PVN' }),
      value: `${vatRateTotal.toFixed(2)} €`,
    },
    ...vatSummaryItems,
    {
      id: 'cart_summary.delivery_price',
      label: intl.formatMessage({ id: 'cart_summary.delivery_price' }),
      value: `${deliveryTotal.toFixed(2)} €`,
    },
  ];

  const handleBuyProducts = () => {
    if (current >= 1 && userForm) {
      cartPay({
        farmProducts: farmProducts,
        priceTotal: priceTotal,
        personalInfo: userForm.getFieldsValue(),
      });

      return;
    }
    if (!form && current <= 1) {
      setCurrent(current + 1);
      return;
    }

    if (current <= 1) setCurrent(current + 1);
  };

  const allSummaryItems =
    windowWidth > 576 ? [...summaryItems] : [...summaryMobileItems];
  const cartSummaryClassName = windowWidth < 576 ? 'mobile-total' : 'total';

  return (
    <StyledCartSummary>
      <Form form={form} layout="vertical" onFinish={handleBuyProducts}>
        <div className={'cart-summary'}>
          <div className={'cart-summary-title'}>
            {intl.formatMessage({ id: 'cart_summary.order_ummary' })}
          </div>
          <div className={'cart-order-summary-wrapper'}>
            {isView ? (
              <div className={'cart-order-summary-collection'}>
                <CartOrderSummary
                  key={Math.random().toString(36)}
                  items={[
                    {
                      id: Math.random().toString(36),
                      label: intl.formatMessage({
                        id: 'cart_summary.order_number',
                      }),
                      value: '#' + searchParams.get('order_id') || '',
                    },
                    {
                      id: Math.random().toString(36),
                      label: intl.formatMessage({
                        id: 'cart_summary.order_date',
                      }),
                      value: searchParams.get('date') || '',
                    },
                  ]}
                  title={intl.formatMessage({ id: 'cart_summary.order' })}
                />
              </div>
            ) : (
              <div className={'cart-order-summary-collection'}>
                {farmProducts.map((farm) => (
                  <CartOrderSummary
                    key={farm.farm_id}
                    items={getFarmSummaryItems(farm)}
                    title={farm.farm_name}
                  />
                ))}
              </div>
            )}

            <CartOrderSummary
              items={allSummaryItems}
              className={cartSummaryClassName}
            />

            {!isView && (
              <>
                <div className={'cart-discount-coupon'}>
                  <Input
                    name={'discount_coupon'}
                    label={intl.formatMessage({
                      id: 'cart_summary.discount_coupon',
                    })}
                  />
                </div>
                <div className={'cart-discount-subscribe'}>
                  <Checkbox
                    name={'news_subscribe'}
                    label={intl.formatMessage({
                      id: 'cart_summary.news_subscribe',
                    })}
                  />
                </div>
              </>
            )}
            <div className={'cart-summary-submit-wrapper'}>
              <div className={'cart-summary-final-price'}>
                <div className={'cart-summary-final-price-title'}>
                  {intl.formatMessage({ id: 'cart_summary.totlal' })}
                </div>
                <div className={'cart-summary-price'}>
                  {priceTotal.toFixed(2)} €
                </div>
              </div>
              {isView && !searchParams.get('status') && (
                <Button
                  type="primary"
                  htmlType="submit"
                  className={'cart-summary-submit-btn-view order-again'}
                  label={intl.formatMessage({
                    id: 'button.order_again',
                  })}
                  onClick={() => handleOrderAgain()}
                />
              )}
              {!isView ? (
                <Button
                  disabled={isAllDeliveriesSelected !== farmProducts.length}
                  type="primary"
                  htmlType="submit"
                  className={'cart-summary-submit-btn'}
                  label={intl.formatMessage({ id: 'cart_summary.buy' })}
                />
              ) : (
                <>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={'cart-summary-submit-btn-view'}
                    label={intl.formatMessage({
                      id: 'cart_summary.download_invoice',
                    })}
                    onClick={() => {
                      farmProducts.map((farmProduct) => {
                        pdfData({
                          order_id: searchParams.get('order_id'),
                          farm_id: farmProduct.farm_id,
                        });
                      });
                    }}
                  />
                  <p>
                    {intl.formatMessage({
                      id: 'process_purchase.saved_purchase_message',
                    })}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        {isAllDeliveriesSelected === farmProducts.length &&
          isView === false && (
            <div className="total-summary">
              <p className="total-summary-text">
                <span>
                  {intl.formatMessage({ id: 'cart_summary.amount_be_paid' })}
                </span>
                <b>{priceTotal.toFixed(2)} €</b>
              </p>
              <Button
                icon={<Icon name={'arrow-right'} />}
                type="primary"
                htmlType="submit"
                iconPosition="end"
                label={intl.formatMessage({ id: 'cart_summary.pay' })}
              />
            </div>
          )}
      </Form>
    </StyledCartSummary>
  );
};

export default CartSummary;

const CartOrderSummary = ({
  items,
  title,
  className = '',
}: {
  items: {
    id: string;
    label: string;
    value: string;
  }[];
  title?: string;
  className?: string;
}) => (
  <div className={`cart-farm-order-summary ${className}`}>
    {title && <div className="farm-title">{title}</div>}
    <div className={'farm-summary-wrapper'}>
      {items.map((item) => (
        <div className={'cart-summary-container'} key={item.id}>
          <p className={'cart-summary-item'}>{item.label}</p>
          <p className={'cart-summary-item price'}>{item.value}</p>
        </div>
      ))}
    </div>
  </div>
);
