import styled from 'styled-components';
import Dragger from 'antd/lib/upload/Dragger';
export const StyledNewProductsGallery = styled(Dragger)`
  .ant-upload-text {
    a {
      color: ${(props) => props.theme.blue};
    }
  }
`;

export const StyledBusinessDraggerImage = styled('div')`
  display: flex;
  padding-top: 30px;
  flex-direction: row;
  font-family: ${(props) => props.theme.fontText};

  .custom-upload-list-item-image {
    width: 150px;
    height: 147px;
  }
  .overlay-wrap {
    position: relative;

    .ant-form-item {
      margin: 0;

      .ant-radio-inner {
        border-radius: 20px;
      }

      .ant-radio-wrapper {
        span {
          padding-inline-start: 2px;
        }
        .ant-radio-inner {
          padding-inline-start: 8px;
        }
      }
    }

    .overlay {
      display: flex;
      align-items: center;
      position: absolute;
      width: auto;
      height: 24px;
      border-radius: 12px;
      margin: 3px;
      padding-right: 5px;
      background-color: ${(props) => props.theme.white};
      z-index: 1;

      .checkbox-thumbnail {
        font-size: 14px;
        padding: 3px;
        border-radius: 12px;
      }
    }
  }

  .custom-upload-list-item-actions {
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    .action {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const StyledBusinessDraggerUploadList = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 40px;

  .ant-radio-group {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }
`;

export const StyledImagePreviews = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  max-width: 200px;
`;
