import { StyledCustomImage } from './style';
import React, { useEffect, useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { Spinner } from '../../Spinner';
import { useIntl } from 'react-intl';
import { Checkbox } from '../../Checkbox';
import { Radio } from '../../Radio';
import { FallbackImage } from '../fallBack/FallBackImage';

interface CustomImageProps {
  file: UploadFile;
  thumbUrl?: string;
}

export const CustomImage = ({ file, thumbUrl }: CustomImageProps) => {
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    setLoading(file.status === 'uploading');
  }, [file]);

  return (
    <Spinner spinning={loading} dontRender>
      <StyledCustomImage>
        <div key={file.uid} className="custom-upload-list-item">
          <div className={'overlay-wrap'}>
            <div className={'overlay'}>
              <Radio
                className="checkbox-thumbnail"
                label={intl.formatMessage({ id: 'images.thumbnail' })}
                value={file.uid + '_' + file.name}
              />
            </div>
          </div>
          <FallbackImage
            src={thumbUrl || file.url || file.preview}
            type={'ProductCard'}
            className="custom-upload-list-item-image"
            preview={false}
          />
        </div>
      </StyledCustomImage>
    </Spinner>
  );
};
