import { StyledLoginPage } from './style';
import { useAuth } from '../../../hooks/useAuth';
import { Spinner } from '../../../ui';
import { CompanyRegistrationForm } from 'ui/Form/auth/CompanyRegistrationForm';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const CompanyRegistration = () => {
  const { isFetchingUser, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.authenticated && !isFetchingUser) {
      navigate('/');
    }
  }, [user, isFetchingUser, navigate]);

  if (!user?.authenticated) return null;

  return (
    <Spinner spinning={isFetchingUser} dontRender>
      <StyledLoginPage>
        <div className={'content'}>
          <CompanyRegistrationForm />
        </div>
      </StyledLoginPage>
    </Spinner>
  );
};
