import React from 'react';
import { MyProfileStyledDrawer } from './style';

export interface MyProfileDrawerProps {
  open?: boolean;
  closable?: boolean;
  destroyOnClose?: boolean;
  children?: React.ReactNode;
  onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  footer?: React.ReactNode;
  title?: string;
  className?: string;
}

export const MyProfileDrawer = ({
  open,
  children,
  destroyOnClose,
  closable = true,
  onClose,
  footer,
  title,
  className,
}: MyProfileDrawerProps) => {
  return (
    <MyProfileStyledDrawer
      className={className}
      id={'mobile-menu-drawer'}
      open={open}
      width={'100%'}
      destroyOnClose={destroyOnClose}
      getContainer={document.getElementById('app-root')!}
      closable={closable}
      onClose={onClose}
      footer={footer}
      placement={'bottom'}
      title={title}
      height={'100%'}
      headerStyle={{ padding: 0 }}
      styles={{
        content: {
          borderRadius: 15,
          boxShadow: '0px 4px 84px #00000026',
          padding: '20px',
          paddingBottom: 150,
        },
        mask: { background: 'white' },
        wrapper: {
          boxShadow: 'none',
          bottom: 0,
          height: 'max-content',
        },
        header: {
          paddingLeft: '0px',
          paddingRight: '0px',
          paddingTop: '0px',
          paddingBottom: '15px',
          marginBottom: '15px',
          borderBottom: '2px solid rgba(5, 5, 5, 0.06)',
        },
        body: { padding: 0 },
      }}
      zIndex={1000}
    >
      {children}
    </MyProfileStyledDrawer>
  );
};
