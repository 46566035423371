import { StyledNewProductForm } from './style';
import { Input } from '../../Input';
import { useIntl } from 'react-intl';
import { Select, SelectOption } from '../../Select';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '../../DatePicker';
import { InputNumber } from '../../InputNumber';
import { CategorySelector } from '../../Select/Selectors/CategorySelectors';
import dayjs, { Dayjs } from 'dayjs';
import { Checkbox, Form } from 'antd';
import { TextArea } from '../../TextArea';
import { Icon } from 'ui';
import { MansLauksProduct } from 'ui/Drawer/Drawers/DrawerMansLauksProducts';
export interface unitTypes {
  id: number;
  name: string;
  short: string;
  type: string;
  status: string;
  extension: boolean;
}

interface CheckboxProps {
  label: string;
  value: string;
  disabled?: boolean;
}

interface NewProductFormProps {
  mainCategoryValue: number | undefined;
  subCategoryValue: number | undefined;
  subSubCategoryValue: number | undefined;
  initStepValue: number | undefined;
  mansLauksSelectedProduct: MansLauksProduct | undefined;
}

export const NewProductForm = ({
  mainCategoryValue,
  subCategoryValue,
  subSubCategoryValue,
  initStepValue,
  mansLauksSelectedProduct,
}: NewProductFormProps) => {
  const [selectedUnit, setSelectedUnit] = useState('');
  const [stepValue, setStepValue] = useState<number | undefined>(undefined);

  const intl = useIntl();

  const { data } = useQueryApiClient({
    request: {
      url: 'api/v2/products/form/attributes',
    },
  });

  useEffect(() => {
    setStepValue(initStepValue);
  }, [initStepValue]);

  const UnitSuffix = () => {
    return selectedUnit ? (
      <div className={'unit-suffix'}>
        <div>Є/{selectedUnit}</div>
      </div>
    ) : (
      <></>
    );
  };

  const MalaSuffix = () => {
    return mansLauksSelectedProduct ? (
      <div className={'unit-suffix mala'}>
        <div>{`${intl.formatMessage({ id: 'products.mans_lauks_amount' })} ${mansLauksSelectedProduct.amount}`}</div>
      </div>
    ) : (
      <></>
    );
  };

  const disabledDate = (current: Dayjs) => {
    return current && current < dayjs().startOf('day');
  };
  const isPackage = selectedUnit !== 'iep';
  const unitTypes: unitTypes[] = data && data['storageUnits'];
  const extensionUnitTypes =
    unitTypes &&
    unitTypes.filter((value) => {
      return value.extension === true;
    });

  const checkboxItems: CheckboxProps[] = [
    {
      label: intl.formatMessage({ id: 'general.gmo' }),
      value: 'gmo',
    },
    {
      label: intl.formatMessage({ id: 'general.e_market' }),
      value: 'emarket',
      disabled: true, // TODO: (NOGA-94) only admin can enable e-market when contract is signed
    },
    {
      label: intl.formatMessage({ id: 'general.visible' }),
      value: 'hidden',
    },
  ];

  return (
    <StyledNewProductForm>
      <div className={'name-title '}>
        <Input
          name={'name'}
          type={'text'}
          validations={'requiredText'}
          label={intl.formatMessage({ id: 'general.title' })}
          layout={'vertical'}
        />
      </div>
      <div className={'gr-cat-typ'}>
        <CategorySelector
          admin
          validations={'required'}
          mainCategoryValue={mainCategoryValue}
          subCategoryValue={subCategoryValue}
          subSubCategoryValue={subSubCategoryValue}
          selectIcon={
            <Icon name={'admin-product-input-arrow-down'} type={'small'} />
          }
        />
      </div>

      <div className={'unit-step col-djfgr'}>
        <div className={'unit'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'general.unit' })}
          </div>
          <Select
            suffixIcon={
              <Icon name={'admin-product-input-arrow-down'} type={'small'} />
            }
            showSearch={false}
            className={'scnd-tr'}
            name={'storage_unit'}
            onChange={(value: string) => setSelectedUnit(value)}
            validations={'required'}
            children={
              unitTypes &&
              unitTypes.map((unit, index) => {
                return (
                  <SelectOption value={unit.short} key={`${index} - opt`}>
                    {unit.name} {unit.short.length > 0 ? `(${unit.short})` : ''}
                  </SelectOption>
                );
              })
            }
          />
        </div>
        <div className={'step'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'general.step' })}
          </div>
          <InputNumber
            className={'scnd-tr'}
            name={'step'}
            validations={'correctStepForUnit'}
            controls={false}
            initialValue={stepValue}
          />
        </div>
      </div>

      {!isPackage && (
        <div className={'amount-merv col-djfgr'}>
          <div className={'amount'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'general.amount' })}
            </div>
            <InputNumber
              validations={['required']}
              className={'scnd-tr'}
              disabled={isPackage}
              name={'package_unit_val'}
              controls={false}
            />
          </div>
          <div className={'merv'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'general.unit' })}
            </div>
            <Select
              validations={'required'}
              className={'scnd-tr'}
              disabled={isPackage}
              name={'package_unit'}
              suffixIcon={
                <Icon name={'admin-product-input-arrow-down'} type={'small'} />
              }
              children={
                extensionUnitTypes &&
                extensionUnitTypes.map((unit, index) => {
                  return (
                    <SelectOption value={unit.short} key={`${index} - opt`}>
                      {unit.name}
                      {unit.short.length > 0 ? `(${unit.short})` : ''}
                    </SelectOption>
                  );
                })
              }
            />
          </div>
        </div>
      )}

      <div className={'minamount-maxamount col-djfgr'}>
        <div className={''}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'general.availability_amount' })}
          </div>
          <InputNumber
            suffix={<MalaSuffix />}
            className={'flex'}
            validations={'minZeroNumber'}
            controls={false}
            name={'storage_stock_count'}
            initialValue={1}
          />
        </div>
        <div className={'minamount'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'general.min_amount_one_purchase' })}
          </div>
          <InputNumber
            className={'flex'}
            controls={false}
            name={'storage_limit_min'}
            initialValue={1}
          />
        </div>
        <div className={'maxamount'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'general.max_amount' })}
          </div>
          <InputNumber
            className={'flex'}
            controls={false}
            name={'storage_limit_max'}
            initialValue={1}
          />
        </div>
      </div>

      <div className={'price-discount-time col-djfgr'}>
        <div className={'price'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'general.price' })}
          </div>
          <InputNumber
            className={'flex'}
            suffix={<UnitSuffix />}
            validations={['priceGreaterThanDiscount', 'minZeroNumber']}
            name={'storage_stock_price'}
            controls={false}
            initialValue={1}
          />
        </div>
        <div className={'discount'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'general.discount_price' })}
          </div>
          <InputNumber
            className={'flex'}
            suffix={<UnitSuffix />}
            name={'storage_stock_discount_price'}
            controls={false}
          />
        </div>
        <div className={'time'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'general.discount_time' })}
          </div>
          <DatePicker
            className={'flex'}
            name="discount_expiration_date"
            disabledDate={disabledDate}
            disabled={true} // Jauns ips "Jauna funkcionalitāte"
            suffixIcon={
              <Icon
                name={'admin-create-product-calendar-icon'}
                type={'medium'}
              />
            }
          />
        </div>
      </div>

      <div className={'checkboxes'}>
        <Form.Item name={'checkbox'} initialValue={['hidden']}>
          <Checkbox.Group>
            {checkboxItems.map((box, index) => {
              return (
                <Checkbox value={box.value} key={index} disabled={box.disabled}>
                  {box.label}
                </Checkbox>
              );
            })}
          </Checkbox.Group>
        </Form.Item>
      </div>

      <div className={'desc'}>
        <div className={'label'}>
          {intl.formatMessage({ id: 'general.description' })}
        </div>
        <TextArea name={'description'} />
      </div>

      <div className={'real-time'}>
        <div className={'label'}>
          {intl.formatMessage({ id: 'general.realisation_time' })}
        </div>
        <Input name={'realisation_time'} validations={'required'} />
      </div>
    </StyledNewProductForm>
  );
};
