import useFormValidation from '../../utils/useFormValidation';
import React from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { FormItemLayout } from 'antd/es/form/Form';

export interface TextAreaProps {
  placeholder?: string;
  required?: boolean;
  size?: 'large' | 'middle' | 'small';
  value?: string;
  rules?: Rule[];
  defaultValue?: string;
  maxLength?: number;
  allowClear?: boolean;
  label?: string;
  name?: (string | number)[] | string | number;
  className?: string;
  validateTrigger?: string | string[];
  noStyle?: boolean;
  initialValue?: string;
  disabled?: boolean;
  autoSize?: boolean;
  showCount?: boolean;
  validations?: string;
  layout?: FormItemLayout;
  areaClassName?: string;
}

const { TextArea: AntdTextArea } = Input;

export const TextArea = ({
  disabled = false,
  placeholder,
  name,
  required,
  size = 'large',
  value,
  defaultValue,
  maxLength = 255,
  allowClear,
  label,
  className,
  validateTrigger,
  noStyle,
  initialValue,
  autoSize,
  showCount,
  validations,
  layout,
  rules,
  areaClassName,
}: TextAreaProps) => {
  const { formValidations } = useFormValidation();

  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={validations ? formValidations(validations) : rules}
      className={className}
      validateTrigger={validateTrigger}
      noStyle={noStyle}
      layout={layout}
    >
      <AntdTextArea
        className={areaClassName}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        size={size}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxLength}
        allowClear={allowClear}
        autoSize={autoSize}
        showCount={showCount}
      />
    </Form.Item>
  );
};
