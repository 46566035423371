import { StyledStatusComponent } from './style';

interface StatusComponentProps {
  step: number;
  label: string;
  selected: boolean;
}

export const StatusComponent = ({
  step,
  selected,
  label,
}: StatusComponentProps) => {
  return (
    <StyledStatusComponent>
      <div className={selected ? 'selected step' : 'step'}>{step}</div>
      <div className={'label'}>{label}</div>
    </StyledStatusComponent>
  );
};
