import React, { useReducer, createContext, useContext } from 'react';
import { FarmDeliveries } from 'types/FarmData';
import { ProductData } from 'types/ProductData';
import { validateProduct } from 'utils/productValidationHelper';

export interface ParcelLocation {
  id?: number;
  name?: string;
  address?: string;
}

export interface SelectedDelivery {
  id?: number;
  city?: string;
  address?: string;
  firm_name?: string;
  name?: string;
  selectedDeliveryId?: number;
  price: number | null;
}

export interface CartProduct {
  cartProduct?: ProductData;
  amount: number;
  vat?: number;
  stock_total?: number;
}

export interface FarmProduct {
  farm_id: number;
  farm_name?: string;
  farmProductsActualPrice: number;
  products: CartProduct[];
  farmDeliveries: FarmDeliveries[] | undefined;
  selectedDelivery?: SelectedDelivery;
}

interface CartState {
  farmProducts: FarmProduct[];
  priceTotal: number;
}

type CartAction =
  | { type: 'ADD_ITEM'; product: ProductData | undefined; amount: number }
  | { type: 'REMOVE_ITEM'; productId: number }
  | { type: 'CLEAR_CART' }
  | {
      type: 'REFETCH_CART_PRODUCTS';
      farmProducts: FarmProduct[];
      priceTotal: number;
    }
  | {
      type: 'SET_DELIVERY';
      farmId: number;
      delivery: SelectedDelivery;
    }
  | { type: 'REMOVE_ALL_SELECTED_DELIVERIES' };

const CartStateContext = createContext<CartState | undefined>(undefined);
const CartDispatchContext = createContext<
  React.Dispatch<CartAction> | undefined
>(undefined);

function cartReducer(state: CartState, action: CartAction): CartState {
  switch (action.type) {
    case 'ADD_ITEM': {
      const { product, amount } = action;
      if (!product) return state;

      const { farm_id, farm_name, farm } = product;
      let updatedFarmProducts: FarmProduct[];

      const farmIndex = state.farmProducts.findIndex(
        (farmProduct) => farmProduct.farm_id === farm_id
      );

      if (farmIndex >= 0) {
        const farmProduct = state.farmProducts[farmIndex];
        const productIndex = farmProduct.products.findIndex(
          (cartProduct) => cartProduct.cartProduct?.id === product.id
        );

        let updatedProducts: CartProduct[];
        if (productIndex >= 0) {
          updatedProducts = [...farmProduct.products];
          const validatedProduct = validateProduct({
            product,
            count: updatedProducts[productIndex].amount + amount,
          });
          updatedProducts[productIndex] = {
            ...updatedProducts[productIndex],
            stock_total: validatedProduct.stock_total,
            amount: validatedProduct.stock_count,
          };
        } else {
          const validatedProduct = validateProduct({ product, count: amount });
          updatedProducts = [
            ...farmProduct.products,
            {
              cartProduct: product,
              amount: validatedProduct.stock_count,
              stock_total: validatedProduct.stock_total,
            },
          ];
        }

        const farmProductsActualPrice = updatedProducts.reduce(
          (total, cartProduct) =>
            total +
            (cartProduct.stock_total ??
              cartProduct.cartProduct?.storage_stock_discount_price ??
              cartProduct.cartProduct?.storage_stock_price ??
              0),
          0
        );

        updatedFarmProducts = [
          ...state.farmProducts.slice(0, farmIndex),
          {
            ...farmProduct,
            products: updatedProducts,
            farmProductsActualPrice: farmProductsActualPrice,
          },
          ...state.farmProducts.slice(farmIndex + 1),
        ];
      } else {
        const validatedProduct = validateProduct({
          product,
          count: product.amount ?? 1,
        });
        const newFarmProduct: FarmProduct = {
          farm_id,
          farm_name,
          products: [
            {
              cartProduct: product,
              amount: validatedProduct.stock_count,
              stock_total: validatedProduct.stock_total,
            },
          ],
          farmProductsActualPrice: validatedProduct.stock_total,
          farmDeliveries: farm?.deliveries,
        };

        updatedFarmProducts = [...state.farmProducts, newFarmProduct];
      }

      const newPriceTotal = updatedFarmProducts.reduce(
        (total, farm) =>
          total +
          (farm.farmProductsActualPrice || 0) +
          (farm.selectedDelivery?.price || 0),
        0
      );

      localStorage.setItem('farmProducts', JSON.stringify(updatedFarmProducts));
      localStorage.setItem('priceTotal', JSON.stringify(newPriceTotal));

      return {
        ...state,
        farmProducts: updatedFarmProducts,
        priceTotal: newPriceTotal,
      };
    }

    case 'REMOVE_ITEM': {
      const updatedFarmProducts = state.farmProducts
        .map((farm) => {
          const updatedProducts = farm.products.filter(
            (cartProduct) => cartProduct.cartProduct?.id !== action.productId
          );

          const farmProductsActualPrice = updatedProducts.reduce(
            (total, cartProduct) => {
              const product = cartProduct.cartProduct ?? ({} as ProductData);
              const validatedProduct = validateProduct({
                product,
                count: cartProduct.amount,
              });
              return total + (validatedProduct.stock_total || 0);
            },
            0
          );

          return updatedProducts.length > 0
            ? {
                ...farm,
                products: updatedProducts,
                farmProductsActualPrice: farmProductsActualPrice,
              }
            : null;
        })
        .filter(Boolean) as FarmProduct[];

      const newPriceTotal = updatedFarmProducts.reduce(
        (total, farm) =>
          total +
          (farm.farmProductsActualPrice || 0) +
          (farm.selectedDelivery?.price || 0),
        0
      );

      localStorage.setItem('farmProducts', JSON.stringify(updatedFarmProducts));
      localStorage.setItem('priceTotal', JSON.stringify(newPriceTotal));

      return {
        ...state,
        farmProducts: updatedFarmProducts,
        priceTotal: newPriceTotal,
      };
    }

    case 'CLEAR_CART': {
      localStorage.removeItem('farmProducts');
      localStorage.removeItem('priceTotal');
      return { farmProducts: [], priceTotal: 0 };
    }

    case 'REFETCH_CART_PRODUCTS': {
      const { farmProducts, priceTotal } = action;

      localStorage.setItem('farmProducts', JSON.stringify(farmProducts));
      localStorage.setItem('priceTotal', JSON.stringify(priceTotal));

      return {
        ...state,
        farmProducts: farmProducts,
        priceTotal: priceTotal,
      };
    }

    case 'SET_DELIVERY': {
      const { farmId, delivery } = action;

      if (delivery.price === null) {
        const updatedFarmProducts = state.farmProducts.map((farmProduct) => {
          if (farmProduct.farm_id === farmId) {
            return {
              ...farmProduct,
              selectedDelivery: delivery,
            };
          }
          return farmProduct;
        });

        const newPriceTotal = updatedFarmProducts.reduce(
          (total, farm) =>
            total +
            (farm.farmProductsActualPrice || 0) +
            (farm.selectedDelivery?.price || 0),
          0
        );

        localStorage.setItem(
          'farmProducts',
          JSON.stringify(updatedFarmProducts)
        );
        localStorage.setItem('priceTotal', JSON.stringify(newPriceTotal));

        return {
          ...state,
          farmProducts: updatedFarmProducts,
          priceTotal: newPriceTotal,
        };
      }

      const updatedFarmProducts = state.farmProducts.map((farmProduct) => {
        if (farmProduct.farm_id === farmId) {
          return {
            ...farmProduct,
            selectedDelivery: delivery,
          };
        }
        return farmProduct;
      });

      const newPriceTotal = updatedFarmProducts.reduce(
        (total, farm) =>
          total +
          (farm.farmProductsActualPrice || 0) +
          (farm.selectedDelivery?.price || 0),
        0
      );

      localStorage.setItem('farmProducts', JSON.stringify(updatedFarmProducts));
      localStorage.setItem('priceTotal', JSON.stringify(newPriceTotal));

      return {
        ...state,
        farmProducts: updatedFarmProducts,
        priceTotal: newPriceTotal,
      };
    }

    case 'REMOVE_ALL_SELECTED_DELIVERIES': {
      const updatedFarmProducts = state.farmProducts.map((farmProduct) => {
        return {
          ...farmProduct,
          selectedDelivery: undefined,
        };
      });

      const newPriceTotal = updatedFarmProducts.reduce(
        (total, farm) => total + (farm.farmProductsActualPrice || 0),
        0
      );

      localStorage.setItem('farmProducts', JSON.stringify(updatedFarmProducts));
      localStorage.setItem('priceTotal', JSON.stringify(newPriceTotal));

      return {
        ...state,
        farmProducts: updatedFarmProducts,
        priceTotal: newPriceTotal,
      };
    }
  }
}

export function CartProvider({ children }: { children: React.ReactNode }) {
  const initialState: CartState = {
    farmProducts: JSON.parse(localStorage.getItem('farmProducts') || '[]'),
    priceTotal: JSON.parse(localStorage.getItem('priceTotal') || '0'),
  };

  const [state, dispatch] = useReducer(cartReducer, initialState);

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
}

export function useCartState() {
  const context = useContext(CartStateContext);
  if (context === undefined) {
    throw new Error('useCartState must be used within a CartProvider');
  }
  return context;
}

export function useCartDispatch() {
  const context = useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error('useCartDispatch must be used within a CartProvider');
  }
  return context;
}
