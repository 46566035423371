import { styled } from 'styled-components';

export const StyledCartPage = styled('div')`
  .container {
    padding: 0 0 200px 0;
    background-color: ${(props) => props.theme.whiteBackground};
    border-radius: 15px;
  }

  .cart-page-title {
    font-size: 25px;
    font-family: ${(props) => props.theme.fontText};

    h1 {
      margin-block-start: 0;
      margin-block-end: 0;
      padding-top: 0.67em;
      padding-bottom: 0.67em;
      font-weight: 500;
    }
  }

  .back-to-catalog {
    display: none;
  }

  .cart-page-title {
    display: block;
  }

  .cart-page-title.mobile {
    color: ${(props) => props.theme.brand01};
    display: none;
    h1 {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 1600px) {
    .container {
      padding: 30px 140px 0 140px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .container {
      padding: 20px 70px 20px 70px;
    }
  }

  @media only screen and (max-width: 800px) {
    .container {
      padding: 6px 20px 0 20px;
    }
  }

  @media only screen and (max-width: 576px) {
    .container {
      padding-bottom: 20px;
    }

    .back-to-catalog {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 37px 0px 17px 20px;
      border-bottom: 1px solid ${(props) => props.theme.lightGrayBorder};
      font-size: 14px;
      font-weight: 400;
      div {
        display: flex;
        height: 17px;
        width: 17px;
      }
    }

    div[class*='ant-steps'] {
      display: none;
    }

    .cart-page-title {
      display: none;
      font-size: 18px;
    }

    .cart-page-title h1 {
      margin: 10px 0;
    }

    .cart-page-title {
      display: none;
    }

    .cart-page-title.mobile {
      display: block;
    }
  }
`;
