import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DefaultLayout from '../../components/DefaultLayout';
import Filter from '../../components/Filter';
import {
  Button,
  Checkbox,
  Input,
  Select,
  SelectOption,
  Table,
  Tabs,
} from '../../../ui';
import { ProductPrice } from '../../../utils/productCardHelpers';
import { ProductData } from 'types/ProductData';
import { CategorySelector } from '../../../ui/Select/Selectors/CategorySelectors';
import { options } from '../../../config/config';
import { StyledButtonList } from '../../../styles/buttonList';
import { DrawerShareProduct } from '../../../ui/Drawer/Drawers/DrawerShareProduct';
import { DrawerCreateProduct } from '../../../ui/Drawer/Drawers/DrawerCreateProduct';
import { ShareProductForm } from '../../../ui/Form/products/ShareProductForm';
import { useAuth } from '../../../hooks/useAuth';
import { StyledProductsPage } from './style';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import { useSearchParams } from 'react-router-dom';
import { DrawerMansLauksProducts } from 'ui/Drawer/Drawers/DrawerMansLauksProducts';
interface linkTokenResponse {
  farm?: number;
  linkSuccessful: boolean;
}

export const Products = () => {
  const [advancedSearch, setAdvancedSearch] = useState({});
  const [mansLauksProducts, setMansLauksProducts] = useState([]);
  const [returnedProducts, setReturnedProducts] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenCreateDrawer, setIsOpenCreteDrawer] = useState(false);
  const [isOpenMansLauksDrawer, setIsOpenMansLauksDrawer] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<
    number | undefined
  >();
  const [mansLauksToken, setMansLauksToken] = useState<string>();
  const [isSuccessLink, setIsSuccessLink] = useState<boolean>(false);
  const [reload, setReload] = useState<number>(0);
  const { user } = useAuth();
  const intl = useIntl();
  const farmId = user?.farmId;
  const per_page = options.filters.pageSizeOptions;
  const frontendUrl = window.runConfig.frontendUrl;
  const mansLauksLink = window.runConfig.mansLauksUrl;

  const mansLauksHref = `${mansLauksLink}/tokens/create/?name=NovadaGarša&return-url=${frontendUrl}/admin/products`;

  const stableFilter = React.useMemo(
    () => ({ ...advancedSearch, farm: user?.farmId }),
    [advancedSearch, user?.farmId]
  );

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const handleGetProducts = React.useCallback(
    (data: any) => {
      setReturnedProducts(data);
    },
    [setReturnedProducts]
  );

  const handleCloseMansLauksDrawer = () => {
    setIsOpenMansLauksDrawer(!isOpenMansLauksDrawer);
  };

  const handleMansLauksClick = () => {
    if (!mansLauksProducts || mansLauksProducts.length == 0) {
      window.location.href = mansLauksHref;
    } else {
      setIsOpenMansLauksDrawer(!isOpenMansLauksDrawer);
    }
  };

  const { appendData: getMansLauksProducts } = useQueryApiClient({
    request: {
      url: `/api/v2/manslauks/${farmId}/products`,
      disableOnMount: !isSuccessLink,
      method: 'GET',
    },
    onSuccess: (response) => {
      setMansLauksProducts(response);
    },
  });

  const { appendData: linkToken } = useQueryApiClient({
    request: {
      url: `/api/v2/manslauks/${farmId}/link`,
      method: 'POST',
    },
    onSuccess: (response: linkTokenResponse) => {
      if (response.linkSuccessful) {
        setIsSuccessLink(true);
        getMansLauksProducts();
      }
    },
  });

  useEffect(() => {
    if (token) {
      setMansLauksToken(token);
      if (!user?.isMansLauksLinked) {
        linkToken({
          token: token,
        });
      }
    }
    if (user?.isMansLauksLinked) {
      getMansLauksProducts();
    }
  }, [token, user?.isMansLauksLinked]);

  const { appendData: deleteProd } = useQueryApiClient({
    request: {
      url: `api/v2/products/:id`,
      method: 'DELETE',
    },
  });

  const handleFilterChange = (values: any) => {
    setAdvancedSearch((prevFilters) => ({
      ...prevFilters,
      ...values,
      farm: user?.farmId,
    }));
  };

  const handleOpenCreateDrawer = () => {
    setIsOpenCreteDrawer(true);
  };

  const handleCloseCreateDrawer = () => {
    setIsOpenCreteDrawer(false);
    setSelectedProductId(undefined);
  };

  const handleOpenDrawer = (productId: number) => {
    setSelectedProductId(productId);
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedProductId(undefined);
  };

  const renderBooleanCheckmarks = (value: boolean) => {
    return <Checkbox checked={value} />;
  };

  const handlePageSizeChange = (value: any) => {
    setPerPage(value);
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.category' }),
      dataIndex: 'category_id',
      render: (value: number) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.count' }),
      dataIndex: 'storage_stock_count',
      render: (value: number) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.price' }),
      render: (text: any, record: ProductData | undefined) => (
        <ProductPrice
          displayFlex
          product={record}
          productHasDiscount={!!record?.storage_stock_discount_price}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'products.availability' }),
      dataIndex: 'availability',
      render: (value: number) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.visibility' }),
      dataIndex: 'hidden',
      render: (value: boolean) => renderBooleanCheckmarks(!value),
    },
    {
      title: intl.formatMessage({ id: 'products.e_market' }),
      dataIndex: 'emarket',
      render: (value: boolean) => renderBooleanCheckmarks(value),
    },
    {
      title: intl.formatMessage({ id: 'products.share' }),
      render: (record: ProductData | undefined) => (
        <img
          onClick={() => handleOpenDrawer(record?.id as number)}
          src={'/share_icon.svg'}
          alt="Share"
          className={'pointer'}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'products.sold' }),
      dataIndex: 'sales_count',
      render: (value: number) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.actions' }),
      render: (record: ProductData) => (
        <div>
          <Button
            type="primary"
            onClick={() => {
              setSelectedProductId(record.id);
              setIsOpenCreteDrawer(true);
            }}
            label={intl.formatMessage({ id: 'products.edit' })}
            ability={'farm_edit'}
          />
          <Button
            type="default"
            onClick={() => {
              if (
                window.confirm(
                  `${intl.formatMessage({ id: 'confirmation.delete' })} : ${
                    record.name
                  }`
                )
              ) {
                deleteProd(null, { id: record.id });
                setReload((old) => old + 1);
              }
            }}
            label={intl.formatMessage({ id: 'products.delete' })}
            ability={'farm_delete'}
          />
        </div>
      ),
    },
  ];

  const items = [
    {
      key: 'all_products',
      label: intl.formatMessage({ id: 'navigation.products' }),
      children: (
        <>
          <Filter onFinish={handleFilterChange} products>
            <div className={'left-side'}>
              <Input
                label={intl.formatMessage({ id: 'general.search' })}
                name="search"
              />
              <Button
                type="primary"
                label={intl.formatMessage({ id: 'general.select' })}
                htmlType="submit"
              />
              <CategorySelector displayFlex admin />

              <Select
                name={'emarket'}
                showSearch={false}
                label={intl.formatMessage({ id: 'products.e_market' })}
              >
                <SelectOption value={true}>
                  {intl.formatMessage({
                    id: 'products.visible',
                  })}
                </SelectOption>
                <SelectOption value={false}>
                  {intl.formatMessage({
                    id: 'products.hidden',
                  })}
                </SelectOption>
              </Select>
            </div>
            <div className={'right-side'}>
              <Select
                onChange={handlePageSizeChange}
                name={'page_size'}
                showSearch={false}
                label={intl.formatMessage({ id: 'products.per_page' })}
                initialValue={perPage}
              >
                {per_page.map((opt, index) => (
                  <SelectOption key={`opt-${index}`} value={opt}>
                    {opt}
                  </SelectOption>
                ))}
              </Select>
            </div>
          </Filter>
          {user?.farmId && (
            <Table
              reload={reload}
              url="/api/v2/products"
              columns={columns}
              filter={stableFilter}
              page_size={perPage}
              options={{ view: 'admin' }}
              saveData={handleGetProducts}
            />
          )}
          <DrawerShareProduct
            open={isOpenDrawer}
            onClose={handleCloseDrawer}
            children={
              <ShareProductForm
                productId={selectedProductId ? selectedProductId : undefined}
              />
            }
            title={intl.formatMessage({ id: 'drawers.share_product' })}
          />
          <DrawerCreateProduct
            open={isOpenCreateDrawer}
            onClose={handleCloseCreateDrawer}
            farmId={user?.farmId}
            closeDrawer={handleCloseCreateDrawer}
            productId={selectedProductId}
            setReload={setReload}
            mansLauksProducts={mansLauksProducts}
            products={returnedProducts}
          />
          {mansLauksProducts && returnedProducts && (
            <DrawerMansLauksProducts
              open={isOpenMansLauksDrawer}
              onClose={handleCloseMansLauksDrawer}
              mansLauksProducts={mansLauksProducts}
              products={returnedProducts}
              getMansLauksProducts={getMansLauksProducts}
              setReload={setReload}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.products' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <StyledProductsPage>
          <Tabs
            items={items}
            tabBarExtraContent={
              <StyledButtonList>
                <Button
                  label={intl.formatMessage({ id: 'products.create' })}
                  type="primary"
                  onClick={handleOpenCreateDrawer}
                  ability={'farm_add'}
                />
                <Button
                  label={intl.formatMessage({ id: 'products.mans_lauks' })}
                  type="default"
                  onClick={handleMansLauksClick}
                  ability={'farm_add'}
                />
              </StyledButtonList>
            }
          />
        </StyledProductsPage>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};
