import { StyledLoginForm } from './style';
import { Input } from '../../Input';
import { Button } from '../../Button';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import useQueryApiClient from 'utils/useQueryApiClient';
import { LlkcResponse } from 'interfaces/BackendModels';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

export const CompanyRegistrationForm = ({}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [form] = StyledLoginForm.useForm();

  const { appendData: findFarm } = useQueryApiClient({
    request: {
      url: '/api/v2/llkc/farms',
      disableOnMount: true,
    },
    onSuccess: (response, passOnSuccess) =>
      handleFindFarmResponse(response.data, passOnSuccess.contact_email),
    onError: (error) => {
      if (error.code === 400)
        message.error(
          intl.formatMessage({ id: 'message.error_incorrect_regnr_pvd' })
        );
    },
  });

  const handleFindFarmResponse = (
    response: LlkcResponse,
    contact_email: string
  ) => {
    if (response.is_empty) {
      return message.error(
        intl.formatMessage({ id: 'message.farm_not_found' })
      );
    }

    createFarm({ ...response, contact_email: contact_email, public: true });
    form.resetFields();
  };

  const { appendData: createFarm } = useQueryApiClient({
    request: {
      url: '/api/v2/farms/create',
      method: 'POST',
    },
    onSuccess: () => {
      message.success(intl.formatMessage({ id: 'message.success_save_farm' }));
      navigate('/');
    },
    onError: (error) => {
      if (error?.code === 409) {
        message.error(
          intl.formatMessage({ id: 'message.farm_already_exists' })
        );
      } else {
        message.error(intl.formatMessage({ id: 'message.error_farm_create' }));
      }
    },
  });

  const handleAddFarm = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const email = values.contact_email;

      findFarm(values, [], {
        pvd_number: values.pvd_number,
        contact_email: email,
      });
    } catch (e: any) {
      message.error(intl.formatMessage({ id: 'message.error' }));
    }
  };

  return (
    <StyledLoginForm form={form}>
      <div className={'inputs'}>
        <div className={'registration-title-wrapper'}>
          <div className={'title'}>
            {intl.formatMessage({ id: 'registration_company.title' })}
          </div>
          <p className="description">
            {intl.formatMessage({ id: 'registration_company.text' })}
          </p>
        </div>
        <div className={'registration-input-wrapper'}>
          <div className={'pw'}>
            <span className="label">
              {intl.formatMessage({ id: 'general.e_mail' })}
            </span>
            <Input
              size={'large'}
              name={'contact_email'}
              validations={'email'}
            />
          </div>
          <div className={'pw'}>
            <span className="label">
              {intl.formatMessage({
                id: 'registration_company.registration_number',
              })}
            </span>
            <Input size={'large'} name={'regNr'} validations={['required']} />
          </div>
          <div className={'pw'}>
            <span className="label">
              {intl.formatMessage({
                id: 'registration_company.PVD_number',
              })}
            </span>
            <Input
              size={'large'}
              name={'pvd_number'}
              validations={'pvdNumber'}
            />
          </div>
        </div>
        <div className={'registration-button-wrapper'}>
          <Button
            className={'cstm-btn submit'}
            label={intl.formatMessage({
              id: 'registration_company.create_account',
            })}
            size={'large'}
            onClick={handleAddFarm}
          />
          <Button
            type={'text'}
            className={'cancel-back-to-landing-page'}
            label={intl.formatMessage({
              id: 'general.cancel',
            })}
            onClick={() => navigate('/')}
          />
        </div>
      </div>
    </StyledLoginForm>
  );
};
