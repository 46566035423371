import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomImage } from './CustomImage';
import { StyledEditCustomImage } from './style';
import { RadioGroup } from 'ui/RadioGroup';
import { Form, RadioChangeEvent } from 'antd';
import { useIntl } from 'react-intl';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { Upload, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';

interface EditCustomImageProps {
  file: UploadFile;
  onEdit: (file: UploadFile) => void;
  onRemove: (file: UploadFile) => void;
}

export const EditCustomImage: React.FC<EditCustomImageProps> = ({
  file,
  onEdit,
  onRemove,
}) => {
  const intl = useIntl();

  const handleEdit = useCallback(
    async ({ file: uploadedFile }: { file: UploadFile }) => {
      onRemove(file);
      onEdit(uploadedFile.originFileObj as RcFile);
    },
    [file, onEdit, onRemove]
  );

  const hiddenUploadProps: UploadProps = useMemo(
    () => ({
      name: 'logo_image',
      accept: 'image/*',
      multiple: false,
      showUploadList: false,
      fileList: file ? [file] : [],
      onChange: handleEdit,
      customRequest: ({ onSuccess }) => {
        onSuccess && onSuccess('ok');
      },
    }),
    [file, handleEdit]
  );

  const wrapWithCrop = (children: any) => {
    return (
      <ImgCrop rotationSlider showReset>
        {children}
      </ImgCrop>
    );
  };
  return (
    <StyledEditCustomImage className={'action'}>
      {wrapWithCrop(
        <Upload {...hiddenUploadProps}>
          <div className={'action'}>
            {intl.formatMessage({ id: 'general.edit' })}
          </div>
        </Upload>
      )}
    </StyledEditCustomImage>
  );
};
