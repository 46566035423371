import { FarmData } from 'types/FarmData';
import { FC, useState } from 'react';
import { Carousel, Icon } from 'ui';
import { FarmGaleriesWrapper, GlobalStyle } from './style';
import { FallbackImage } from 'ui/UploadImage/fallBack/FallBackImage';

interface Props {
  farm: FarmData | undefined;
}

const Galeries: FC<Props> = ({ farm }) => {
  const [isOpenImageGallery, setIsOpenImageGallery] = useState(true);

  const handleCloseClick = () => {
    setIsOpenImageGallery(false);
  };

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      <GlobalStyle isModalActive={isOpenImageGallery} />
      <FarmGaleriesWrapper
        className="farm-gallery"
        galeryCount={farm?.images ? farm?.images.length : 0}
      >
        <div className="mobile-farm-galeries">
          {farm?.images.map((galery, idx) => (
            <div className="grid-item" key={idx}>
              <FallbackImage
                src={galery.url_original ?? ''}
                preview={false}
                type="FarmGalery"
              />
            </div>
          ))}
        </div>
        {isOpenImageGallery && (
          <div
            className={'image-gallery-wrapper-wrapper'}
            onClick={handleCloseClick}
          >
            <div className={'image-gallery-wrapper'} onClick={stopPropagation}>
              <Carousel
                productImages={farm?.images}
                arrows
                customNextArrow={<Icon name="next-arrow-icon" />}
                customPrevArrow={<Icon name="prew-arrow-icon" />}
              />
              <div className={'close-image-btn'} onClick={handleCloseClick}>
                <Icon name="close-Icon" />
              </div>
            </div>
          </div>
        )}
      </FarmGaleriesWrapper>
    </>
  );
};

export default Galeries;
