import { useIntl } from 'react-intl';
import {
  ProfileMenuContainer,
  WelcomeText,
  MenuItem,
  AddCompanyButton,
} from './style';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import { useState } from 'react';
import { Form, message } from 'antd';
import { FarmCreate } from 'ui/Form/FarmCreate';

interface Props {
  fullName: string;
}

const ProfileMenu = ({ fullName }: Props) => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const intl = useIntl();
  const { user } = useAuth();
  const [form] = Form.useForm();

  const { appendData } = useQueryApiClient({
    request: {
      url: '/api/v2/logout',
      method: 'POST',
    },
    onSuccess: () => (window.location.href = '/'),
  });

  const { data } = useQueryApiClient({
    request: {
      url: '/api/v2/user/order',
      data: {
        count: true,
      },
    },
  });

  const onCloseModal = () => {
    setShowCreateModal(false);
    form.resetFields();
  };

  const isAdminPanel = user?.isAdmin || user?.isFarmOwner;
  return (
    <ProfileMenuContainer>
      <WelcomeText>
        {intl.formatMessage({ id: 'general.hello' })}, <br />
        <span>{fullName}!</span>
      </WelcomeText>
      <hr />
      <MenuItem onClick={() => navigate('/profile/my_orders')}>
        {intl.formatMessage({ id: 'general.my_orders' })}
        {''}
        <span className="order-count">({data})</span>
      </MenuItem>
      <MenuItem onClick={() => navigate('/profile/profile_information')}>
        {intl.formatMessage({ id: 'general.profile_information' })}
      </MenuItem>
      {isAdminPanel && (
        <MenuItem>
          <a className={'admin-link'} href={'/admin'}>
            {intl.formatMessage({ id: 'general.admin' })}
          </a>
        </MenuItem>
      )}
      <MenuItem onClick={() => appendData()}>
        {intl.formatMessage({ id: 'general.exit' })}
      </MenuItem>
      <AddCompanyButton
        ability={'profile_panel'}
        onClick={() => setShowCreateModal(true)}
        label={intl.formatMessage({ id: 'farms.add_farm' })}
      />
      <Form form={form}>
        <FarmCreate
          showCreateModal={showCreateModal}
          onCloseModal={onCloseModal}
          setShowCreateModal={setShowCreateModal}
        />
      </Form>
    </ProfileMenuContainer>
  );
};

export default ProfileMenu;
