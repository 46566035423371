import React, {
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, message, Upload, UploadFile, UploadProps } from 'antd';
import { useIntl } from 'react-intl';
import { StyledCreateEditProductsGallery } from './style';
import { getBase64 } from '../../../utils/productCardHelpers';
import { CustomUploadList } from './CustomUploadList';
import ImgCrop from 'antd-img-crop';
import { options } from '../../../config/config';

interface CreateEditProductsGalleryProps {
  onValidate: (isValid: boolean) => void;
  onFileListChange?: Dispatch<any>;
  fileList: UploadFile[];
  multiple?: boolean;
}

export const CreateEditProductsGallery = (
  newProductProps: CreateEditProductsGalleryProps
) => {
  const {
    onValidate,
    onFileListChange,
    fileList,
    multiple = true,
  } = newProductProps;

  const [thumbUrls, setThumbUrls] = useState<{ [uid: string]: string }>({});
  const intl = useIntl();

  const formInstance = Form.useFormInstance();

  const allowedTypes = options.images.allowedFormats;
  const maxImages = multiple ? options.images.maxImages : 1;

  useEffect(() => {
    if (fileList.length === 0) {
      setThumbUrls({});
    }
  }, [fileList]);

  const renderAllowedTypes = () => {
    const types = allowedTypes.map((type) => type.replace('image/', ''));

    return types.join(',');
  };

  const handleChange = async (info: { fileList: UploadFile[] }) => {
    let newList = [...info.fileList];

    newList = newList.filter((file) => {
      const isValidType = allowedTypes.includes(file.type || '');
      if (!isValidType) {
        message.error(
          `${intl.formatMessage({
            id: 'images.allowed_types',
          })}${renderAllowedTypes()}`
        );
      }
      return isValidType;
    });

    if (maxImages && newList.length > maxImages) {
      message.warning(
        `${intl.formatMessage({ id: 'images.max_images' })}${maxImages}`
      );
      newList = newList.slice(0, maxImages);
    }

    const updatedThumbUrls: { [uid: string]: string } = {};
    await Promise.all(
      newList.map(async (file: UploadFile) => {
        if (!file.url && !file.thumbUrl && file.originFileObj) {
          const thumbUrl = await getBase64(file.originFileObj as File);
          updatedThumbUrls[file.uid] = thumbUrl;
        } else if (file.thumbUrl) {
          updatedThumbUrls[file.uid] = file.thumbUrl;
        }
      })
    );

    setThumbUrls((prev) => ({ ...prev, ...updatedThumbUrls }));
    onValidate(newList.length > 0);
    if (onFileListChange) {
      onFileListChange(newList);
    }
  };

  const handleEditBeforeUpload = useCallback(
    async (file: any) => {
      const croppedFile: UploadFile = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: URL.createObjectURL(file),
        originFileObj: file,
      };

      onFileListChange &&
        onFileListChange((files: UploadFile[]) => [...files, croppedFile]);

      let updatedThumbUrls: { [uid: string]: string } = {};
      await Promise.all(
        fileList.map(async (file: UploadFile) => {
          if (!file.url && !file.thumbUrl && file.originFileObj) {
            updatedThumbUrls[file.uid] = await getBase64(
              file.originFileObj as File
            );
          } else if (file.thumbUrl) {
            updatedThumbUrls[file.uid] = file.thumbUrl;
          }
        })
      );

      setThumbUrls((prev) => ({ ...prev, ...updatedThumbUrls }));
      message.success(intl.formatMessage({ id: 'images.edited_successfully' }));
      return false;
    },
    [onFileListChange, fileList]
  );

  const handleRemove = useCallback(
    async (file: any) => {
      const newFileList = fileList.filter((item) => item.uid !== file.uid);
      const newThumbUrls = { ...thumbUrls };

      delete newThumbUrls[file.uid];
      setThumbUrls(newThumbUrls);
      formInstance.setFieldValue('selected-image', null);
      if (onFileListChange) {
        onFileListChange(newFileList);
      }
    },
    [onFileListChange, fileList, thumbUrls]
  );

  const props: UploadProps = {
    name: multiple ? 'image' : 'logo_image',
    multiple: multiple,
    onChange: handleChange,
    fileList: fileList,
    listType: 'picture-card',
    showUploadList: false,
    onRemove: handleRemove,
  };

  return (
    <div>
      <Form.Item name="images">
        <ImgCrop rotationSlider showReset>
          <StyledCreateEditProductsGallery {...props}>
            <p className="ant-upload-drag-icon">
              <img src={'/svgs/upload-image.svg'} alt="upload icon" />
            </p>
            <p className="ant-upload-text">
              <a>{intl.formatMessage({ id: 'gallery.upload_file' })} </a>
              {intl.formatMessage({ id: 'gallery.or_here' })}
            </p>
          </StyledCreateEditProductsGallery>
        </ImgCrop>
      </Form.Item>
      <CustomUploadList
        fileList={fileList}
        thumbUrls={thumbUrls}
        onRemove={handleRemove}
        onEdit={handleEditBeforeUpload}
      />
    </div>
  );
};
