import { useIntl } from 'react-intl';
import { StyledBusinessForm } from '../style';
import { Input } from '../../../../ui';
import { UploadFile } from 'antd';
import useQueryApiClient from '../../../../utils/useQueryApiClient';
import { useEffect } from 'react';
import { FileUploader } from '../../../../ui/UploadImage/FileUploader';
interface GalleryProps {
  fileLists: any;
  setFileLists: any;
  farmsLogo: string;
  galleryUrls: string[];
}

export const BusinessGallery = ({
  fileLists,
  setFileLists,
  farmsLogo,
  galleryUrls,
}: GalleryProps) => {
  const intl = useIntl();

  useEffect(() => {
    if (farmsLogo) {
      farmsLogoFetch({ path: farmsLogo }, [], { logoUrl: farmsLogo });
    }
    if (galleryUrls) {
      galleryUrls.forEach((url: string) =>
        farmsGalleryFetch({ path: url }, [], { galleryUrl: url })
      );
    }
  }, [farmsLogo, galleryUrls]);

  const { appendData: farmsLogoFetch } = useQueryApiClient({
    request: {
      url: '/api/v2/image',
      multipart: true,
      disableOnMount: true,
    },
    onSuccess: (response, passOnSuccess) =>
      handleImageFile(response, passOnSuccess.logoUrl, true),
  });

  const { appendData: farmsGalleryFetch } = useQueryApiClient({
    request: {
      url: '/api/v2/image',
      multipart: true,
      disableOnMount: true,
    },
    onSuccess: (response, passOnSuccess) =>
      handleImageFile(response, passOnSuccess.galleryUrl, false),
  });

  const handleImageFile = (response: any, url: string, isLogo: boolean) => {
    const fileName = url.split('/').pop() || 'unknown_file';

    const file = new File([response], fileName, { type: response.type });

    const blobUrl = URL.createObjectURL(response);

    const fileObj = {
      name: fileName,
      preview: blobUrl,
      originFileObj: file,
    };

    if (isLogo) {
      setFileLists((prev: any) => ({ ...prev, logo: [fileObj] }));
    } else {
      setFileLists((prev: any) => ({
        ...prev,
        gallery: [...(prev.gallery || []), fileObj],
      }));
    }
  };

  const setLogoFileList = (newList: UploadFile[]) =>
    setFileLists((prev: any) => ({ ...prev, logo: newList }));

  const setGalleryFileList = (newList: UploadFile[]) =>
    setFileLists((prev: any) => ({ ...prev, gallery: newList }));

  return (
    <StyledBusinessForm>
      <div className={'wide-section'}>
        <div className={'title'}>
          <h3>{intl.formatMessage({ id: 'gallery.logo' })}</h3>
        </div>
        <div className={'input-filelist'}>
          <div className={'input'}>
            <FileUploader
              fileList={fileLists.logo}
              onFileListChange={setLogoFileList}
              showActions={false}
              onValidate={() => {}}
              multiple={false}
              title={null}
              isCrop={false}
            />
          </div>
        </div>
        <div className={'gallery'}>
          <div className={'title'}>
            <h3>{intl.formatMessage({ id: 'gallery.business_gallery' })}</h3>
          </div>
          <FileUploader
            fileList={fileLists.gallery}
            onFileListChange={setGalleryFileList}
            showActions={false}
            onValidate={() => {}}
            multiple={true}
            title={null}
            isCrop={false}
            needThumb={false}
            isColumn
          />
        </div>
        <div className={'video'}>
          <div className={'title'}>
            <h3>{intl.formatMessage({ id: 'gallery.product_video' })}</h3>
            {intl.formatMessage({ id: 'gallery.video_description' })}
          </div>
          <Input
            label={intl.formatMessage({ id: 'gallery.youtube_link' })}
            name={'youtube_link'}
            layout={'vertical'}
            className={'yt'}
          />
        </div>
      </div>
    </StyledBusinessForm>
  );
};
