import styled, { createGlobalStyle } from 'styled-components';

export const FarmGlobalStyle = createGlobalStyle`
  @media (max-width: 440px) {
    .mobile-header-container .buy-latvia-food {
      display: none !important;
    }
  }
  `;

export const StyledFarmView = styled.div`
  background-color: ${(props) => props.theme.white};

  .breadcrumbs-container {
    padding: 23px 0;
  }

  .breadcrumbs-container {
    display: flex;
    justify-content: space-between;

    .breadcrumb {
      cursor: pointer;
    }

    div {
      span {
        a {
          text-decoration: none;
          color: ${(props) => props.theme.black};
        }
      }
    }
  }

  .farm-details {
    padding: 15px 200px;
  }

  .farm-members {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 24px;
      height: 21px;
    }
  }

  .working-time-container {
    padding: 20px;
    background-color: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.lightGrey};

    p {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .working-time {
    display: flex;
    flex-direction: column;
    width: 400px;

    @media (max-width: 440px) {
      width: 100%;
    }
  }

  .day-time-row {
    display: flex;
    max-width: 320px;

    @media (max-width: 440px) {
      max-width: 100%;
    }
  }

  .day-name {
    display: block;
    width: 120px;
  }

  .day-name,
  .day-time {
    padding: 12px 15px;
    font-size: 17px;
    font-weight: 400;
  }

  .day-time-row:nth-child(even) {
    background-color: ${(props) => props.theme.lightGrey};
  }

  .content-section {
    padding: 40px 0;
  }

  .popular-products {
    padding: 40px 0 61px 0;

    h1 {
      margin-top: 0;
    }

    .desktop-products-container .title,
    .grid-view {
      padding: 0 !important;
      justify-content: flex-start;
      gap: 30px;

      @media (max-width: 892px) {
        justify-content: center;
      }
    }

    .title h1 {
      margin-top: 0;
      margin-bottom: 36px;
      font-weight: 500;
      font-size: 42px;
    }
  }

  .grid-view {
    row-gap: 30px;
  }

  .menu-products,
  .mobile-menu-container {
    display: none;
    @media (max-width: 440px) {
      display: block;

      background-color: ${(props) => props.theme.brightGrey};

      .mobile-back {
        background-color: ${(props) => props.theme.white};
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 47px 15px 17px 16px;
        margin-top: 2px;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid ${(props) => props.theme.lightGrayBorder};

        .back-icon,
        img {
          width: 17px;
          height: 17px;
        }

        .back-text {
          font-size: 14px !important;
          line-height: 16px;
        }
      }

      .custom-menu {
        display: flex;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .custom-menu-item {
        padding: 10px;
        cursor: pointer;
        color: ${(props) => props.theme.grey};
        font-size: 16px;
      }

      .custom-menu-item.active {
        color: ${(props) => props.theme.black};
        font-weight: bold;
      }

      .content-section {
        padding: 10px;
      }

      .custom-menu::-webkit-scrollbar {
        height: 4px;
      }
      ul {
        background-color: ${(props) => props.theme.white};
      }
    }
  }

  .banners {
    .slick-slider {
      margin-bottom: 0;
    }

    .banner-wrapper {
      padding: 66px 0 78px 0;
    }

    .banner-image-wrapper {
      padding: 0;
    }

    .slick-dots-bottom {
      display: none !important;
    }
  }

  @media (max-width: 1400px) {
    .farm-details {
      padding: 15px 50px;
    }
  }
  @media (max-width: 845px) {
    .farm-details {
      padding: 20px;
    }
  }
  @media (max-width: 440px) {
    padding: 0;
    background-color: ${(props) => props.theme.inputGrey};

    .laptop-search-section-box {
      display: none;
    }

    .content-section {
      padding: 0 !important;

      .contact_name {
        padding: 20px;
      }
    }

    .desktop-content {
      display: none;
    }

    .menu-container {
      display: none;
    }

    .product-description {
      margin: 20px 0;
      padding: 5px 10px;
      border: 1px solid ${(props) => props.theme.darkRed};
      border-radius: 5px;
      color: ${(props) => props.theme.colorText};
      font-family: ${(props) => props.theme.fontText};
      font-size: 16px;
      line-height: 22px;
      font-weight: 300;
    }

    .popular-products {
      padding: 0;

      .title {
        padding: 0 !important;
      }

      .title h1 {
        margin-top: 0;
      }

      .grid-view {
        row-gap: 15px;
      }

      .price-quality-marks {
        margin-bottom: 0 !important;
      }

      .price-type-container {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }

      .add-to-cart-container {
        padding-top: 0;
        padding-bottom: 0;
        margin: 5px 0 10px 0;
        height: 30px;
      }

      .custom-title {
        margin-bottom: 5px;
        padding-left: 0;
        padding-bottom: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-card-body .price-quality-marks .farm-name-container-mobile {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btn-add-to-cart .ant-btn {
        height: 30px !important;
        width: 30px !important;
      }
    }

    .mobile-description {
      padding: 20px;
    }

    .farm-details {
      display: none;
    }

    .desktop-products-container {
      display: none;
    }

    .mobile-farm-info {
      margin: 10px 20px;
      padding: 10px 20px;
      border-radius: 8px;
      background-color: ${(props) => props.theme.white};
      .mobile-title {
        font-size: 18px;
      }

      .mobile-info-div {
        .info-column {
          display: flex;
          gap: 8px;
          align-items: center;
          border-bottom: 1px solid ${(props) => props.theme.lightGrey};
          color: ${(props) => props.theme.black};
          font-weight: 400;
          padding: 12px 0;
        }

        .info-column a {
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          max-width: 100%;
          color: ${(props) => props.theme.black};
          text-decoration: underline;
        }

        .bookmark {
          display: flex;
          flex-direction: column;
          .bookmark-2 {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 12px 0;
          }
        }
        .mobile-nums {
          display: flex;
          flex-direction: column;
          padding: 0;
          margin: 0;
        }
      }

      .mobile-buttons {
        display: flex;
        justify-content: center;
        gap: 10px;

        a {
          width: 100%;
          padding: 5px 0;

          button {
            width: 100%;
          }
        }
      }
    }

    .banners {
      .banner-wrapper {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .farm-info {
    display: flex;

    .farm-img-box .logos {
      display: none;

      @media (max-width: 440px) {
        display: block;
      }
      img {
        width: 61px;
        height: 41px;

        @media (max-width: 440px) {
          width: 36px;
          height: 23px;
          margin: 0 5px 11px;
        }
      }
    }
    h1 {
      font-size: 18px;
      margin: 15px 20px;
    }

    @media (max-width: 545px) {
      flex-direction: column;
    }

    .farm-img-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      border: 1px solid ${(props) => props.theme.lightGrey};
      border-radius: 5px;

      @media (max-width: 545px) {
        display: flex;
        justify-content: center;
        width: 350px;

        @media (max-width: 440px) {
          width: 100%;
          padding-top: 0;
          gap: 15px;
          background-color: ${(props) => props.theme.white};
        }
      }
      img {
        max-width: 193px;
        @media (max-width: 545px) {
          width: 300px;
        }
        @media (max-width: 440px) {
          width: 340px;
          height: 139px;
        }
      }
      .bottom-img img {
        width: 51px;
        height: 51px;
        border-radius: 50%;
        border: 1px solid ${(props) => props.theme.brand02};
      }
    }
    .farm-title h1 {
      margin: 0;
      font-family: ${(props) => props.theme.fontText};
      font-size: 42px;
      font-weight: 500;

      @media (max-width: 1200px) {
        font-size: 20px;
      }
      @media (max-width: 440px) {
        font-size: 18px;
      }
    }
    .farm-contact {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 70px;
      width: 100%;
      padding-left: 50px;

      @media (max-width: 845px) {
        padding: 0 20px;
      }
      @media (max-width: 545px) {
        padding: 0;
        margin-top: 50px;
      }

      .farm-title {
        display: flex;
        justify-content: space-between;

        .mobile-website-box {
          display: none;

          @media (max-width: 1200px) {
            display: block;
          }

          .mobile-website {
            display: flex;
            flex-direction: column;
            gap: 10px;
            a {
              text-decoration: underline;
              cursor: pointer;
              color: ${(props) => props.theme.black};
            }
            div {
              display: flex;
              gap: 20px;
            }
          }
        }

        @media (max-width: 1200px) {
          flex-direction: column;
          gap: 30px;
        }
        @media (max-width: 545px) {
          gap: 10px;
        }
        @media (max-width: 440px) {
          padding: 0 20px;
        }
      }

      .logos {
        display: flex;
        gap: 20px;

        img {
          width: 61px;
          height: 41px;

          @media (max-width: 440px) {
            width: 36px;
            height: 23px;
          }
        }
      }
    }
  }
  .menu-container {
    padding-top: 80px;

    .ant-menu-title-content {
      font-family: ${(props) => props.theme.fontText};
    }

    .ant-menu-horizontal {
      border-bottom: 0;
    }

    .menu-line {
      margin: 0;
      height: 1px;
      background-color: ${(props) => props.theme.borderBottom};
      border: 0;
    }

    .ant-menu-item {
      padding-left: 0;
    }

    .ant-menu-item::after {
      left: 0;
    }

    ul li span {
      font-weight: 500;
      color: ${(props) => props.theme.grey};
    }

    .slick-arrow {
      width: 44px;
      height: 44px;
      background-color: ${(props) => props.theme.white};
      border-radius: 4px;
      color: ${(props) => props.theme.black2};
      opacity: 1;
    }

    .slick-prev {
      left: -55px;
    }

    .slick-next {
      right: -55px;
    }

    .slick-prev::after,
    .slick-next::after {
      left: 16px;
      top: 16px;
    }

    @media (max-width: 845px) {
      padding-top: 30px;
    }
    @media (max-width: 440px) {
      padding-top: 20px;
      order: -1;
      padding: 0 20px;

      ul {
        width: 100vw;

        &::before,
        &::after {
          display: none;
        }
      }
    }

    .content-section {
      padding: 34px 0 70px 0;

      .title,
      .grid-view {
        padding: 0;
      }

      @media (max-width: 440px) {
        padding: 32px 0 51px 0;
      }
    }
  }
  .farm-datas {
    display: flex;
    justify-content: space-between;
    margin-top: -50px;

    @media (max-width: 1200px) {
      display: none;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 10px 25px;
      padding-bottom: 0;
      width: 100%;
      border-left: 1px solid ${(props) => props.theme.borderBottom};
      height: 100%;
      margin: 0;

      li {
        display: flex;
        gap: 5px;
        list-style: none;
        font-weight: 400;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 16px;
          height: 16px;
        }
        a {
          color: ${(props) => props.theme.black};

          button {
            padding: 0 30px;
          }
        }
      }

      li:hover {
        background-color: transparent;
      }
    }

    ul:nth-child(1) {
      border: none;
      padding: 10px 50px 10px 0;
    }
  }
  .mobile-farm-datas-div {
    display: none;

    @media (max-width: 1200px) {
      display: block;
    }
    @media (max-width: 845px) {
      display: none;
    }
  }
  .mobile-farm-datas {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 20px;

    ul {
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 10px 50px;
      width: 100%;
      border-left: 1px solid ${(props) => props.theme.borderBottom};
      height: 100%;
      margin: 0;

      li {
        display: flex;
        gap: 5px;
        align-items: center;
        list-style: none;
        font-weight: 400;
        a {
          color: ${(props) => props.theme.black};
        }
      }
    }

    @media (max-width: 1200px) {
      ul:nth-child(4) {
        display: none;
      }
    }

    ul:nth-child(1) {
      border: none;
      padding: 10px 50px 10px 0;
    }
    ul:nth-child(4) {
      padding: 10px 0 10px 50px;
    }
  }
  .farm-description {
    margin-top: 50px;
  }

  .ant-menu-item-selected {
    &::after {
      border-bottom-color: ${(props) => props.theme.black} !important;
    }

    .ant-menu-title-content {
      color: ${(props) => props.theme.black};
    }
  }

  .ant-menu-item:hover {
    &::after {
      border-bottom-color: ${(props) => props.theme.black} !important;
    }

    .ant-menu-title-content {
      color: ${(props) => props.theme.black};
    }
  }
`;
