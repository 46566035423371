import DefaultLayout from 'admin/components/DefaultLayout';
import { StyledBusinessInformation } from './style';
import { useIntl } from 'react-intl';
import { Button, Spinner, Tabs } from '../../../ui';
import React, { useEffect, useMemo, useState } from 'react';
import { BaseInformation } from './BaseInformation/BaseInformation';
import { useForm } from 'antd/lib/form/Form';
import { Form, message } from 'antd';
import { ContactInformation } from './ContactInformation/ContactInformation';
import { AdditionalInformation } from './AdditionalInformation/AdditionalInformation';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import { useAuth } from '../../../hooks/useAuth';
import { BusinessGallery } from './BusinessGallery/BusinessGallery';
import { BusinessAddresses } from './BusinessStructuralUnit/BusinessAddresses';
import {
  FarmsProps,
  ImageProps,
  InfrastructureProps,
  LlkcData,
} from '../../../interfaces/BackendModels';
interface IFileLists {
  logo: any;
  gallery: any;
}

export const BusinessInformation = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pvdNr, setPvdNr] = useState<number>(0);
  const [farmsLogo, setFarmsLogo] = useState<string>('');
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [infrastructure, setInfrastructure] = useState<InfrastructureProps[]>(
    []
  );
  const [fileLists, setFileLists] = useState<IFileLists>({
    logo: [],
    gallery: [],
  });

  const intl = useIntl();
  const [form] = useForm();
  const { user } = useAuth();

  const stableFarmId = useMemo(() => {
    return user?.farmId;
  }, [user?.farmId]);

  const { appendData } = useQueryApiClient({
    // TODO: fetch data from DB inted of registry NOGA-94
    request: {
      url: '/api/v2/llkc/farms',
    },
    onSuccess: (response: LlkcData) => handleSuccesLlkcFarmsFetch(response),
    onError: (error) => {
      if (error.code === 400)
        message.error(
          intl.formatMessage({ id: 'message.error_incorrect_regnr_pvd' })
        );
    },
  });

  const { data } = useQueryApiClient({
    request: {
      url: `/api/v2/farms/${stableFarmId}`,
    },
    onSuccess: (response: FarmsProps) => handleSuccessFarmDataFetch(response),
  });

  const { appendData: submitData } = useQueryApiClient({
    request: {
      url: '/api/v2/farms/:farmId',
      method: 'POST',
      multipart: true,
    },
    onSuccess: () =>
      message.success(
        intl.formatMessage({ id: 'message.success_update_farm' })
      ),
  });

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      appendData({ regNr: user.regNr, contact_email: user.email });
    }
  }, [user?.email, user?.regNr]);

  const handleSuccesLlkcFarmsFetch = (response: LlkcData) => {
    Object.entries(response.data).forEach(([key, value]) => {
      form.setFieldValue(key, value);
    });
    if (response.data.infrastructure) {
      setInfrastructure(response.data.infrastructure);
    }
    setIsLoading(false);
  };

  function handleSuccessFarmDataFetch(response: FarmsProps) {
    const keysToMapToIds = new Set(['types', 'services', 'quality_marks']);
    const galleryUrls = response.images.map(
      (img: ImageProps) => img.url_original
    );
    setPvdNr(response.pvd_nr);
    setGalleryImages(galleryUrls);
    setFarmsLogo(response.logo);

    Object.entries(response).forEach(([key, value]) => {
      const finalValue = keysToMapToIds.has(key)
        ? value.map((item: { id: number }) => item.id)
        : value;
      form.setFieldValue(key, finalValue);
    });
  }

  const items = [
    {
      key: 'base_information',
      label: intl.formatMessage({ id: 'navigation.company' }),
      children: <BaseInformation />,
    },
    {
      key: 'contact_information',
      label: intl.formatMessage({ id: 'navigation.contact_information' }),
      children: <ContactInformation />,
    },
    {
      key: 'additional_information',
      label: intl.formatMessage({ id: 'navigation.additional_information' }),
      children: <AdditionalInformation />,
    },
    {
      key: 'business_gallery',
      label: intl.formatMessage({ id: 'navigation.gallery' }),
      children: (
        <BusinessGallery
          setFileLists={setFileLists}
          fileLists={fileLists}
          farmsLogo={farmsLogo}
          galleryUrls={galleryImages}
        />
      ),
    },
    {
      key: 'business_addresses',
      label: intl.formatMessage({ id: 'navigation.business_addresses' }),
      children: (
        <BusinessAddresses infrastructure={infrastructure} pvdNr={pvdNr} />
      ),
    },
  ];

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      let logoObj: string | Blob;
      const formData = new FormData();
      const values = form.getFieldsValue();

      if (fileLists.logo[0]) {
        if (!fileLists.logo[0].existingImage) {
          logoObj = (fileLists.logo[0].originFileObj as Blob) ?? null;
          formData.append('logo_img', logoObj);
        }
      }

      if (fileLists.gallery) {
        fileLists.gallery.map((img: any, index: number) => {
          formData.append('gallery[]', img.originFileObj as Blob);
        });
      }

      Object.entries(values).forEach(([key, value]) => {
        if (key === 'gallery' || key === 'logo') {
          return;
        }

        if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(`${key}[]`, item);
          });
        } else if (value != null) {
          formData.append(key, String(value));
        }
      });

      submitData(formData, { farmId: stableFarmId });
    } catch (e) {}
  };
  const Footer = () => {
    return (
      <div className={'footer'}>
        <Button label={intl.formatMessage({ id: 'general.cancel' })} />
        <Button
          label={intl.formatMessage({ id: 'general.save' })}
          onClick={handleSubmit}
          type={'primary'}
        />
      </div>
    );
  };

  return (
    <div>
      <DefaultLayout.PageLayout
        breadcrumbs={[
          {
            title: intl.formatMessage({ id: 'navigation.beginning' }),
          },
          {
            title: intl.formatMessage({ id: 'navigation.company' }),
          },
        ]}
      >
        <DefaultLayout.PageContent>
          <StyledBusinessInformation>
            <Spinner spinning={isLoading}>
              <Form form={form} layout={'vertical'}>
                <Tabs items={items} />
              </Form>
            </Spinner>
          </StyledBusinessInformation>
        </DefaultLayout.PageContent>
      </DefaultLayout.PageLayout>
      <DefaultLayout.PageFooter>
        <Footer />
      </DefaultLayout.PageFooter>
    </div>
  );
};
