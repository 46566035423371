import { StyledLoginPage } from './style';
import { RegisterForm } from '../../../ui/Form/auth/RegisterForm';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Form } from 'antd';

export const Register = () => {
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState(null);
  const [form] = Form.useForm();
  const { appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/register',
      method: 'POST',
    },
    onSuccess: (response) => {
      if (response) {
        navigate(`/confirmation?email=${form.getFieldValue('email')}`);
      }
    },
    onError: (error) => {
      setErrorData(error);
    },
  });

  return (
    <StyledLoginPage>
      <div className={'content'}>
        <RegisterForm
          appendData={appendData}
          errorData={errorData}
          form={form}
        />
      </div>
    </StyledLoginPage>
  );
};
