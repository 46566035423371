import { StyledPopularCategories } from './style';
import { CategoryData } from 'types/CategoryData';
import { CategoryCard } from '../../../../ui/Card/CategoryCard';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ImageProps } from '../../../../types/BannerData';
import UseQueryApiClient from '../../../../utils/useQueryApiClient';

interface PopularCategoriesProps {
  categories?: CategoryData[];
}

const PopularCategories = ({ categories }: PopularCategoriesProps) => {
  const [images, setImages] = useState<ImageProps[]>([]);
  const APP_URL = window.runConfig.backendUrl;

  const { data } = UseQueryApiClient({
    request: {
      url: '/api/v2/categories/popular/images',
    },
    onSuccess: (response) => {
      setImages(response);
    },
  });

  const intl = useIntl();
  return (
    <StyledPopularCategories className="popular-categories">
      <div className="container">
        <div className={'title'}>
          <h1>{intl.formatMessage({ id: 'popular_categories.title' })}</h1>
        </div>
        <div className={'container-category'}>
          {categories &&
            categories.map((category, index) => {
              const image = images.find(
                (img) => img.table_row_id === category?.top_category_position
              );
              return (
                <CategoryCard
                  key={`category-${index}`}
                  category={category}
                  bordered={true}
                  imageUrl={image?.url_original}
                  hoverable={true}
                />
              );
            })}
        </div>
      </div>
    </StyledPopularCategories>
  );
};

export default PopularCategories;
