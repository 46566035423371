import React, { ReactNode } from 'react';
import { DotPosition } from 'antd/es/carousel';
import {
  StyledAppendDotsContainer,
  StyledAppendDotsInner,
  StyledCarousel,
  StyledCustomPaging,
  StyledDotContainer,
  StyledImage,
} from './style';
import { ProductData, ProductImageProps } from 'types/ProductData';
import { Card } from '../Card';
import { CategoryCard } from 'ui/Card/CategoryCard';
import { CategoryData } from 'types/CategoryData';
import { FallbackImage } from '../UploadImage/fallBack/FallBackImage';
import { ImageProps } from '../../types/BannerData';

export interface ResponsiveObject {
  breakpoint: number;
  settings: 'unslick' | Settings;
}

export interface Settings {
  slidesToShow?: number | undefined;
}

export interface CarouselProps {
  arrows?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  adaptiveHeight?: boolean;
  dotPosition?: DotPosition;
  dots?: boolean | { className?: string };
  draggable?: boolean;
  fade?: boolean;
  infinite?: boolean;
  speed?: number;
  easing?: string;
  effect?: 'scrollx' | 'fade';
  afterChange?: (current: number) => void;
  beforeChange?: (current: number, next: number) => void;
  waitForAnimate?: boolean;
  productImages?: ProductImageProps[];
  slidesToShow?: number;
  slidesPerRow?: number;
  products?: ProductData[];
  background?: boolean;
  dotsClass?: string;
  popularCategory?: CategoryData[];
  responsive?: ResponsiveObject[];
  customDots?: boolean;
  customPrevArrow?: React.JSX.Element;
  customNextArrow?: React.JSX.Element;
  children?: (slotProps: {
    category: CategoryData;
    index: number;
  }) => React.ReactNode;
}

export const Carousel = ({
  arrows,
  autoplay,
  autoplaySpeed,
  adaptiveHeight,
  dotPosition,
  dots,
  draggable,
  fade,
  infinite,
  speed,
  easing,
  effect,
  afterChange,
  beforeChange,
  waitForAnimate,
  slidesPerRow,
  slidesToShow,
  products,
  popularCategory,
  responsive,
  customDots = true,
  productImages,
  customPrevArrow,
  customNextArrow,
  children,
}: CarouselProps) => {
  const showDots = customDots && (dots || (products && products.length > 1));
  return (
    <StyledCarousel
      responsive={responsive}
      arrows={arrows}
      autoplay={autoplay}
      autoplaySpeed={autoplaySpeed}
      adaptiveHeight={adaptiveHeight}
      dotPosition={dotPosition}
      dots={showDots}
      draggable={draggable}
      fade={fade}
      infinite={infinite}
      speed={speed}
      easing={easing}
      effect={effect}
      afterChange={afterChange}
      beforeChange={beforeChange}
      waitForAnimate={waitForAnimate}
      slidesPerRow={slidesPerRow}
      slidesToShow={slidesToShow}
      customPaging={(i: number) =>
        productImages && productImages[i] ? (
          <StyledCustomPaging key={i} className="custom-dot">
            <StyledDotContainer>
              <FallbackImage
                src={productImages[i].url_original}
                type={'FarmGalery'}
                alt={`thumbnail-${i}`}
                className={'custom-dot'}
                preview={false}
              />
            </StyledDotContainer>
          </StyledCustomPaging>
        ) : (
          <StyledCustomPaging key={i} className="custom-dot" />
        )
      }
      appendDots={
        !!productImages
          ? (dots: ReactNode) => (
              <StyledAppendDotsContainer>
                <StyledAppendDotsInner>{dots}</StyledAppendDotsInner>
              </StyledAppendDotsContainer>
            )
          : undefined
      }
      nextArrow={customNextArrow}
      prevArrow={customPrevArrow}
      className="slider"
    >
      {popularCategory &&
        popularCategory.length > 0 &&
        popularCategory.map((category, index) => (
          <div key={`product-category-${index}`}>
            {children ? (
              children({ category, index })
            ) : (
              <CategoryCard
                category={category}
                bordered={true}
                hoverable={true}
              />
            )}
          </div>
        ))}
      {products &&
        products.length > 0 &&
        products.map((product, index) => (
          <Card
            product={product}
            imageUrl={product.image_small}
            hoverable
            key={`product-${index}`}
          />
        ))}

      {productImages &&
        productImages.length > 0 &&
        productImages.map((image, index) => (
          <div key={index} className={'gallery-img-container'}>
            <FallbackImage
              src={image.url_original}
              type={'FarmGalery'}
              preview={false}
            />
          </div>
        ))}
    </StyledCarousel>
  );
};
