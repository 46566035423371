import styled from 'styled-components'

export const StyledDrawer = styled.div`
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .ant-drawer-close {
      margin-inline-end: 0;
      color: ${(props) => props.theme.black};
    }
  }
  .ant-drawer-footer {
    .footer {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: flex-end;
    }
  }
`
