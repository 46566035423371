import { StyledMobileHeader } from './style';
import { useIntl } from 'react-intl';
import { useProductState } from 'public/context';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileHeaderMap from './MobileHeaderMap/MobileHeaderMap';
import { Icon } from 'ui';

const MobileHeader = () => {
  const intl = useIntl();
  const location = useLocation();
  const { productsCounted } = useProductState();
  const buyLatviaFoodTitle = intl.formatMessage({
    id: 'mobile_header.buy_latvia_food',
  });
  const navigate = useNavigate();

  return (
    <StyledMobileHeader>
      <div id="mobile-header-container" className={'mobile-header-container'}>
        <div className="mobile-image-container" onClick={() => navigate('/')}>
          <Icon name="mobile-header-img-left" type={'header'} />
          <Icon name="mobile-header-img-logo" type={'headerMiddle'} />
          <Icon name="mobile-header-img-right" type={'header'} />
        </div>

        {location.pathname.includes('/farms') && <MobileHeaderMap />}
        {location.pathname === '/' && (
          <div className={'buy-latvia-food'}>
            <div className={'title-section'}>
              <h3 dangerouslySetInnerHTML={{ __html: buyLatviaFoodTitle }}></h3>
            </div>

            <div className={'counter-section'}>
              <h3>
                <b>{productsCounted}</b>
              </h3>
              <h3>{intl.formatMessage({ id: 'general.products' })}</h3>
            </div>
          </div>
        )}
      </div>
    </StyledMobileHeader>
  );
};

export default MobileHeader;
