import styled from 'styled-components';

export const StyledSelfDelivery = styled.div`
  .delivery {
    font-family: ${(props) => props.theme.fontText};
    .content {
      p {
        font-weight: 500;
        margin: 0;
        padding: 0 0 15px 0;
      }
      .open-hours-table {
        border-spacing: 0;
        border: 1px solid ${(props) => props.theme.lightGrey};
        width: 100%;

        td {
          padding: 0;
          padding-left: 15px;
          font-weight: 400;
        }

        td.td-day {
          width: 100px;
        }

        tr {
          height: 44px;
        }

        tr.light-gray-background {
          background-color: ${(props) => props.theme.lightGrey};
        }
        tr.white-background {
          background-color: ${(props) => props.theme.white};
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    padding-bottom: 16px;
  }
`;

export const StyledDelivery = styled.div`
  .ant-space {
    width: 100%;
    padding-bottom: 10px;

    .ant-space-gap-row-small {
      row-gap: 20px;
    }

    .ant-space-item {
      .ant-form-item-label {
        padding-bottom: 4px;
      }

      .ant-select {
        height: 100%;
      }

      .ant-select .ant-select-single {
        height: 45px;
      }

      .ant-select .ant-select-selector {
        border-radius: 5px;
        border: 1px solid ${(props) => props.theme.borderBottom};
        height: 45px;
        background-color: ${(props) => props.theme.inputBitDarkerOverlay};
      }
      .ant-select-arrow {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .ant-space {
      .ant-space-item {
        .ant-form-item {
          margin: 0;
          .ant-select-selection-item {
            font-weight: 500;
          }
          .ant-select-arrow {
            display: flex;
            align-items: center;
            color: ${(props) => props.theme.black};
          }
        }
      }
    }
  }
`;
