import styled from 'styled-components';
import { Drawer } from 'antd';

export const StyledCreateEditUserDrawer = styled(Drawer)`
  .footer {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 15px;
  }

  .ant-form-item-label {
    padding: 0;
  }

  .ant-drawer-header {
    min-height: 113px;
    .ant-drawer-header-title {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      .ant-drawer-title {
        max-width: 300px;
      }
    }
  }
`;
