import { StyledOrderInvoiceBase } from './style';
import { Divider } from '../../../../../ui';
import { Field } from './Field';
import { useIntl } from 'react-intl';
import {
  Transaction,
  TransactionsProps,
} from '../../../../../interfaces/BackendModels';

interface OrderInvoiceBaseProps {
  order: TransactionsProps;
  transaction?: Transaction | null;
}

export const OrderInvoiceBase = ({
  transaction,
  order,
}: OrderInvoiceBaseProps) => {
  const intl = useIntl();

  const productTotal = order?.product_total ?? 0;
  const vatPercentage = order?.vat ?? 0;
  const priceWithPvn = productTotal * (1 + vatPercentage / 100);
  const pvn = (productTotal * vatPercentage) / 100;

  const pvnFormatted = pvn.toFixed(2);
  const priceWithPvnFormatted = priceWithPvn.toFixed(2);

  const firstColumn = [
    { labelKey: 'invoice.client', value: order?.customer_name ?? '' },
    {
      labelKey: 'invoice.phone',
      value: order?.customer_phone ?? order?.phone,
    },
    {
      labelKey: 'invoice.email',
      value: order?.customer_email ?? '',
    },
    {
      labelKey: 'invoice.delivery_type',
      value: transaction?.delivery?.name ?? '',
    },
    {
      labelKey: 'invoice.delivery_address',
      value: transaction?.delivery?.address ?? '',
    },
  ];

  const secondColumn = [
    { labelKey: 'invoice.price', value: `€ ${order?.product_total}` ?? '' },
    { labelKey: 'invoice.pvn', value: `€ ${pvnFormatted}` ?? '' },
    {
      labelKey: 'invoice.price_with_pvn',
      value: `€ ${priceWithPvnFormatted}` ?? '',
    },
    {
      labelKey: 'invoice.delivery',
      value: `€ ${transaction?.delivery?.price}` ?? '',
    },
    { labelKey: 'invoice.total', value: `€ ${order?.total}` ?? '' },
  ];

  return (
    <StyledOrderInvoiceBase className="invoice-content">
      <div className="content">
        <div className="invoice-data">
          <div className="first">
            {firstColumn.map((fieldDef) => (
              <Field
                key={fieldDef.labelKey}
                labelKey={fieldDef.labelKey}
                value={fieldDef.value}
              />
            ))}
          </div>
          <div className="second">
            {secondColumn.map((fieldDef) => (
              <Field
                key={fieldDef.labelKey}
                labelKey={fieldDef.labelKey}
                value={fieldDef.value}
              />
            ))}
          </div>
        </div>

        <Divider />

        <div className="footer">
          {intl.formatMessage({ id: 'invoice.checkout' })}
        </div>
      </div>
    </StyledOrderInvoiceBase>
  );
};
