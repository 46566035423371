import DefaultLayout from 'admin/components/DefaultLayout';
import { StyledFarmDeliveries } from './style';
import { useIntl } from 'react-intl';
import { Form, MenuProps, message } from 'antd';
import { Button, Checkbox, Dropdown, Table, Tabs } from '../../../ui';
import React, { useMemo, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useAuth } from '../../../hooks/useAuth';
import { DrawerCreateDelivery } from '../../../ui/Drawer/Drawers/DrawerCreateDelivery';
import dayjs, { Dayjs } from 'dayjs';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import { options } from '../../../config/config';
import { convertToDayJs } from '../../../utils/timeHelpers';

interface DeliveryProps {
  name: string;
  phone: string;
  type: string;
  id: number;
  address?: string;
  open_hours: DefaultHoursProps[];
  status: boolean;
}

export interface DefaultHoursProps {
  no: Dayjs | null;
  lidz: Dayjs | null;
  closed?: boolean | string;
}
export const FarmDeliveries = () => {
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [isOpenCreateDelivery, setIsOpenCreateDelivery] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { user } = useAuth();
  const [form] = useForm();
  const intl = useIntl();

  const { appendData: deleteDelivery } = useQueryApiClient({
    request: {
      url: `/api/v2/deliveries/:id`,
      method: 'DELETE',
    },
    onSuccess: () => handleSuccessDelete(),
    onError: () => handleError(),
  });

  const { appendData: fetchDelivery } = useQueryApiClient({
    request: {
      url: `/api/v2/deliveries/:id`,
      disableOnMount: true,
    },
    onSuccess: (response) => handleSuccessFetch(response),
    onError: () => handleError(),
  });

  const handleError = () => {
    message.error(`error`);
    handleReload();
  };
  const handleSuccessDelete = () => {
    message.success(
      intl.formatMessage({ id: 'message.success_delete_delivery' })
    );
    handleReload();
  };

  const handleSuccessFetch = async (response: DeliveryProps) => {
    let parsedOpenHours;
    const { open_hours, ...otherData } = response;
    const { address, ...otherDataFinal } = otherData;
    if (typeof open_hours === 'string') {
      parsedOpenHours = open_hours ? JSON.parse(open_hours) : {};
    }

    const defaultHours: DefaultHoursProps[] = Array.from({ length: 7 }, () => ({
      no: null,
      lidz: null,
      closed: false,
    }));

    for (let dayNumber = 1; dayNumber <= 7; dayNumber++) {
      const dayIndex = dayNumber - 1;
      const dataForDay = parsedOpenHours[String(dayNumber)];
      if (!dataForDay) continue;

      defaultHours[dayIndex].closed = dataForDay.closed === '1';

      defaultHours[dayIndex].no = dataForDay.no
        ? dayjs(dataForDay.no, 'HH:mm')
        : null;

      defaultHours[dayIndex].lidz = dataForDay.lidz
        ? dayjs(dataForDay.lidz, 'HH:mm')
        : null;
    }

    const finalValues: DeliveryProps = {
      ...otherDataFinal,
      open_hours: defaultHours,
    };

    if (address && address.trim() !== '') {
      finalValues.address = address;
    }

    form.setFieldsValue(finalValues);

    setIsOpenCreateDelivery(true);
  };

  const handleEdit = (record: DeliveryProps) => {
    setIsEdit(true);
    fetchDelivery(null, { id: record.id });
  };
  const handleDelete = (record: DeliveryProps) => {
    if (
      window.confirm(
        intl.formatMessage({
          id: 'message.confirm_delete_delivery',
        })
      )
    ) {
      deleteDelivery(null, { id: record.id });
    }
  };

  const handleReload = () => {
    setReloadCount((prev) => prev + 1);
  };

  const handleCreateDelivery = () => {
    form.resetFields();
    setIsOpenCreateDelivery(true);
  };

  const handleCloseDeliveryDrawer = () => {
    form.resetFields();
    setIsOpenCreateDelivery(false);
    setIsEdit(false);
  };

  const stableFilter = useMemo(() => {
    return { farmId: user?.farmId };
  }, [user?.farmId]);

  const initialValues = {
    open_hours: convertToDayJs(options.open_hours),
  };

  const columns = useMemo(() => {
    return [
      {
        title: intl.formatMessage({ id: 'general.name' }),
        dataIndex: 'name',
        render: (value: string) => value,
      },
      {
        title: intl.formatMessage({ id: 'general.delivery_type' }),
        dataIndex: 'type',
        render: (value: string) => {
          return intl.formatMessage({ id: `delivery.${value}` });
        },
      },
      {
        title: intl.formatMessage({ id: 'general.address' }),
        dataIndex: 'address',
        render: (value: string) => value,
      },
      {
        title: intl.formatMessage({ id: 'general.phone' }),
        dataIndex: 'phone',
        render: (value: string) => value,
      },
      {
        title: intl.formatMessage({ id: 'general.status' }),
        dataIndex: 'status',
        render: (value: boolean) => <Checkbox checked={value} />,
      },
      {
        title: intl.formatMessage({ id: 'general.actions' }),
        render: (record: DeliveryProps) => {
          const items: MenuProps['items'] = [
            {
              key: 'edit',
              label: intl.formatMessage({ id: 'general.edit' }),
            },
            {
              key: 'delete',
              label: intl.formatMessage({ id: 'general.delete' }),
            },
          ];

          const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
            switch (key) {
              case 'edit':
                handleEdit(record);
                break;
              case 'delete':
                handleDelete(record);
                break;
            }
          };

          return (
            <Dropdown
              menu={{ items, onClick: handleMenuClick }}
              trigger={['click']}
              children={
                <Button label={intl.formatMessage({ id: 'general.actions' })} />
              }
            />
          );
        },
      },
    ];
  }, []);

  const tableElement = (
    <Table
      url={'/api/v2/farms/deliveries'}
      columns={columns}
      filter={stableFilter}
      reload={reloadCount}
      disablePagination
    />
  );

  const items = [
    {
      key: 'deliveries',
      label: intl.formatMessage({ id: 'navigation.deliveries' }),
      children: <>{tableElement}</>,
    },
  ];

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.deliveries' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <StyledFarmDeliveries>
          <Form form={form} initialValues={initialValues}>
            <Tabs
              items={items}
              tabBarExtraContent={
                <div>
                  <Button
                    label={intl.formatMessage({ id: 'general.add_delivery' })}
                    type={'primary'}
                    onClick={handleCreateDelivery}
                  />
                  <DrawerCreateDelivery
                    open={isOpenCreateDelivery}
                    closable={true}
                    onClose={handleCloseDeliveryDrawer}
                    form={form}
                    setReloadCount={handleReload}
                    isEdit={isEdit}
                    title={intl.formatMessage({
                      id: 'general.add_delivery_title',
                    })}
                  />
                </div>
              }
            />
          </Form>
        </StyledFarmDeliveries>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};
