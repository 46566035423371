import React, { useEffect, useState } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import { Button, Tabs, Table, DatePicker, Dropdown, Input, Modal } from 'ui';
import Filter from 'admin/components/Filter';
import { StyledButtonList } from 'styles/buttonList';
import dayjs from 'dayjs';
import useQueryApiClient from '../../../../utils/useQueryApiClient';
import FileDownload from 'js-file-download';
import { MenuProps } from 'antd/es/menu';
import YesNoSelect from '../../../Selects/YesNoSelect';
import { EditOutlined } from '@ant-design/icons';
import { Pagination } from '../../../../types/Pagination';
import { print } from '../../../../utils/print';
import copyToClipboard from '../../../../utils/copyToClipboard';
import { ContractModalContent, TittleModal } from './style';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export interface AdvancedSearchState {
  pagination?: number;
  date_from?: string;
  date_to?: string;
  confirmed?: number;
  search?: string;
}

interface Contracts {
  name: string;
  reg_nr: string;
  contract_nr: string;
  contract_date: string;
  user: string;
  created_at: string;
}

const ContractListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState<AdvancedSearchState>({});
  const [paginationStatus, setPaginationStatus] = useState<Pagination>({});
  const [contractId, setIsContract] = useState<number>(0);

  const [contractData, setContractData] = useState<Contracts>();
  const intl = useIntl();

  const { appendData: exportData } = useQueryApiClient({
    request: {
      url: `api/v2/contracts/export`,
      multipart: true,
      method: 'POST',
    },
    onSuccess: (response, format) => {
      FileDownload(
        response,
        `${format}_${dayjs().format('YYYY-MM-DD')}.${format}`
      );
    },
  });

  const { data, refetch } = useQueryApiClient({
    enabled: !!contractId,
    request: {
      url: `api/v2/contracts/${contractId}`,
    },
  });

  useEffect(() => {
    if (contractId) {
      refetch();
    }
  }, [contractId]);

  const columns = [
    {
      title: intl.formatMessage({ id: 'contracts.name' }),
      dataIndex: 'name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'contracts.reg_nr' }),
      dataIndex: 'reg_nr',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'contracts.contract_nr' }),
      dataIndex: 'contract_nr',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'contracts.contract_date' }),
      dataIndex: 'contract_date',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'contracts.user' }),
      dataIndex: 'user',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: string) => (
        <Button
          className="action"
          icon={<EditOutlined />}
          onClick={() => setIsContract(Number(value))}
        />
      ),
    },
  ];
  const items = [
    {
      key: 'contracts',
      label: intl.formatMessage({ id: 'contracts.active_contracts' }),
      children: (
        <>
          <Filter
            onFinish={setAdvancedSearch}
            totalRecord={paginationStatus?.total ?? 0}
          >
            <DatePicker
              label={intl.formatMessage({ id: 'general.date_from' })}
              name="date_from"
            />
            <DatePicker
              label={intl.formatMessage({ id: 'general.date_to' })}
              name="date_to"
            />
            <YesNoSelect
              label={intl.formatMessage({ id: 'contracts.confirmed' })}
              name="confirmed"
            />
            <Input
              label={intl.formatMessage({ id: 'general.search' })}
              name="search"
            />
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />
          </Filter>
          <Table
            url="/api/v2/contracts"
            columns={columns}
            filter={advancedSearch}
            page_size={advancedSearch?.pagination ?? 20}
            paginnationStatus={(el: object) => {
              setPaginationStatus(el);
            }}
            saveData={(el: Contracts) => {
              setContractData(el);
            }}
          />
        </>
      ),
    },
  ];

  const headers = [
    intl.formatMessage({ id: 'contracts.name' }),
    intl.formatMessage({ id: 'contracts.reg_nr' }),
    intl.formatMessage({ id: 'contracts.contract_nr' }),
    intl.formatMessage({ id: 'contracts.contract_date' }),
    intl.formatMessage({ id: 'contracts.user' }),
    intl.formatMessage({ id: 'general.created_at' }),
  ].join('\t');

  const formatRow = (row: Contracts) => {
    return [
      row.name || '',
      row.reg_nr || '',
      row.contract_nr || '',
      row.contract_date ? dayjs(row.contract_date).format('DD.MM.YYYY') : '',
      row.user || '',
      row.created_at ? dayjs(row.created_at).format('DD.MM.YYYY') : '',
    ].join('\t');
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'copy':
        copyToClipboard({
          data: contractData,
          headers: headers,
          formatRow: formatRow,
          intl: intl,
        });
        break;
      case 'xlsx':
      case 'csv':
        exportData({ format: e.key, ...advancedSearch }, [], e.key);
        break;
      case 'print':
        print(intl.formatMessage({ id: 'general.printing_header' }), 6);
        break;
    }
  };

  const dropdownItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'general.print' }),
      key: 'print',
    },
    {
      label: intl.formatMessage({ id: 'general.copy' }),
      key: 'copy',
    },
    {
      label: intl.formatMessage({ id: 'general.excel' }),
      key: 'xlsx',
    },
    {
      label: intl.formatMessage({ id: 'general.csv' }),
      key: 'csv',
    },
  ];

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  };

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.contracts' }),
        },
      ]}
    >
      <Modal
        title={
          <TittleModal>
            {intl.formatMessage({ id: 'contracts.view_contract' })}
          </TittleModal>
        }
        open={!!contractId}
        footer={null}
        onCancel={() => setIsContract(0)}
        destroyOnClose
        getContainer={document.getElementsByClassName('ant-layout-content')[0]}
      >
        <ContractModalContent>
          <div className="horizont-line-container">
            <div className="horizont-line" />
          </div>
          <span className="contract-info">
            <p>
              {intl.formatMessage({ id: 'contracts.contract' })}:
              {data['contract_nr']}
            </p>
            <p>
              {intl.formatMessage({ id: 'contracts.date' })}:
              {data['contract_date']}
            </p>
          </span>
          <span className="contract-accepted">
            <p>
              {intl.formatMessage({ id: 'contracts.confirmed_by' })}:
              {data['user']}
            </p>
            <p>
              {intl.formatMessage({ id: 'contracts.approver' })}:
              {
                <span>
                  {data['confirmed'] ? (
                    <CheckOutlined className="checked" />
                  ) : (
                    <CloseOutlined className="minus" />
                  )}
                </span>
              }
            </p>
          </span>
        </ContractModalContent>
      </Modal>
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Dropdown menu={menuProps}>
                <Button
                  label={intl.formatMessage({ id: 'general.export' })}
                  type="primary"
                />
              </Dropdown>
            </StyledButtonList>
          }
          items={items}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default ContractListPage;
