import styled from 'styled-components';

export const StyledCart = styled.div<{ isView?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding-top: 30px;

  @media only screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    flex-direction: ${({ isView }) => (isView ? 'column-reverse' : 'column')};
  }

  @media only screen and (max-width: 576px) {
    padding-top: 0;
    .form-title {
      display: block;
    }
  }
`;

export const StyledCartWrapper = styled.div`
  .form-title {
    display: none;
    margin-top: 14px;
  }

  @media only screen and (max-width: 576px) {
    .form-title {
      display: block;
    }
  }

  .cart-warning-container {
    padding: 18px !important;
  }
`;
