import { StyledIcon } from './style';
import { CSSProperties } from 'react';

export interface IconProps {
  name?: string;
  type?:
    | 'small'
    | 'medium'
    | 'large'
    | 'card'
    | 'filter-icon'
    | 'avarage'
    | 'header'
    | 'any'
    | 'headerMiddle'
    | 'footerMobile'
    | 'footer'
    | 'cartDrawerClose';
  svg?: boolean;
  style?: CSSProperties;
  onClick?: any;
  className?: string;
}

export const Icon = ({
  name,
  type = 'medium',
  svg = true,
  style,
  onClick,
  className,
}: IconProps) => {
  return (
    <StyledIcon type={type} onClick={onClick} className={className}>
      <img src={svg ? `/svgs/${name}.svg` : `/${name}`} style={style} />
    </StyledIcon>
  );
};
