import React from 'react';

interface PayloadType {
  refetch?: Function;
  isFilter?: boolean;
  isOpenMobileFilter?: boolean;
  isOpenMobileMenu?: boolean;
  openCloseDrawer?: Function;
}

type PayloadAction = {
  type: 'SAVE_PAYLOAD' | 'REFETCH';
  payload: PayloadType;
};

type RefetchAction = {
  type: 'REFETCH';
};

type Action = PayloadAction | RefetchAction;

type Dispatch = (action: Action) => void;

type State = {
  isFilter?: boolean;
  isOpenMobileFilter?: boolean;
  isOpenMobileMenu?: boolean;
  refetch: Function;
  openCloseDrawer: Function;
};

type FilterProviderProps = { children: React.ReactNode };

const initialState: State = {
  isFilter: false,
  isOpenMobileFilter: false,
  isOpenMobileMenu: false,
  openCloseDrawer: () => null,
  refetch: () => null,
};

const FilterStateContext = React.createContext<State>(initialState);
const FilterDispatchContext = React.createContext<Dispatch>(() => {});

function filterReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SAVE_PAYLOAD': {
      let payload = action.payload;
      return {
        ...state,
        ...payload,
      };
    }
    case 'REFETCH': {
      state.refetch();
      return state;
    }
    default:
      return state;
  }
}

function FilterProvider({ children }: FilterProviderProps) {
  const [state, dispatch] = React.useReducer(filterReducer, initialState);

  return (
    <FilterStateContext.Provider value={state}>
      <FilterDispatchContext.Provider value={dispatch}>
        {children}
      </FilterDispatchContext.Provider>
    </FilterStateContext.Provider>
  );
}

const useFilterState = () => React.useContext(FilterStateContext);
const useFilterDispatch = () => React.useContext(FilterDispatchContext);

export { FilterProvider, useFilterDispatch, useFilterState };
