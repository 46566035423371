import styled from 'styled-components';

export const StyledPartnersPage = styled('div')`
  .ant-table-cell::before {
    content: none !important;
    border: none !important;
    display: none !important;
  }

  .partners {
    height: 115px;
    background-color: ${(props) => props.theme.filterBackground};
    padding: 0 20px;

    label {
      bottom: -10px;
    }

    .under-text {
      position: relative;
      top: -18px;
      font-size: 14px;
      color: ${(props) => props.theme.underTextColor};
      z-index: 3;
    }
  }

  .actions-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .actions-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inbound {
    display: flex;
    gap: 15px;
  }

  .underline {
    text-decoration: underline;
  }
`;
