import Cart from 'public/components/DefaultLayout/Cart';
import { FarmProduct, useCartState } from 'public/context/CartContext';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from 'ui';
import useQueryApiClient from 'utils/useQueryApiClient';
import { StyledOrderPage } from './style';

export const OrderPage = () => {
  const [farmProducts, setFarmProducts] = useState<FarmProduct[]>([]);
  const [priceTotal, setPriceTotal] = useState<number>(0);
  let [searchParams] = useSearchParams();
  const orderId = searchParams.get('order_id');

  const { data, isLoading: orderLoading } = useQueryApiClient({
    request: {
      url: `/api/v2/farm/order/${orderId}`,
      method: 'GET',
    },
    onSuccess: (response) => {
      setFarmProducts(response.farmProducts);
      setPriceTotal(response.priceTotal);
    },
  });

  return (
    <StyledOrderPage>
      <div className={'container'}>
        <Spinner spinning={orderLoading}>
          <Cart
            priceTotal={priceTotal}
            farmProducts={farmProducts}
            setCurrent={() => {}}
            current={3}
            isView={true}
          />
        </Spinner>
      </div>
    </StyledOrderPage>
  );
};
