import { StyledRequirements } from './style';
import { useIntl } from 'react-intl';
import { options } from '../../config/config';

export const Requirements = () => {
  const intl = useIntl();

  return (
    <StyledRequirements>
      <div className={'reqs'}>
        <div className={'req'}>
          <div className={'intl'}>
            {intl.formatMessage({ id: 'gallery.requirement_max_images' })} -
          </div>
          <div>{options.images.maxImages}</div>
        </div>
        <div className={'req'}>
          <div className={'intl'}>
            {intl.formatMessage({ id: 'gallery.requirement_max_size' })} -
          </div>
          <div>{options.images.maxSizeInMB}MB</div>
        </div>
        <div className={'req'}>
          <div className={'intl'}>
            {intl.formatMessage({ id: 'gallery.requirement_allowed_formats' })}-
          </div>
          <div className={'formats'}>
            {options.images.allowedFormats.map((format, index) => {
              const formats = format.split('/');
              return (
                <div key={index}>
                  {formats[1]}
                  {!(index === formats.length) ? ',' : ''}
                </div>
              );
            })}
          </div>
        </div>
        <div className={'req'}>
          <div className={'intl'}>
            {intl.formatMessage({ id: 'gallery.requirement_image_size' })} -
          </div>
          <div>{options.images.maxImageSize}px</div>
        </div>
      </div>
    </StyledRequirements>
  );
};
