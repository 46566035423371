import styled from 'styled-components';
import { Form } from 'antd';

export const StyledMobileAuthForm = styled(Form)`
  font-family: ${(props) => props.theme.fontText};
  .mobile-login-form-title {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid ${(props) => props.theme.borderBottom};
  }

  .ant-form-item {
    margin-bottom: 10px;
    background-color: ${(props) => props.theme.inputBitDarkerOverlay};
  }

  .mobile-forgot-password {
    display: flex;
    justify-content: center;
    padding: 5px 0 15px 0;
  }

  .mobile-login-submit-btn {
    display: flex;
    flex-direction: row-reverse;
    height: 56px;
    width: 100%;
    background: transparent;
    color: ${(props) => props.theme.darkRed};
    border: 1px solid ${(props) => props.theme.darkRed};
    font-weight: 500;
    font-size: 16px;
  }

  .mobile-login-connect {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 28px;

    &.google {
      padding: 15px 0 15px 0;
      border-bottom: 1px solid ${(props) => props.theme.borderBottom};
      margin-bottom: 15px;
    }

    .ant-btn {
      padding: 0;
    }
    .mobile-login-google-btn {
      color: ${(props) => props.theme.darkRed};
    }
  }
`;
