import {CategoryData} from "../../../types/CategoryData";
import React, {useRef} from "react";
import {useDraggable} from "@dnd-kit/core";
import {StyledDraggable} from "./style";
import {CategoryTab} from "../../components/Category";

interface DraggableCategoryProps {
  category: CategoryData
  isDragging: boolean
  onEdit: (category: CategoryData) => void
  handleOpen?: () => void
  isOpen?: boolean
}

const DraggableCategory = ({
  category,
  isDragging,
  onEdit,
  handleOpen,
  isOpen,
}: DraggableCategoryProps) => {
  const handleRef = useRef<HTMLDivElement>(null)

  const {attributes, listeners, setNodeRef} = useDraggable({
    id: category.id ? category.id.toString() : 'none',
    data: {category: JSON.stringify(category)},
  })

  return (
    <StyledDraggable>
      <div
        ref={setNodeRef}
        {...attributes}
        className={isDragging ? 'dragging' : ''}
      >
        <CategoryTab
          listeners={listeners}
          handleRef={handleRef}
          name={category.name}
          vat={category.vat}
          handleAction={() => onEdit(category)}
          hasSubcategories={category.has_subcategories}
          handleOpen={handleOpen}
          isOpen={isOpen}
        />
      </div>
    </StyledDraggable>
  )
}

export default DraggableCategory
