import React, { useState, useEffect, useRef } from 'react';
import { StyledSecondHeader } from './style';
import { useIntl } from 'react-intl';
import { Button, Icon, Search } from 'ui';
import { useCategoryState } from '../../../context';
import { CategoryData } from 'types/CategoryData';
import CategoryMenu from '../../../../ui/Menu/CategoryMenu';
import { useNavigate } from 'react-router-dom';

import { Dropdown, MenuProps } from 'antd';
import ProfileMenu from '../ProfileMenu';
import CartDrawer from 'ui/Drawer/Drawers/CartDrawer';
import { useCartState } from 'public/context/CartContext';
import { useAuth } from '../../../../hooks/useAuth';

const DefaultSecondHeader = () => {
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState<boolean>(false);
  const [menuData, setMenuData] = useState<CategoryData[]>([]);
  const [showDropdownOverlay, setShowDropdownOverlay] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const { priceTotal } = useCartState();
  const { category } = useCategoryState();
  const { user } = useAuth();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <ProfileMenu fullName={`${user?.name ?? ''} ${user?.surname ?? ''}`} />
      ),
    },
  ];

  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (category ? category : []) {
      const categories: CategoryData[] =
        Object.values(category ? category : []) || [];
      setMenuData(categories);
    }
  }, [category]);

  const toggleMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  const handleNavigateProducts = () => {
    navigate(`products/all`);
  };

  const handleOnClickFarms = () => {
    navigate(`farms/all`);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onCartClose = () => {
    setIsCartDrawerOpen(false);
  };

  return (
    <StyledSecondHeader>
      <div className="content-wrapper">
        <div className="container">
          <div className="content">
            <div>
              {showMenu && !!menuData.length && (
                <div ref={menuRef} className={'menu-pos'}>
                  <CategoryMenu categories={menuData} />
                </div>
              )}
            </div>
            <div className="block block-1">
              <Button
                onMouseEnter={toggleMenu}
                icon={<Icon name={'arrow-down-white'} type={'small'} />}
                iconPosition={'end'}
                size={'large'}
                className="products-link"
                label={intl.formatMessage({ id: 'general.products' })}
                onClick={handleNavigateProducts}
              />
              <Button
                type="primary"
                size={'small'}
                label={intl.formatMessage({ id: 'general.manufacturers' })}
                onClick={handleOnClickFarms}
              />
              <Button
                type="text"
                label={intl.formatMessage({ id: 'general.sales' })}
                href={'/products/all/discounted'}
              />
              <Button
                type="text"
                label={intl.formatMessage({ id: 'general.news' })}
                href={'/products/all/new'}
              />
            </div>
            <Search
              prefix={<Icon name={'search-icon-black'} />}
              isPlaceholder={false}
            />
            <div className="block block-2">
              <Button
                type="text"
                label={intl.formatMessage({ id: 'general.whish_list' })}
                icon={<Icon name={'heart-black'} />}
              />
              <div className={'second-header-btn-wrapper'}>
                <div className={'second-header-cart-total'}>
                  {priceTotal.toFixed(2) + ' €'}
                </div>
                <Button
                  type="text"
                  label={intl.formatMessage({ id: 'general.my_basked' })}
                  icon={<Icon name={'shopping-cart-black'} />}
                  onClick={() => setIsCartDrawerOpen(true)}
                />
              </div>
            </div>
            {user?.authenticated ? (
              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                onOpenChange={(open) => setShowDropdownOverlay(open)}
                getPopupContainer={(trigger) =>
                  trigger.parentNode as HTMLElement
                }
                arrow={true}
              >
                <div className={'profile-button'}>
                  <Button
                    type="text"
                    label={intl.formatMessage({ id: 'general.profile' })}
                    icon={<Icon name={'user-profile-black'} />}
                  />
                  <Icon name={'arrow-down'} className={'profile-icon'} />
                </div>
              </Dropdown>
            ) : (
              <Button
                className="profile-btn"
                type="text"
                label={intl.formatMessage({ id: 'general.profile' })}
                icon={<Icon name={'user-profile-black'} />}
                onClick={() => navigate('/login')}
              />
            )}
          </div>
        </div>
      </div>
      <CartDrawer
        onCartClose={onCartClose}
        isCartDrawerOpen={isCartDrawerOpen}
      />
      {showMenu && <div className="overlay" />}
      {showDropdownOverlay && <div className="overlay" />}
    </StyledSecondHeader>
  );
};

export default DefaultSecondHeader;
