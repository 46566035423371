import { StyledFileUploaderForm } from './style';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Dispatch, useCallback, useMemo, useState } from 'react';
import { RequirementProps, Requirements } from './Uploader/Requirements';
import { CustomUploadList } from './Uploader/CustomUploadList';
import { UploadFile } from 'antd/es/upload/interface';
import { getBase64 } from '../../utils/productCardHelpers';
import { message } from 'antd';
import { Uploader } from './Uploader';

interface FileUploaderProps {
  onValidate: (isValid: boolean) => void;
  onFileListChange?: Dispatch<any>;
  fileList: any;
  multiple?: boolean;
  onFileListRemove?: Dispatch<any>;
  requirement?: RequirementProps;
  isCrop?: boolean;
  needThumb?: boolean;
  showActions?: boolean;
  title?: string | null;
  isColumn?: boolean;
}

const Title = styled.div`
  font-weight: 600;
`;

export const FileUploader = ({
  onValidate,
  onFileListChange,
  fileList,
  multiple = true,
  onFileListRemove,
  requirement,
  isCrop = true,
  needThumb = true,
  showActions = true,
  title,
  isColumn,
}: FileUploaderProps) => {
  const intl = useIntl();

  const UploaderTitle = useMemo(() => {
    if (title) return title;
    else if (title === null) return null;
    return multiple
      ? intl.formatMessage({ id: 'general.gallery_title' })
      : intl.formatMessage({ id: 'general.logo_title' });
  }, [multiple, intl, title]);

  const [thumbUrls, setThumbUrls] = useState<{ [uid: string]: string }>({});
  const [, setEditingFile] = useState<UploadFile | null>(null);

  const handleEditBeforeUpload = useCallback(
    async (file: any) => {
      const croppedFile: UploadFile = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: URL.createObjectURL(file),
        originFileObj: file,
      };
      if (onFileListChange) {
        if (multiple) {
          onFileListChange((files: any) => [...files, croppedFile]);
        } else onFileListChange([croppedFile]);
      }
      let updatedThumbUrls: { [uid: string]: string } = {};
      await Promise.all(
        fileList.map(async (file: UploadFile) => {
          if (!file.url && !file.thumbUrl && file.originFileObj) {
            updatedThumbUrls[file.uid] = await getBase64(
              file.originFileObj as File
            );
          } else if (file.thumbUrl) {
            updatedThumbUrls[file.uid] = file.thumbUrl;
          }
        })
      );

      setThumbUrls((prev) => ({ ...prev, ...updatedThumbUrls }));
      message.success(intl.formatMessage({ id: 'images.edited_successfully' }));
      setEditingFile(null);
      return false;
    },
    [fileList, intl, multiple, onFileListChange]
  );

  const handleRemove = useCallback(
    (file: UploadFile) => {
      const newFileList = fileList.filter((item: any) => item.uid !== file.uid);
      const newThumbUrls = { ...thumbUrls };
      delete newThumbUrls[file.uid];
      setThumbUrls(newThumbUrls);
      if (onFileListChange) {
        onFileListChange(newFileList);
      }
      if (onFileListRemove && typeof file.uid === 'number') {
        onFileListRemove((removedFile: any) => [...removedFile, file.uid]);
      }
    },
    [fileList, onFileListChange, onFileListRemove, thumbUrls]
  );

  const UploadList = useMemo(
    () => (
      <CustomUploadList
        fileList={fileList}
        thumbUrls={thumbUrls}
        onRemove={handleRemove}
        onEdit={handleEditBeforeUpload}
        isCrop={isCrop}
        needThumb={needThumb}
        showActions={showActions}
      />
    ),
    [
      fileList,
      handleEditBeforeUpload,
      handleRemove,
      isCrop,
      needThumb,
      showActions,
      thumbUrls,
    ]
  );

  return (
    <StyledFileUploaderForm className={multiple && !isColumn ? 'multiple' : ''}>
      {UploaderTitle && <Title>{UploaderTitle}</Title>}
      <div className={'requirements'}>
        <div>
          <Requirements {...(requirement || {})} multiple={multiple} />
          <div className={'image-upload'}>
            <Uploader
              onFileListChange={onFileListChange}
              fileList={fileList}
              multiple={multiple}
              thumbUrls={thumbUrls}
              handleRemove={handleRemove}
              setThumbUrls={setThumbUrls}
              onValidate={onValidate}
              handleEditBeforeUpload={handleEditBeforeUpload}
              isCrop={isCrop}
              needThumb={needThumb}
              showActions={showActions}
              isColumn={isColumn}
            />
          </div>
        </div>
        {isColumn ? UploadList : !multiple ? UploadList : null}
      </div>
    </StyledFileUploaderForm>
  );
};
