import React from 'react';
import Router from 'admin/components/Router';
import { Layout } from 'antd';
import { StyledPage } from './style';
import DefaultHeader from './DefaultHeader';
import PageContent from './PageContent';
import PageLayout from './PageLayout';
import PageFooter from './PageFooter';
import Sider from './Sider';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useCategoryDispatch } from '../../../public/context';
import { Spinner } from '../../../ui';
const { Content } = Layout;

const DefaultLayout = () => {
  const dispatchCategory = useCategoryDispatch();

  const { refetch: refetchCategory, isLoading: isLoadingCategory } =
    useQueryApiClient({
      request: {
        url: 'api/v2/categories/menu/desktop',
      },
      onSuccess: (response) => {
        dispatchCategory({
          type: 'SAVE_PAYLOAD',
          payload: { category: response, refetch: refetchCategory },
          isLoading: isLoadingCategory,
        });
      },
    });

  return (
    <StyledPage>
      <Spinner spinning={isLoadingCategory} dontRender>
        <Layout className="layout">
          <Sider />
          <Layout>
            <DefaultHeader />
            <Content>
              <Router />
            </Content>
          </Layout>
        </Layout>
      </Spinner>
    </StyledPage>
  );
};

DefaultLayout.PageLayout = PageLayout;
DefaultLayout.PageContent = PageContent;
DefaultLayout.PageFooter = PageFooter;

export default DefaultLayout;
