import { useIntl } from 'react-intl';
import { Button } from 'ui';
import { StyledDeliveryDrawer, StyledDeliveryDrawerFooter } from './style';
import { FarmDeliveries } from 'types/FarmData';
import { CartProduct } from 'public/context/CartContext';
import { Form } from 'antd';

interface DeliveryDrawerProps {
  isDeliveryDrawerOpen: boolean;
  onDeliveryClose: Function;
  children?: React.ReactNode;
  id?: string;
  footer?: JSX.Element;
  closeIcon?: React.ReactNode;
}

const DeliveryDrawer = ({
  isDeliveryDrawerOpen,
  onDeliveryClose,
  children,
  id,
  footer,
  closeIcon,
}: DeliveryDrawerProps) => {
  const intl = useIntl();

  const innerOnClose = () => {
    onDeliveryClose();
  };

  return (
    <StyledDeliveryDrawer
      id={id}
      width={512}
      open={isDeliveryDrawerOpen}
      onClose={innerOnClose}
      footer={footer}
      closeIcon={closeIcon}
    >
      {children}
    </StyledDeliveryDrawer>
  );
};

export default DeliveryDrawer;
