import styled from 'styled-components'
export const ContractModalContent = styled.div`
span, p {
    margin: 6px 0px;
}

.horizont-line-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .horizont-line {
    border-bottom: 1px solid ${(props) => props.theme.modalHorizontalLine};
    width: 110%;
  }

  .contract-info {
    color: ${(props) => props.theme.contractTextColor};
    font-weight: bold;
  }

  .contract-accepted {
    color: ${(props) => props.theme.contractTextColor};
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .checked {
    color: ${(props) => props.theme.checked};
  }

  .minus {
    color: ${(props) => props.theme.minus};
  }

  .contract-modal {
    border-radius: 30px;
  }
`

export const TittleModal = styled.span`
    color: ${(props) => props.theme.contractTextTitleColor};
    font-weight: 500;
    position: relative;
    top: -5px;
    font-weight: 600;
`