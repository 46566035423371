import { StyledCartAmountSelector } from './style';
import { ProductData } from 'types/ProductData';
import { useCartDispatch, useCartState } from 'public/context/CartContext';

interface CartAmountSelectorProps {
  product: ProductData | undefined;
  unitType?: string;
  amountCart: number;
  className?: string;
}

const CartAmountSelector = ({
  product,
  unitType,
  amountCart = 0,
  className,
}: CartAmountSelectorProps) => {
  const dispatch = useCartDispatch();

  const step = 1;

  if (!product) return null;

  const handleIncrement = () => {
    dispatch({
      type: 'ADD_ITEM',
      product: product,
      amount: 1,
    });
  };

  const handleDecrement = () => {
    const newAmount = Math.max(0, amountCart - step);

    if (newAmount === 0 || newAmount < (product.storage_limit_min ?? 1)) {
      dispatch({
        type: 'REMOVE_ITEM',
        productId: product.id,
      });
    } else {
      dispatch({
        type: 'ADD_ITEM',
        product: product,
        amount: -1,
      });
    }
  };

  return (
    <StyledCartAmountSelector>
      <div
        className={
          className ? 'amount-selector ' + className : 'amount-selector'
        }
      >
        <button onClick={handleDecrement}>-</button>
        <div className={'unit-unit-type'}>
          <span>{amountCart * product.step}</span>
          <span>{unitType}</span>
        </div>
        <button onClick={handleIncrement}>+</button>
      </div>
    </StyledCartAmountSelector>
  );
};

export default CartAmountSelector;
