import { StyledDrawer, StyledFooter } from './style';
import { BannerProps } from '../../../types/BannerData';
import { Input } from '../../Input';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { Button } from '../../Button';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../Spinner';
import { options } from '../../../config/config';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import {FileUploader} from "../../UploadImage/FileUploader";

interface DrawerProps {
  open?: boolean;
  onClose?: () => void;
  banner: BannerProps | null;
  updateBanner: (values: any) => void;
  updateSuccess?: boolean;
  setUpdateSuccess?: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void;
  isLoading: boolean;
}

export const EditBannerDrawer = ({
  open,
  onClose,
  banner,
  updateBanner,
  updateSuccess,
  isLoading,
  setUpdateSuccess,
}: DrawerProps) => {
  const [imagesData, setImagesData] = useState<any>([]);
  const [removedImageIds, setRemovedImageIds] = useState<number[]>([]);
  const [productsCount, setProductsCount] = useState<number>(0);
  const intl = useIntl();
  const [form] = useForm();

  useQueryApiClient({
    request: {
      url: '/api/v2/products/get/count',
    },
    onSuccess: (response: any) => setProductsCount(response),
  });
  const bannersSize = {
    banner_1: '1920 x 365 ',
    banner_2: '1440 x 397 ',
    banner_3: '1440 x 574 ',
    banner_4: '1440 x 481 ',
  };

  useEffect(() => {
    if (banner) {
      form.setFieldsValue({
        title: banner.title,
        url: banner.url,
      });

      const initialImagesData = banner.images.map((image, index) => ({
        uid: image.id || index.toString(),
        id: image.id,
        name: image.image_name || `Image ${index + 1}`,
        status: 'done',
        url: image.url_original,
        thumbUrl: image.url_thumbnail,
        url_to: image.url_to || '',
        originFileObj: null,
      }));

      setImagesData(initialImagesData);
    }

    if (updateSuccess && setUpdateSuccess) {
      form.resetFields();
      setImagesData([]);
      setRemovedImageIds([]);

      setUpdateSuccess(false);
    }
  }, [banner, form, updateSuccess]);

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const formData = new FormData();

    formData.append('title', values.title ?? '');
    formData.append('url', values.url ?? '');

    const existingImages = imagesData.filter(
      (file: any) => !file?.originFileObj
    );
    const newImages = imagesData.filter((file: any) => file?.originFileObj);

    existingImages.forEach((file: any, index: number) => {
      formData.append('existing_image_ids[]', file?.id || '');
      formData.append('existing_url_to[]', file?.url_to || '');
    });

    newImages.forEach((file: any, index: number) => {
      formData.append('images[]', file?.originFileObj);
      formData.append('url_to[]', file?.url_to || '');
    });

    if (removedImageIds.length > 0) {
      removedImageIds.forEach((id: any) => {
        formData.append('removed_image_ids[]', id);
      });
    }

    updateBanner(formData);
  };

  const TotalProducts = () => {
    return (
      <div className={'product-count-wrapper'}>
        <div className={'productCount'}>
          <span>
            {intl.formatMessage({ id: 'banners.total_product_count' })}
          </span>
          <h1>{productsCount}</h1>
        </div>
      </div>
    );
  };

  const Footer = () => {
    return (
      <StyledFooter>
        <Button
          label={intl.formatMessage({ id: 'general.submit' })}
          htmlType={'submit'}
          onClick={handleSubmit}
        />
      </StyledFooter>
    );
  };

  return (
    <StyledDrawer
      open={open}
      closable
      onClose={onClose}
      footer={<Footer />}
      width={1000}
      title={intl.formatMessage({ id: 'general.edit_banner' })}
    >
      <Spinner spinning={isLoading}>
        <Form layout={'vertical'} form={form} name={'banner_edit'}>
          <Input
            value={banner?.title}
            label={intl.formatMessage({ id: 'general.name' })}
            name={'title'}
            validations={'requiredText'}
          />
          <div>
            {banner?.banner_type === 'static' ? (
              <Input
                value={banner?.url}
                type={'text'}
                label={intl.formatMessage({ id: 'banners.url' })}
                name={'url'}
              />
            ) : (
              ''
            )}
            <TotalProducts />
            <div className={'label'}>
              {intl.formatMessage({ id: 'general.upload_image' })}
            </div>
            <Form.Item name={'images'}>
              <FileUploader
                onFileListChange={setImagesData}
                onFileListRemove={setRemovedImageIds}
                fileList={imagesData}
                multiple={banner?.type !== 'banner_1'}
                isCrop={false}
                requirement={{
                  maxImageSize: banner?.type
                      ? bannersSize[banner?.type]
                      : options.images.maxImageSize,
                  multiple: banner?.type !== 'banner_1',
                }}
                onValidate={()=> {}}
              />
            </Form.Item>
          </div>
        </Form>
      </Spinner>
    </StyledDrawer>
  );
};
