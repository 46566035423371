import styled from 'styled-components';

interface StyledIconProps {
  type?:
    | 'small'
    | 'medium'
    | 'large'
    | 'card'
    | 'filter-icon'
    | 'avarage'
    | 'header'
    | 'any'
    | 'headerMiddle'
    | 'footerMobile'
    | 'footer'
    | 'cartDrawerClose';
}

const getIconDimensions = (type: StyledIconProps['type'], theme: any) => {
  switch (type) {
    case 'small':
      return {
        width: theme.iconWidthSM,
        height: theme.iconHeightSM,
      };
    case 'medium':
      return {
        width: theme.iconWidthMED,
        height: theme.iconHeightMED,
      };
    case 'large':
      return {
        width: theme.iconWidthLG,
        height: 'auto',
      };
    case 'filter-icon':
      return {
        width: theme.iconWidthFilter,
        height: theme.iconHeightFilter,
      };
    case 'avarage':
      return {
        width: theme.iconWidthAverage,
        height: theme.iconHeightAverage,
      };
    case 'header':
      return {
        width: theme.iconWidthHeader,
        height: theme.iconHeightHeader,
      };
    case 'card':
      return {
        width: theme.iconWidthCard,
        height: theme.iconHeightCard,
      };
    case 'headerMiddle':
      return {
        width: theme.iconWidthHeaderMiddle,
        height: theme.iconHeihtHeaderMiddle,
      };
    case 'footerMobile':
      return {
        width: theme.iconWidthMobileFooter,
        height: theme.iconHeihtMobileFooter,
      };
    case 'footer':
      return {
        width: theme.iconWidthFooter,
        height: theme.iconHeihtFooter,
      };
    case 'cartDrawerClose':
      return {
        width: theme.iconWidthCartDrawerClose,
        height: theme.iconHeihtCartDrawerClose,
      };
    case 'any':
    default:
      return {
        width: theme.iconWidthAny,
        height: theme.iconHeightAny,
      };
  }
};

export const StyledIcon = styled.div<StyledIconProps>`
  ${({ theme, type }) => {
    const { width, height } = getIconDimensions(type, theme);

    return `
      width: ${width};
      height: ${height};

      img {
        width: ${width};
        height: ${height};
      }
    `;
  }}
`;
