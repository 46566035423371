import dayjs, { Dayjs } from 'dayjs';

export const convertToDayJs = (configOpenHours: any) => {
  const result: Record<
    number,
    { no: Dayjs | null; lidz: Dayjs | null; closed: boolean }
  > = {};

  for (let i = 0; i < 7; i++) {
    const { no, lidz, closed } = configOpenHours[i];
    result[i] = {
      no: no ? dayjs(no, 'HH:mm') : null,
      lidz: lidz ? dayjs(lidz, 'HH:mm') : null,
      closed: !!closed,
    };
  }

  return result;
};
