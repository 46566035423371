import { StyledOrderItems } from './style';
import { useIntl } from 'react-intl';
import { ItemProps, Product } from '../../../../../interfaces/BackendModels';

interface OrderItemsTableProps {
  products: ItemProps[];
}

export const OrderItemsTable = ({ products }: OrderItemsTableProps) => {
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.product' }),
      dataIndex: 'product_name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.price' }),
      dataIndex: 'stock_price',
      render: (value: number, record: ItemProps) => {
        return (
          <>
            {value} € /{record.stock_unit}
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'general.amount_table' }),
      dataIndex: 'stock_count',
      render: (value: number) => {
        return <>x {value}</>;
      },
    },
    {
      title: intl.formatMessage({ id: 'general.total' }),
      dataIndex: 'stock_total',
      render: (value: number) => {
        return <>€ {value}</>;
      },
    },
  ];

  return <StyledOrderItems columns={columns} dataSource={products} />;
};
