import { Select, SelectOption } from 'ui';
import { Validations } from 'interfaces';

interface PaginationSelectProps extends Validations {
  name?: (string | number)[] | string | number;
  mode?: 'multiple' | 'tags';
  noStyle?: boolean;
  label?: string;
  disabled?: boolean;
  className?: string;
  defaultValue?: number;
  onChange?: (sizePerPage: number) => void;
}

const paginationOptions = [
  { key: 1, value: 10, label: '10' },
  { key: 2, value: 25, label: '25' },
  { key: 3, value: 50, label: '50' },
  { key: 4, value: 100, label: '100' },
];

const PaginationSelect = ({
  name,
  mode,
  validations,
  noStyle,
  label,
  disabled,
  className = '',
  onChange,
}: PaginationSelectProps) => {
  return (
    <Select
      mode={mode}
      label={label}
      name={name}
      validations={validations}
      noStyle={noStyle}
      disabled={disabled}
      className={className}
      defaultValue={paginationOptions[1].value}
      onChange={onChange}
    >
      {paginationOptions.map((option) => (
        <SelectOption key={option.key} value={option.value}>
          {option.label}
        </SelectOption>
      ))}
    </Select>
  );
};

export default PaginationSelect;
