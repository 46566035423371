import React, { useState } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import { Button, Tabs, Table, Dropdown, Input } from 'ui';
import Filter from 'admin/components/Filter';
import { StyledButtonList } from 'styles/buttonList';
import dayjs from 'dayjs';
import useQueryApiClient from 'utils/useQueryApiClient';
import FileDownload from 'js-file-download';
import { MenuProps } from 'antd/es/menu';

const UserListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState<any>({});
  const [totalCount, setTotalCount] = useState<any>({});

  const intl = useIntl();

  const { appendData: exportData } = useQueryApiClient({
    request: {
      url: `api/v2/users/farms/export`,
      data: {
        filter: advancedSearch,
      },
      multipart: true,
      method: 'POST',
    },
    onSuccess: (response, format) => {
      FileDownload(
        response,
        `${format}_${dayjs().format('YYYY-MM-DD')}.${format}`
      );
    },
  });

  const handlePrint = () => {
    const printContents = document.getElementById('printTable')?.innerHTML;
    if (!printContents) return;

    const newWindow = window.open('', '', 'width=800,height=600');
    if (newWindow) {
      newWindow.document.write(`
      <html>
        <head>
          <title>Print Table</title>
          <style>
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #000; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
      newWindow.document.close();
      newWindow.focus();
      newWindow.print();
      newWindow.close();
    }
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.user_id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'farm_users.full_name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'general.email' }),
      dataIndex: 'email',
    },
    {
      title: intl.formatMessage({ id: 'general.role' }),
      dataIndex: 'role',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
  ];

  const items = [
    {
      key: 'farm_users',
      label: intl.formatMessage({ id: 'navigation.users' }),
      children: (
        <>
          <Filter
            onFinish={setAdvancedSearch}
            isPagination
            totalRecord={totalCount.total}
          >
            <Input label={intl.formatMessage({ id: 'general.id' })} name="id" />
            <Input
              label={intl.formatMessage({ id: 'farm_users.full_name' })}
              name="full_name"
            />
            <Input
              label={intl.formatMessage({ id: 'general.email' })}
              name="email"
            />
            <Input
              label={intl.formatMessage({ id: 'general.role' })}
              name="role"
            />

            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />
          </Filter>
          <div id={'printTable'}>
            <Table
              url="/api/v2/users"
              columns={columns}
              filter={advancedSearch}
              page_size={advancedSearch.page_size ?? 25}
              paginnationStatus={setTotalCount}
            />
          </div>
        </>
      ),
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'xlsx' || e.key === 'csv' || e.key === 'pdf') {
      exportData({ format: e.key, filter: advancedSearch }, [], e.key);
    }

    if (e.key === 'print') {
      handlePrint();
    }
  };

  const dropdownItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'general.print' }),
      key: 'print',
    },
    {
      label: intl.formatMessage({ id: 'general.copy' }),
      key: '2',
    },
    {
      label: intl.formatMessage({ id: 'general.excel' }),
      key: 'xlsx',
    },
    {
      label: intl.formatMessage({ id: 'general.csv' }),
      key: 'csv',
    },
    {
      label: intl.formatMessage({ id: 'general.pdf' }),
      key: 'pdf',
    },
  ];

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  };

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.users' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Dropdown menu={menuProps}>
                <Button
                  label={intl.formatMessage({ id: 'general.export' })}
                  type="primary"
                />
              </Dropdown>
            </StyledButtonList>
          }
          items={items}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default UserListPage;
