import styled from 'styled-components';

export const TestPageWrapper = styled.div`
  .popular-products {
    padding: 32px 0 60px 0;
  }

  .popular-categories ~ .desktop-banner {
    padding: 50px 0;
  }

  .popular-products h1,
  .popular-categories h1 {
    font-family: ${(props) => props.theme.fontText};
    font-size: 42px;
    font-weight: 500;
    line-height: 38px;
  }

  .popular-categories .title {
    padding-bottom: 0;
  }

  .popular-categories h1 {
    margin-bottom: 54px;
  }

  .second-banner {
    padding: 50px 20px;

    .slick-dots-bottom {
      display: none !important;
    }
  }

  .third-banner {
    padding-top: 10px;

    .slick-slide {
      justify-content: center !important;
    }

    .banner-image-wrapper {
      padding-bottom: 40px;
    }
  }

  .fourth-banner {
    padding-top: 50px;
    padding-bottom: 8px;
  }
  .banner-1 {
    background-color: ${(props) => props.theme.white};
  }

  .banner-2 {
    background-color: ${(props) => props.theme.containerBgColor};
  }

  .grid-view {
    padding: 0 !important;
    column-gap: 33px;
    row-gap: 31px;
  }

  .banner-image-wrapper {
    width: 100%;
    padding: 50px 0;
    margin: 0 auto;
  }

  .banner_1 {
    padding: 0;
  }

  .slick-slider {
    margin-bottom: 0;
  }

  .banners {
    padding-top: 100px;
    padding-bottom: 58px;
    .container > div {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .banner-wrapper, .banner-image-wrapper {
      padding: 0 !important;
    }
  }

  .banner-1 .slick-dots.slick-dots-bottom,
  .banner-2 .slick-dots.slick-dots-bottom {
    display: none !important;
  }

  .product-slider-container {
    padding: 0;
    padding-bottom: 10px;
  }

  .discount-items {
    background-color: ${(props) => props.theme.brightGrey};

    .slick-list:not(.desktop-banner .slick-list) {
      min-height: 397px;
    }
    .slick-dots {
      padding: 40px 0;
      bottom: 0;
    }
  }
  .discount-items .ant-card {
    margin-left: 0;
    margin-right: 0;
  }

  .discount-items .slick-slide {
    justify-content: space-between;
  }

  .discount-items .slick-dots .slick-active {
    width: 64px !important;
  }

  @media only screen and (max-width: 1200px) {
    .banner-wrapper.banner_1 {
      display: none;
    }
  }

  @media only screen and (max-width: 440px) {
    .popular-products {
      padding: 0px 0 48px 0;

      .title h1 {
        margin-top: 0;
      }

      .grid-view {
        padding-left: 0 !important;
        padding-right: 0 !important;
        row-gap: 14px;
      }

      .ant-card {
        margin-left: 0;
        margin-right: 0;
      }

      .ant-card-body {
        gap: 5px;
      }

      .ant-image {
        height: 100%;
      }

      .ant-image-img {
        height: 100%;
        min-height: 136px;
      }

      .custom-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-card-body .price-quality-marks .farm-name-container-mobile {
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price-quality-marks {
        margin-bottom: 0;
        gap: 5px;
      }

      .add-to-cart-container {
        padding-top: 5px;
      }

      .price-container-quality-marks-wraper {
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
      }
    }

    .discount-items {
      padding-top: 20px;

      .slick-list {
        min-height: 140px;
      }
    }

    .product-slider-container {
      .slick-list {
        min-height: 140px;
      }

      .title h1 {
        margin: 0;
        padding-top: 4px;
        padding-bottom: 9px;
      }

      .slick-dots {
        padding-top: 20px;
      }

      .slick-slide {
        display: grid !important;
        grid-template-columns: 1fr;
      }

      .ant-card {
        display: grid;
        grid-template-columns: 136px 1fr;
        height: 100%;

        .card-img {
          height: 100%;
          max-height: 153px;
          display: block;
        }

        .ant-image {
          height: 100%;
          display: block;
        }

        .ant-image-img {
          height: 100%;
          min-height: 136px;
        }

        .custom-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ant-card-body .price-quality-marks .farm-name-container-mobile {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ant-card-cover {
          height: 153px;
        }

        .ant-card-body {
          gap: 5px;
          justify-content: flex-start;
          height: 153px;
        }

        .price-quality-marks-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          padding-bottom: 10px;
        }

        .price-quality-marks {
          gap: 5px;
        }

        .price-quality-marks {
          margin-bottom: 0;
        }

        .price-type-second {
          line-height: 19px !important;
        }

        .price,
        .type-of-price,
        .price-type-second {
          font-size: 16px !important;
          font-weight: 500;
          line-height: 19px;
        }

        .add-to-cart-container {
          padding-bottom: 0px;
          padding-top: 5px;
        }

        .ant-card-body
          .add-to-cart-container
          .add-to-cart-icon
          .btn-add-to-cart {
          height: 30px;
        }

        .btn-add-to-cart .ant-btn {
          width: 30px !important;
          height: 30px !important;
        }
      }

      .view-all-items-button {
        padding-top: 5px;
        padding-bottom: 45px;
      }
    }

    .fourth-banner {
      display: block;
      padding-top: 20px;
      padding-bottom: 53px;
    }

    .banners {
      background-color: ${(props) => props.theme.brightGrey};

      .banner-image-wrapper {
        padding-top: 0;
      }

      .banner-wrapper {
        padding-bottom: 20px;
      }

      .banner-wrapper:last-child {
        padding-bottom: 0;
      }

      .slick-dots {
        display: none !important;
      }
    }
  }
`;
