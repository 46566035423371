import React from 'react';
import { StyledDivider } from './style';

interface DividerProps {
  marginTop?: number;
  marginBottom?: number;
}

export const Divider = ({
  marginTop = 20,
  marginBottom = 20,
}: DividerProps) => {
  return <StyledDivider style={{ marginTop, marginBottom }} />;
};
