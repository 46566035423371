import styled from 'styled-components';

export const WorkingTimeWrapper = styled.div`
  .working-time-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    margin-top: 0;
  }
  .day-time-row {
    height: 44px;
  }

  .day-time-row:nth-child(odd) {
    background-color: ${(props) => props.theme.white};
  }

  .day-time-row:nth-child(even) {
    background-color: ${(props) => props.theme.veryLightGray};
  }

  .day-name {
    font-size: 16px;
    line-height: 20px;
  }

  .day-time {
    font-size: 16px;
    line-height: 20px;
  }
`;
