import styled from 'styled-components'

export const StyledFilter = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
  padding: 15px 0 0 0;
  margin-bottom: 21px;

  .main-filter {
    background-color: ${({ theme }) => theme.filterBackground} !important;
    padding: 15px 20px;

    .horizontal-form {
      display: flex;
      align-items: end;
      gap: 10px;

      .left-side {
        display: flex;
        align-items: end;
        gap: 10px;
      }

      .right-side {
        display: flex;
        align-items: end;
        gap: 10px;
      }

      .ant-form-item {
        margin: 0;
      }
    }
  }

  .tag-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 20px;
  }

  .tag-list svg  {
    color: ${({ theme }) => theme.tagCloseColor};
  }

  .record-count {
    margin: 0;
    text-align: center;
    margin-left: auto;
  }
`
