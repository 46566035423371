import React, { useState } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import { Button, DatePicker, Input, Table, Dropdown, Modal } from 'ui';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Filter from '../../../components/Filter';
import useQueryApiClient from '../../../../utils/useQueryApiClient';
import EnumSelect from '../../../Selects/EnumSelect';
import { Pagination } from '../../../../types/Pagination';
import { Tabs } from 'antd';
import {
  OrderStatus,
  ActionsKeys,
  OrderPaymentStatus,
} from '../../../../enums/order-status';
import { OrdersViewOrder } from '../OrdersViewOrder/OrdersViewOrder';
import { TransactionsProps } from '../../../../interfaces/BackendModels';
import { log } from 'node:util';

const OrderListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState({});
  const [paginationStatus, setPaginationStatus] = useState<Pagination>({});
  const [reloadPage, setReloadPage] = useState<number>(0);
  const [cancelOrderId, setCancelOrderId] = useState<number>(0);
  const [isOnStatusChange, setIsOnStatusChange] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionsProps | null>(null);
  const [isOpenViewOrderDrawer, setIsOpenViewOrderDrawer] =
    useState<boolean>(false);
  const intl = useIntl();

  const { appendData: changeStatus } = useQueryApiClient({
    request: {
      url: `api/v2/orders/change-status`,
      method: 'POST',
    },
    onSuccess: () => {
      setIsOnStatusChange(false);
      setReloadPage(reloadPage + 1);
    },
    onError: () => {
      setIsOnStatusChange(false);
    },
  });

  const onCloseDrawer = () => {
    setIsOpenViewOrderDrawer(false);
    setSelectedTransaction(null);
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.date' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'orders.customer' }),
      dataIndex: 'customer_name',
    },
    {
      title: intl.formatMessage({ id: 'orders.number' }),
      dataIndex: 'invoice_nr',
    },
    {
      title: intl.formatMessage({ id: 'orders.status' }),
      dataIndex: 'status',
      render: (value: string) => {
        return intl.formatMessage({ id: `enum.${value.toUpperCase()}` });
      },
    },
    {
      title: intl.formatMessage({ id: 'orders.payment_status' }),
      dataIndex: 'payment_status',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'orders.total' }),
      dataIndex: 'total',
      render: (value: string) => (
        <span className="table-currency">€ {parseFloat(value).toFixed(2)}</span>
      ),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      render: (record: any) => {
        return (
          <Dropdown
            className="actions"
            overlayClassName="status-actions"
            getPopupContainer={(trigger: any) =>
              trigger.parentNode.querySelector('button') as HTMLElement
            }
            menu={{
              items: [
                {
                  label: intl.formatMessage({ id: 'general.buyer_order' }),
                  key: 'buyer_order',
                },
                {
                  label: intl.formatMessage({ id: 'general.view' }),
                  key: 'view',
                },
                ...(record.status != OrderStatus.CANCELED &&
                Object.values(OrderPaymentStatus).includes(
                  record.payment_status
                )
                  ? [
                      {
                        label: intl.formatMessage({ id: 'general.confirm' }),
                        key: '1',
                      },
                      {
                        label: intl.formatMessage({ id: 'general.canceled' }),
                        key: '2',
                      },
                    ]
                  : []),
              ],
              onClick: (value) => {
                if (value.key == 'buyer_order') {
                  // TODO NOGA-127
                  return;
                }

                if (value.key == ActionsKeys.CANCEL) {
                  setCancelOrderId(record.id);
                  return;
                }

                if (value.key == 'view') {
                  setIsOpenViewOrderDrawer(true);
                  setSelectedTransaction(record);
                }

                setIsOnStatusChange(true);

                changeStatus({
                  status: value.key,
                  id: record.id,
                });
              },
            }}
          >
            <Button
              className="action"
              label={intl.formatMessage({ id: 'general.actions' })}
              icon={<DownOutlined />}
              iconPosition="end"
              size="middle"
              ability={'farm_edit'}
            />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.orders' }),
        },
      ]}
    >
      <Modal
        open={cancelOrderId > 0}
        onCancel={() => setCancelOrderId(0)}
        destroyOnClose
        footer={[
          <Button
            key="yes"
            type="primary"
            onClick={() => {
              setIsOnStatusChange(true);
              changeStatus({
                status: OrderPaymentStatus.CONFIRM,
                id: cancelOrderId,
              });
              setCancelOrderId(0);
            }}
            label={intl.formatMessage({ id: 'general.yes' })}
          />,
          <Button
            key="no"
            type="primary"
            onClick={() => {
              setCancelOrderId(0);
            }}
            label={intl.formatMessage({ id: 'general.no' })}
          />,
        ]}
      >
        <h3>{intl.formatMessage({ id: 'general.cancel_purchase_info' })}</h3>
        <p>{intl.formatMessage({ id: 'general.transactions_warning' })}</p>
      </Modal>
      <Tabs
        items={[
          {
            key: 'purchases',
            label: intl.formatMessage({ id: 'navigation.purchases' }),
            children: (
              <DefaultLayout.PageContent>
                <Filter
                  onFinish={setAdvancedSearch}
                  totalRecord={paginationStatus?.total ?? 0}
                >
                  <DatePicker
                    label={intl.formatMessage({ id: 'general.date_from' })}
                    name="date_from"
                  />
                  <DatePicker
                    label={intl.formatMessage({ id: 'general.date_to' })}
                    name="date_to"
                  />
                  <span className="order-status-field">
                    <EnumSelect
                      label={intl.formatMessage({
                        id: 'orders.order_status',
                      })}
                      name="status"
                      code="OrderStatusType"
                    />
                  </span>
                  <Input
                    label={intl.formatMessage({ id: 'general.search' })}
                    name="search"
                  />
                  <Button
                    type="primary"
                    label={intl.formatMessage({ id: 'general.select' })}
                    htmlType="submit"
                  />
                </Filter>
                <Table
                  url="/api/v2/orders"
                  columns={columns}
                  filter={advancedSearch}
                  reload={reloadPage}
                  paginnationStatus={(el) => setPaginationStatus(el)}
                  loading={isOnStatusChange}
                />
                <OrdersViewOrder
                  open={isOpenViewOrderDrawer}
                  onClose={onCloseDrawer}
                  transaction={selectedTransaction}
                />
              </DefaultLayout.PageContent>
            ),
          },
        ]}
      ></Tabs>
    </DefaultLayout.PageLayout>
  );
};

export default OrderListPage;
