import styled, { createGlobalStyle } from 'styled-components';

interface FarmGaleriesWrapperProps {
  galeryCount: number;
}

export const GlobalStyle = createGlobalStyle<{ isModalActive: boolean }>`
    @media (min-width: 1200px) {
      body {
        height: ${({ isModalActive }) => (isModalActive ? '100vh' : 'auto')};
        overflow: ${({ isModalActive }) => (isModalActive ? 'hidden' : 'auto')};
      }
    }
`;

export const FarmGaleriesWrapper = styled.div<FarmGaleriesWrapperProps>`
  .image-gallery-wrapper-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 138px;
    display: none;
    justify-content: center;
    background-color: ${(props) => props.theme.backgroundOverlay};
    z-index: 1000;

    @media (min-width: 1201px) {
      display: flex;
    }
  }

  @media (max-width: 440px) {
    padding: 23px 20px 20px 20px;
  }

  .slick-list {
    max-height: 500px;

    @media (min-height: 870px) {
      max-height: 600px;
    }

    @media (min-height: 1000px) {
      max-height: 665px;
    }
  }

  .gallery-img-container {
    display: block !important;
    width: 100% !important;
  }

  .gallery-img-container img {
    display: block;
    width: 100%;

    @media (min-height: 870px) {
      max-height: 600px;
      width: 960px;
    }
  }

  .slick-dots-bottom > :first-child {
    justify-content: flex-start;
    padding-left: 38px;
    gap: 105px;
  }

  .image-gallery-wrapper {
    position: relative;
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    padding: 20px;

    .close-image-btn {
      position: absolute;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.lightGrey};
      background-color: ${(props) => props.theme.white};
      top: 20px;
      right: -40px;

      img,
      div {
        width: 15px;
        height: 15px;
      }
    }

    .slick-prev::after,
    .slick-next::after {
      display: none;
    }

    .slick-arrow,
    .close-image-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .mobile-farm-galeries {
    display: none;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(
      ${({ galeryCount }) => Math.ceil(galeryCount / 3)},
      25px
    );
    gap: 10px;

    @media (max-width: 1200px) {
      display: grid;
      min-height: auto;
    }

    @media (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(
        ${({ galeryCount }) => Math.ceil((galeryCount * 8) / 2)},
        25px
      );
    }

    @media (max-width: 440px) {
      padding: 20px;
    }

    @media (max-width: 440px) {
      grid-template-rows: 51px;
    }

    .grid-item {
      position: relative;
    }

    .grid-item:nth-child(odd) {
      grid-row: span 5;

      @media (max-width: 800px) {
        grid-row: span 6;
      }

      @media (max-width: 440px) {
        grid-row: auto;
      }
    }

    .grid-item:nth-child(even) {
      grid-row: span 6;

      @media (max-width: 800px) {
        grid-row: span 7;
      }

      @media (max-width: 440px) {
        grid-row: auto;
      }
    }

    .grid-item:nth-child(3n + 3) {
      grid-row: span 8;

      @media (max-width: 800px) {
        grid-row: span 10;
      }

      @media (max-width: 440px) {
        grid-row: auto;
      }
    }

    .grid-item:nth-child(2n + 4) {
      grid-row: span 10;

      @media (max-width: 800px) {
        grid-row: span 9;
      }

      @media (max-width: 440px) {
        grid-row: auto;
      }
    }

    @media (max-width: 440px) {
      .ant-image img {
        object-fit: cover;
        border-radius: 5px;
      }

      .grid-item:nth-child(2n + 1) {
        grid-row: span 5;
        .ant-image img {
          height: 295px !important;
        }
      }

      .grid-item:first-child {
        grid-row: span 2 !important;
        .ant-image img {
          height: 112px !important;
        }
      }

      .grid-item:nth-child(2n + 2) {
        grid-row: span 3;

        .ant-image img {
          height: 173px !important;
        }
      }

      .grid-item:nth-child(5) {
        grid-row: span 3;

        .ant-image img {
          height: 173px !important;
        }
      }
    }

    .grid-item .carusel-banner-image,
    .grid-item .ant-image {
      display: block;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }
  }
`;
