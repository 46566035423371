import { styled } from 'styled-components'

export const StyledGuestOrLoginPrompt = styled('div')`
  min-height: 100vh;
  background-color: ${(props) => props.theme.greyBackground1};
  margin-top: 50px;

  @media only screen and (max-width: 576px) {
    margin-top: 21px;
  }

  .guest-or-login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    height: 180px;
    background-color: ${(props) => props.theme.whiteSmoke};
    height: fit-content;
    border: 1px solid ${(props) => props.theme.lightBorder};

    .guest-or-login-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 32px 20px 20px;
      width: 100%;
      height: 100%;

      /* border-radius: 0 0 5px 5px; */

      @media only screen and (max-width: 576px) {
        padding: 20px 15px;
      }

      &:first-child::after {
        position: absolute;
        top: 20px;
        right: 0;
        width: 1px;
        height: 140px;
        background-color: ${(props) => props.theme.lightBorder};
        content: '';

        @media only screen and (max-width: 576px) {
          top: auto;
          bottom: 0;
          left: 15px;
          width: calc(100% - 30px);
          height: 1px;
        }
      }

      .guest-or-login-title {
        margin: 0 0 20px 0;
        font-size: 18px;
        font-weight: 600;
      }

      .guest-or-login-text {
        margin: 0 0 20px 0;
        font-size: 16px;
        font-weight: 400;
      }

      .login_register_btn {
        height: 52px;
        font-size: 16px;
        font-weight: 600;
        font-family: ${(props) => props.theme.fontText};
      }

      .skip-btn {
        height: 52px;
        border: solid 1px ${(props) => props.theme.brand02};
        background: transparent;
        color: ${(props) => props.theme.black};
        font-size: 16px;
        font-weight: 600;
        font-family: ${(props) => props.theme.fontText};
      }
    }
    @media only screen and (max-width: 576px) {
      flex-direction: column;
    }
  }
`
