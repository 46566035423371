import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyledGroupedFarmCart } from './style';
import { CartCard } from 'ui/Card/CartCard';
import { useIntl } from 'react-intl';
import { Button, Drawer, Icon, Modal } from 'ui';
import { notification } from 'antd';
import {
  FarmProduct,
  useCartDispatch,
  useCartState,
} from 'public/context/CartContext';
import DeliveryForm from '../DeliveryForm/DeliveryForm';
import { Form } from 'antd';
import useQueryApiClient from 'utils/useQueryApiClient';
import { StyledDeliveryForm } from '../DeliveryForm/style';
import { StyledDeliveryDrawerFooter } from 'ui/Drawer/Drawers/style';
import useWindowSize from 'utils/useWindowSize';
import { StyledCartDeliveryModal } from 'ui/Modal/style';
import DeliveryDrawer from 'ui/Drawer/Drawers/DeliveryDrawer';
import { useSearchParams } from 'react-router-dom';
interface GroupedFarmCartProps {
  farmProducts: FarmProduct[];
  isView?: boolean | undefined;
}

const GroupedFarmCart = ({ farmProducts, isView }: GroupedFarmCartProps) => {
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState<boolean>(false);
  const intl = useIntl();
  const dispatchCart = useCartDispatch();
  const { priceTotal } = useCartState();
  const [_, innerWidth] = useWindowSize();
  const productIds: number[] = [];
  const [notificationApi, contextHolder] = notification.useNotification();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  const [deliveryForm] = Form.useForm();

  const deliverySubmitCallbacks = useRef<(() => void)[]>([]);

  const {} = useQueryApiClient({
    request: {
      url: `api/v2/products/cart/refetch`,
      method: 'POST',
      data: {
        farmProducts: farmProducts,
        priceTotal: priceTotal,
      },
      enableOnMount: !(isView || status),
    },
    onSuccess: (response) => {
      if (response.cartUpdated) openNotificationCartUpdated();
      dispatchCart({
        type: 'REFETCH_CART_PRODUCTS',
        farmProducts: response.farmProducts,
        priceTotal: response.priceTotal,
      });
    },
  });

  farmProducts.forEach((farmProduct) => {
    farmProduct.products.forEach((cartProduct) => {
      if (cartProduct.cartProduct?.id !== undefined) {
        productIds.push(cartProduct.cartProduct.id);
      }
    });
  });

  const onFinishDeliveryDrawer = async () => {
    deliverySubmitCallbacks.current.forEach((callback) => callback());
    setIsCartDrawerOpen(false);
  };

  const registerSubmitCallback = (submitCallback: () => void) => {
    deliverySubmitCallbacks.current.push(submitCallback);
  };

  const openNotificationCartUpdated = () => {
    notificationApi['warning']({
      message: intl.formatMessage({ id: 'cart.updated_message' }),
      description: intl.formatMessage({ id: 'cart.updated_description' }),
      showProgress: true,
      pauseOnHover: true,
      duration: 10,
    });
  };

  const submitDeliveryFooter: JSX.Element = (
    <StyledDeliveryDrawerFooter>
      <Button
        className={
          innerWidth < 1200
            ? 'delivery-drawer-btn' + ' mobile'
            : 'delivery-drawer-btn'
        }
        label={intl.formatMessage({ id: 'general.add_delivery' })}
        type="primary"
        onClick={deliveryForm.submit}
        icon={innerWidth < 1200 ? <Icon name={'arrow-right-red'} /> : null}
      />
    </StyledDeliveryDrawerFooter>
  );

  return (
    <StyledGroupedFarmCart>
      {contextHolder}
      <div className="cart-products-container">
        {farmProducts.map((farmGroup) => (
          <>
            <div className={'cart-products-farm-title'}>
              {farmGroup.farm_name}
            </div>
            <div className="cart-products-farm-wrapper" key={farmGroup.farm_id}>
              <div className="cart-products-header">
                <div className="cart-cell-1">
                  {intl.formatMessage({ id: 'cart.products' })}
                </div>
                <div className="cart-cell-2">
                  {intl.formatMessage({ id: 'general.price' })}
                </div>
                <div className="cart-cell-3">
                  {intl.formatMessage({ id: 'cart.amount' })}
                </div>
                <div className="cart-cell-4">
                  {intl.formatMessage({ id: 'cart.totlal' })}
                </div>
              </div>
              <div className="cart-products-content-wrapper">
                {farmGroup.products.map((product) => (
                  <div
                    className="cart-product show-sum-card"
                    key={product.cartProduct?.id}
                  >
                    <h3 className="title">{product.cartProduct?.farm_name}</h3>
                    <CartCard product={product} isView={isView} />
                  </div>
                ))}
                <div className="cart-delivery-container">
                  {farmGroup.selectedDelivery ? (
                    <div className="cart-delivery-info">
                      {farmGroup.selectedDelivery.firm_name !== 'self' ? (
                        <>
                          <div>
                            {`${intl.formatMessage({ id: `process_purchase.${farmGroup.selectedDelivery.firm_name}` })}${
                              farmGroup.selectedDelivery.price
                                ? ` - ${farmGroup.selectedDelivery.price.toFixed(2)} €`
                                : ''
                            }`}
                          </div>
                          <div>{farmGroup.selectedDelivery.name}</div>
                        </>
                      ) : (
                        <>
                          <div>
                            {intl.formatMessage({
                              id: 'process_purchase.buy_locally_placeholder_first_part',
                            })}
                          </div>
                          <div>
                            {intl.formatMessage({
                              id: 'process_purchase.buy_locally_placeholder_second_part',
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="cart-delivery-info" />
                  )}
                  {!isView && (
                    <div
                      className={
                        farmGroup.selectedDelivery
                          ? 'cart-delivery-button choosen'
                          : 'cart-delivery-button'
                      }
                    >
                      <Button
                        icon={
                          farmGroup.selectedDelivery ? (
                            <Icon name={'car-delivery-black'} />
                          ) : (
                            <Icon name={'car-delivery'} />
                          )
                        }
                        type={farmGroup.selectedDelivery ? 'text' : 'primary'}
                        label={
                          farmGroup.selectedDelivery
                            ? innerWidth > 576
                              ? intl.formatMessage({
                                  id: 'cart_summary.change_delivery_method',
                                })
                              : intl.formatMessage({
                                  id: 'cart_summary.change_delivery',
                                })
                            : intl.formatMessage({
                                id: 'general.choose_delivery',
                              })
                        }
                        onClick={() => setIsCartDrawerOpen(true)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>

      {innerWidth > 1200 ? (
        <DeliveryDrawer
          id={'delivery-drawer'}
          isDeliveryDrawerOpen={isCartDrawerOpen}
          onDeliveryClose={() => setIsCartDrawerOpen(false)}
          footer={submitDeliveryFooter}
          closeIcon={<Icon name={'delivery-drawer-close'} type={'any'} />}
        >
          <StyledDeliveryForm>
            <Form form={deliveryForm} onFinish={onFinishDeliveryDrawer}>
              {farmProducts.map((farmProduct, index) => (
                <DeliveryForm
                  deliveryForm={deliveryForm}
                  farmProduct={farmProduct}
                  key={index}
                  registerSubmitCallback={registerSubmitCallback}
                />
              ))}
            </Form>
          </StyledDeliveryForm>
        </DeliveryDrawer>
      ) : (
        <StyledCartDeliveryModal
          open={isCartDrawerOpen}
          closable={true}
          onCancel={() => setIsCartDrawerOpen(false)}
          footer={submitDeliveryFooter}
          wrapClassName={'custom-modal-wrap'}
        >
          <StyledDeliveryForm>
            <Form form={deliveryForm} onFinish={onFinishDeliveryDrawer}>
              {farmProducts.map((farmProduct, index) => (
                <DeliveryForm
                  deliveryForm={deliveryForm}
                  farmProduct={farmProduct}
                  key={index}
                  registerSubmitCallback={registerSubmitCallback}
                />
              ))}
            </Form>
          </StyledDeliveryForm>
        </StyledCartDeliveryModal>
      )}
    </StyledGroupedFarmCart>
  );
};

export default GroupedFarmCart;
