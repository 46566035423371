import { StyledNewProductForm } from './style';
import { Input } from '../../Input';
import { useIntl } from 'react-intl';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import { InputNumber } from '../../InputNumber';
import { Checkbox, Form } from 'antd';
import { useEffect, useState } from 'react';
import { CategoryOption } from '../../Drawer/Drawers/DrawerCreateProduct';
import { Select, SelectOption } from 'ui';

export interface QmarkResponse {
  id: number;
  label: string;
}
interface OptionsResponse {
  id: number;
  label: string;
}

interface NewProductBaseInfoFormProps {
  optionsInput?: CategoryOption[];
}

interface AvailabilityOptions {
  value: number;
  label: string;
}

export const NewProductBaseInfoForm = ({
  optionsInput,
}: NewProductBaseInfoFormProps) => {
  const [options, setOptions] = useState([]);
  const [qmarks, setQmarks] = useState([]);
  const intl = useIntl();

  const availabilityOptions: AvailabilityOptions[] = [
    {
      label: intl.formatMessage({ id: 'availability_options.seasonal' }),
      value: 1,
    },
    {
      label: intl.formatMessage({ id: 'availability_options.on_order' }),
      value: 2,
    },
    {
      label: intl.formatMessage({ id: 'availability_options.all_year' }),
      value: 3,
    },
  ];

  const { data } = useQueryApiClient({
    request: {
      url: 'api/v2/products/store/options',
      data: {
        view: 'admin',
      },
    },
    onSuccess: (response) => {
      setOptions(response.options);
      setQmarks(response.qmarks);
    },
  });

  useEffect(() => {}, [optionsInput]);

  return (
    <StyledNewProductForm>
      <div className={'availability'}>
        <div className={'label'}>
          {intl.formatMessage({ id: 'general.availability' })}
        </div>
        <Select
          validations={'required'}
          name={'availability'}
          size={'middle'}
          children={availabilityOptions.map((options) => {
            return (
              <SelectOption value={options.value} key={options.value}>
                {options.label}
              </SelectOption>
            );
          })}
        />
      </div>

      <div className={'prod-availability'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'general.product_viable_in_mass' })}
        </div>
        <div className={'min-price col-djfgr'}>
          <div className={'min'}>
            <div className={'label'}>
              {intl.formatMessage({
                id: 'general.min_amount',
              })}
            </div>
            <InputNumber
              className={'scnd-tr'}
              name={'storage_wholesale_limit'}
            />
          </div>
          <div className={'price'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'general.bulk_price' })}
            </div>
            <InputNumber
              className={'scnd-tr'}
              name={'storage_wholesale_price'}
            />
          </div>
        </div>
      </div>
      <div className={'additional-info'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'general.additional_information' })}
        </div>
        <div className={'checkboxes'}>
          <Form.Item name={'qmarks'}>
            <Checkbox.Group>
              {qmarks.map((qmark: QmarkResponse, index: number) => (
                <Checkbox value={qmark.id} name={'qmark'} key={index}>
                  {qmark.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name={'options'}>
            <Checkbox.Group>
              {options.map((option: OptionsResponse, index: number) => (
                <Checkbox value={option.id} name={'option'} key={index}>
                  {option.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>
    </StyledNewProductForm>
  );
};
