import React from 'react';
import { Ability, useAuth } from '../../../../hooks/useAuth';

interface ProtectedRouteProps {
  children: React.ReactNode;
  authenticated?: boolean;
  requiresAdmin?: boolean;
  requiresFarmAdmin?: boolean;
}

const ProtectedRoute = ({
  children,
  requiresAdmin,
  requiresFarmAdmin,
}: ProtectedRouteProps) => {
  const { user, isFetchingUser } = useAuth();

  const isFarmAdmin = user?.isFarmOwner;
  const isSuperAdmin = user?.abilities?.includes('admin_panel');

  if (isFetchingUser) {
    return null;
  }

  if (isSuperAdmin) {
    return <>{children}</>;
  }

  if (!user?.authenticated) {
    window.location.href = '/login';
    return null;
  }

  if (requiresAdmin && !isSuperAdmin) {
    window.location.href = '/';
    return null;
  }

  if (requiresFarmAdmin && !isFarmAdmin) {
    window.location.href = '/';
    return null;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
