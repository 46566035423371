import React from 'react';
import { Form, TimePicker as AntdTimePicker } from 'antd';
import { Dayjs } from 'dayjs';
import { useIntl } from 'react-intl';
import useFormValidation from '../../utils/useFormValidation';
import { Validations } from '../../interfaces';
import { Icon } from '../Icon';

export interface TimePickerProps extends Validations {
  size?: 'large' | 'middle' | 'small';
  placeholder?: string;
  onChange?: any;
  disabled?: boolean;
  value?: Dayjs;
  format?: string;
  name?: string | (string | number)[];
  label?: string;
  initialValue?: Dayjs;
  className?: string;
  allowClear?: boolean;
  dependencies?: (string | number)[][];
  noStyle?: boolean;
  Validations?: Validations;
  prefix?: React.ReactNode;
}

export const TimePicker = ({
  size = 'large',
  placeholder,
  onChange,
  disabled,
  value,
  format = 'HH:mm',
  name,
  label,
  initialValue,
  className,
  allowClear = true,
  dependencies,
  noStyle = false,
  validations,
  prefix,
}: TimePickerProps) => {
  const intl = useIntl();
  const { formValidations } = useFormValidation();

  const rules = validations ? formValidations(validations) : [];

  return (
    <Form.Item noStyle dependencies={dependencies}>
      {({ getFieldValue }) => {
        const dependencyPath = dependencies?.[0];
        const isDependentDisabled =
          dependencyPath && getFieldValue
            ? Boolean(getFieldValue(dependencyPath))
            : false;

        return (
          <Form.Item
            name={name}
            label={label}
            initialValue={initialValue}
            rules={rules}
            noStyle={noStyle || !label}
          >
            <AntdTimePicker
              className={className}
              placeholder={
                placeholder || intl.formatMessage({ id: 'general.choose_time' })
              }
              suffixIcon={<Icon name="arrow-down-black" />}
              prefix={prefix}
              allowClear={allowClear}
              format={format}
              size={size}
              disabled={disabled || isDependentDisabled}
              value={value}
              onChange={onChange}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};
