import styled from 'styled-components';

export const StyledFooter = styled.div`
  font-family: ${(props) => props.theme.fontText};
  min-height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 23px;
  background-color: ${(props) => props.theme.white};
  border-top: 1px solid ${(props) => props.theme.borderBottom};

  .container-footer {
    max-height: 215px;
    padding: 0 210px;
  }

  .footer-contacts {
    .footer-title {
      display: flex;
      justify-content: space-between;

      p {
        margin: 0;
        font-family: ${(props) => props.theme.fontText};
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
      }

      .footer-icon-wrapper {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
    }

    .footer-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin: 53px 0 27px 0;

      p {
        margin: 0;
        line-height: 28px;
      }

      .footer-content-container {
        display: flex;
        align-items: flex-start;
        font-size: 16px;
        gap: 10px;

        img {
          padding: 0 5px 0 0;
        }
      }

      .footer-content-container-right {
        display: flex;
        flex-direction: column;
        gap: 13px;
      }

      .footer-content-container-end {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 13px;
      }

      .footer-content-container:first-child {
        width: 100%;
        max-width: 346px;
      }
    }

    .contact-icon img {
      width: 24px;
      height: 24px;
    }
  }

  .desktop-footer-image-wrapper {
    display: flex;
    height: 85px;
    width: 100%;
    overflow: hidden;
  }

  .mobile-footer-image-wrapper {
    display: none;
    overflow: hidden;
  }

  .footer-copyright {
    text-align: left;
    padding: 54px 0 54px 0;
    background-color: ${(props) => props.theme.brand01};
    color: ${(props) => props.theme.white};
    max-height: 139px;
    display: flex;
    align-items: center;
    .container {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .footer-content-container-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 13px;
    .footer-content {
      p {
        font-size: 16px;
      }
    }

    p {
      margin: 0;
      font-family: ${(props) => props.theme.fontText};
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .footer-contacts {
      .footer-content-wrapper {
        flex-wrap: wrap;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .container-footer {
      padding: 0 100px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .container-footer {
      padding: 0px 20px;
      max-height: 250px;
    }

    padding-bottom: 100px;
  }

  @media only screen and (max-width: 768px) {
    background-color: transparent;
    border-top: none;
    margin-top: 30px;

    .container-footer {
      max-height: 540px;
    }

    .footer-contacts {
      padding: 0;

      .footer-content-wrapper {
        flex-direction: column;
        gap: 22px;
        margin: 30px 0 140px 0;

        .footer-content-container-right,
        .footer-content-container-end {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 22px;
        }

        .footer-content-container {
          display: flex;
          align-items: flex-start;
          gap: 10px;

          .footer-content {
            p {
              margin-bottom: 0;
              line-height: 28px;
            }
          }
        }
      }
    }

    .mobile-footer-image-wrapper {
      display: flex;
      height: 41px;
      width: 100%;
    }

    .footer-copyright {
      text-align: center;
      padding: 0 0 0 0;
      background-color: transparent;
      color: ${(props) => props.theme.black};
    }

    .desktop-footer-image-wrapper {
      display: none;
    }

    .footer-image-wrapper {
      display: none;
    }
  }

  @media only screen and (max-width: 576px) {
    margin-top: 0px;
  }

  @media only screen and (max-width: 440px) {
    padding-top: 53px;

    .footer-contacts {
      .footer-content-wrapper {
        margin-top: 0;
      }

      .footer-title {
        p {
          margin: 0;
          padding: 0;
          font-size: 18px;
          font-weight: 500;
        }

        .footer-icon-wrapper {
          display: none;
        }
      }

      .footer-content-wrapper {
        margin: 24px 0 51px 0;
      }

      .footer-content p {
        color: ${(props) => props.theme.colorText};
      }
    }

    .footer-content-container-end {
      display: none !important;
    }
  }
`;
