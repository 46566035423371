import { StyledPartnersPage } from './style';
import { Button, Checkbox, Input, Table, Tabs } from '../../../ui';
import { useIntl } from 'react-intl';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useMemo, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { message } from 'antd';
import { useDebounce } from '../../../utils/debounceSearch';
import useQueryApiClient from '../../../utils/useQueryApiClient';

interface FarmsProps {
  reg_nr: number;
  name: string;
  id: number;
}

interface PartnerProps {
  confirmed: ConfirmedStatus;
  confirmed_at: string;
  created_at: string;
  farm_id: number;
  id: number;
  name: string;
  partner_id: number;
  reg_nr: number;
  updated_at: string;
  is_inbound?: boolean;
}

interface FetchProps {
  inbound: PartnerProps[];
  outbound: PartnerProps[];
}

type ConfirmedStatus = 0 | 1 | 2;

type StatusKey = 'canceled' | 'accepted' | 'waiting';

const StringStatus: { [key in ConfirmedStatus]: StatusKey } = {
  0: 'canceled',
  1: 'accepted',
  2: 'waiting',
};

export const Partners = () => {
  const [search, setSearch] = useState('');
  const [mergedPartners, setMergedPartners] = useState<PartnerProps[]>([]);
  const intl = useIntl();
  const { user } = useAuth();

  const stableFilter = useMemo(() => {
    return { farm_id: user?.farmId };
  }, [user?.farmId]);

  const { appendData: deletePartner } = useQueryApiClient({
    request: {
      url: '/api/v2/partners/:id',
      method: 'DELETE',
    },
    onSuccess: () => {
      refetchPartners();
      message.success(
        intl.formatMessage({ id: 'message.successfully_deleted_partner' })
      );
    },
  });

  const { appendData: acceptPartner } = useQueryApiClient({
    request: {
      url: '/api/v2/partners/accept/:partner',
      method: 'POST',
    },
    onSuccess: () => {
      refetchPartners();
      message.success(
        intl.formatMessage({ id: 'message.successfully_accepted_partner' })
      );
    },
  });

  const { appendData: cancelPartner } = useQueryApiClient({
    request: {
      url: '/api/v2/partners/cancel/:partner',
      method: 'POST',
    },
    onSuccess: () => {
      refetchPartners();
      message.success(
        intl.formatMessage({ id: 'message.successfully_canceled_partner' })
      );
    },
  });

  const { data: partnersData, refetch: refetchPartners } = useQueryApiClient({
    request: {
      url: '/api/v2/partners',
      data: {
        page: 1,
        page_size: 10,
        filter: { ...stableFilter },
      },
    },
    onSuccess: (response: FetchProps) => handlePartnersSuccess(response),
  });

  const { appendData: invitePartner } = useQueryApiClient({
    request: {
      url: '/api/v2/partners/invite',
      method: 'POST',
    },
    onSuccess: (response) => handleInviteSuccess(response),
  });
  const stableSearch = useDebounce({ search: search }, 1000);

  const stableFilterObject = useMemo(() => stableSearch, [stableSearch.search]);

  const handlePartnersSuccess = (response: FetchProps) => {
    setMergedPartners([...response.inbound, ...response.outbound]);
  };

  const handleInviteSuccess = (response: any) => {
    if (response.error) {
      message.error(intl.formatMessage({ id: `message.${response.error}` }));
    } else {
      refetchPartners();
      message.success(
        intl.formatMessage({ id: 'message.successfully_invited_partner' })
      );
    }
  };

  const handleAcceptPartnership = (recordId: number) => {
    acceptPartner(stableFilter, { partner: recordId });
    refetchPartners();
  };

  const handleCancelPartnership = (recordId: number) => {
    cancelPartner(stableFilter, { partner: recordId });
    refetchPartners();
  };

  const handleDelete = (partnerId: number) => {
    if (
      window.confirm(
        intl.formatMessage({ id: 'message.confirm_delete_partnership' })
      )
    ) {
      deletePartner(null, { id: partnerId });
    }
  };

  const handleInvite = (farmId: number) => {
    if (
      window.confirm(
        intl.formatMessage({ id: 'message.invite_partner_confirm' })
      )
    ) {
      invitePartner({ ...stableFilter, partner_id: farmId });
    }
  };

  const RenderActions = (record: PartnerProps) => {
    const states: { [key in StatusKey]: React.ReactNode } = {
      canceled: intl.formatMessage({ id: 'general.canceled' }),
      accepted: (
        <Button
          type={'text'}
          label={intl.formatMessage({ id: 'general.delete' })}
          onClick={() => handleDelete(record.id)}
          className={'underline'}
        />
      ),
      waiting: intl.formatMessage({ id: 'general.waiting' }),
    };

    if (record.is_inbound && record.confirmed == 2) {
      return (
        <div className={'inbound'}>
          <Button
            label={intl.formatMessage({ id: 'general.accept' })}
            type={'primary'}
            onClick={() => handleAcceptPartnership(record.id)}
          />
          <Button
            label={intl.formatMessage({ id: 'general.cancel' })}
            onClick={() => handleCancelPartnership(record.id)}
          />
        </div>
      );
    }

    return states[StringStatus[record.confirmed]];
  };

  const searchColumns = [
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.reg_nr' }),
      dataIndex: 'reg_nr',
      render: (value: number) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      render: (record: FarmsProps) => {
        return (
          <Button
            type={'primary'}
            label={intl.formatMessage({ id: 'general.invite' })}
            onClick={() => handleInvite(record.id)}
          />
        );
      },
    },
  ];

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'name',
      render: (value: string) => value,
    },

    {
      title: intl.formatMessage({ id: 'general.reg_nr' }),
      dataIndex: 'reg_nr',
      render: (value: number) => value,
    },

    {
      title: intl.formatMessage({ id: 'general.status' }),
      dataIndex: 'confirmed',
      render: (value: number) => <Checkbox checked={value === 1} />,
    },

    {
      title: (
        <div className={'actions-title'}>
          {intl.formatMessage({ id: 'general.actions' })}
        </div>
      ),
      render: (record: PartnerProps) => {
        return <div className={'actions-wrap'}>{RenderActions(record)}</div>;
      },
    },
  ];

  const items = [
    {
      key: 'partners',
      label: intl.formatMessage({ id: 'navigation.partners' }),
      children: (
        <>
          <div className={'partners'}>
            <Input
              label={intl.formatMessage({ id: 'general.invite_partner' })}
              name={'search'}
              layout={'vertical'}
              onChange={(event) => setSearch(event.target.value)}
            />
            <span className={'under-text'}>
              {intl.formatMessage({ id: 'general.add_farm_name_or_reg_nr' })}
            </span>
          </div>
          {!!stableSearch.search && (
            <Table
              columns={searchColumns}
              url={'/api/v2/partners/farms'}
              filter={stableFilterObject}
            />
          )}
          {mergedPartners && (
            <Table
              columns={columns}
              filter={stableFilter}
              disablePagination={true}
              dataSource={mergedPartners}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
          href: '/',
        },
        {
          title: intl.formatMessage({ id: 'navigation.partners' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <StyledPartnersPage>
          <Tabs items={items} />
        </StyledPartnersPage>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};
