import { StyledNewProductForm } from './style';
import { Table } from '../../Table';
import { useIntl } from 'react-intl';
import { InfrastructureProps } from 'interfaces/BackendModels';
import { Checkbox } from 'ui/Checkbox';
interface ProductStructureProps {
  infrastructure: InfrastructureProps[];
  pvdNr: number;
}
export const NewProductsStructureForm = ({
  infrastructure,
  pvdNr,
}: ProductStructureProps) => {
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: 'delivery.address' }),
      dataIndex: 'full_address',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'object_name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.status' }),
      render: (record: InfrastructureProps) => {
        return <Checkbox checked={record.pvd_number === pvdNr} />;
      },
    },
  ];

  return (
    <StyledNewProductForm>
      <Table columns={columns} dataSource={infrastructure} />
    </StyledNewProductForm>
  );
};
