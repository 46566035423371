import React from 'react';
import { Dropdown as AntdDropdown } from 'antd';
import { MenuProps } from 'antd/es/menu';

interface DropdownProps {
  menu?: MenuProps;
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  onOpenChange?: (
    open: boolean,
    info: {
      source: 'trigger' | 'menu';
    }
  ) => void;

  destroyPopupOnHide?: boolean
  className?: string
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  children?: React.ReactNode
  overlayClassName?: string
}

export const Dropdown = ({
  children,
  menu,
  destroyPopupOnHide,
  onOpenChange,
  trigger,
  className,
  getPopupContainer,
  overlayClassName,
}: DropdownProps) => {
  return (
    <AntdDropdown
      menu={menu}
      className={className}
      destroyPopupOnHide={destroyPopupOnHide}
      onOpenChange={onOpenChange}
      trigger={trigger}
      getPopupContainer={getPopupContainer}
      overlayClassName={overlayClassName}
    >
      {children}
    </AntdDropdown>
  );
};
