import { Button } from '../../../../ui';
import React, { useState } from 'react';
import { StyledCounter } from './style';
interface CounterProps {
  label?: string | React.ReactNode;
}

const Counter = ({ label }: CounterProps) => {
  const [quantity, setQuantity] = useState<number>(0);
  if (quantity >= 0) {
    return (
      <div className="btn-add-to-cart">
        <Button
          className={'ariel-sans view-product'}
          label={label || 'Pievienot grozam'}
          onClick={() => setQuantity(quantity + 1)}
          iconPosition="end"
        />
      </div>
    );
  }

  return (
    <StyledCounter>
      <div className="counter-container">
        <button
          className="counter-button"
          onClick={() => setQuantity(quantity - 1)}
          disabled={quantity <= 0}
        >
          -
        </button>
        <span className="counter-quantity">{quantity} gab</span>
        <button
          className="counter-button"
          onClick={() => setQuantity(quantity + 1)}
        >
          +
        </button>
      </div>
    </StyledCounter>
  );
};

export default Counter;
