import { Carousel } from '../../../../ui'
import { useIntl } from 'react-intl'
import { StyledMobileProductCategoriesCarousel } from './style'
import { CategoryData } from 'types/CategoryData'
import React, {useState} from "react";
import {ImageProps} from "../../../../types/BannerData";
import UseQueryApiClient from "../../../../utils/useQueryApiClient";
import {CategoryCard} from "../../../../ui/Card/CategoryCard";

interface MobileProductCategoriesCarouselProps {
  popularCategory?: CategoryData[];
}

const MobileProductCategoriesCarousel = ({
  popularCategory,
}: MobileProductCategoriesCarouselProps) => {
  const intl = useIntl()
  const [images, setImages] = useState<ImageProps[]>([])
  const { data } = UseQueryApiClient({
    request: {
      url: '/api/v2/categories/popular/images',
    },
    onSuccess: (response) => {
      setImages(response)
    },
  })

  return (
    <StyledMobileProductCategoriesCarousel className="mobile-product-category">
      <div className={'title'}>
        <h1>{intl.formatMessage({ id: 'popular_categories.title' })}</h1>
        <Carousel popularCategory={popularCategory} slidesPerRow={1} >
          {({category, index})=> {
            const image = images.find(
              (img) => img.table_row_id === category?.top_category_position
            )
            return (
              <CategoryCard
                key={`category-${index}`}
                category={category}
                bordered={true}
                imageUrl={image?.url_original}
                hoverable={true}
              />
            )
          }}
        </Carousel>
      </div>
    </StyledMobileProductCategoriesCarousel>
  );
};

export default MobileProductCategoriesCarousel;
