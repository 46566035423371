import React, { useState } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import { Tabs, Table, Button, CustomGrid, Input, Modal } from 'ui';
import dayjs from 'dayjs';
import { StyledButtonList } from 'styles/buttonList';
import { Form } from 'antd';
import useQueryApiClient from 'utils/useQueryApiClient';

const EmarketSettingListPage = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<number>(0);
  const [reload, setReload] = useState<number>(0);
  const [isModalVisibleType, setIsModalVisibleType] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>('product_attributes');

  const [form] = Form.useForm();
  const [formSecond] = Form.useForm();

  const intl = useIntl();

  const {} = useQueryApiClient({
    request: {
      url: `api/v2/product-attributes/${selectedEntry}`,
      disableOnMount: !selectedEntry && !isModalVisible,
    },
    onSuccess: (response) => form.setFieldsValue(response),
  });

  const { appendData } = useQueryApiClient({
    request: {
      url: selectedEntry
        ? `api/v2/product-attributes/${selectedEntry}`
        : `api/v2/product-attributes`,
      method: selectedEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const {} = useQueryApiClient({
    request: {
      url: `api/v2/warehouse-measurements/${selectedEntry}`,
      disableOnMount: !selectedEntry && !isModalVisibleType,
    },
    onSuccess: (response) => formSecond.setFieldsValue(response),
  });

  const { appendData: appendDataPage } = useQueryApiClient({
    request: {
      url: selectedEntry
        ? `api/v2/warehouse-measurements/${selectedEntry}`
        : `api/v2/warehouse-measurements`,
      method: selectedEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'label',
    },
    {
      title: intl.formatMessage({ id: 'settings.icon_name' }),
      dataIndex: 'icon_name',
    },
    {
      title: intl.formatMessage({ id: 'settings.sort' }),
      dataIndex: 'sort',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value);
              setIsModalVisible(true);
            }}
          />
        </>
      ),
    },
  ];

  const columnsWarehouse = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'settings.short' }),
      dataIndex: 'short',
    },
    {
      title: intl.formatMessage({ id: 'settings.type' }),
      dataIndex: 'type',
    },
    {
      title: intl.formatMessage({ id: 'settings.status' }),
      dataIndex: 'status',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'settings.extension' }),
      dataIndex: 'extension',
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value);
              setIsModalVisibleType(true);
            }}
          />
        </>
      ),
    },
  ];

  const items = [
    {
      key: 'product_attributes',
      label: intl.formatMessage({ id: 'settings.product_attributes' }),
      children: (
        <>
          <Table
            url="/api/v2/product-attributes"
            columns={columns}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
    {
      key: 'warehouse_measurements',
      label: intl.formatMessage({ id: 'settings.warehouse_measurements' }),
      children: (
        <>
          <Table
            url="/api/v2/warehouse-measurements"
            columns={columnsWarehouse}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
  ];

  const onModalClose = () => {
    setIsModalVisible(false);
    setIsModalVisibleType(false);
    setSelectedEntry(0);
    form.resetFields();
    formSecond.resetFields();
    setReload((old) => old + 1);
  };

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.emarket_settings' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Button
                label={intl.formatMessage({ id: 'general.create_new' })}
                type="primary"
                onClick={() => {
                  if (activeKey === 'product_attributes') {
                    setIsModalVisible(true);
                  }

                  if (activeKey === 'warehouse_measurements') {
                    setIsModalVisibleType(true);
                  }
                }}
              />
            </StyledButtonList>
          }
          items={items}
          onChange={setActiveKey}
        />

        <Modal
          open={isModalVisible}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'settings.product_attributes' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => form.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={form} onFinish={appendData} layout="vertical">
            <Input
              name="label"
              label={intl.formatMessage({ id: 'general.name' })}
            />
            <Input
              name="icon_name"
              label={intl.formatMessage({ id: 'settings.icon_name' })}
            />
          </Form>
        </Modal>
        <Modal
          open={isModalVisibleType}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'settings.warehouse_measurements' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => formSecond.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={formSecond} onFinish={appendDataPage} layout="vertical">
            <Input
              name="name"
              label={intl.formatMessage({ id: 'general.name' })}
            />
            <Input
              name="short"
              label={intl.formatMessage({ id: 'settings.short' })}
            />
            <Input
              name="type"
              label={intl.formatMessage({ id: 'settings.type' })}
            />
            <Input
              name="status"
              label={intl.formatMessage({ id: 'settings.status' })}
            />
            <Input
              name="extension"
              label={intl.formatMessage({ id: 'settings.extension' })}
            />
          </Form>
        </Modal>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default EmarketSettingListPage;
