import { StyledCartCard } from './style';
import { useIntl } from 'react-intl';
import { ProductPrice, ProductUnityPrice } from 'utils/productCardHelpers';
import AmountSelector from 'ui/CartAmountSelector/CartAmountSelector';

import { CartProduct, useCartDispatch } from 'public/context/CartContext';
import { FallbackImage } from 'ui/UploadImage/fallBack/FallBackImage';
import { validateProduct } from 'utils/productValidationHelper';
import { ProductData } from 'types/ProductData';
import { Icon } from 'ui/Icon';

export interface CartCardProps {
  product: CartProduct;
  isView?: boolean | undefined;
}

export const CartCard = ({ product, isView }: CartCardProps) => {
  const intl = useIntl();
  const cartDispatch = useCartDispatch();

  const productHasDiscount = !!(
    product.cartProduct?.storage_stock_discount_price &&
    product.cartProduct?.storage_stock_price &&
    product.cartProduct.storage_stock_discount_price <
      product.cartProduct.storage_stock_price
  );

  const validatedProduct = validateProduct({
    product: product.cartProduct ?? ({} as ProductData),
    count: product.amount,
  });

  const productPrice = validatedProduct.stock_total || 0;

  const formattedPrice = (Number(productPrice) || 0).toFixed(2);

  return (
    <StyledCartCard className="cart-product">
      <div className={'cart-products-content'}>
        <div className={'cart-cell-1'}>
          <Icon
            onClick={() =>
              cartDispatch({
                type: 'REMOVE_ITEM',
                productId: product?.cartProduct?.id || 0,
              })
            }
            name={'cart-product-colse-button'}
            className={'cart-products-remove-button'}
          />
          <FallbackImage
            src={product.cartProduct?.image_small || ''}
            type={'ProductCard'}
          />
        </div>
        <div className={'cart-cell-2'}>
          <p className={'cart-product-farm-name'}>
            {product.cartProduct?.farm_name}
          </p>
          <p className={'cart-product-name'}>{product.cartProduct?.name} </p>
        </div>
        <div className={'cart-cell-3'}>
          <div className={'price-container'}>
            <ProductPrice
              product={product.cartProduct}
              productHasDiscount={productHasDiscount}
            />
            <ProductUnityPrice product={product.cartProduct} />
          </div>
        </div>
        <div className={'cart-cell-4'}>
          {isView ? (
            <div className={'cart-product-amount-view'}>
              {'x' + product.amount}
            </div>
          ) : (
            <AmountSelector
              amountCart={product.amount}
              product={product.cartProduct}
              unitType={product.cartProduct?.storage_unit}
            />
          )}
        </div>
        <div className={'cart-cell-5'}>
          {formattedPrice}
          {' €'}
        </div>
      </div>

      <div className="mobile-cart-cells">
        <div className={'cart-cell-4'}>
          <AmountSelector
            amountCart={product.amount}
            product={product.cartProduct}
            unitType={product.cartProduct?.storage_unit}
          />
        </div>

        <div className={'cart-cell-5'}>
          <span className="label">
            {intl.formatMessage({ id: 'orders.summary' }) + ': '}
            <b>{`${formattedPrice} €`}</b>
          </span>
        </div>
      </div>
    </StyledCartCard>
  );
};
