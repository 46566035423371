import { StyledCustomImage } from './style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { Spinner } from '../../Spinner';
import { useIntl } from 'react-intl';
import { FallbackImage } from '../fallBack/FallBackImage';
import { Upload, UploadProps } from 'antd';
import { Radio } from '../../Radio';
import ImgCrop from 'antd-img-crop';

interface CustomImageProps {
  file: UploadFile;
  onRemove: (file: UploadFile) => void;
  onEdit: (file: RcFile) => void;
  isThumbnail: boolean;
  thumbUrl?: string;
  onThumbnailChange: (uid: string) => void;
  multiple?: boolean;
  isCrop?: boolean;
  needThumb?: boolean;
  showActions?: boolean;
}

export const CustomImage = ({
  file,
  onEdit,
  onRemove,
  isThumbnail,
  onThumbnailChange,
  thumbUrl,
  multiple = true,
  isCrop = true,
  needThumb = true,
  showActions = true,
}: CustomImageProps) => {
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  const handleEdit = useCallback(
    ({ file: uploadedFile }: { file: UploadFile }) => {
      onRemove(file);
      onEdit(uploadedFile.originFileObj as RcFile);
    },
    [file, onEdit, onRemove]
  );

  const handleRemove = () => {
    onRemove(file);
  };

  useEffect(() => {
    setLoading(file.status === 'uploading');
  }, [file]);

  const hiddenUploadProps: UploadProps = useMemo(
    () => ({
      name: 'logo_image',
      accept: 'image/*',
      multiple: false,
      showUploadList: false,
      fileList: file ? [file] : [],
      onChange: handleEdit,
    }),
    [file, handleEdit]
  );

  const wrapWithCrop = (children: any) => {
    if (isCrop) {
      return (
        <ImgCrop rotationSlider showReset>
          {children}
        </ImgCrop>
      );
    } else {
      return children;
    }
  };

  return (
    <Spinner spinning={loading} dontRender>
      <StyledCustomImage>
        <div className="custom-upload-list-item">
          {multiple && needThumb && (
            <div className={'overlay-wrap'}>
              <div className={'overlay'}>
                <Radio
                  className="checkbox-thumbnail"
                  checked={isThumbnail}
                  onChange={() => onThumbnailChange(file.uid)}
                  label={intl.formatMessage({ id: 'images.thumbnail' })}
                />
              </div>
            </div>
          )}
          <FallbackImage
            src={thumbUrl || file.url || file.preview}
            type={'ProductCard'}
            className="custom-upload-list-item-image"
            preview={true}
          />
          {showActions && (
            <div className="custom-upload-list-item-actions">
              {wrapWithCrop(
                <Upload {...hiddenUploadProps}>
                  <div className={'action'}>
                    {intl.formatMessage({ id: 'general.edit' })}
                  </div>
                </Upload>
              )}
              <div onClick={() => handleRemove()} className={'action'}>
                {intl.formatMessage({ id: 'general.delete' })}
              </div>
            </div>
          )}
        </div>
      </StyledCustomImage>
    </Spinner>
  );
};
