import styled from 'styled-components';

export const StyledQmarkCheckboxes = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  .qmarks-filter-checkboxes {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 16px;
    padding-left: 25px;

    .qmark {
      display: flex;
      flex-wrap: wrap;

      .ant-form-item {
        margin: 0;
        min-width: 100%;
      }
    }
  }

  .qmark-filter-title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.lightGrey};
    padding-bottom: 5px;
    font-weight: 600;
    gap: 10px;
    cursor: pointer;

    .icon {
      display: flex;
      font-size: 14px;
      align-items: center;
    }
  }

  &:last-child .qmark-filter-title {
    border-bottom: none;
  }
`;
