import { Select, Space } from 'antd';
import { SelectedDelivery } from 'public/context/CartContext';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FarmDeliveries } from 'types/FarmData';
import { parseOpenHours } from 'utils/productCardHelpers';
import { StyledSelfDelivery } from '../style';

interface SelfDeliveryProps {
  selectedDelivery: FarmDeliveries;
  setSelectedDeliveryData: React.Dispatch<
    React.SetStateAction<SelectedDelivery[] | null>
  >;
  selectedDeliveryData: SelectedDelivery[] | null;
}

const SelfDelivery = ({
  selectedDelivery,
  setSelectedDeliveryData,
  selectedDeliveryData,
}: SelfDeliveryProps) => {
  const intl = useIntl();

  useEffect(() => {
    setSelectedDeliveryData([
      ...(selectedDeliveryData || []),
      {
        id: undefined,
        selectedDeliveryId: selectedDelivery.id,
        city: undefined,
        address: selectedDelivery.address,
        firm_name: selectedDelivery?.type,
        name: selectedDelivery.name,
        price: selectedDelivery.price,
      },
    ]);
  }, []);

  return (
    <StyledSelfDelivery>
      <div className={'delivery'}>
        <div className={'content'}>
          <p>
            {intl?.formatMessage({ id: 'delivery.address' })}
            {selectedDelivery && selectedDelivery.address}
          </p>
          <table className="open-hours-table">
            <tbody>
              {selectedDelivery?.open_hours &&
                parseOpenHours(selectedDelivery?.open_hours).map(
                  (hours, index) => (
                    <tr
                      key={index}
                      className={
                        index && index % 2 === 1
                          ? 'light-gray-background'
                          : 'white-background'
                      }
                    >
                      <td className={'td-day'}>
                        {intl?.formatMessage({
                          id: `${'open_hours'}.${hours.day}`,
                        })}
                      </td>
                      <td>
                        {hours.from !== 'Closed'
                          ? hours.from + ' - ' + hours.to
                          : ''}
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
    </StyledSelfDelivery>
  );
};

export default SelfDelivery;
