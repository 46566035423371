import { useIntl } from 'react-intl';
import { Checkbox, Table } from '../../../../ui';
import { InfrastructureProps } from '../../../../interfaces/BackendModels';

interface BusinessAddressProps {
  infrastructure: InfrastructureProps[];
  pvdNr: number;
}

export const BusinessAddresses = ({
  infrastructure,
  pvdNr,
}: BusinessAddressProps) => {
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: 'delivery.address' }),
      dataIndex: 'full_address',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'object_name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.working_hours' }),
      render: () => {
        return <></>;
      },
    },
    {
      title: intl.formatMessage({ id: 'general.status' }),
      render: (record: InfrastructureProps) => {
        return <Checkbox checked={record.pvd_number === pvdNr} />;
      },
    },
    {
      title: intl.formatMessage({ id: 'general.show_in_map' }),
      render: () => {
        return <></>;
      },
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      render: () => {
        return <></>;
      },
    },
  ];

  return <Table columns={columns} dataSource={infrastructure} />;
};
