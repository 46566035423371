import { StyledDrawer } from './style';
import { Button } from '../../Button';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Select, SelectOption } from '../../Select';
import { WithoutModalAddressFinder } from '../../AdressSearch/WithoutModal';
import { Form, FormInstance, message, Input as AntdInput } from 'antd';
import { Input } from '../../Input';
import { WorkingHours } from '../../WorkingHours';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import { useAuth } from '../../../hooks/useAuth';
import useFormValidation from '../../../utils/useFormValidation';
import { DefaultHoursProps } from '../../../admin/pages/FarmDeliveries';
import EnumSelect from '../../../admin/Selects/EnumSelect';
import { TextArea } from '../../TextArea';

interface DrawerProps {
  open: boolean;
  onClose?: any;
  closable?: boolean;
  form: FormInstance;
  setReloadCount: () => void;
  isEdit: boolean;
  title: string;
}

export const DrawerCreateDelivery = ({
  open,
  onClose,
  closable = true,
  form,
  setReloadCount,
  isEdit,
  title,
}: DrawerProps) => {
  const container = document.getElementById('app-root');
  const intl = useIntl();
  const { user } = useAuth();
  const { formValidations } = useFormValidation();
  const farmId = useMemo(() => {
    return user?.farmId;
  }, [user]);
  const buttonLabel = isEdit
    ? intl.formatMessage({ id: 'general.edit' })
    : intl.formatMessage({ id: 'general.add' });
  const deliveryTypeValue = Form.useWatch('type', form);

  const isSelfDelivery = deliveryTypeValue === 'self';
  const isParcelDelivery = deliveryTypeValue === 'parcel';
  const isIzipiziDelivery = deliveryTypeValue === 'izipizi';
  const isDelivery = deliveryTypeValue === 'delivery';

  useEffect(() => {
    if (isIzipiziDelivery) {
      form.setFieldValue('name', 'izipizi');
    } else if (!isEdit) {
      form.setFieldValue('name', null);
    }
  }, [deliveryTypeValue, isEdit]);

  const { appendData: createDelivery } = useQueryApiClient({
    request: {
      url: '/api/v2/deliveries',
      method: 'POST',
    },
    onSuccess: () => {
      form.resetFields();
      onClose();
      setReloadCount();
      message.success(
        intl.formatMessage({ id: 'message.success_delivery_added' })
      );
    },
  });

  const { appendData: updateDelivery } = useQueryApiClient({
    request: {
      url: '/api/v2/deliveries/:id?_method=PATCH',
      method: 'POST',
    },
    onSuccess: () => {
      form.resetFields();
      onClose();
      setReloadCount();
      message.success(
        intl.formatMessage({ id: 'message.success_delivery_updated' })
      );
    },
  });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const { open_hours, ...otherFields } = values;

      const serializedOpenHours = Object.keys(open_hours).reduce(
        (acc: any, dayIndex) => {
          const dayData = open_hours[dayIndex];

          const serializedDay: DefaultHoursProps = {
            no: dayData.no ? dayData.no.format('HH:mm') : null,
            lidz: dayData.lidz ? dayData.lidz.format('HH:mm') : null,
          };

          if (dayData.closed !== undefined) {
            serializedDay.closed = dayData.closed ? '1' : '0';
          }

          acc[parseInt(dayIndex) + 1] = serializedDay;
          return acc;
        },
        {}
      );

      const payload = {
        ...otherFields,
        farm_id: `${farmId}`,
        open_hours: serializedOpenHours,
      };

      if (isEdit && values.id) {
        updateDelivery(payload, { id: values.id });
      } else {
        createDelivery(payload);
      }
    } catch (e: any) {
      message.error(intl.formatMessage({ id: 'message.error' }));
    }
  };

  const Footer = () => {
    return (
      <div className={'btns'}>
        <Button
          label={intl.formatMessage({ id: 'general.cancel' })}
          onClick={handleClose}
        />
        <Button label={buttonLabel} type={'primary'} onClick={handleSubmit} />
      </div>
    );
  };

  return (
    <StyledDrawer
      open={open}
      onClose={onClose}
      closable={closable}
      footer={<Footer />}
      width={850}
      getContainer={container || document.body}
      title={title}
    >
      <Input hidden name={'id'} />

      <EnumSelect
        label={intl.formatMessage({ id: 'general.delivery_type' })}
        name="type"
        code="DeliveryType"
        layout={'vertical'}
        validations={'required'}
      />

      <div className={'address-finder'}>
        <div className={'label'}>
          {intl.formatMessage({ id: 'general.address' })}
        </div>
        <Form.Item
          name={'address'}
          rules={isSelfDelivery ? formValidations('required') : []}
        >
          <WithoutModalAddressFinder form={form} disabled={!isSelfDelivery} />
        </Form.Item>
      </div>

      {isParcelDelivery ? (
        <EnumSelect
          label={intl.formatMessage({ id: 'general.name' })}
          code={'DeliveryProviders'}
          name={'name'}
          validations={'required'}
          layout={'vertical'}
        />
      ) : (
        <Input
          label={intl.formatMessage({ id: 'general.name' })}
          name={'name'}
          layout={'vertical'}
          disabled={isIzipiziDelivery}
        />
      )}

      <Input
        label={intl.formatMessage({ id: 'general.delivery_price' })}
        layout={'vertical'}
        name={'price'}
        type={'number'}
        validations={'minZeroNumber'}
      />

      <Input
        label={intl.formatMessage({ id: 'general.phone_number' })}
        layout={'vertical'}
        name={'phone'}
        validations={
          isSelfDelivery || isDelivery
            ? ['required', 'phoneNumber']
            : 'phoneNumber'
        }
      />

      <div className={'working-hours'}>
        <div className={'label-wrapper'}>
          <h4>{intl.formatMessage({ id: 'general.working_hours' })}</h4>
        </div>
        <WorkingHours />
      </div>

      <TextArea
        layout="vertical"
        name="description"
        label={intl.formatMessage({
          id: 'general.additional_info_description',
        })}
        className={'desc'}
      />
    </StyledDrawer>
  );
};
