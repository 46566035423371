import dayjs from 'dayjs';

declare global {
  interface Window {
    runConfig: {
      frontendUrl: string;
      backendUrl: string;
      nodeEnv: string;
      mansLauksUrl: string;
      llkcRegistryUrl: string;
    };
  }
}

export const routes = {
  api: {
    frontendUrl: window?.runConfig?.frontendUrl,
    baseUrl: window?.runConfig?.backendUrl,
    mansLauksUrl: window?.runConfig?.mansLauksUrl,
    llkcRegistryUrl: window?.runConfig?.llkcRegistryUrl,
  },
  admin: {
    beginning: '/',
    transactions: '/admin/transactions',
    orders: '/admin/orders',
    farmUsers: '/admin/farm/users',
    farmContracts: '/admin/farm/contracts',
    products: '/admin/products',
    eShopEmail: '/admin/eshop-email',
    farmEmail: '/admin/farm-email',
    qmark: '/admin/qmark',
    users: '/admin/users',
    farms: '/admin/farms',
    contracts: '/admin/contracts',
    roles: '/admin/permissions',
    banners: '/admin/banners',
    topCategories: '/admin/top-categories',
    categories: '/admin/categories',
    globalSettings: '/admin/settings',
    catalogSettings: '/admin/catalog-settings',
    emarketSettings: '/admin/emarket-settings',
  },
};

export const options = {
  filters: {
    pageSizeOptions: [20, 40],
  },
  images: {
    defaultProductImage: '/defaults/Rectangle_149.png',
    defaultImageNotFound: '/defaults/image-not-found.jpeg',
    defaultFarmImage: '/defaults/farm_default.png',
    defaultCategoryImage: '/defaults/Rectangle_142.png',
    defaultBannerOneImage: '/banners/banner_1.png',
    defaultBannerTwoImage: '/banners/banner_2.png',
    defaultBannerThreeImage: '/banners/banner_3.png',
    defaultBannerFourImage: '/banners/banner_4.png',
    defaultFarmGaleryImage: '/defaults/farm_galery.png',
    maxImages: 3,
    maxImageSize: 1440,
    maxSizeInMB: 22,
    allowedFormats: ['image/png', 'image/jpeg', 'image/bmp'],
  },
  open_hours: {
    0: { no: '00:00', lidz: '00:00', closed: false },
    1: { no: '00:00', lidz: '00:00', closed: false },
    2: { no: '00:00', lidz: '00:00', closed: false },
    3: { no: '00:00', lidz: '00:00', closed: false },
    4: { no: '00:00', lidz: '00:00', closed: false },
    5: { no: '00:00', lidz: '00:00', closed: false },
    6: { no: '00:00', lidz: '00:00', closed: false },
  },
  toolbarOptions: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'], // remove formatting button
  ],
};
