import React, { ReactNode } from 'react'
import {
  StyledAppendDotsContainer,
  StyledAppendDotsInner,
  StyledCarousel,
  StyledCustomPaging,
  StyledDotContainer,
} from '../style'
import { ImageProps } from '../../../types/BannerData'
import { FallbackImage } from '../../UploadImage/fallBack/FallBackImage'
import { QualityMarkData } from 'types/ProductData'
import { Icon } from 'ui/Icon'

interface ProductGalleryProps {
  productImages?: ImageProps[]
  customDots?: boolean
  productQualitymarks?: QualityMarkData[]
  isFavorites?: boolean
}

export const ProductGallery = ({
  productImages,
  productQualitymarks,
  isFavorites = false,
  customDots,
}: ProductGalleryProps) => {
  const customPaging = (i: number) => (
    <StyledCustomPaging className={'custom-dot'}>
      <StyledDotContainer>
        {productImages && productImages[i] ? (
          <FallbackImage
            src={`${productImages[i]?.url_original}`}
            key={i}
            type={'ProductCard'}
            preview={false}
          />
        ) : (
          <FallbackImage
            src={productImages ? `${productImages[i].url_original}` : ''}
            type={'ProductCard'}
            preview={false}
          />
        )}
      </StyledDotContainer>
    </StyledCustomPaging>
  )

  return (
    <StyledCarousel
      customPaging={customPaging}
      appendDots={(dots: ReactNode) => (
        <StyledAppendDotsContainer>
          <StyledAppendDotsInner>{dots}</StyledAppendDotsInner>
        </StyledAppendDotsContainer>
      )}
      arrows
    >
      {productImages &&
        productImages.length > 0 &&
        productImages.map((image, index) => (
          <div key={index} className={'gallery-img-container'}>
            <FallbackImage
              src={`${image.url_original}`}
              type={'ProductCard'}
              preview={false}
            />
            {productQualitymarks && productQualitymarks.length > 0 && (
              <div className="product-qualitymarks">
                {productQualitymarks.map((image, index) => (
                  <img
                    key={image.slug}
                    className="product-qualitymarks-image"
                    src={image.logo}
                    alt={image.name}
                  />
                ))}
              </div>
            )}

            {isFavorites && (
              <div className={'add-to-favorites'}>
                <Icon className="heart-icon" name={'heart-filled'} />
              </div>
            )}
          </div>
        ))}
    </StyledCarousel>
  )
}
