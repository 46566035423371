import React, { useEffect, useState } from 'react';
import { Checkbox as AntdCheckbox, Col, Form } from 'antd';
import { useIntl } from 'react-intl';

export interface CheckboxProps {
  id?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  defaultChecked?: boolean;
  value?: number | string;
  key?: string | number;
  label?: any;
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
  itemClassName?: string;
  name?: string | [string, number, string];
  formLabel?: string;
  options?: any;
  initialValue?: any;
  breakNewLine?: boolean;
  filterView?: boolean;
}

export const Checkbox = ({
  disabled,
  label,
  className,
  defaultChecked,
  name,
  value,
  checked,
  onChange,
  key,
  indeterminate,
  formLabel,
  options,
  initialValue,
  breakNewLine,
  filterView = false,
  itemClassName,
}: CheckboxProps) => {
  const [innerOptions, setInnerOptions] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    setInnerOptions(options);
  }, []);

  const defaultCheckedValues =
    initialValue !== undefined
      ? initialValue
      : innerOptions
          ?.filter((item: any) => item.checked)
          ?.map((item: any) => item.value) || [];

  const renderOption = (e: any) => {
    const option = (
      <AntdCheckbox key={e.value} value={e.value} disabled={e.disabled}>
        {e.label}
      </AntdCheckbox>
    );

    if (breakNewLine) {
      return <Col span={24}>{option}</Col>;
    }

    return option;
  };

  const checkbox = options ? (
    <AntdCheckbox.Group
      disabled={disabled}
      className={className}
      onChange={onChange}
      defaultValue={defaultCheckedValues}
      key={key}
    >
      {!filterView ? (
        options.map(renderOption)
      ) : (
        <div className={'qmarks-filter-checkboxes'}>
          <div className={'qmark'}>{options.map(renderOption)}</div>
        </div>
      )}
    </AntdCheckbox.Group>
  ) : (
    <AntdCheckbox
      indeterminate={indeterminate}
      disabled={disabled}
      className={className}
      defaultChecked={defaultChecked}
      value={value}
      checked={checked}
      onChange={onChange}
      key={key}
    >
      {label}
    </AntdCheckbox>
  );

  if (name) {
    return (
      <Form.Item
        className={itemClassName}
        name={name}
        label={formLabel}
        valuePropName={options ? undefined : 'checked'}
      >
        {checkbox}
      </Form.Item>
    );
  }

  return checkbox;
};
