import styled from 'styled-components'

export const StyledMobileProductCategoriesCarousel = styled.div`
  display: none;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};

  .title {
    text-align: left;
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 18px;
  }

  .container-category {
    display: flex;
    padding: 0 20px 10px 20px;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 440px) {
    padding: 0 20px;
    padding-bottom: 44px;
    display: flex;

    .ant-card-cover {
      img {
        height: 194px;
        object-fit: cover;
      }
    }
    .slick-track {
      display: flex;
      gap: 0;
    }

    .container-category {
      padding: 0 0 5px 0;
    }
    .title {
      padding: 1em 0 0 0;

      h1 {
        font-size: 18px;
        margin: 0;
        padding: 4px 0 20px 0;
        line-height: 18px;
        font-weight: 600;
      }
    }

    .slick-dots {
      bottom: -24px;
    }
  }
`
