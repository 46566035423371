import styled from 'styled-components';

interface FarmGaleriesWrapperProps {}

export const FarmDeliveriesWrapper = styled.div<FarmGaleriesWrapperProps>`
  padding: 0 20px;

  .custom-collapse {
    background: none;
    border-radius: 0;
    border: 0;
    font-family: ${(props) => props.theme.fontText};
  }

  .custom-collapse .ant-collapse-item:nth-child(odd) {
    background-color: ${(props) => props.theme.whiteSmoke} !important;
  }

  .custom-collapse .ant-collapse-item:nth-child(even) {
    background-color: ${(props) => props.theme.white};
  }

  .custom-collapse .ant-collapse-item {
    border-bottom: 1px solid ${(props) => props.theme.mistBlue};
  }

  .custom-collapse .ant-collapse-header-text {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.colorText};
  }

  .custom-collapse .ant-collapse-content {
    border: 0;
  }

  .custom-collapse > .ant-collapse-item:last-child {
    border-radius: 0;
  }

  .custom-collapse .ant-collapse-header {
    background: none;
    border-radius: 0;
    padding: 10px 20px 10px 20px;
  }

  .custom-collapse .ant-collapse-content-box {
    padding: 0px 20px 10px 20px;
  }

  .custom-collapse .ant-collapse-arrow {
    color: inherit;
  }

  .delivery-item-title {
    display: block;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
  }

  @media (max-width: 440px) {
    &.farm-partner {
      padding: 32px 20px 7px 20px;
    }

    .custom-collapse .ant-collapse-content-box {
      padding: 0px 20px 10px 20px;
    }

    .custom-collapse .ant-collapse-item {
      border-bottom: 0;
      border-width: 1px 0px 1px 0px;
      border-style: solid;
      border-color: ${(props) => props.theme.mistBlue};
    }
  }
`;
