import { StyledFarmCreateForm } from './style';
import { Input } from '../../Input';
import { useIntl } from 'react-intl';
import { Button } from '../../Button';
import { StyledFarmCreateModal } from '../../Modal/style';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { message } from 'antd';
import { useState } from 'react';
import { Spinner } from '../../Spinner';
import { LlkcResponse } from '../../../interfaces/BackendModels';

interface FarmCreateProps {
  showCreateModal: boolean;
  onCloseModal: () => void;
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FarmCreate = ({
  showCreateModal,
  onCloseModal,
  setShowCreateModal,
}: FarmCreateProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const form = useFormInstance();

  const { appendData: findFarm } = useQueryApiClient({
    request: {
      url: '/api/v2/llkc/farms',
      disableOnMount: true,
    },
    onSuccess: (response, passOnSuccess) =>
      handleFindFarmResponse(response.data, passOnSuccess.contact_email),
    onError: (error) => {
      setIsLoading(false);
      if (error.code === 400)
        message.error(
          intl.formatMessage({ id: 'message.error_incorrect_regnr_pvd' })
        );
    },
  });

  const handleFindFarmResponse = (
    response: LlkcResponse,
    contact_email: string
  ) => {
    if (response.is_empty) {
      return message.error(
        intl.formatMessage({ id: 'message.farm_not_found' })
      );
    }

    createFarm({ ...response, contact_email: contact_email, public: true });
    setShowCreateModal(false);
    form.resetFields();
  };

  const { appendData: createFarm } = useQueryApiClient({
    request: {
      url: '/api/v2/farms/create',
      method: 'POST',
    },
    onSuccess: () => {
      message.success(intl.formatMessage({ id: 'message.success_save_farm' }));
    },
    onError: (error) => {
      if (error?.code === 409) {
        message.error(
          intl.formatMessage({ id: 'message.farm_already_exists' })
        );
      } else {
        message.error(intl.formatMessage({ id: 'message.error_farm_create' }));
      }
    },
  });

  const handleAddFarm = async () => {
    try {
      setIsLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const email = values.contact_email;

      findFarm(values, [], {
        pvd_number: values.pvd_number,
        contact_email: email,
      });
    } catch (e: any) {
      message.error(intl.formatMessage({ id: 'message.error' }));
    }
  };

  const Footer = () => {
    return (
      <div className={'actions'}>
        <Button
          label={intl.formatMessage({ id: 'general.close' })}
          className={'radius'}
          onClick={() => onCloseModal()}
        />
        <Button
          label={intl.formatMessage({ id: 'general.add' })}
          type={'primary'}
          className={'blue radius'}
          onClick={handleAddFarm}
        />
      </div>
    );
  };

  const Header = () => {
    return (
      <div className={'title-wrapper-wrapper'}>
        <div className={'title-wrapper'}>
          <div className={'title'}>
            {intl.formatMessage({ id: 'farms.create_modal' })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <StyledFarmCreateForm>
      <Spinner spinning={isLoading}>
        <StyledFarmCreateModal
          title={<Header />}
          open={showCreateModal}
          onCancel={onCloseModal}
          footer={<Footer />}
          className={'modal'}
        >
          <Input
            label={intl.formatMessage({ id: 'general.e_mail' })}
            layout={'vertical'}
            name={'contact_email'}
            validations={'email'}
          />

          <Input
            label={intl.formatMessage({ id: 'general.registration_number' })}
            layout={'vertical'}
            name={'regNr'}
          />

          <Input
            label={intl.formatMessage({ id: 'general.pvd_number' })}
            layout={'vertical'}
            name={'pvd_number'}
            validations={'pvdNumber'}
          />
        </StyledFarmCreateModal>
      </Spinner>
    </StyledFarmCreateForm>
  );
};
