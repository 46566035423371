import { StyledLoginForm } from './style';
import { Input } from '../../Input';
import { Button } from '../../Button';
import { useIntl } from 'react-intl';
import { useState } from 'react';

interface Email {
  email: string;
}
export interface ResetPasswordFormProps {
  appendData: (data?: Email) => void;
}

export const ResetPasswordForm = ({ appendData }: ResetPasswordFormProps) => {
  const intl = useIntl();
  const [formValid, setFormValid] = useState(true);
  const [form] = StyledLoginForm.useForm();

  const handleSubmit = () => {
    appendData({
      email: form.getFieldValue('email'),
    });
  };

  return (
    <StyledLoginForm
      form={form}
      onFinish={handleSubmit}
      onValuesChange={() =>
        setFormValid(
          form.getFieldsError().some((item) => item.errors.length > 0)
        )
      }
    >
      <div className={'inputs'}>
        <div className={'registration-title-wrapper reset-pw'}>
          <div className={'title'}>
            {intl.formatMessage({ id: 'password.reset_password' })}
          </div>
          <div className={'description'}>
            {intl.formatMessage({ id: 'password.type_email' })}
          </div>
        </div>
        <div className={'registration-input-wrapper'}>
          <div className={'email'}>
            <span className="label">
              {intl.formatMessage({ id: 'login.e_mail' })}
            </span>
            <Input
              size={'large'}
              name={'email'}
              validations={['email', 'required']}
              placeholder="**********"
            />
          </div>
        </div>
        <div className={'registration-button-wrapper'}>
          <div className={'registration-buttons'}>
            <Button
              className={'cstm-btn submit'}
              label={intl.formatMessage({
                id: 'password.reset_password_button',
              })}
              size={'large'}
              htmlType={'submit'}
              disabled={formValid}
            />
          </div>
        </div>
      </div>
    </StyledLoginForm>
  );
};
