import React from 'react';
import { Form, DatePicker as AntdDatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import { Validations } from '../../interfaces';
import useFormValidation from '../../utils/useFormValidation';

export interface DatePickerProps extends Validations {
  size?: 'large' | 'middle' | 'small' | undefined;
  placeholder?: string;
  onChange?: (date: Dayjs, dateString: string | string[]) => void;
  disabled?: boolean;
  value?: Dayjs;
  picker?: any;
  format?: string;
  name?: string;
  label?: string;
  disabledDate?: (date: Dayjs) => boolean;
  initialValue?: Dayjs;
  className?: string;
  suffixIcon?: JSX.Element;
}

export const DatePicker = ({
  className,
  placeholder = '',
  onChange,
  disabled,
  validations,
  value,
  size = 'large',
  picker,
  format = 'DD.MM.YYYY',
  name,
  label,
  disabledDate,
  initialValue,
  suffixIcon,
}: DatePickerProps) => {
  const { formValidations } = useFormValidation();

  const rules = validations ? formValidations(validations) : [];

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      rules={rules}
    >
      <AntdDatePicker
        className={className}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        value={value}
        size={size}
        format={format || 'DD.MM.YYYY'}
        picker={picker}
        disabledDate={disabledDate}
        suffixIcon={suffixIcon}
      />
    </Form.Item>
  );
};
