import React from 'react';
import { StyledPage } from './style';

interface PageContentProps {
  children?: React.ReactNode;
}

const PageFooter = ({ children }: PageContentProps) => {
  return <StyledPage className={'page-footer'}>{children}</StyledPage>;
};

export default PageFooter;
