import React, { useState } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import {
  Tabs,
  Table,
  Button,
  CustomGrid,
  Input,
  TextArea,
  Modal,
  InputNumber,
  Checkbox,
  ColorPicker,
} from 'ui';
import dayjs from 'dayjs';
import { StyledButtonList } from 'styles/buttonList';
import { Form } from 'antd';
import useQueryApiClient from 'utils/useQueryApiClient';
import FarmActivityStatus from './FarmActivityStatus';
import { StyledSettingsPage } from '../style';

const SettingListPage = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<number>(0);
  const [reload, setReload] = useState<number>(0);
  const [isModalVisiblePage, setIsModalVisiblePage] = useState<boolean>(false);
  const [isModalVisibleType, setIsModalVisibleType] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>('farm_services');

  const [form] = Form.useForm();
  const [formSecond] = Form.useForm();

  const intl = useIntl();

  const {} = useQueryApiClient({
    request: {
      url: `api/v2/farm-settings/${selectedEntry}`,
      disableOnMount: !selectedEntry && !isModalVisible,
    },
    onSuccess: (response) => form.setFieldsValue(response),
  });

  const { appendData } = useQueryApiClient({
    request: {
      url: selectedEntry
        ? `api/v2/farm-settings/${selectedEntry}`
        : `api/v2/farm-settings`,
      method: selectedEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const {} = useQueryApiClient({
    request: {
      url: `api/v2/farm-types/${selectedEntry}`,
      disableOnMount: !selectedEntry && !isModalVisiblePage,
    },
    onSuccess: (response) => formSecond.setFieldsValue(response),
  });

  const { appendData: appendDataPage } = useQueryApiClient({
    request: {
      url: selectedEntry
        ? `api/v2/farm-types/${selectedEntry}`
        : `api/v2/farm-types`,
      method: selectedEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'settings.description' }),
      dataIndex: 'description',
    },
    {
      title: intl.formatMessage({ id: 'general.logo' }),
      dataIndex: 'logo',
    },
    {
      title: intl.formatMessage({ id: 'general.color' }),
      dataIndex: 'color',
      render: (value: string) =>
        value && <ColorPicker value={value} disabled />,
    },
    {
      title: intl.formatMessage({ id: 'general.icon' }),
      dataIndex: 'icon',
    },
    {
      title: intl.formatMessage({ id: 'general.search_url' }),
      dataIndex: 'search_url',
    },
    {
      title: intl.formatMessage({ id: 'general.hide' }),
      dataIndex: 'hidden',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value);
              setIsModalVisible(true);
            }}
          />
        </>
      ),
    },
  ];

  const farmTypeColumns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'settings.description' }),
      dataIndex: 'description',
    },
    {
      title: intl.formatMessage({ id: 'settings.conflict_id' }),
      dataIndex: 'conflict_id',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value);
              setIsModalVisiblePage(true);
            }}
          />
        </>
      ),
    },
  ];

  const items = [
    {
      key: 'farm_services',
      label: intl.formatMessage({ id: 'settings.farm_services' }),
      children: (
        <>
          <Table
            url="/api/v2/farm-settings"
            columns={columns}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
    {
      key: 'farm_types',
      label: intl.formatMessage({ id: 'settings.farm_types' }),
      children: (
        <>
          <Table
            url="/api/v2/farm-types"
            columns={farmTypeColumns}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
    {
      key: 'farm_activity_status',
      label: intl.formatMessage({ id: 'settings.farm_activity_status' }),
      children: (
        <FarmActivityStatus
          setIsModalVisibleType={setIsModalVisibleType}
          isModalVisibleType={isModalVisibleType}
        />
      ),
    },
  ];

  const onModalClose = () => {
    setIsModalVisible(false);
    setIsModalVisiblePage(false);
    setSelectedEntry(0);
    form.resetFields();
    formSecond.resetFields();
    setReload((old) => old + 1);
  };

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.catalog_settings' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Button
                label={intl.formatMessage({ id: 'general.create_new' })}
                type="primary"
                onClick={() => {
                  if (activeKey === 'farm_services') {
                    setIsModalVisible(true);
                  }

                  if (activeKey === 'farm_types') {
                    setIsModalVisiblePage(true);
                  }

                  if (activeKey === 'farm_activity_status') {
                    setIsModalVisibleType(true);
                  }
                }}
              />
            </StyledButtonList>
          }
          items={items}
          onChange={setActiveKey}
        />

        <Modal
          open={isModalVisible}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'settings.farm_services' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => form.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={form} onFinish={appendData} layout="vertical">
            <Input
              name="name"
              label={intl.formatMessage({ id: 'general.name' })}
            />
            <TextArea
              name="description"
              label={intl.formatMessage({ id: 'settings.description' })}
            />
            <Input
              name="logo"
              label={intl.formatMessage({ id: 'general.logo' })}
            />
            <ColorPicker
              name="color"
              label={intl.formatMessage({ id: 'general.color' })}
            />
            <Input
              name="icon"
              label={intl.formatMessage({ id: 'general.icon' })}
            />
            <Input
              name="search_url"
              label={intl.formatMessage({ id: 'general.search_url' })}
            />
            <Checkbox
              name="hidden"
              label={intl.formatMessage({ id: 'general.hide' })}
            />
          </Form>
        </Modal>

        <Modal
          open={isModalVisiblePage}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'settings.farm_types' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => formSecond.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={formSecond} onFinish={appendDataPage} layout="vertical">
            <Input
              name="name"
              label={intl.formatMessage({ id: 'general.name' })}
            />
            <TextArea
              name="description"
              label={intl.formatMessage({ id: 'settings.description' })}
            />
            <Input
              name="conflict_id"
              label={intl.formatMessage({ id: 'settings.conflict_id' })}
            />
          </Form>
        </Modal>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default SettingListPage;
