import { StyledLoginForm } from './style';
import { Input } from '../../Input';
import { Button } from '../../Button';
import { useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';

export interface NewPasswordRequest {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export interface NewPasswordFormProps {
  appendData?: (data: NewPasswordRequest) => void;
}

export const NewPasswordForm = ({ appendData }: NewPasswordFormProps) => {
  const intl = useIntl();
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const handleSubmit = () => {
    if (appendData) {
      appendData({
        token: token || '',
        email: email || '',
        password: form.getFieldValue('password'),
        password_confirmation: form.getFieldValue('password_confirmation'),
      });
    }
  };

  const [form] = StyledLoginForm.useForm();

  return (
    <StyledLoginForm form={form} onFinish={handleSubmit}>
      <div className={'inputs'}>
        <div className={'registration-title-wrapper'}>
          <div className={'title'}>
            {intl.formatMessage({ id: 'register.password_renewal' })}
          </div>
        </div>

        <div className={'registration-input-wrapper'}>
          <div className={'email'}>
            <span className="label">
              {intl.formatMessage({ id: 'register.e_mail' })}
            </span>
            <Input
              size={'large'}
              required
              name={'email'}
              placeholder="**********"
              validations={['required', 'email']}
              initialValue={email || ''}
              disabled={true}
            />
          </div>

          <div className={'pw-wrap'}>
            <div className={'pw-first'}>
              <span className="label">
                {intl.formatMessage({ id: 'register.password' })}
              </span>
              <Input
                password
                size={'large'}
                required
                name={'password'}
                placeholder="**********"
                validations={['required']}
              />
            </div>
            <div className={'pw-scnd'}>
              <span className="label">
                {intl.formatMessage({ id: 'register.password_again' })}
              </span>
              <Input
                password
                size={'large'}
                required
                name={'password_confirmation'}
                placeholder="**********"
                dependencies={['password']}
                validations={['required', 'password_confirmation']}
              />
            </div>
          </div>
        </div>

        <div className={'registration-button-wrapper'}>
          <div className={'registration-buttons'}>
            <Button
              className={'cstm-btn'}
              label={intl.formatMessage({ id: 'register.register' })}
              size={'large'}
              htmlType={'submit'}
            />
          </div>
        </div>
      </div>
    </StyledLoginForm>
  );
};
