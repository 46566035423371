import { StyledForm } from './style';
import { CategorySelector } from '../Select/Selectors/CategorySelectors';
import { LocationSelector } from '../Select/Selectors/LocationSelector';
import { FarmsSelector } from '../Select/Selectors/FarmsSelector';
import { QualityMarkCheckboxes } from '../Checkbox/Checkboxes/QualityMarkCheckboxes';
import { AdditionalOptionsCheckboxes } from '../Checkbox/Checkboxes/AdditionalOptionsCheckboxes';
import { DeliveriesCheckboxes } from '../Checkbox/Checkboxes/DeliveriesCheckboxes';
import { useEffect } from 'react';
import { Filter } from 'types/Filter';
import { FilterOptions } from 'types/FilterOptions';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
  setFilter: (newFilter: Filter) => void;
  filterOptions: FilterOptions;
  filter: Filter;
  mapView?: boolean;
  onCloseFilter?: () => void;
}
export const Form = ({
  setFilter,
  filterOptions,
  filter,
  mapView,
  onCloseFilter,
}: Props) => {
  const [form] = StyledForm.useForm();
  const filterCategory: any = { ...filter };

  useEffect(() => {
    if (!!filter) {
      form.setFieldsValue(filter);
    }
  }, [form, filter]);

  return (
    <div className={'form-container'}>
      <StyledForm
        layout={'vertical'}
        onFieldsChange={() => setFilter(form.getFieldsValue())}
        form={form}
      >
        {mapView && (
          <div className={'close-btn-wrapper'}>
            <div className={'close-btn'} onClick={onCloseFilter}>
              <CloseOutlined />
            </div>
          </div>
        )}
        <div className={'categories-form'}>
          <CategorySelector
            mainCategoryValue={filterCategory['main-category']}
            subCategoryValue={filterCategory['sub-category']}
            subSubCategoryValue={filterCategory['sub-subcategory']}
          />
          <LocationSelector locations={filterOptions?.locations} form={form} />
          <FarmsSelector farms={filterOptions?.farms} form={form} />
          <QualityMarkCheckboxes qmarks={filterOptions?.qmark} />
          <AdditionalOptionsCheckboxes services={filterOptions?.services} />
          <DeliveriesCheckboxes delivery={filterOptions?.delivery} />
        </div>
      </StyledForm>
    </div>
  );
};
