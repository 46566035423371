import React, { useEffect, useState } from 'react';
import { Form, FormInstance, RadioChangeEvent } from 'antd';
import { FarmDeliveries } from 'types/FarmData';
import SelectDelivery from '../SelectDelivery';
import {
  FarmProduct,
  SelectedDelivery,
  useCartDispatch,
} from 'public/context/CartContext';
import { Radio, RadioGroup, Spinner } from 'ui';

interface DeliveryFormProps {
  farmProduct: FarmProduct;
  deliveryForm: FormInstance<any>;
  registerSubmitCallback: (callback: () => void) => void;
}

const DeliveryForm = ({
  farmProduct,
  deliveryForm,
  registerSubmitCallback,
}: DeliveryFormProps) => {
  const dispatchCart = useCartDispatch();
  const selectedDeliveryId = farmProduct?.selectedDelivery?.selectedDeliveryId;

  const [selectedDelivery, setSelectedDelivery] = useState<
    FarmDeliveries | undefined
  >();

  const fieldName = `delivery-radio-${farmProduct.farm_name}`;

  useEffect(() => {
    const deliv = farmProduct?.farmDeliveries?.find(
      (delivery) => delivery.id === selectedDeliveryId
    );

    setSelectedDelivery(deliv);

    if (deliv) {
      deliveryForm.setFieldsValue({ [fieldName]: deliv.id });
    }
  }, [farmProduct, selectedDeliveryId, deliveryForm, fieldName]);

  const [selectedDeliveryData, setSelectedDeliveryData] = useState<
    SelectedDelivery[] | null
  >(null);

  const deliveriesSubmited = () => {
    if (selectedDeliveryData) {
      selectedDeliveryData.forEach((selectedData) => {
        dispatchCart({
          type: 'SET_DELIVERY',
          farmId: farmProduct.farm_id,
          delivery: selectedData,
        });
      });
    }
  };

  const handleDeliveryChange = (e: RadioChangeEvent) => {
    const deliveryId = e.target.value as number;
    const handledDelivery = farmProduct?.farmDeliveries?.find(
      (delivery) => delivery.id === deliveryId
    );
    setSelectedDelivery(handledDelivery);

    deliveryForm.setFieldsValue({ [fieldName]: deliveryId });
  };

  useEffect(() => {
    registerSubmitCallback(deliveriesSubmited);
  }, [selectedDeliveryData, selectedDelivery, registerSubmitCallback]);

  return (
    <div className="delivery-options-container">
      <div className="pick-delivery-header">
        <div>{farmProduct.farm_name}</div>
      </div>
      <div className="delivery-options-wrapper">
        <div className="delivery-radio-group">
          <RadioGroup
            name={fieldName}
            onChange={handleDeliveryChange}
            validations={'required'}
          >
            {farmProduct?.farmDeliveries?.map((delivery, index) => (
              <Radio
                key={index}
                label={`${delivery.name} (${delivery.price ? delivery.price?.toFixed(2) : 0} €)`}
                value={delivery.id}
              />
            ))}
          </RadioGroup>
        </div>
        {selectedDelivery && (
          <SelectDelivery
            farmProduct={farmProduct}
            selectedDelivery={selectedDelivery}
            setSelectedDeliveryData={setSelectedDeliveryData}
            selectedDeliveryData={selectedDeliveryData}
            deliveryForm={deliveryForm}
          />
        )}
      </div>
    </div>
  );
};

export default DeliveryForm;
