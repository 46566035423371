import styled from 'styled-components';

export const StyledDiscountItems = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${(props) => props.theme.fontText};

  .banner-wrapper {
    padding: 60px 0 40px 0 !important;
  }
  .banner-wrapper * {
    padding: 0 !important;
  }

  .product-slider-container {
    padding: 0 0 20px 0;
  }

  .slick-dots {
    position: relative;
    padding: 30px 0 0 0;
  }

  .view-all-items-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 50px 0;
  }

  .title {
    display: none;
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;
  }

  .slick-slide {
    &>div {
      width: 100%;
      padding: 0 15px;
      .ant-card {
        max-width: 100%;
        .ant-image {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .product-slider-container {
      padding: 0 0 20px 0;
    }
  }

  @media only screen and (max-width: 440px) {
    .view-all-items-button {
      width: 100%;
      padding: 0 20px 50px 20px;
      a {
        width: 100%;
      }
    }

    .product-slider-container {
      padding: 0 0 20px 0;

      .slick-active > div {
        width: 100%;
      }
    }

    .view-all-items-button {
      button {
        width: 100%;
      }
    }

    .slick-list {
      min-height: 140px;
    }

    .title h1 {
      margin: 0;
      padding-top: 4px;
      padding-bottom: 9px;
    }

    .slick-dots {
      padding-top: 20px;
    }

    .slick-slide {
      display: grid !important;
      grid-template-columns: 1fr;
    }

    .ant-card {
      display: grid;
      grid-template-columns: 136px 1fr;
      height: 100%;
      margin: 0 !important;

      .card-img {
        height: 100%;
        max-height: 153px;
        display: block;
      }

      .ant-image {
        height: 100%;
        display: block;
      }

      .ant-image-img {
        height: 100%;
        min-height: 136px;
      }

      .custom-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-card-body .price-quality-marks .farm-name-container-mobile {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-card-cover {
        height: 153px;
      }

      .ant-card-body {
        gap: 5px;
        justify-content: flex-start;
        height: 153px;
      }

      .price-quality-marks-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-bottom: 10px;
      }

      .price-quality-marks {
        gap: 5px;
      }

      .price-quality-marks {
        margin-bottom: 0;
      }

      .price-type-second {
        line-height: 19px !important;
      }

      .price,
      .type-of-price,
      .price-type-second {
        font-size: 16px !important;
        font-weight: 500;
        line-height: 19px;
      }

      .add-to-cart-container {
        padding-bottom: 0px;
        padding-top: 5px;
      }

      .ant-card-body .add-to-cart-container .add-to-cart-icon .btn-add-to-cart {
        height: 30px;
      }

      .btn-add-to-cart .ant-btn {
        width: 30px !important;
        height: 30px !important;
      }
    }

    .view-all-items-button {
      padding-top: 5px;
      padding-bottom: 45px;
    }
  }
`;
