import React from 'react';
import { StyledHeader } from './style';
import { Profile } from '../../Profile/profile';

const DefaultHeader = () => {
  return (
    <StyledHeader>
      <div className="right-side-header">
        <Profile />
      </div>
    </StyledHeader>
  );
};

export default DefaultHeader;
