import React, { useState } from 'react';
import { StyledFilter } from './style';
import { Form } from 'antd';
import { Button, Tag } from 'ui';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import PaginationSelect from '../../Selects/PaginationSelect';

interface FilterProps {
  children: React.ReactNode;
  onFinish: (v: any) => void;
  products?: boolean;
  totalRecord?: number;
  staticFilter?: any;
  customParse?: any;
  isPagination?: boolean;
}

enum FilterKeys {
  Pagination = 'pagination',
  Confirmed = 'confirmed',
}

const Filter = ({
  children,
  onFinish,
  products,
  totalRecord,
  staticFilter,
  customParse,
  isPagination = true,
}: FilterProps) => {
  const [filterProps, setFilterProps] = useState({});

  const ENUM_TRANSLATION_FIELD = ['status', 'role'];
  const intl = useIntl();
  const [form] = Form.useForm();

  const onFinishInner = (value: any) => {
    let parseValues = {};
    Object.entries(value).forEach((e: any) => {
      if (customParse?.[e?.[0]]) {
        parseValues = {
          ...parseValues,
          [e[0]]: customParse?.[e?.[0]](e[1]),
        };
        return;
      }

      if (dayjs.isDayjs(e?.[1])) {
        parseValues = {
          ...parseValues,
          [e[0]]: e?.[1].format('DD.MM.YYYY'),
        };
        return;
      }

      parseValues = {
        ...parseValues,
        [e[0]]: e[1],
      };
    });

    setFilterProps(parseValues);
    onFinish({ ...parseValues, ...staticFilter });
  };

  const onClear = () => {
    onFinishInner({});
    form.resetFields();
  };

  const onClearInner = (key: string) => {
    form.setFieldValue(key, undefined);
    form.submit();
  };

  return (
    <StyledFilter>
      <div className="main-filter">
        <Form form={form} layout="vertical" onFinish={onFinishInner}>
          <div
            className="horizontal-form"
            style={products ? { justifyContent: 'space-between' } : {}}
          >
            {children}
            {isPagination && (
              <span className="pagination">
                <PaginationSelect
                  label={intl.formatMessage({ id: 'general.show' })}
                  name="page_size"
                  className="pagination"
                  defaultValue={25}
                  onChange={(sizePerPage: number) =>
                    onFinish((advancedSearch: any) => ({
                      ...advancedSearch,
                      page_size: sizePerPage,
                    }))
                  }
                />
              </span>
            )}
          </div>
        </Form>
      </div>
      <div className="tag-list">
        {Object.entries(filterProps).map((entry: any, index) => {
          if (
            entry?.[1] === undefined ||
            entry?.[0] === FilterKeys.Pagination
          ) {
            return <></>;
          }

          if (ENUM_TRANSLATION_FIELD.includes(entry?.[0])) {
            entry[1] = intl.formatMessage({
              id: 'enum.' + entry?.[1].toUpperCase(),
            });
          }

          if (dayjs.isDayjs(entry?.[1])) {
            return (
              <Tag
                key={`tag-${index}`}
                closable
                bordered={false}
                onClose={() => onClearInner(entry[0])}
              >
                {entry?.[1].format('DD.MM.YYYY')}
              </Tag>
            );
          }

          if (entry?.[0] === FilterKeys.Confirmed) {
            return (
              <Tag
                key={`tag-${index}`}
                closable
                bordered={false}
                onClose={() => onClearInner(entry[0])}
              >
                {intl.formatMessage({
                  id: 'general.' + (entry[1] ? 'yes' : 'no'),
                })}
              </Tag>
            );
          }

          return (
            <Tag
              key={`tag-${index}`}
              closable
              bordered={false}
              onClose={() => onClearInner(entry[0])}
            >
              {entry?.[1]}
            </Tag>
          );
        })}
        {Object.entries(filterProps)
          .filter(([key]) => key !== FilterKeys.Pagination)
          .some(([, value]) => !!value || value === 0) && (
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.delete_filter' })}
            onClick={onClear}
          />
        )}
        {Number.isInteger(totalRecord) && (
          <span className="record-count">
            {intl.formatMessage({ id: 'general.count_record' }) + totalRecord}
          </span>
        )}
      </div>
    </StyledFilter>
  );
};

export default Filter;
