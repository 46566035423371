import { StyledFarmCard, StyledFarmListCard } from './style';
import { useIntl } from 'react-intl';
import { FarmData } from '../../types/FarmData';
import { FallbackImage } from '../UploadImage/fallBack/FallBackImage';

export interface FarmProps {
  farm?: FarmData;
  isFarmList?: boolean;
}

export const FarmCard = ({ farm, isFarmList = false }: FarmProps) => {
  const intl = useIntl();
  const coverImg = (
    <FallbackImage
      src={farm?.logo ? farm.logo : ''}
      type={'FarmCard'}
      preview={false}
    />
  );

  return !isFarmList ? (
    <StyledFarmCard cover={coverImg} hoverable={true}>
      <div className={'container'}>
        <div className={'farm-name'}>{farm?.name}</div>
        <div className={'address-farm'}>
          <div className={'address'}>{farm?.actual_full_address}</div>
        </div>
        <div className={'qmarks-wrapper'}>
          <div className={'qmarks-url'}>
            <div className={'qmarks'}>
              {farm?.quality_marks?.map((qmark, index) => (
                <FallbackImage
                  src={qmark.logo ? qmark.logo : ''}
                  type={'notFound'}
                  preview={false}
                  key={index}
                />
              ))}
            </div>
            <div className={'url-to'}>
              <a href={`/farm/${farm?.id}`} className={'url-to-href'}>
                {intl.formatMessage({ id: 'farm_card.view' })}
              </a>
              <div className={'rect-angle-wrap'}>
                <div className={'rect'}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFarmCard>
  ) : (
    <StyledFarmListCard cover={coverImg} hoverable={true}>
      <div className={'container'}>
        <div className={'farm-name'}>{farm?.name}</div>
        <div className={'address-farm'}>
          <div className={'address'}>{farm?.actual_full_address}</div>
        </div>
        <div className={'qmarks-wrapper'}>
          <div className={'qmarks-url'}>
            <div className={'qmarks'}>
              {farm?.quality_marks?.map((qmark, index) => (
                <FallbackImage
                  src={qmark.logo ? qmark.logo : ''}
                  type={'notFound'}
                  preview={false}
                  key={index}
                />
              ))}
            </div>
            <div className={'url-to'}>
              <a href={`/farm/${farm?.id}`}>
                {intl.formatMessage({ id: 'farm_card.view' })}
              </a>
              <div className={'rect-angle-wrap'}>
                <div className={'rect'}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFarmListCard>
  );
};
