import { ProductData } from 'types/ProductData';
import { GlobalStyle, StyledProductView } from './style';
import { Button, Icon } from '../../../ui';
import { useIntl } from 'react-intl';
import { Menu, Rate } from 'antd';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import type { MenuInfo } from 'rc-menu/lib/interface';
import he from 'he';
import {
  ProductUnityPrice,
  ProductPackageUnit,
  ProductPrice,
  RenderProductDelivery,
} from 'utils/productCardHelpers';
import PopularProducts from '../../components/DefaultLayout/PopularProducts';
import { useNavigate } from 'react-router-dom';
import { DefaultThirdHeader } from '../../components/DefaultLayout/DefaultThirdHeader';
import DiscountItems from 'public/components/DefaultLayout/DiscountItems/DiscountItems';
import Banner from 'public/components/DefaultLayout/Banner';
import {
  useBannerState,
  useCategoryState,
  useProductState,
} from 'public/context';
import MobileProductCategoriesCarousel from 'public/components/MobileLayout/MobileProductCategoriesCarousel/MobileProductCategoriesCarousel';
import Counter from '../../components/DefaultLayout/Counter/index';
import AmountSelector from 'ui/CartAmountSelector/CartAmountSelector';
import {
  CartProduct,
  useCartDispatch,
  useCartState,
} from 'public/context/CartContext';
import { options } from '../../../config/config';
import { ProductGallery } from '../../../ui/Carousel/ProductGallery';
import { FallbackImage } from '../../../ui/UploadImage/fallBack/FallBackImage';

export interface ProductViewProps {
  product?: ProductData;
}

const ProductView = ({ product }: ProductViewProps) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('farm_owner');
  const [isOpenImageGallery, setIsOpenImageGallery] = useState(false);
  const { popularCategory } = useCategoryState();
  const { productsDiscounted } = useProductState();
  const { banners } = useBannerState();
  const { farmProducts } = useCartState();
  const APP_URL = window.runConfig.backendUrl;
  const cartDispatch = useCartDispatch();

  const banner_3_data = banners.data.find(
    (banner) => banner.type === 'banner_3'
  );
  const banner_4_data = banners.data.find(
    (banner) => banner.type === 'banner_4'
  );

  const intl = useIntl();
  const navigate = useNavigate();

  const decodedFarmDescription =
    product?.farm_description &&
    he
      .decode(product.farm_description as string)
      .replace(/<\/?[^>]+(>|$)/g, '');

  const hasNutritionalValues = product?.nutritional_values
    ? Object.values(product.nutritional_values).some((value) => value > 0)
    : false;

  const isProductFarmDeliveries = product?.farm_deliveries
    ? !!product.farm_deliveries.length
    : false;

  const hasReviews = false; // TODO new Functionality

  const items = [
    {
      key: 'about_of_product',
      label: intl.formatMessage({ id: 'product.about_of_product' }),
      class: 'about-of-product ant-menu-item',
    },
    ...(isProductFarmDeliveries
      ? [
          {
            key: 'delivery',
            label: intl.formatMessage({ id: 'product.delivery' }),
          },
        ]
      : []),
    {
      key: 'farm_owner',
      label: intl.formatMessage({ id: 'product.farm_owner' }),
    },
    ...(hasNutritionalValues
      ? [
          {
            key: 'ingredients',
            label: intl.formatMessage({ id: 'product.ingredients' }),
          },
        ]
      : []),

    ...(hasReviews
      ? [
          {
            key: 'reviews',
            label: intl.formatMessage({ id: 'product.reviews' }),
          },
        ]
      : []),
    {
      key: 'rate',
      label: <Rate />,
      className: 'rate',
    },
  ];

  const otherProducts = product?.otherProducts;
  const productImages = product?.images && product.images;

  const matchedCartProduct: CartProduct | undefined = farmProducts
    .flatMap((farm) => farm.products)
    .find((cartProduct) => cartProduct?.cartProduct?.id === product?.id);

  const amountCart = matchedCartProduct ? matchedCartProduct.amount : 0;

  const renderContent = (menuItem: string) => {
    switch (menuItem) {
      case 'about_of_product':
        return (
          <div className={'farm-owner'}>
            <div className={'farm-wrap'}>
              <div className={'desc'}> {product?.description}</div>
            </div>
          </div>
        );
      case 'ingredients':
        return (
          <div className={'ingredients'}>
            <div className={'nutrition-values d-flex flex-col'}>
              {hasNutritionalValues && (
                <p>
                  {intl.formatMessage({
                    id: 'nutrition.hundred_gs_contain',
                  })}
                </p>
              )}
              {product?.nutritional_values && (
                <>
                  {[
                    ...Object.entries(product.nutritional_values),
                    ['allergens', product?.allergens],
                    ['storage_conditions', product?.storage_conditions],
                  ]
                    .filter(
                      ([_, value]) => value && (value > 0 || isNaN(+value))
                    )
                    .map(([key, value], index) => (
                      <div
                        className={`value-nutrition d-flex flex-row ${
                          index % 2 === 1
                            ? 'light-gray-background'
                            : 'transparent'
                        }`}
                        key={key}
                      >
                        <div className="nutrition">
                          {intl.formatMessage({ id: `nutrition.${key}` })}
                        </div>
                        <div className="value">{value}</div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        );

      case 'delivery':
        return (
          <div className={'delivery'}>{RenderProductDelivery({ product })}</div>
        );

      case 'farm_owner':
        return (
          <div className={'farm-owner'}>
            <div className={'farm-wrap'}>
              <div className={'desc'}> {decodedFarmDescription}</div>
              <div className={'goto'}>
                <Button
                  className={'goto-btn customized'}
                  size={'small'}
                  type={'primary'}
                  label={intl.formatMessage({
                    id: 'product_view.go_to_farm_profile',
                  })}
                />
              </div>
            </div>
          </div>
        );

      case 'reviews':
        return <div className={'reviews'}></div>;

      default:
        return null;
    }
  };

  const handleMenuClick = (e: MenuInfo) => {
    setSelectedMenuItem(e.key);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const productHasDiscount = !!(
    product?.storage_stock_discount_price &&
    product?.storage_stock_price &&
    product.storage_stock_discount_price < product.storage_stock_price
  );

  const handleZoomClick = () => {
    setIsOpenImageGallery(true);
  };

  const handleCloseClick = () => {
    setIsOpenImageGallery(false);
  };

  const handleOutsideClick = (e: React.MouseEvent) => {
    setIsOpenImageGallery(false);
  };

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const addToCart = () => {
    cartDispatch({
      type: 'ADD_ITEM',
      product: product,
      amount: 1,
    });
  };

  const qualityMark = product?.qualityMarkItems?.[0]?.quality_mark;

  return (
    <>
      <GlobalStyle />
      <StyledProductView className="product-page">
        <div className={'content-container'}>
          <DefaultThirdHeader
            label={`${product?.name}`}
            breadcrumb={product?.breadCrumbs}
            qmarks={product?.qualitymarks}
            unitType={<ProductPackageUnit product={product} />}
          />
          <div className={'breadcrumb-mobile light-gray'}>
            <div className={'icon-bread'} onClick={handleBackClick}>
              <LeftOutlined />
              <p>
                {intl.formatMessage({
                  id: 'product_view.back_to_product_view',
                })}
              </p>
            </div>
          </div>
          <div className={'mobile-product-images'}>
            <ProductGallery
              productImages={productImages}
              customDots={true}
              productQualitymarks={product?.qualitymarks}
              isFavorites={true}
            />
          </div>
          <div className={'product-container d-flex flex-row'}>
            <div className={'photo-desc-price d-flex flex-row'}>
              <div className={'photo'}>
                <FallbackImage
                  src={product?.image_small ? product.image_small : ''}
                  type={'ProductCard'}
                  preview={false}
                />
                <div className={'product-tags'}>
                  <div className={'tags'}>
                    {!!product?.storage_stock_discount_price && (
                      <Icon name={'discount-tag'} />
                    )}
                    {product?.is_top_bought_product && (
                      <div className={'tag'}>
                        {intl.formatMessage({ id: 'product.top_price' })}
                      </div>
                    )}
                  </div>
                  <div className={'add-to-favorites'}>
                    <Icon name={'heart-filled'} />
                  </div>
                </div>
                <div className={'zoom-svg-wrapper'}>
                  <div className={'zoom-svg'}>
                    {productImages && (
                      <Icon name={'open-image'} onClick={handleZoomClick} />
                    )}
                  </div>
                </div>
                {qualityMark && (
                  <div className="quality-mark">
                    <img alt={qualityMark.slug} src={qualityMark.logo} />
                  </div>
                )}
              </div>
              <div className={'desc-price d-flex flex-col'}>
                <div className={'desc'}>{product?.description}</div>
                <div className={'mobile-name'}>{product?.name}</div>
                <div className={'owner'}>
                  {intl.formatMessage({ id: 'product.creator' })}
                  {product?.farm_name}
                </div>
                <div className={'price-wrapp'}>
                  <div className={'price-main d-flex flex-col'}>
                    <ProductUnityPrice product={product} />
                    <ProductPrice
                      product={product}
                      productHasDiscount={productHasDiscount}
                    />
                  </div>
                </div>
              </div>
              <div className={'breadcrumb-add-to-cart-btn d-flex flex-col'}>
                <div className={'breadcrumb light-gray'}>
                  <div className={'icon-bread'} onClick={handleBackClick}>
                    <LeftOutlined />
                    <p>
                      {intl.formatMessage({
                        id: 'product_view.back_to_product_view',
                      })}
                    </p>
                  </div>
                </div>
                {amountCart === 0 && (
                  <div
                    className={'btn-add-to-cart'}
                    onClick={() => addToCart()}
                  >
                    <Counter />
                  </div>
                )}

                <div
                  className={'select-ammount-container'}
                  style={{ display: amountCart > 0 ? 'inline' : 'none' }}
                >
                  <AmountSelector
                    product={product}
                    unitType={product?.storage_unit}
                    amountCart={amountCart}
                    className={'productView'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={'menu-container'}>
            <Menu
              mode="horizontal"
              defaultSelectedKeys={[`${selectedMenuItem}`]}
              items={items}
              onClick={handleMenuClick}
              style={{ flex: 1, minWidth: 0 }}
              className={'product-menu'}
            />
            <div className={'content-section'}>
              {renderContent(selectedMenuItem)}
            </div>
          </div>
          <div className={'other-products-container'}>
            <PopularProducts
              products={otherProducts}
              farm_name={product?.farm_name}
            />
          </div>
          <MobileProductCategoriesCarousel popularCategory={popularCategory} />
          <div className={'product-slider'}>
            <DiscountItems products={productsDiscounted} slidesPerRow={1} />
          </div>
          <div className="banners">
            <div className="container">
              {banner_3_data && (
                <Banner banners={[banner_3_data]} className="first-banner" />
              )}
              {banner_4_data && (
                <Banner banners={[banner_4_data]} className="second-banner" />
              )}
            </div>
          </div>
          {isOpenImageGallery && (
            <div
              className={'image-gallery-wrapper-wrapper'}
              onClick={handleCloseClick}
            >
              <div
                className={'image-gallery-wrapper'}
                onClick={stopPropagation}
              >
                <ProductGallery productImages={productImages} />
                <div className={'close-image-btn'} onClick={handleCloseClick}>
                  <CloseOutlined />
                </div>
              </div>
            </div>
          )}
        </div>
      </StyledProductView>
    </>
  );
};

export default ProductView;
