import { StyledOrderStatus } from './style';
import { StatusComponent } from './StatusComponent/StatusComponent';
import { Icon } from '../../../../../ui';
import { useIntl } from 'react-intl';

interface OrderStatus {
  transactionStatus: Status;
}

export type Status = (typeof ALL_STATUSES)[number];
export const ALL_STATUSES = ['new', 'confirmed', 'sent', 'delivered'] as const;

export const OrderStatus = ({ transactionStatus }: OrderStatus) => {
  const intl = useIntl();

  return (
    <StyledOrderStatus>
      {ALL_STATUSES.map((status, index) => {
        return (
          <>
            <StatusComponent
              step={index + 1}
              label={intl.formatMessage({ id: `status.${status}` })}
              selected={status === transactionStatus}
            />
            {ALL_STATUSES.length !== index + 1 && (
              <Icon name={'arrow-right-big'} />
            )}
          </>
        );
      })}
    </StyledOrderStatus>
  );
};
