import { adminToken, token } from './token'

export const publicComponents = {
  Button: {
    defaultBg: adminToken.brand01,
    defaultColor: adminToken.white,

    //large
    paddingBlockLG: 20,
    borderRadiusLG: 30,
    controlHeightLG: 15,
    paddingInlineLG: 50,
    contentFontSizeLG: 16,

    //middle
    paddingBlockSM: 20,
    borderRadiusSM: 30,
    controlHeightSM: 15,
    paddingInlineSM: 20,
    contentFontSizeSM: 16,
  },
  Carousel: {
    colorBgContainer: token.brand01,
  },
  Spin: {
    colorBgMask: token.white,
  },
  Form: {
    labelHeight: 32,
  },
  Select: {
    colorBgContainer: token.white,
    borderRadius: 0,
    optionHeight: 45,
    colorBorder: 'none',
    colorText: token.black,
    optionLineHeight: 2,
    optionPadding: 6,
    optionSelectedBg: token.lightGrey,
  },
  Checkbox: {
    colorBorder: token.checkboxBorderColor,
    borderRadiusSM: 5,
  },
  Input: {
    paddingBlockLG: 10,
    borderRadiusLG: 5,
  },
}

export const adminComponents = {
  Layout: {
    siderBg: adminToken.brand01,
    bodyBg: adminToken.white,
  },
  Breadcrumb: {
    separatorColor: adminToken.colorText,
    itemColor: adminToken.colorText,
    lastItemColor: adminToken.inactiveColor,
  },
  Tabs: {
    inkBarColor: adminToken.colorText,
    itemActiveColor: adminToken.colorText,
    itemColor: adminToken.inactiveColor,
    itemSelectedColor: adminToken.colorText,
    horizontalItemPadding: '20px 0px',
  },
  Button: {
    paddingBlock: 19,
    paddingInline: 20,
  },
  Pagination: {
    borderRadius: 30,
    itemActiveBg: adminToken.colorText,
    colorPrimary: adminToken.white,
    colorText: adminToken.colorPadding,
    colorPrimaryHover: adminToken.white,
  },
  Table: {
    cellFontSize: 16,
    cellPaddingBlock: 26,
    cellPaddingInline: 24,
    headerBg: 'transparent',
    borderColor: 'transparent',
  },
  Tag: {
    defaultColor: adminToken.colorText,
    defaultBg: 'transparent',
    paddingXXS: 10,
    // lineHeightSM: 3,
    marginXS: 30,
    fontSizeSM: 16,
    fontSizeIcon: 16,
  },
  Checkbox: {
    colorPrimaryBorder: adminToken.activeCheckboxBorder,
    colorPrimaryHover: adminToken.activeCheckboxBorder,
    colorPrimary: adminToken.activeCheckboxBackground,
    colorBorder: adminToken.borderCheckbox,

    colorBgContainerDisabled: adminToken.disableCheckboxBackground,
    colorTextDisabled: adminToken.disableCheckboxBorder,
    borderRadiusSM: 4,
  },
  Input: {
    addonBg: adminToken.inputAddonBg,
    inputFontSizeLG: 14,
    paddingBlockLG: 9,
  },
  DatePicker: {
    addonBg: adminToken.inputAddonBg,
    inputFontSizeLG: 14,
    paddingBlockLG: 9,
  },
  Form: {
    labelFontSize: 14,
  },
  Steps: {
    colorText: adminToken.colorPrimary,
    colorTextDescription: adminToken.stepInactiveColor,
    navArrowColor: adminToken.stepArrowColor,
  },
  Drawer: {
    footerPaddingBlock: 20,
    footerPaddingInline: 40,
  },
  Dropdown: {
    margin: 0
  }
}
