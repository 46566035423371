import styled from 'styled-components';

export const StyledHeader = styled.div`
  width: 100%;
  height: 113px;

  display: flex;
  justify-content: right;
  align-items: center;

  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};

  padding: 0 50px;

  .left-side-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right-side-header {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 16px;
    padding: 8px;
  }
`;
