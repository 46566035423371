import React, { useEffect, useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { CustomImage } from './CustomImage';
import { StyledCustomUploadList } from './style';
import { RadioGroup } from 'ui/RadioGroup';
import { Form, RadioChangeEvent } from 'antd';
import { useIntl } from 'react-intl';
import { EditCustomImage } from './EditCustomImage';

interface CustomUploadListProps {
  fileList: UploadFile[];
  onRemove: (file: UploadFile) => void;
  onEdit: (file: UploadFile) => void;
  thumbUrls: { [uid: string]: string };
}

export const CustomUploadList: React.FC<CustomUploadListProps> = ({
  fileList,
  onRemove,
  onEdit,
  thumbUrls,
}) => {
  const form = Form.useFormInstance();
  const intl = useIntl();

  useEffect(() => {
    if (fileList.length === 1) {
      form.setFieldValue(
        'selected-image',
        fileList[0].uid + '_' + fileList[0].name
      );
    } else if (fileList.length > 1) {
      form.setFieldValue('selected-image', null);
    }
  }, [fileList]);

  return (
    <StyledCustomUploadList>
      <RadioGroup name={'selected-image'} validations={'required'}>
        {fileList.map((file) => (
          <CustomImage
            key={file.uid}
            file={file}
            thumbUrl={thumbUrls[file.uid]}
          />
        ))}
      </RadioGroup>
      <div className={'custom-upload-list-item-container'}>
        {fileList.map((file) => (
          <div className="custom-upload-list-item-actions">
            <EditCustomImage onRemove={onRemove} onEdit={onEdit} file={file} />
            <div onClick={() => onRemove(file)} className={'action'}>
              {intl.formatMessage({ id: 'general.delete' })}
            </div>
          </div>
        ))}
      </div>
    </StyledCustomUploadList>
  );
};
