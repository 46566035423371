import styled from 'styled-components';

export const StyledLoginPage = styled('div')`
  .content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 100%;
    min-height: 100vh;
    background-image: url('/man-farmland-with-watering-can.png');
    background-size: cover;
    background-position: center;
  }

  @media only screen and (max-width: 1100px) {
    .content {
      justify-content: center;
      align-items: stretch;
    }
  }
`;

export const StyledConfirmationPageWrapper = styled.div`
  .content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 100%;
    min-height: 100vh;
    background-image: url('/man-farmland-with-watering-can.png');
    background-size: cover;
    background-position: center;

    .content_inner {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 545px;
      background-color: ${(props) => props.theme.white};
      font-family: ${(props) => props.theme.fontText};
      border-radius: 6px;
      margin-right: 200px;
      width: 100%;
      max-width: 640px;

      .title {
        margin: 0;
        margin-bottom: 9px;
        color: ${(props) => props.theme.darkGrayishBlue};
        line-height: 62px;
        font-size: 32px;
        font-weight: 600;
        text-align: center;
      }

      .text {
        margin: 0;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: ${(props) => props.theme.darkGrayishBlue};
      }

      .email {
        display: block;
        margin-bottom: 39px;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        color: ${(props) => props.theme.darkGrayishBlue};
      }

      .img {
        display: block;
        margin: 0 auto;
        width: 131px;
        height: 131px;
      }

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .content {
      justify-content: center;
      .content_inner {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .content {
      .content_inner {
        height: 100vh;
        padding: 35px;
        .box {
          display: flex;
          flex-direction: column;
          height: 100vh;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
`;

export const StyledApproveRegistrationPageWrapper = styled.div`
  .content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 100%;
    min-height: 100vh;
    background-image: url('/man-farmland-with-watering-can.png');
    background-size: cover;
    background-position: center;

    .content_inner {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 545px;
      background-color: ${(props) => props.theme.white};
      font-family: ${(props) => props.theme.fontText};
      border-radius: 6px;
      margin-right: 200px;
      width: 100%;
      max-width: 640px;

      .box.unsuccess {
        width: 60%;
      }

      .title {
        margin: 0;
        margin-bottom: 9px;
        color: ${(props) => props.theme.darkGrayishBlue};
        line-height: 62px;
        font-size: 32px;
        font-weight: 600;
        text-align: center;
      }

      .text {
        margin: 0;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: ${(props) => props.theme.darkGrayishBlue};
      }

      .approve-registration-wrapper-btns {
        display: flex;
        justify-content: center;
        gap: 20px;
        padding-top: 81px;
      }

      .approve-registration-wrapper-btn {
        min-width: 241px;
        min-height: 55px;
        border: 1px solid ${(props) => props.theme.brand01};
        border-radius: 5px;
      }

      .add-business {
        background-color: ${(props) => props.theme.brand01};
        min-width: 241px;
        min-height: 55px;
        border: 1px solid ${(props) => props.theme.brand01};
        border-radius: 5px;
      }

      .add-business:hover {
        background-color: ${(props) => props.theme.white};
        border: 1px solid ${(props) => props.theme.brand02};
      }

      .add-business .text {
        color: ${(props) => props.theme.white};
      }

      .add-business:hover .text {
        color: ${(props) => props.theme.brand02};
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .content {
      .content_inner {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .content {
      justify-content: center;
      .content_inner {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .content {
      .content_inner {
        height: 100vh;
        .box {
          display: flex;
          flex-direction: column;
          height: 100vh;
          justify-content: center;

          .approve-registration-wrapper-btns {
            flex-direction: column;
          }
        }
      }
    }
  }
`;
export const StyledTermsOfUseWrapper = styled.div`
  .page-inner {
    padding: 50px 0;
  }

  .title {
    margin: 0;
    margin-bottom: 5px;
    font-size: 42px;
    font-weight: 500;
    line-height: 60px;
    color: ${(props) => props.theme.darkGrayishBlue};
  }

  .sub-title {
    margin: 0;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 500;
    line-height: 21px;
    color: ${(props) => props.theme.darkGrayishBlue};
  }

  .contnet span {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 20px;
    color: ${(props) => props.theme.darkGrayishBlue};
  }
`;
