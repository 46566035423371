import React, { useEffect } from 'react';
import DefaultLayout from 'public/components/DefaultLayout';
import { useBannerState } from '../../context';
import { useParams } from 'react-router-dom';
import useQueryApiClient from 'utils/useQueryApiClient';
import { Spinner } from 'ui';
import FarmView from './FarmView';
import Banner from 'public/components/DefaultLayout/Banner';
import { useFilterDispatch } from 'public/context/FilterContext';
import { FarmGlobalStyle } from './style';

const FarmPage = () => {
  const { id } = useParams<{ id: string }>();
  const { banners } = useBannerState();
  const parsedBanners = Object.values(banners);
  const banner_3_data = parsedBanners.find(
    (banner) => banner.type === 'banner_3'
  );
  const dispatchFilter = useFilterDispatch();

  const { isLoading: isLoadingFarm, data } = useQueryApiClient({
    request: {
      url: `api/v2/farms/${id}`,
    },
  });

  useEffect(() => {
    dispatchFilter({
      type: 'SAVE_PAYLOAD',
      payload: { isFilter: false, openCloseDrawer: () => null },
    });
  }, [dispatchFilter]);

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        <FarmGlobalStyle />
        <Spinner spinning={isLoadingFarm}>
          <FarmView farm={data} />
          {banner_3_data && <Banner banners={[banner_3_data]} />}
        </Spinner>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default FarmPage;
