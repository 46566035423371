import { StyledNewProductForm } from './style';
import { useIntl } from 'react-intl';
import { Requirements } from '../../UploadImage/Requirements';
import { UploadFile } from 'antd/es/upload/interface';
import { CreateEditProductsGallery } from 'ui/UploadImage/product/CreateEditProductsGallery';

interface NewProductGalleryProps {
  onValidate: (isValid: boolean) => void;
  onFileListChange?: (fileList: UploadFile[]) => void;
  fileList: any;
}

export const NewProductGallery = ({
  onValidate,
  onFileListChange,
  fileList,
}: NewProductGalleryProps) => {
  const intl = useIntl();

  return (
    <StyledNewProductForm>
      <div className={'title'}>
        {intl.formatMessage({ id: 'general.gallery_title' })}
      </div>
      <div className={'requirements'}>
        <Requirements />
        <div className={'image-upload'}>
          <CreateEditProductsGallery
            onValidate={onValidate}
            onFileListChange={onFileListChange}
            fileList={fileList}
            multiple={true}
          />
        </div>
      </div>
    </StyledNewProductForm>
  );
};
