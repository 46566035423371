import { styled } from 'styled-components';

export const StyledCartSummary = styled.div`
  .cart-summary {
    max-width: 380px;
    width: max-content;
    margin-bottom: 30px;

    .cart-summary-title {
      font-size: 24px;
      font-weight: 400;
      padding-bottom: 5px;
      padding-top: 15px;
    }

    .cart-order-summary-wrapper {
      background-color: ${(props) => props.theme.summaryBackground};
      width: 100%;
      padding: 20px;
      border: solid 1px ${(props) => props.theme.lightborder};
      .cart-order-summary-collection {
        .cart-farm-order-summary {
          font-size: 16px;
          margin-bottom: 32px;

          &.mobile-total {
            display: none;
          }

          .farm-title {
            font-weight: 600;
          }

          .farm-summary-wrapper {
            .cart-summary-container {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .cart-summary-item {
                margin: 4px 0 0 0;
                font-weight: 400;
                height: 24px;
              }
            }
          }
        }
      }
    }

    .cart-order-summary-wrapper {
      font-family: ${(props) => props.theme.fontText};
      .cart-farm-order-summary.total {
        margin-bottom: 32px;
        padding: 32px 0;
        border-top: 1px solid ${(props) => props.theme.borderBottom};
        border-bottom: 1px solid ${(props) => props.theme.borderBottom};

        .farm-summary-wrapper {
          .cart-summary-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .cart-summary-item {
              margin: 4px 0 0 0;
              font-weight: 400;
              height: 24px;
            }

            .cart-summary-item.price {
              font-weight: 700;
            }
          }
        }
      }

      .cart-summary-submit-wrapper {
        .cart-summary-final-price {
          display: flex;
          justify-content: space-between;
          font-family: ${(props) => props.theme.fontText};
          font-size: 16px;
          padding-bottom: 16px;

          .cart-summary-final-price-title {
            font-weight: 400;
          }

          .cart-summary-price {
            font-weight: 700;
          }
        }

        .cart-summary-submit-btn {
          font-weight: 700;
          width: 100%;
          height: 52px;
          font-family: ${(props) => props.theme.fontText};
        }

        .cart-summary-submit-btn-view {
          width: 100%;
          height: 52px;
          font-family: ${(props) => props.theme.fontText};
        }

        .cart-summary-submit-btn-view {
          border: solid 1px ${(props) => props.theme.brand02};
          background: transparent;
          color: ${(props) => props.theme.black};
        }

        .cart-summary-submit-btn-view.order-again {
          margin-bottom: 10px;
        }
      }

      .cart-discount-coupon {
        border-bottom: 1px solid ${(props) => props.theme.borderBottom};
        padding-bottom: 32px;

        .ant-form-item {
          margin-bottom: 0;

          .ant-form-item-label {
            padding: 0 0 3px;
            line-height: 19px;
          }
          .ant-form-item-control {
            height: 45px;
          }
        }
      }

      .cart-discount-subscribe {
        height: 24px;
        margin: 32px 0;
        font-weight: 300;
        .ant-form-item {
          margin: 0;
        }
      }
    }
  }

  .total-summary {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    .cart-summary {
      .cart-order-summary-wrapper {
        background-color: transparent;
        border: none;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .ant-form {
      display: flex;
      justify-content: center;
      .cart-summary {
        width: 100%;
        max-width: 100%;
        .cart-order-summary-wrapper {
          border-radius: 15px;
          .cart-order-summary-collection {
            border-bottom: 1px solid ${(props) => props.theme.borderBottom};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .cart-summary {
      .cart-summary-title {
        display: none;
      }

      .cart-order-summary-wrapper {
        padding: 16px 16px 16px 16px;

        .cart-farm-order-summary {
          margin-bottom: 20px;
          &.total {
            display: none;
          }

          &.mobile-total {
            display: block;

            .farm-summary-wrapper {
              margin-top: 27px;

              .cart-summary-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 13px 0 13px 0;
                padding: 9px 0 13px 0;
                border-bottom: 1px solid
                  ${(props) => props.theme.lightGrayBorder};

                &:last-child {
                  border-bottom: 0;
                }

                .cart-summary-item {
                  margin: 0;
                }

                .cart-summary-item.price {
                  color: ${(props) => props.theme.colorText};
                  font-weight: 600 !important;
                  margin: 0;
                }
              }
            }
          }
        }

        .cart-discount-coupon,
        .cart-discount-subscribe,
        .cart-summary-submit-wrapper {
          .cart-summary-submit-btn {
            display: none;
          }
        }
      }

      .cart-summary-submit-btn-view {
        padding: 0;
        background-color: ${(props) => props.theme.white};
      }
    }

    .total-summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 31px 20px 61px 20px;
      background-color: ${(props) => props.theme.brand01};
      border-radius: 15px;
      position: fixed;
      width: 100vw;
      bottom: 70px;
      left: 0;
      z-index: 10;

      .total-summary-text {
        color: ${(props) => props.theme.white};
        font-weight: 400;
      }

      button {
        height: auto;
        padding: 8px 16px;
      }

      .cart-summary-submit-btn-view,
      .cart-summary-submit-btn {
        display: none;
      }
    }
  }
`;
