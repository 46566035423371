import styled from 'styled-components';

export const StyledStatusComponent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70px;
  max-height: 54px;
  gap: 10px;

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.colorPrimary};
    border-radius: 40px;
    min-width: 28px;
    min-height: 28px;
  }

  .selected {
    background-color: ${(props) => props.theme.colorPrimary};
    color: ${(props) => props.theme.white};
  }
`;
