import { useAuth } from 'hooks/useAuth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Icon } from 'ui';
import { StyledMobileAuthForm as StyledMobileLoginForm } from './style';

interface MobileLoginProps {
  setOpenMobileLogin: (value: React.SetStateAction<boolean>) => void;
}

const MobileLogin = ({ setOpenMobileLogin }: MobileLoginProps) => {
  const intl = useIntl();
  const { user, isFetchingUser, login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (formData: any) => {
    await login(formData).then(() => setOpenMobileLogin(false));
  };

  const APP_URL = window.runConfig.backendUrl;

  const handleSubmit = (values: any) => {
    if (handleLogin) {
      handleLogin(values);
    }
  };

  const handleGoogleAuth = () => {
    window.location.href = `${APP_URL}/api/v2/login/google`;
  };

  const [form] = StyledMobileLoginForm.useForm();

  if (user?.authenticated) return null;

  return (
    <StyledMobileLoginForm form={form} onFinish={handleSubmit}>
      <div className={'mobile-login-form-title'}>
        {intl.formatMessage({ id: 'login.mobile_login' })}
      </div>
      <Input
        size={'large'}
        required
        name={'email'}
        validations={'email'}
        placeholder={intl.formatMessage({ id: 'login.e_mail' })}
      />

      <Input
        password
        size={'large'}
        required
        name={'password'}
        validations={'required'}
        placeholder={intl.formatMessage({ id: 'login.password' })}
      />

      <div
        className={'mobile-forgot-password'}
        onClick={() => navigate('/restet-password')}
      >
        <span>{intl.formatMessage({ id: 'login.forgot_password' })}</span>
      </div>

      <Button
        className={'mobile-login-submit-btn'}
        label={intl.formatMessage({ id: 'login.login' })}
        size={'large'}
        htmlType={'submit'}
        icon={<Icon name={'arrow-right-red'} />}
      />

      <div className={'mobile-login-connect google'}>
        <span>{intl.formatMessage({ id: 'login.connect_with' })}</span>
        <Button
          className={'mobile-login-google-btn'}
          label={intl.formatMessage({ id: 'login.google' })}
          type={'link'}
          onClick={handleGoogleAuth}
        />
      </div>

      <div className={'mobile-login-connect'}>
        <span>{intl.formatMessage({ id: 'login.not_registred_yet' })}</span>
        <Button
          className={'mobile-login-google-btn'}
          label={intl.formatMessage({ id: 'login.click_there' })}
          type={'link'}
          onClick={() => navigate('/register')}
        />
      </div>
    </StyledMobileLoginForm>
  );
};

export default MobileLogin;
