import { useIntl } from 'react-intl';
import { StyledApproveRegistrationPageWrapper } from './style';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'ui';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useState } from 'react';
import { AuthProps, useAuth } from 'hooks/useAuth';

export const ApproveRegistrationPage = () => {
  const { atuhenticate } = useAuth();
  const [isApproved, setIsApproved] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const {} = useQueryApiClient({
    request: {
      url: `/api/v2/confirm-email`,
      method: 'POST',
      data: {
        token: token,
      },
      enableOnMount: true,
    },
    onSuccess: (response) => {
      atuhenticate({ authenticated: response.authenticated } as AuthProps);
      setIsApproved(true);
    },
    onError: () => {
      setIsApproved(false);
    },
  });

  return (
    <StyledApproveRegistrationPageWrapper>
      <div className={'content'}>
        <div className={'content_inner'}>
          {isApproved ? (
            <div className="box">
              <h2 className={'title'}>
                {intl.formatMessage({ id: 'successful_registration.title' })}
              </h2>
              <p className={'text'}>
                {intl.formatMessage({ id: 'successful_registration.text' })}
              </p>
              <div className={'approve-registration-wrapper-btns'}>
                <Button
                  type={'text'}
                  className={'approve-registration-wrapper-btn'}
                  label={intl.formatMessage({
                    id: 'successful_registration.shop',
                  })}
                  onClick={() => navigate('/')}
                />
                <Button
                  type={'text'}
                  className={'btn add-business'}
                  label={intl.formatMessage({
                    id: 'successful_registration.add_business',
                  })}
                  onClick={() => navigate('/registration-company')}
                />
              </div>
            </div>
          ) : (
            <div className={'box unsuccess'}>
              <h2 className={'title'}>
                {intl.formatMessage({
                  id: 'successful_registration.unsuccess',
                })}
              </h2>
              <div className={'approve-registration-wrapper-btns'}>
                <Button
                  type={'text'}
                  className={'approve-registration-wrapper-btn'}
                  label={intl.formatMessage({
                    id: 'successful_registration.shop',
                  })}
                  onClick={() => navigate('/')}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledApproveRegistrationPageWrapper>
  );
};
