import { StyledBusinessForm } from '../style';
import { useIntl } from 'react-intl';
import { Button, Checkbox, Dropdown, Table } from '../../../../ui';
import { MenuProps, message, Form } from 'antd';
import { useState } from 'react';
import { CreateEditUser } from './CreateEditUser/CreateEditUser';
import { useForm } from 'antd/lib/form/Form';

export interface RecordProps {
  full_name: string;
  contact_phone: number;
  email: string;
  created_at: string;
  created_by: number;
  id: number;
  name: string;
  public: boolean;
  role: string;
  updated_at: string;
  status: boolean;
  farm_id: number;
  deleted_at: string | undefined;
}
export const ContactInformation = () => {
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [isOpenEditCreateDrawer, setIsOpenEditCreateDrawer] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const intl = useIntl();
  const [form] = useForm();

  const handleEdit = (record: RecordProps) => {
    form.setFieldValue('contact_name', record.full_name);
    form.setFieldValue('contact_phone', record.contact_phone);
    form.setFieldValue('contact_email', record.email);
    form.setFieldValue('id', record.id);
    form.setFieldValue('farm_id', record.farm_id);
    form.setFieldValue('status', record.deleted_at == null);

    setIsOpenEditCreateDrawer(true);
    setIsEdit(true);
  };
  const handleDelete = (id: number) => {};
  const handleCloseDrawer = () => {
    form.resetFields();
    setIsOpenEditCreateDrawer(false);
    setIsEdit(false);
  };

  const handleOpenEditCreateDrawer = () => {
    setIsOpenEditCreateDrawer(true);
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'farm_users.full_name' }),
      dataIndex: 'full_name',
      render: (value: string) => value ?? '-',
    },
    {
      title: intl.formatMessage({ id: 'general.phone' }),
      dataIndex: 'contact_phone',
      render: (value: number) => value ?? '-',
    },
    {
      title: intl.formatMessage({ id: 'general.email' }),
      dataIndex: 'email',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.position' }),
      dataIndex: 'role',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.status' }),
      render: (record: RecordProps) => (
        <Checkbox checked={record?.deleted_at == null ?? false} />
      ),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      render: (record: RecordProps) => {
        const items: MenuProps['items'] = [
          {
            key: 'edit',
            label: intl.formatMessage({ id: 'general.edit' }),
            onClick: () => handleEdit(record),
          },
          {
            key: 'delete',
            label: intl.formatMessage({ id: 'general.delete' }),
            onClick: () => handleDelete(record.id),
          },
        ];

        return (
          <Dropdown menu={{ items }} trigger={['click']} key={record.id}>
            <Button
              label={intl.formatMessage({ id: 'general.actions' })}
              disabled={record.deleted_at != null ?? false}
            />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Form form={form}>
      <StyledBusinessForm>
        <Button
          label={intl.formatMessage({ id: 'general.add_new_contact_user' })}
          type={'primary'}
          className={'new-btn'}
          onClick={handleOpenEditCreateDrawer}
        />
        <Table
          url={'/api/v2/users/farms'}
          columns={columns}
          reload={reloadCount}
        />
        <CreateEditUser
          open={isOpenEditCreateDrawer}
          isEdit={isEdit}
          setReloadCount={setReloadCount}
          onClose={handleCloseDrawer}
        />
      </StyledBusinessForm>
    </Form>
  );
};
