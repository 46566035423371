// #384654
// 928D01 second button

export const token = {
  productCount: '#696969',
  brand01: '#384654',
  black: '#2A1A1A',
  softShadowBlack: '#00000040',
  halfBlack: 'rgba(0, 0, 0, 0.4)',
  brand02: '#928D01',
  discountTagBg: '#C9DA00',
  white: '#ffffff',
  whiteSmoke: '#F5F5F5',
  mistBlue: '#cdd6da',
  almostWhite: '#FCFCFC',
  black2: '#000000',
  bgColorHover: '#384654',
  topPriceBgColor: '#D6353B',
  grey: '#838383',
  deepSlate: '#384656',
  lightGrey: '#E9E9E9',
  silverGray: '#E0E0E0',
  mediumGray: '#D9D9D9',
  cloudGray: '#F1F4F7',
  neutralGray: '#8D8D8D',
  softSilverGray: '#A9ABAD',
  lightGrayishBlue: '#D1D1D8',
  softSlateBlue: '#38465433',
  containerBgColor: '#F8F8F8',
  borderBottom: '#E1E1E1',
  lightBorder: '#E8E8E8',
  lightGrayBorder: '#EEEEEE',
  darkRed: '#9D0909',
  red: '#D6353B',
  removeProductRed: '#E14B4B',
  brightGrey: '#F4F4F4',
  mediumGrey: '#CBCBCB',
  softShadowGray: '#3846540D',
  stepArrowColor: '#292D32',
  menuBorderBottom: '#CCCCCC',
  backgroundOverlay: '#38465480',
  goldenYellow: '#DAC400',
  filterSelectBg: 'rgba(34, 197, 94, 1)',
  inputGrey: 'rgba(244, 244, 244);',
  veryLightGray: '#f2f2f2',
  cartWarningBackground: `rgba(131, 148, 161, 1)`,
  greyBackground: `rgba(245, 245, 245, 1)`,
  backgroundColorFarmButton: '#6D757D',
  mainBgColor: `rgba(255, 255, 255, 1)`,
  lightborder: `rgba(245, 245, 245, 1)`,
  summaryBackground: `rgba(248, 248, 248, 1)`,
  boxShadowColor: `#00000026`,
  inputBitDarkerOverlay: `rgba(56, 70, 84, 0.05)`,
  paginationLink: '#475569',
  radioCheckGreen: ` #22c55e`,
  counterBrown: `#3C3C3C`,
  headerBottom: '#E5E7EB',

  checkboxBorderColor: '#B0BABF',
  activeCheckboxBackground: '#22C55E',
  activeCheckboxBorder: '#16A34A',

  contentWidth: '1440px',
  mainFooterFontSize: '14px',

  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2);',

  profileBoxShadow: '0 2px 8px rgba(0, 0, 0, 0.1);',
  profileFontFamily: 'Arial, sans-serif;',
  profileFontFamilySecondary: 'Albert Sans',
  profileBlack: '#333',
  profileName: '#666',
  profileColor: '#17183B',
  profileLineColor: '#d1d1d8',
  profileAddCompany: '#384654',
  profileMenuLine: '#eaeaea',
  profileTitle: '#384654',
  profileCount: '#696969',
  profileSubTitle: '#12141D',

  adressBlue: '#384654',
  adressGray: '#606b76',
  adressBoxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',

  mainHeaderHeight: '81px',
  secondHeaderHeight: '77px',

  mainFooterHeight: '140px',

  //icons
  iconWidthSM: '14px',
  iconHeightSM: '14px',
  iconWidthLG: '70px',
  iconWidthMED: '24px',
  iconHeightMED: '24px',
  iconWidthCard: '33px',
  iconHeightCard: '33px',
  iconHeightFilter: '79px',
  iconWidthFilter: '79px',
  iconHeightAvarage: '19px',
  iconWidthAvarage: '19px',
  iconWidthHeader: '92px',
  iconHeihtHeader: '60px',
  iconWidthHeaderMiddle: '60px',
  iconHeihtHeaderMiddle: '60px',
  iconWidthAny: '100%',
  iconHeihtAny: '100%',
  iconWidthMobileFooter: '414px',
  iconHeihtMobileFooter: '41px',
  iconHeihtFooter: '85px',
  iconWidthFooter: '880px',
  iconHeihtCartDrawerClose: '40px',
  iconWidthCartDrawerClose: '40px',

  // SeedToken
  borderRadius: 30,
  colorPrimary: '#928D01',
  fontSize: 16,
  darkGrayishBlue: '#4C4F54',
  grayishBlue: '#94A3B8',
  vividBlue: '#2563EB',
  dustyBlue: '#64748B',

  // MapToken
  colorBgBlur: 'transparent',
  colorBgContainer: 'transparent',
  colorBorder: '#DADADA',
  colorBorderSecondary: '#f0f0f0',
  colorText: '#2A1A1A',
  colorSplit: 'rgba(5, 5, 5, 0.06)',
  controlOutline: 'rgba(5, 145, 255, 0.1)',
  fontText: "'Albert Sans', sans-serif",
};

export const adminToken = {
  brand01: '#384654',
  brand02: '#928D01',
  white: '#ffffff',
  blue: 'blue',
  fontText: "'Albert Sans', sans-serif",
  black: '#000000',
  contentWidth: '1437px',
  border: '#DADADA',
  filterBackground: '#F5F6F7',
  siderColor: '#C4C7CB',
  stepInactiveColor: '#888E98',
  stepArrowColor: '#292D32',
  actionCollor: '#93a2dd',
  modalHorizontalLine: '#ebedf2',
  checked: '#0abb87',
  minus: '#fd397a',
  underTextColor: '#7B7B7B',
  red: 'red',
  borderTable: '#E6E6E6',

  inactiveColor: '#7C7C7C',
  tableSecondRowColor: '#F9FAFB',
  borderCheckbox: '#D5DAE1',
  activeCheckboxBackground: '#22C55E',
  activeCheckboxBorder: '#16A34A',
  disableCheckboxBackground: '#D5DAE1',
  disableCheckboxBorder: '#8896AB',
  inputAddonBg: '#EDEDED',
  tagCloseColor: '#292D32',
  backgroundInput: '#F7F7F7',
  borderInput: '#D5D5D5',

  contractTextColor: '#646c9a',
  contractTextTitleColor: '#48465b',
  outlineColor: '#C4C4C4',
  blueColor: '#5867dd',
  silverGray: '#E0E0E0',

  categoryBorder: '#ccc',
  categoryVatBackground: 'rgb(145, 154, 173)',
  categoryContentBackground: '#fafafa',
  categoryHandlerBackground: '#d6d6d6',
  categoryActionBackground: '#5d78ff',

  addProductUnderline: 'rgba(196, 196, 196, 1)',

  // SeedToken
  borderRadius: 0,
  colorPrimary: '#4C4F54',
  fontSize: 16,
  fontFamily: 'Roboto',
  colorLink: '#4C4F54',

  // MapToken
  colorBorder: '#d9d9d9',
  colorBorderSecondary: '#f0f0f0',
  colorText: '#4C4F54',
  colorSplit: 'rgba(5, 5, 5, 0.06)',
  controlOutline: 'rgba(5, 145, 255, 0.1)',

  colorPadding: '#6B7280',
  colorButtonText: '#065F46',
  colorStatusChangeLine: '#d7d7d7',

  colorPaginationActiveBorder: '#E5E7EB',
  userCompanyBoxShadow: '#0000001a',
};
