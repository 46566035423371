import { StyledBusinessForm } from '../style';
import { useIntl } from 'react-intl';
import { Checkbox, Divider, Input, TextArea } from '../../../../ui';
import { useState } from 'react';
import useQueryApiClient from '../../../../utils/useQueryApiClient';
import { FarmType } from '../../../../interfaces/BackendModels';

interface Options {
  label: string;
  value: number;
}
export const BaseInformation = () => {
  const [options, setOptions] = useState<Options[]>([]);
  const intl = useIntl();

  const { data } = useQueryApiClient({
    request: {
      url: '/api/v2/farm-types',
    },
    onSuccess: (response) => handleOptionsParse(response),
  });

  const handleOptionsParse = (options: FarmType[]) => {
    const parsedOptions: Options[] = options
      .map((option: FarmType) => ({
        label: option.name ?? '',
        value: option.id ?? 0,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setOptions(parsedOptions);
  };

  return (
    <StyledBusinessForm>
      <div className={'content'}>
        <div className={'section'}>
          <div className={'title'}>
            <h3>{intl.formatMessage({ id: 'navigation.base_information' })}</h3>
          </div>
          <Input
            label={intl.formatMessage({ id: 'company.name' })}
            layout={'vertical'}
            name={'name'}
            className={'label-fix'}
            disabled
          />

          <div className={'double-input'}>
            <Input
              label={intl.formatMessage({ id: 'company.reg_nr' })}
              layout={'vertical'}
              name={'reg_nr'}
              className={'label-fix'}
              disabled
              validations={'minZeroNumber'}
            />

            <Input
              label={intl.formatMessage({ id: 'company.registered_address' })}
              layout={'vertical'}
              name={'registered_address'}
              className={'label-fix'}
              disabled
            />
          </div>

          <div className={'double-checkbox'}>
            <Checkbox
              name={'public'}
              label={intl.formatMessage({ id: 'company.public' })}
            />

            <Checkbox
              name={'registered_address_is_actual_address'}
              label={intl.formatMessage({
                id: 'company.reg_address_is_actual_address',
              })}
            />
          </div>

          <div className={'double-input'}>
            <Input
              label={intl.formatMessage({ id: 'company.pvn_number' })}
              layout={'vertical'}
              name={'pvn_nr'}
              className={'label-fix'}
              disabled
              validations={'minZeroNumber'}
            />

            <Input
              label={intl.formatMessage({ id: 'company.pvd_number' })}
              layout={'vertical'}
              name={'pvd_nr'}
              disabled
              className={'label-fix'}
              validations={'minZeroNumber'}
            />
          </div>
        </div>
        <Divider />
        <div className={'section'}>
          <div className={'title'}>
            <h3>
              {intl.formatMessage({ id: 'general.additional_information' })}
            </h3>
          </div>
          <TextArea
            label={intl.formatMessage({
              id: 'general.short_company_description',
            })}
            className={'text-area'}
            maxLength={250}
            showCount
            name={'short_description'}
          />
          <TextArea
            label={intl.formatMessage({
              id: 'general.company_description',
            })}
            className={'text-area'}
            name={'description'}
          />

          <div className={'double-input'}>
            <Input
              label={intl.formatMessage({ id: 'general.phone' })}
              className={'label-fix'}
              validations={'phoneNumber'}
              name={'contact_phone'}
            />
            <Input
              label={intl.formatMessage({ id: 'general.email' })}
              className={'label-fix'}
              validations={'email'}
              name={'contact_email'}
            />
          </div>
          <div className={'double-input'}>
            <Input
              label={intl.formatMessage({ id: 'general.website' })}
              className={'label-fix'}
              name={'website'}
            />
          </div>
        </div>
        <Divider />
        <div className={'section'}>
          <div className={'title'}>
            <h3>{intl.formatMessage({ id: 'general.company_type' })}</h3>
          </div>
          <div className={'double-column-checkbox'}>
            <Checkbox options={options} name={'types'} />
          </div>
        </div>
      </div>
    </StyledBusinessForm>
  );
};
