import styled from 'styled-components';

export const StyledFarmContractsPage = styled('div')`
  .flex-center {
    display: flex;
    justify-content: center;
  }

  .actions {
    min-width: 210px;
    display: flex;
    gap: 15px;
    border: none !important;
    justify-content: right;
    .ant-btn {
      .text {
        color: ${(props) => props.theme.white} !important;
        border: none;
      }
    }
  }

  .ant-table-content {
    min-height: 900px;
  }

  .ant-form-item-control-input-content {
    max-width: 150px;
  }

  .ant-form-item {
    margin: 0;
  }
`;
