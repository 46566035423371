enum OrderStatus {
    CREATED = 'created',
    PROCESSING = 'processing',
    NEW = 'new',
    CANCELED = 'canceled'
}

enum ActionsKeys {
    BUYER_ORDER = 'buyer_order',
    CONFIRM = '1',
    CANCEL = '2'
}

enum OrderPaymentStatus {
    CONFIRM = 1,
    CANCELED = 2,
    FAILED = 3
}

export { OrderStatus, ActionsKeys, OrderPaymentStatus };