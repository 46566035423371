import React, { useMemo } from 'react';
import { Layout } from 'antd';
import { Icon, Label } from '../../../../ui';
import { useIntl } from 'react-intl';
import { StyledBlock, StyledSiderHeader, CustomMenu } from './style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import { routes } from '../../../../config/config';
import { HomeOutlined } from '@ant-design/icons';

const { Sider: AntdSider } = Layout;
const Sider = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuth();

  const isAdmin = useMemo(() => {
    return user?.isAdmin;
  }, [user?.isAdmin]);

  const menuItems = [
    {
      label: intl.formatMessage({ id: 'navigation.beginning' }),
      url: routes.admin.beginning,
    },
    {
      label: intl.formatMessage({ id: 'navigation.company_info' }),
      url: '/admin/business',
    },
    {
      label: intl.formatMessage({ id: 'navigation.transactions' }),
      url: routes.admin.transactions,
    },
    {
      label: intl.formatMessage({ id: 'navigation.orders' }),
      url: routes.admin.orders,
    },
    {
      label: intl.formatMessage({ id: 'navigation.farm_users' }),
      url: routes.admin.farmUsers,
    },
    {
      label: intl.formatMessage({ id: 'navigation.contracts' }),
      url: routes.admin.farmContracts,
    },
    {
      label: intl.formatMessage({ id: 'navigation.deliveries' }),
      url: '/admin/farm/deliveries',
    },
    {
      label: intl.formatMessage({ id: 'navigation.products' }),
      url: routes.admin.products,
    },
    {
      label: intl.formatMessage({ id: 'navigation.partners' }),
      url: '/admin/partners',
    },
  ];

  const menuItems2 = [
    {
      label: intl.formatMessage({ id: 'navigation.e_shop_email' }),
      url: routes.admin.eShopEmail,
    },
    {
      label: intl.formatMessage({ id: 'navigation.farm_email' }),
      url: routes.admin.farmEmail,
    },
    {
      label: intl.formatMessage({ id: 'navigation.qmark' }),
      url: routes.admin.qmark,
    },
    {
      label: intl.formatMessage({ id: 'navigation.users' }),
      url: routes.admin.users,
    },
    {
      label: intl.formatMessage({ id: 'navigation.farms' }),
      url: routes.admin.farms,
    },
    {
      label: intl.formatMessage({ id: 'navigation.contracts' }),
      url: routes.admin.contracts,
    },
    {
      label: intl.formatMessage({ id: 'navigation.roles' }),
      url: routes.admin.roles,
    },
    {
      label: intl.formatMessage({ id: 'navigation.banners' }),
      url: routes.admin.banners,
    },
    {
      label: intl.formatMessage({ id: 'navigation.top_categories' }),
      url: routes.admin.topCategories,
    },
    {
      label: intl.formatMessage({ id: 'navigation.category' }),
      url: routes.admin.categories,
    },
    {
      label: intl.formatMessage({ id: 'navigation.global_settings' }),
      url: routes.admin.globalSettings,
    },
    {
      label: intl.formatMessage({ id: 'navigation.catalog_settings' }),
      url: routes.admin.catalogSettings,
    },
    {
      label: intl.formatMessage({ id: 'navigation.emarket_settings' }),
      url: routes.admin.emarketSettings,
    },
  ];
  return (
    <AntdSider width={346}>
      <StyledSiderHeader>
        <img src="/logo_ng.png" width={69} height={66} alt="logo" />
        <Label
          color="white"
          label={intl.formatMessage({ id: 'general.project_name' })}
        />
      </StyledSiderHeader>
      <StyledBlock>
        <div className="role">{user?.farmName}</div>
        <CustomMenu>
          {menuItems.map((e, index) => {
            const splitUrl = e.url.split('/');
            const endpoint = splitUrl.pop();

            if (e.url === '/') {
              return (
                <a
                  href={e.url}
                  className={`custom-menu-item ${
                    pathname.includes(e.url) && e.url !== '/' ? 'active' : ''
                  }`}
                  key={index}
                >
                  <div className={'start-page-icon'}>
                    <HomeOutlined className={'home'} />
                  </div>
                  <Label label={e.label} />
                </a>
              );
            }
            return (
              <div
                className={`custom-menu-item ${
                  pathname.includes(e.url) && e.url !== '/' ? 'active' : ''
                }`}
                onClick={() => navigate(e.url)}
                key={index}
              >
                <Icon
                  name={`admin/${endpoint}`}
                  className={`${
                    pathname.includes(e.url) && e.url !== '/' ? 'active' : ''
                  }`}
                />
                <Label label={e.label} />
              </div>
            );
          })}
        </CustomMenu>
      </StyledBlock>

      {isAdmin && (
        <StyledBlock>
          <CustomMenu>
            {menuItems2.map((e, index) => (
              <div
                className={`custom-menu-item ${
                  pathname.includes(e.url) ? 'active' : ''
                }`}
                onClick={() => navigate(e.url)}
                key={index + 100}
              >
                <Icon name={'admin/default-admin-icon'} />
                <Label label={e.label} />
              </div>
            ))}
          </CustomMenu>
        </StyledBlock>
      )}
    </AntdSider>
  );
};

export default Sider;
