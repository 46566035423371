import { StyledLoginPage } from './style';
import { LoginForm } from '../../../ui/Form/auth/LoginForm';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useEffect } from 'react';
import { Spinner } from '../../../ui';
import { ResetPasswordForm } from 'ui/Form/auth/ResetPasswordForm';
import useQueryApiClient from 'utils/useQueryApiClient';
import { notification } from 'antd';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { appendData } = useQueryApiClient({
    request: {
      url: `api/v2/forgot-password`,
      method: 'POST',
    },
    onSuccess: (response) => {
      openNotification(response.message);
      setTimeout(() => {
        navigate('/');
      }, 5000);
    },
    onError: (error) => {
      openNotification(error.message);
    },
  });

  const openNotification = (message: string) => {
    notificationApi['info']({
      message: message,
      showProgress: true,
      pauseOnHover: true,
      duration: 5,
    });
  };

  return (
    <StyledLoginPage>
      {contextHolder}
      <div className={'content'}>
        <ResetPasswordForm appendData={appendData} />
      </div>
    </StyledLoginPage>
  );
};
