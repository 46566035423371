import React, { useMemo, useState } from 'react';
import { FarmData } from '../../../types/FarmData';
import { useIntl } from 'react-intl';
import { StyledFarmView } from './style';
import { Menu, Spin } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { Button, Icon, Spinner } from 'ui';
import PopularProducts from 'public/components/DefaultLayout/PopularProducts';
import DOMPurify from 'dompurify';
import FarmCardTabs from 'enums/farm-card-tabs';
import Galeries from './components/Galeries';
import Partners from './components/Partners';
import Deliveries from './components/Deliveries';
import { useBannerState } from 'public/context';
import Banner from 'public/components/DefaultLayout/Banner';
import SearchAndFilter from 'public/components/DefaultLayout/SearchAndFilter';
import { useNavigate } from 'react-router-dom';
import { FallbackImage } from '../../../ui/UploadImage/fallBack/FallBackImage';

export interface FarmViewProps {
  farm?: FarmData;
}

const FarmView = ({ farm }: FarmViewProps) => {
  const intl = useIntl();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(
    FarmCardTabs.DESCRIPTION
  );
  const navigate = useNavigate();
  const workingTimeData = farm?.working_time;
  const website = farm?.website ? farm?.website.split('/')[2] : '';

  const destination = farm?.maps_api
    ? farm?.maps_api
    : farm?.actual_full_address;
  const googleUrl =
    'https://www.google.com/maps/dir/?api=1&destination=' +
    encodeURIComponent(destination || '');

  const wazeUrl = farm?.maps_api
    ? `https://waze.com/ul?ll=${encodeURIComponent(destination || '')}&z=10`
    : 'https://waze.com/ul?navigate=yes&q=' +
      encodeURIComponent(destination || '');

  const daysOfWeek = [
    intl.formatMessage({ id: 'open_hours.Monday' }),
    intl.formatMessage({ id: 'open_hours.Tuesday' }),
    intl.formatMessage({ id: 'open_hours.Wednesday' }),
    intl.formatMessage({ id: 'open_hours.Thursday' }),
    intl.formatMessage({ id: 'open_hours.Friday' }),
    intl.formatMessage({ id: 'open_hours.Saturday' }),
    intl.formatMessage({ id: 'open_hours.Sunday' }),
  ];
  const { banners } = useBannerState();

  const products = useMemo(() => {
    return farm?.products
      ? farm?.products.map((product) => ({
          ...product,
          farm_name: farm?.name,
        }))
      : [];
  }, [farm?.products, farm?.name]);

  const address = farm?.actual_full_address
    ? farm?.actual_full_address.split(',')
    : [];

  const renderContent = (menuItem: string) => {
    switch (menuItem) {
      case FarmCardTabs.DESCRIPTION:
        return (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(farm?.description || ''),
            }}
          ></div>
        );
      case FarmCardTabs.WORKING_TIME:
        return (
          <div className="farm-delivery">
            <Deliveries
              deliveries={
                farm?.deliveries &&
                farm?.deliveries?.map((delivaery) => ({
                  ...delivaery,
                  open_hours: workingTimeData,
                }))
              }
              daysOfWeek={daysOfWeek}
            />
          </div>
        );
      case FarmCardTabs.DELIVERY:
        return (
          <div className="farm-delivery">
            <Deliveries deliveries={farm?.deliveries} daysOfWeek={daysOfWeek} />
          </div>
        );
      case FarmCardTabs.GALLERY:
        return <Galeries farm={farm} />;
      case FarmCardTabs.PARTNERS:
        return <Partners farm={farm} />;
      case FarmCardTabs.MEMBERS:
        return <>{intl.formatMessage({ id: 'farms.members' })}</>;

      default:
        return null;
    }
  };

  const items = [
    {
      key: FarmCardTabs.DESCRIPTION,
      label: intl.formatMessage({ id: 'farms.description' }),
    },
    {
      key: FarmCardTabs.WORKING_TIME,
      label: intl.formatMessage({ id: 'farms.working_time' }),
    },
    {
      key: FarmCardTabs.DELIVERY,
      label: intl.formatMessage({ id: 'farms.delivery' }),
    },
    {
      key: FarmCardTabs.GALLERY,
      label: intl.formatMessage({ id: 'farms.gallery' }),
    },
    {
      key: FarmCardTabs.PARTNERS,
      label: intl.formatMessage({ id: 'farms.partners' }),
    },
    {
      key: FarmCardTabs.MEMBERS,
      label: (
        <div className={'farm-members'}>
          <span>{intl.formatMessage({ id: 'farms.members' })}</span>
          <img alt={'quality-mark-img'} src={'../qualityMark/3-09 1.jpg'} />
        </div>
      ),
    },
  ];

  const filterItems = useMemo(() => {
    const conditions: any = {
      [FarmCardTabs.DESCRIPTION]: !!farm?.description,
      [FarmCardTabs.WORKING_TIME]: !!workingTimeData,
      [FarmCardTabs.GALLERY]: !!farm?.images?.length,
      [FarmCardTabs.DELIVERY]: !!farm?.deliveries?.length,
      [FarmCardTabs.PARTNERS]: !!farm?.partner_farms?.length,
    };

    return items.filter((item) => conditions[item.key] ?? true);
  }, [items, workingTimeData, farm]);

  const mobileRenderContent = (menuItem: string) => {
    switch (menuItem) {
      case FarmCardTabs.DESCRIPTION:
        return (
          <div className={'farm-info'}>
            <div className={'farm-img-box'}>
              <img alt={'farm-img'} src={'../rt6rp0d0e2o 1.png'} />
              <div className="logos">
                <img
                  alt={'logo'}
                  src={'../qualityMark/eu_organic_logo_colour_rgb.jpg'}
                />
                <img alt={'logo'} src={'../qualityMark/karote.jpg'} />
                <img
                  alt={'logo'}
                  src={'../qualityMark/global-gap-certification-500x500.jpg'}
                />
              </div>
            </div>
            <h1>{farm?.name}</h1>
            <div className={'mobile-farm-info'}>
              <div className={'mobile-info-div'}>
                <div className={'info-column'}>
                  <Icon name="user-square" />
                  {farm?.contact_name ||
                    intl.formatMessage({ id: 'farms.contact_name' })}
                </div>
                <div className={'info-column'}>
                  <Icon name="sms-tracking" />
                  {farm?.contact_email ||
                    intl.formatMessage({ id: 'farms.email' })}
                </div>
                <div className={'info-column'}>
                  <Icon name="call-calling" />
                  {farm?.contact_phone ||
                    intl.formatMessage({ id: 'farms.contact_phone' })}
                </div>
                <div className={'info-column'}>
                  <Icon name="export" />
                  <a
                    href={farm?.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {farm?.website || intl.formatMessage({ id: 'farms.email' })}
                  </a>
                </div>
                <div className={'info-column'}>
                  <Icon name="location-tick-icon" />
                  {farm?.actual_address ||
                    'Krasta iela 7, Līksna, Līksnas pag., Augšdaugavas nov., LV-5456'}
                </div>
                <div className={'bookmark'}>
                  <div className={'bookmark-2'}>
                    <Icon name="bookmark-2" />
                    {intl.formatMessage({
                      id: 'farms.reg_nr_short',
                    })}
                    {farm?.reg_nr} <br />
                    {intl.formatMessage({
                      id: 'farms.pvd_nr',
                    })}
                    {farm?.pvd_nr}
                  </div>
                  <div className={'mobile-buttons'}>
                    <a href={googleUrl} target={'_blank'} rel="noreferrer">
                      <Button
                        label={intl.formatMessage({ id: 'farms.google' })}
                      />
                    </a>
                    <a href={wazeUrl} target={'_blank'} rel="noreferrer">
                      <Button
                        label={intl.formatMessage({ id: 'farms.waze' })}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mobile-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(farm?.description || ''),
              }}
            ></div>
          </div>
        );
      case FarmCardTabs.PRODUCTS:
        return (
          <>
            {!!products?.length && (
              <div className="other-products-container">
                <div className="container">
                  <p className="product-description ">
                    {intl.formatMessage({ id: 'farm_view.farm_produce_text' })}
                  </p>
                </div>

                <PopularProducts
                  title={`${farm?.name} produkcija`}
                  products={products}
                  farm_name={farm?.name}
                />
              </div>
            )}
          </>
        );
      case FarmCardTabs.WORKING_TIME:
        return (
          <div className="farm-delivery">
            <Deliveries
              deliveries={
                farm?.deliveries &&
                farm?.deliveries?.map((delivaery) => ({
                  ...delivaery,
                  open_hours: workingTimeData,
                }))
              }
              daysOfWeek={daysOfWeek}
            />
          </div>
        );
      case FarmCardTabs.DELIVERY:
        return (
          <div className="farm-delivery">
            <Deliveries deliveries={farm?.deliveries} daysOfWeek={daysOfWeek} />
          </div>
        );
      case FarmCardTabs.GALLERY:
        return <Galeries farm={farm} />;
      case FarmCardTabs.PARTNERS:
        return <Partners farm={farm} />;
      case FarmCardTabs.MEMBERS:
        return <>{intl.formatMessage({ id: 'farms.members' })}</>;
    }
  };

  const MobileItems = [
    {
      key: FarmCardTabs.DESCRIPTION,
      label: intl.formatMessage({ id: 'farms.description' }),
    },
    {
      key: FarmCardTabs.PRODUCTS,
      label: intl.formatMessage({ id: 'farms.products' }),
      className: 'menu-products',
    },
    {
      key: FarmCardTabs.WORKING_TIME,
      label: intl.formatMessage({ id: 'farms.working_time' }),
    },
    {
      key: FarmCardTabs.DELIVERY,
      label: intl.formatMessage({ id: 'farms.delivery' }),
    },
    {
      key: FarmCardTabs.GALLERY,
      label: intl.formatMessage({ id: 'farms.gallery' }),
    },
    {
      key: FarmCardTabs.PARTNERS,
      label: intl.formatMessage({ id: 'farms.partners' }),
    },
    {
      key: FarmCardTabs.MEMBERS,
      label: intl.formatMessage({ id: 'farms.members' }),
    },
  ];

  const handleMenuClick = (e: MenuInfo) => {
    setSelectedMenuItem(e.key);
  };

  const secondBanner = banners.data.find(
    (banner) => banner.type === 'banner_4'
  );

  if (!farm) {
    return (
      <Spinner spinning={true} wrapperClassName="spinner">
        <Spin />
      </Spinner>
    );
  }

  return (
    <StyledFarmView>
      <SearchAndFilter rootClassName="laptop-search-section-box" />
      <div className={'mobile-menu-container'}>
        <div className="mobile-back">
          <span onClick={() => navigate(-1)}>
            <Icon name="arrow-left" className="back-icon" />
          </span>
          <span className="back-text">
            {intl.formatMessage({
              id: 'general.back_to_catalog',
            })}
          </span>
        </div>
        <ul className="custom-menu">
          {MobileItems.map((item) => (
            <li
              key={item.key}
              className={`custom-menu-item ${
                selectedMenuItem === item.key ? 'active' : ''
              }`}
              onClick={() => setSelectedMenuItem(item.key)}
            >
              {item.label}
            </li>
          ))}
        </ul>
        <div className={'content-section'}>
          {mobileRenderContent(selectedMenuItem)}
        </div>
      </div>
      <div className="container desktop-content">
        <div className={'farm-info'}>
          <div className={'farm-img-box'}>
            <FallbackImage src={farm.logo} type={'FarmCard'} />
            {/*<div className={'bottom-img'}>*/}
            {/*  <img alt={'quality-mark-img'} src={'../qualityMark/3-09 1.jpg'} />*/}
            {/*</div>*/}
          </div>
          <div className={'farm-contact'}>
            <div className={'farm-title'}>
              <h1>{farm.name}</h1>
              <div className="logos">
                {farm.quality_marks &&
                  farm.quality_marks?.map((qualityMark) => (
                    <img
                      alt={qualityMark.name}
                      src={'../qualityMark/eu_organic_logo_colour_rgb.jpg'}
                    />
                  ))}
              </div>
              <div className={'mobile-website-box'}>
                <div className={'mobile-website'}>
                  <a
                    href={farm.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {website}
                  </a>
                  <div>
                    <a href={googleUrl} target={'_blank'} rel="noreferrer">
                      <Button
                        label={intl.formatMessage({ id: 'farms.google' })}
                      />
                    </a>
                    <a href={wazeUrl} target={'_blank'} rel="noreferrer">
                      <Button
                        label={intl.formatMessage({ id: 'farms.waze' })}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={'farm-datas'}>
              <ul>
                <li>
                  <Icon name="red-mail" />
                  {farm.contact_email ||
                    intl.formatMessage({ id: 'farms.email' })}
                </li>
                <li>
                  <Icon name="red-phone" />
                  {farm.contact_phone ||
                    intl.formatMessage({ id: 'farms.contact_phone' })}
                </li>
              </ul>

              <ul>
                {address.map((item, index) => (
                  <li key={index}>
                    <span>
                      {item} {index === address.length - 1 ? '' : ','}
                    </span>
                  </li>
                ))}
              </ul>

              <ul>
                <li>
                  {intl.formatMessage({
                    id: 'farms.reg_nr_short',
                  })}
                  {farm.reg_nr}
                </li>
                <li>
                  {intl.formatMessage({
                    id: 'farms.pvd_nr',
                  })}
                  {farm.pvd_nr}
                </li>
              </ul>

              <ul>
                <li>
                  <a href={farm.website}>{website || 'www.example.org'}</a>
                </li>
                <li>
                  <a href={googleUrl} target={'_blank'} rel="noreferrer">
                    <Button
                      label={intl.formatMessage({ id: 'farms.google' })}
                    />
                  </a>
                </li>
                <li>
                  <a href={wazeUrl} target={'_blank'} rel="noreferrer">
                    <Button label={intl.formatMessage({ id: 'farms.waze' })} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={'mobile-farm-datas-div'}>
        <div className={'mobile-farm-datas'}>
          <ul>
            <li>
              <Icon name="red-mail" />
              {farm.contact_email}
            </li>
            <li>
              <Icon name="red-phone" />
              {farm.contact_phone}
            </li>
          </ul>

          <ul>
            {address.map((item, index) => (
              <li key={index}>
                <span>
                  {item} {index === address.length - 1 ? '' : ','}
                </span>
              </li>
            ))}
          </ul>

          <ul>
            <li>
              {intl.formatMessage({
                id: 'farms.reg_nr_short',
              })}
              {farm.reg_nr}
            </li>
            <li>
              {intl.formatMessage({
                id: 'farms.pvd_nr',
              })}
              {farm.pvd_nr}
            </li>
          </ul>

          <ul>
            <li>
              <a href={farm.website}>{website}</a>
            </li>
            <li>
              <a href={googleUrl} target={'_blank'} rel="noreferrer">
                <Button label={intl.formatMessage({ id: 'farms.google' })} />
              </a>
            </li>
            <li>
              <a href={wazeUrl} target={'_blank'} rel="noreferrer">
                <Button label={intl.formatMessage({ id: 'farms.waze' })} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={'menu-container'}>
        <div className="container">
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[selectedMenuItem]}
            items={filterItems}
            onClick={handleMenuClick}
            style={{ flex: 1, minWidth: 0 }}
            rootClassName="farm-menu-root"
          />
        </div>
        <hr className="menu-line" />
        <div className="container">
          <div className={'content-section'}>
            {renderContent(selectedMenuItem)}
          </div>
        </div>
      </div>
      {!!products?.length && (
        <div className="desktop-products-container">
          <PopularProducts
            title={`${farm?.name} ${intl.formatMessage({
              id: 'farms.product',
            })}`}
            products={products}
            farm_name={farm?.name}
          />
        </div>
      )}

      <div className="banners">
        <div className="container">
          {secondBanner && <Banner banners={[secondBanner]} />}
        </div>
      </div>
    </StyledFarmView>
  );
};

export default FarmView;
