import { Space } from 'antd';
import {
  FarmProduct,
  ParcelLocation,
  SelectedDelivery,
} from 'public/context/CartContext';
import React from 'react';
import { useIntl } from 'react-intl';
import { FarmDeliveries } from 'types/FarmData';
import {
  Icon,
  Select,
  SelectOption,
  SelectOptionsValueStringNumber,
  Spinner,
} from 'ui';
import useQueryApiClient from 'utils/useQueryApiClient';
import { StyledDelivery } from '../style';
import { FormInstance } from 'antd';

interface IzipiziDeliveryProps {
  farmProduct: FarmProduct;
  selectedDelivery: FarmDeliveries;
  setSelectedDeliveryData: React.Dispatch<
    React.SetStateAction<SelectedDelivery[] | null>
  >;
  selectedDeliveryData: SelectedDelivery[] | null;
  deliveryForm: FormInstance<any>;
}

const IzipiziDelivery = ({
  selectedDelivery,
  setSelectedDeliveryData,
  farmProduct,
  selectedDeliveryData,
  deliveryForm,
}: IzipiziDeliveryProps) => {
  const intl = useIntl();

  const { isLoading: parishLoading, data: parcelData } = useQueryApiClient({
    request: { url: 'api/v2/delivery/izipiziPickupGetLocations' },
  });

  const handleParcelChange = (parcelName: string) => {
    const selected = parcelData?.find(
      (location: ParcelLocation) => location.name === parcelName
    );

    if (selected) {
      setSelectedDeliveryData([
        ...(selectedDeliveryData || []),
        {
          id: selected.id,
          selectedDeliveryId: selectedDelivery.id,
          city: undefined,
          address: selected.address,
          firm_name: selectedDelivery?.type,
          name: selected.name,
          price: selectedDelivery.price,
        },
      ]);
    }
  };

  const optionsParcels: SelectOptionsValueStringNumber[] = parcelData?.map(
    (location: ParcelLocation) => ({
      label: location.name,
      value: location.name,
    })
  );

  return (
    <StyledDelivery>
      <Spinner spinning={parishLoading}>
        <Space direction="vertical">
          {parcelData && (
            <Select
              suffixIcon={<Icon name={'arrow-down-select-black'} />}
              validations="required"
              name={`izipiziLocation.${farmProduct.farm_id}`}
              size="middle"
              onChange={handleParcelChange}
              initialValue={
                farmProduct.selectedDelivery?.firm_name === 'izipizi'
                  ? farmProduct.selectedDelivery?.name
                  : undefined
              }
              children={
                optionsParcels &&
                optionsParcels.map((options, index) => (
                  <SelectOption value={options.value} key={`${index} - opt`}>
                    {options.label}
                  </SelectOption>
                ))
              }
            />
          )}
        </Space>
      </Spinner>
    </StyledDelivery>
  );
};

export default IzipiziDelivery;
