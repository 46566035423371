import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { StyledConfirmationPageWrapper } from './style';

export const ConfirmationPage = () => {
  const intl = useIntl();
  let [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  return (
    <StyledConfirmationPageWrapper>
      <div className={'content'}>
        <div className="content_inner">
          <div className="box">
            <h2 className={'title'}>
              {intl.formatMessage({ id: 'confirmation.title' })}
            </h2>
            <p className="text">
              {intl.formatMessage({ id: 'confirmation.text' })}
            </p>
            <b className="email">{email}</b>
            <img className="img" src="confirmation-email.png" alt="email" />
          </div>
        </div>
      </div>
    </StyledConfirmationPageWrapper>
  );
};
