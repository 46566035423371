import styled from 'styled-components';

export const StyledBanner = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
`;

export const StyledBannerSlider = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`;

export const StyledBannerWrapper = styled.div`
  position: relative;
  .banner_1-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    .container {
      padding-top: 162px;
      .banner_1-text-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;
        color: ${(props) => props.theme.white};
        h1 {
          max-width: 393px;
          font-family: 'Poppins', sans-serif;
          font-size: 50px;
          line-height: 60px;
          margin: 0;
        }
        .banner_1-product-count {
          max-width: 182px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          span {
            font-family: 'Poppins', sans-serif;
            font-size: 38px;
            font-weight: 500;
            line-height: 26px;
            text-align: right;
          }
          p {
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: right;
            margin: 0;
          }
        }
      }
    }
  }
  .banner_1 {
    @media only screen and (max-width: 440px) {
      display: none;
    }

    .banner-image-wrapper {
      padding: 0;
    }
  }

  .banner-wrapper:not(.banner_1) {
    padding: 0 20px;
  }

  .desktop-banner {
    @media only screen and (max-width: 440px) {
      display: none;
    }
  }

  .mobile-banner {
    @media only screen and (min-width: 440px) {
      display: none;
    }
  }
`;
