import styled from 'styled-components';

export const StyledPage = styled.div`
  border-radius: 8px;
  min-height: 100vh;
  margin: 0 auto;

  .order-status-field {
    min-width: 305px;
  }

  .flex-end {
    display: flex;
    flex-grow: 1;
    justify-content: end;
  }

  .actions .text {
    color: ${({ theme }) => theme.colorButtonText};
  }

  .actions {
    width: 130px;
    border: 1px solid ${({ theme }) => theme.colorText};
    border-radius: 3px;
  }

  .actions .anticon-down {
    color: ${({ theme }) => theme.black};
  }

  .status-actions {
    width: 130px;
    border: solid 1px ${({ theme }) => theme.colorPrimary};
    border-radius: 3px;
    top: 38px !important;
  }

  .status-actions li {
    border-bottom: 1px solid ${({ theme }) => theme.colorStatusChangeLine};
  }

  .status-actions li:last-child {
    border-bottom: none;
  }

  .status-actions .ant-dropdown-menu {
    padding: 0px;
  }

  .ant-dropdown-menu-title-content {
    text-wrap: wrap;
    text-align: left;
  }
`;
