import React from 'react';
import { MobileAuthStyledDrawer as MobileLoginStyledDrawer } from './style';

export interface MobileAuthDrawerProps {
  open?: boolean;
  closable?: boolean;
  destroyOnClose?: boolean;
  children?: React.ReactNode;
  onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  footer?: React.ReactNode;
  title?: string;
  className?: string;
}

export const MobileAuthDrawer = ({
  open,
  children,
  destroyOnClose,
  closable = true,
  onClose,
  footer,
  title,
  className,
}: MobileAuthDrawerProps) => {
  return (
    <MobileLoginStyledDrawer
      className={className}
      id={'mobile-menu-drawer'}
      open={open}
      width={'100%'}
      destroyOnClose={destroyOnClose}
      getContainer={document.getElementById('app-root')!}
      closable={closable}
      onClose={onClose}
      footer={footer}
      placement={'bottom'}
      title={title}
      height={'75%'}
      headerStyle={{ padding: 0 }}
      styles={{
        content: {
          marginTop: 0,
          borderRadius: 15,
          paddingBottom: 150,
          boxShadow: '0px 4px 84px #00000026',
          padding: '20px',
        },
        mask: { background: 'transparent' },
        wrapper: { boxShadow: 'none', paddingBottom: 90 },
        header: {
          paddingLeft: '0px',
          paddingRight: '0px',
          paddingTop: '0px',
          paddingBottom: '15px',
          marginBottom: '15px',
        },
        body: { padding: 0, height: '100%' },
      }}
    >
      {children}
    </MobileLoginStyledDrawer>
  );
};
