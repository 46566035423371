import {StyledTopCategorySelector} from './style'
import {CategoryData} from '../../../types/CategoryData'
import {Select, SelectOption} from '../Select'
import {useIntl} from 'react-intl'
import {Button, GetProp, message, Upload, UploadFile, UploadProps} from 'antd'
import {UploadOutlined} from '@ant-design/icons'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {ImageProps} from '../../../types/BannerData'
import {getBase64} from '../../../utils/productCardHelpers'
import {FallbackImage} from '../../UploadImage/fallBack/FallBackImage'
import {options} from "../../../config/config";
import {RcFile} from "antd/es/upload/interface";

export interface FormDataType {
  position: string
  file?: File | RcFile
  file_name?: string
}

interface CategorySelectorProps {
  categories: CategoryData[]
  name: string
  placeholder?: string
  label: string
  onCategoryChange: (position: number, categoryId: number) => void
  initialValue?: CategoryData
  image?: ImageProps,
  setFormData: React.Dispatch<React.SetStateAction<Record<any, FormDataType>>>
  clearFile?: boolean
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const TopCategorySelector = ({
  placeholder,
  name,
  categories,
  label,
  initialValue,
  image,
  onCategoryChange,
  setFormData,
  clearFile
}: CategorySelectorProps) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const intl = useIntl()
  const allCategories: CategoryData[] = []

  const allowedTypes = options.images.allowedFormats
  const maxImages = 1

  useEffect(()=> {
    if (clearFile && fileList.length) setFileList([]);
  }, [fileList, clearFile])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const renderAllowedTypes = () => {
    return allowedTypes.map((type) => type.replace('image/', '')).join(',')
  }

  function validateImage(file: File): Promise<boolean> {
    const FIX_WIDTH = 467;
    const FIX_HEIGHT = 194;

    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const correctedWidth = adjustDimension(img.width, FIX_WIDTH);
        const correctedHeight = adjustDimension(img.height, FIX_HEIGHT);

        if (isAspectRatioValid(correctedWidth, correctedHeight, FIX_WIDTH, FIX_HEIGHT)) {
          resolve(true);
        } else {
          reject(`Invalid dimensions: ${img.width}x${img.height}. Expected ${FIX_WIDTH}x${FIX_HEIGHT}.`);
        }
      };

      img.onerror = () => reject('Invalid image file.');
      img.src = URL.createObjectURL(file);
    });

    // Helper to adjust dimensions
    function adjustDimension(actual: number, expected: number): number {
      if (Math.abs(actual - expected) <= 1) {
        return expected;
      }
      return actual;
    }

    // Helper to validate aspect ratio
    function isAspectRatioValid(width: number, height: number, targetWidth: number, targetHeight: number): boolean {
      return width / height === targetWidth / targetHeight;
    }
  }

  const handleChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    if(newFileList && newFileList.length) {
      validateImage(newFileList[0]?.originFileObj as File).then(() => {
        setFileList(newFileList)
        let newList = [...newFileList]

        newList = newList.filter((file) => {
          const isValidType = allowedTypes.includes(file.type || '')
          if (!isValidType) {
            message.error(
              `${intl.formatMessage({
                id: 'images.allowed_types',
              })}${renderAllowedTypes()}`
            )
          }
          return isValidType
        })

        if (newList.length > maxImages) {
          message.warning(
            `${intl.formatMessage({id: 'images.max_images'})}${maxImages}`
          )
          newList = newList.slice(maxImages)
        }

        if (setFileList && newList.length) {
          setFileList(newList)
        }
      })
        .catch(() => {
          message.error(
            intl.formatMessage({ id: 'validation.image_size' })
          )
        })
    } else {
      setFileList(newFileList)
    }
  }

  const handleOnSelectCategory = (value: number) => {
    const nameToNumber = parseInt(name)

    onCategoryChange(nameToNumber, value)
  }

  categories.forEach((category) => {
    allCategories.push(category)

    if (category.has_subcategories) {
      category.subcategories?.forEach((subCat) => {
        allCategories.push(subCat)

        if (subCat.has_subcategories) {
          subCat.subcategories?.forEach((subSubCat) => {
            allCategories.push(subSubCat)
          })
        }
      })
    }
  })

  const handleCustomRequest = useCallback(({ file }: any) => {
    validateImage(file).then(() => {
      const formData: FormDataType = {
        file: file,
        position: name,
        file_name: file.name,
      }

      setFormData((fd)=> ({
        ...fd,
        [name]: formData
      }))
    }).catch(()=> {})
  }, [fileList, name, setFormData])

  const uploadProps: UploadProps = useMemo(()=> ({
    name: 'file',
    fileList: fileList,
    onPreview: handlePreview,
    onChange: handleChange,
    customRequest: handleCustomRequest,
    accept: allowedTypes.join(','),
    multiple: false,
  }), [allowedTypes, fileList, handleChange, handleCustomRequest])

  useEffect(() => {
    const formData: FormDataType = {
      file: fileList?.[0]?.originFileObj,
      position: name,
      file_name: fileList[0]?.originFileObj?.name,
    }
    setFormData((fd)=> ({
      ...fd,
      [name]: formData
    }))
    fileList.forEach((file) => {
      file.status = 'done'
    })
  }, [fileList]);

  return (
    <StyledTopCategorySelector>
      <div className={'category'}>
        <div className={'label'}>
          {intl.formatMessage({ id: `top_category.${label}` })}
        </div>
        <Select
          name={name}
          placeholder={<span className={'initial-value'}>{initialValue?.name}</span>}
          onChange={handleOnSelectCategory}
          value={initialValue?.id}
        >
          {allCategories.map((category) => (
            <SelectOption key={category.id} value={category.id}>
              {category.name}
            </SelectOption>
          ))}
        </Select>
        <p className={'validation-text'}>{ intl.formatMessage({ id: 'validation.image_size' }) }</p>
        <div className={'preview'}>
          <FallbackImage
            type={'CategoryCard'}
            src={image?.url_original}
            preview={true}
          />
        </div>
        <div className={'img'}>
          <Upload {...uploadProps}>
            <Button className={'btn'} icon={<UploadOutlined/>}>
              {intl.formatMessage({id: 'general.upload'})}
            </Button>
          </Upload>
        </div>
      </div>
    </StyledTopCategorySelector>
  )
}

export default TopCategorySelector
