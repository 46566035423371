import { StyledCreateEditUserDrawer } from './style';
import { Button, Checkbox, Input } from '../../../../../ui';
import { useIntl } from 'react-intl';
import useQueryApiClient from '../../../../../utils/useQueryApiClient';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { message } from 'antd';
import { useAuth } from '../../../../../hooks/useAuth';
import { useMemo } from 'react';
import { FarmsProps } from '../../../../../interfaces/BackendModels';

interface CreateEditProps {
  open: boolean;
  onClose: () => void;
  isEdit: boolean;
  setReloadCount: React.Dispatch<React.SetStateAction<number>>;
}

export const CreateEditUser = ({
  open,
  onClose,
  isEdit,
  setReloadCount,
}: CreateEditProps) => {
  const intl = useIntl();
  const form = useFormInstance();
  const { user } = useAuth();

  const { appendData: storeUser } = useQueryApiClient({
    request: {
      url: '/api/v2/users',
      method: 'POST',
    },
  });

  const { appendData: editUser } = useQueryApiClient({
    request: {
      url: `/api/v2/farms/contacts/:id?_method=PATCH`,
      method: 'POST',
    },
    onSuccess: (response: FarmsProps) => handleSuccess(response),
  });

  const title = isEdit
    ? intl.formatMessage({ id: 'general.edit_contact_user' })
    : intl.formatMessage({ id: 'general.add_new_contact_user' });

  const submitText = isEdit
    ? intl.formatMessage({ id: 'general.edit' })
    : intl.formatMessage({ id: 'general.add' });

  const msg = isEdit
    ? intl.formatMessage({ id: 'message.success_edit_farm_contact' })
    : intl.formatMessage({ id: 'message.success_create_farm_contact' });

  const stableFarmId = useMemo(() => {
    return user?.farmId;
  }, [user?.farmId]);

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      isEdit ? editUser(values, { id: stableFarmId }) : storeUser(values);
    } catch (e) {
      form.getFieldsError().map((error) => {
        error.errors.map((err) => {
          message.error(err);
        });
      });
    }
  };

  const handleSuccess = (response: FarmsProps) => {
    setReloadCount((prev: number) => prev + 1);
    onClose();
    message.success(msg);
  };

  const Footer = () => {
    return (
      <div className={'footer'}>
        <Button
          label={intl.formatMessage({ id: 'general.cancel' })}
          onClick={onClose}
        />
        <Button label={submitText} type={'primary'} onClick={handleSubmit} />
      </div>
    );
  };

  return (
    <StyledCreateEditUserDrawer
      footer={<Footer />}
      open={open}
      title={title}
      onClose={onClose}
      width={800}
    >
      <Input name={'id'} hidden />
      <Input name={'farm_id'} hidden />

      <Input
        name={'contact_name'}
        label={intl.formatMessage({ id: 'farm_users.full_name' })}
        validations={'requiredText'}
      />
      <Input
        name={'contact_phone'}
        label={intl.formatMessage({ id: 'general.phone' })}
        validations={'phoneNumber'}
      />
      <Input
        name={'contact_email'}
        label={intl.formatMessage({ id: 'general.email' })}
        validations={'email'}
      />
      <Checkbox
        name={'status'}
        label={intl.formatMessage({ id: 'general.status' })}
      />
    </StyledCreateEditUserDrawer>
  );
};
