import { Navigate, Route, Routes } from 'react-router-dom';
import TestPage from 'public/pages/TestPage';
import { AllProducts } from '../../pages/AllProducts';
import MapPage from 'public/pages/MapPage';
import ProductPage from '../../pages/ProductPage';
import { Login } from '../../pages/Auth/Login';
import { Register } from '../../pages/Auth/Register';
import { AllDiscountProducts } from '../../pages/AllDiscountProducts';
import { AllFarms } from '../../pages/AllFarms';
import { IconsPage } from '../../pages/IconsPage';
import FarmPage from '../../pages/FarmPage';
import { AllNewProducts } from 'public/pages/AllNewProducts';
import { MyProfilePage } from '../../pages/MyProfilePage';
import { CartPage } from 'public/pages/CartPage';
import RoutesPath from 'enums/routes';
import { ConfirmationPage } from 'public/pages/Auth/ConfirmationPage';
import { TermsOfUse } from 'public/pages/Auth/TermsOfUse';
import FailedPayment from '../DefaultLayout/FailedPayment/FailedPayment';
import { OrderPage } from 'public/pages/OrderPage/OrderPage';

const Router = () => {
  const renderRoutes = () => {
    return (
      <>
        <Route path={RoutesPath.MAP} element={<MapPage />} />
        <Route path={RoutesPath.PRODUCTS_ALL} element={<AllProducts />} />
        <Route
          path={RoutesPath.PRODUCTS_ALL_NEW}
          element={<AllNewProducts />}
        />
        <Route
          path={RoutesPath.PRODUCTS_ALL_DISCOUNTED}
          element={<AllDiscountProducts />}
        />
        <Route path={RoutesPath.FARMS_ALL} element={<AllFarms />} />
        <Route path={RoutesPath.FARM_PAGE} element={<FarmPage />} />
        <Route path={RoutesPath.TEST_PAGE} element={<TestPage />} />
        <Route path={RoutesPath.TERMS_OF_USE} element={<TermsOfUse />} />
        <Route path={RoutesPath.PRODUCT_PAGE} element={<ProductPage />} />
        <Route path={RoutesPath.HOME} element={<TestPage />} />
        <Route path={RoutesPath.LOGIN} element={<Login />} />
        <Route path={RoutesPath.REGISTER} element={<Register />} />
        <Route
          path={RoutesPath.NOT_FOUND}
          element={<Navigate to={RoutesPath.LOGIN} replace />}
        />
        <Route path={RoutesPath.ICONS_PAGE} element={<IconsPage />} />
        <Route path={RoutesPath.CART_PAGE} element={<CartPage />} />
        <Route path={RoutesPath.PROFILE} element={<MyProfilePage />} />
        <Route path={RoutesPath.PAYMENT_FAILED} element={<FailedPayment />} />
        <Route path={RoutesPath.USER_ORDER} element={<OrderPage />} />
      </>
    );
  };

  return <Routes>{renderRoutes()}</Routes>;
};

export default Router;
