import styled from 'styled-components';

export const StyledMobileHeader = styled.div`
  display: none;
  z-index: 1000;
  width: 100%;
  .mobile-header-container {
    width: 100%;

    .mobile-image-container {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      background-color: ${({ theme }) => theme.brand01};
      z-index: 1001;
    }
  }

  .filter-open-btn {
    display: flex;
    height: auto;
    padding: 11px 14px !important;
    border-radius: 5px;
  }

  .filter-open-btn:hover {
    background: ${({ theme }) => theme.brand01} !important;
    border-color: ${({ theme }) => theme.colorBorder} !important;
    color: ${({ theme }) => theme.white} !important;
  }

  @media only screen and (max-width: 1200px) {
    display: flex;
    .mobile-header-container {
      width: 100%;

      &.filterOpen {
        .buy-latvia-food {
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .title-section {
            width: 300px;
            justify-content: center;
            padding: 0 0 0 0;
          }

          .counter-section {
            position: absolute;
            padding: 0 0 0 0;
            flex-direction: row;
            gap: 10px;
            top: 154px;
            background-color: ${({ theme }) => theme.filterSelectBg};
            max-width: 120px;
            border-radius: 10px;
            padding: 2px 5px 2px 5px;
            color: white;

            h3 {
              font-size: 16px;
              b {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .buy-latvia-food {
      display: flex;
      height: 107px;
      width: 100%;
      background-color: ${({ theme }) => theme.white};
      justify-content: space-between;

      .title-section {
        height: 110px;
        width: 175px;
        display: flex;
        align-items: center;
        padding: 25px 0px 24px 18px;

        h3 {
          font-family: ${({ theme }) => theme.fontText};
          margin: 0;
          font-weight: 400;
          font-size: 24px;
        }
      }

      .counter-section {
        max-height: 34px;
        max-width: 61px;
        display: flex;
        align-items: end;
        flex-direction: column;
        padding: 47px 21px 26px 0;

        h3 {
          font-family: ${({ theme }) => theme.fontText};
          margin: 0;
          font-weight: 400;
          font-size: 14px;

          b {
            font-size: 18px;
          }
        }
      }
    }

    .search-section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px 20px 25px 20px;
      padding-right: 10px;
      background-color: ${({ theme }) => theme.inputGrey};
    }

    .fixed-search-section {
      position: fixed !important;
      top: 0px !important;
      z-index: 1;
    }
  }

  @media only screen and (max-width: 440px) {
    .mobile-header-container.filterOpen .buy-latvia-food .counter-section {
      top: 165px;
    }

    .filterOpen .buy-latvia-food {
      height: 94px;
      margin-top: 23px;
      margin-bottom: 22px;
    }
  }
`;
