import { StyledBusinessForm } from '../style';
import { useIntl } from 'react-intl';
import useQueryApiClient from '../../../../utils/useQueryApiClient';
import { useState } from 'react';
import { Divider, Checkbox } from '../../../../ui';

interface ServiceProps {
  value: number;
  label: string;
}

interface QmarksProps {
  value: number;
  label: string;
}

export const AdditionalInformation = () => {
  const [services, setServices] = useState<ServiceProps[]>([]);
  const [qmarks, setQmarks] = useState<QmarksProps[]>([]);
  const intl = useIntl();

  const { data: servicesFetch } = useQueryApiClient({
    request: {
      url: '/api/v2/filters/services',
    },
    onSuccess: (response) => handleSuccessServicesFetch(response),
  });

  const { data: qmarksFetch } = useQueryApiClient({
    request: {
      url: '/api/v2/filters/qmarks',
      data: {
        view: 'admin',
      },
    },
    onSuccess: (response: QmarksProps[]) => handleSuccessQmarksFetch(response),
  });

  const handleSuccessServicesFetch = (response: ServiceProps[]) => {
    if (Array.isArray(response)) {
      response.sort((a, b) => a.label.localeCompare(b.label));
      setServices(response);
    }
  };

  const handleSuccessQmarksFetch = (response: QmarksProps[]) => {
    setQmarks(response);
  };

  return (
    <StyledBusinessForm>
      <div className={'content'}>
        <div className={'section'}>
          <div className={'title'}>
            <h3>{intl.formatMessage({ id: 'general.additional_options' })}</h3>
          </div>
          <div className={'double-column-checkbox-two-sixteen'}>
            <Checkbox options={services} name={'services'} />
          </div>
        </div>
        <Divider />
        <div className={'section'}>
          <div className={'title'}>
            <h3>{intl.formatMessage({ id: 'navigation.qmark' })}</h3>
          </div>
          <div className={'double-column-checkbox-two-sixteen'}>
            <Checkbox options={qmarks} name={'quality_marks'} />
          </div>
        </div>
      </div>
    </StyledBusinessForm>
  );
};
