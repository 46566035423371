import { StyledAdressFinder, StyledModal } from './style';
import AddressFinder from './react-amk.min.js';
import { Modal, Button } from 'ui';
import { useIntl } from 'react-intl';

export type AMKResponse = {
  index: number;
  data: {
    address: string;
    code: number;
    dzvCode: number;
    dzvName: string;
    ielCode: number;
    ielName: string;
    irAdrese: boolean;
    nltCode: number;
    nltName: string;
    pilCode: number;
    pilName: string;
    typ: number;
    zipCode: string;
    novName: string;
    pagName: string;
  };
};

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<any>>;
  onItemSelect: Function;
}

const AMK_ADRESS_SEARCH = `${window.runConfig.backendUrl}/api/v2/vraa/adress`;

export const AdresSearch = ({ isOpen, setIsOpen, onItemSelect }: Props) => {
  const intl = useIntl();

  return (
    <>
      <StyledModal
        open={isOpen}
        destroyOnClose
        onCancel={() => setIsOpen(false)}
        footer={
          <Button
            onClick={() => setIsOpen(false)}
            label={intl.formatMessage({ id: 'general.close' })}
          />
        }
      >
        <StyledAdressFinder>
          <AddressFinder
            apiBaseEndpointUri={AMK_ADRESS_SEARCH}
            onItemSelect={onItemSelect}
            highlightMatches={false}
            defaultOpenAllRows={false}
            visibleColumnLabels={['address', 'selectButton']}
            visibleDataLabels={[]}
            showOnlyLowestLevelAddresses={false}
          />
        </StyledAdressFinder>
      </StyledModal>
    </>
  );
};
