import { Navigate, Route, Routes } from 'react-router-dom';
import TestPage2 from 'admin/pages/TestPage2';
import TestPage3 from 'admin/pages/TestPage3';
import TransactionListPage from 'admin/pages/Transactions/TransactionListPage';
import QualityMarkListPage from 'admin/pages/QualityMarks/QualidtyMarkListPage';
import EShopEmailListPage from '../../pages/EShopEmailListPage';
import FarmEmailListPage from '../../pages/FarmEmailListPage';
import ContractListPage from '../../pages/Contracts/ContractListPage';
import OrderListPage from '../../pages/Orders/OrderListPage';
import FarmListPage from '../../pages/Farms/FarmListPage';
import FarmUserListPage from '../../pages/Farms/FarmUserListPage';
import UserListPage from '../../pages/Users/UserListPage';
import PermisionListPage from '../../pages/Users/PermisionListPage';
import { Products } from '../../pages/Products';
import ProtectedRoute from '../../../public/components/DefaultLayout/ProtectedRoutes/ProtectedRoute';
import CategoryPage from '../../pages/Category';
import { BannersPage } from '../../pages/Banners';
import TopCategories from '../../pages/TopCategories';
import { FarmContracts } from '../../pages/FarmContracts/FarmContracts';
import { FarmDeliveries } from '../../pages/FarmDeliveries';
import SettingListPage from '../../pages/Settings/SettingListPage';
import FarmSettingListPage from '../../pages/Settings/FarmSettingListPage';
import EmarketSettingListPage from '../../pages/Settings/EmarketSettingListPage';
import { Partners } from '../../pages/Partners/Partners';
import { BusinessInformation } from '../../pages/BusinessInformation';

const Router = () => {
  const renderRoutes = () => {
    return (
      <>
        <Route path="/form" element={<TestPage2 />} />
        <Route path="/b" element={<TestPage3 />} />
        <Route
          path="/partners"
          element={<ProtectedRoute children={<Partners />} requiresFarmAdmin />}
        />

        <Route
          path="/transactions"
          element={
            <ProtectedRoute
              children={<TransactionListPage />}
              requiresFarmAdmin
            />
          }
        />
        <Route
          path="/qmark"
          element={
            <ProtectedRoute children={<QualityMarkListPage />} requiresAdmin />
          }
        />
        <Route
          path="/eshop-email"
          element={
            <ProtectedRoute children={<EShopEmailListPage />} requiresAdmin />
          }
        />
        <Route
          path="/farm-email"
          element={
            <ProtectedRoute children={<FarmEmailListPage />} requiresAdmin />
          }
        />
        <Route
          path="/contracts"
          element={
            <ProtectedRoute children={<ContractListPage />} requiresFarmAdmin />
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute children={<OrderListPage />} requiresFarmAdmin />
          }
        />
        <Route
          path="/farms"
          element={
            <ProtectedRoute children={<FarmListPage />} requiresFarmAdmin />
          }
        />
        <Route
          path="/farm/users"
          element={
            <ProtectedRoute requiresFarmAdmin children={<FarmUserListPage />} />
          }
        />
        <Route
          path="/farm/contracts"
          element={
            <ProtectedRoute children={<FarmContracts />} requiresFarmAdmin />
          }
        />
        <Route
          path="/users"
          element={<ProtectedRoute requiresAdmin children={<UserListPage />} />}
        />
        <Route
          path="/top-categories"
          element={
            <ProtectedRoute children={<TopCategories />} requiresAdmin />
          }
        />
        <Route
          path="/products"
          element={<ProtectedRoute children={<Products />} requiresFarmAdmin />}
        />
        <Route
          path="/banners"
          element={<ProtectedRoute requiresAdmin children={<BannersPage />} />}
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute requiresAdmin children={<SettingListPage />} />
          }
        />
        <Route
          path="/catalog-settings"
          element={
            <ProtectedRoute requiresAdmin children={<FarmSettingListPage />} />
          }
        />
        <Route
          path="/emarket-settings"
          element={
            <ProtectedRoute
              children={<EmarketSettingListPage />}
              requiresAdmin
            />
          }
        />
        <Route
          path="/business"
          element={
            <ProtectedRoute
              children={<BusinessInformation />}
              requiresFarmAdmin
            />
          }
        />
        <Route
          path="/farm/deliveries"
          element={
            <ProtectedRoute children={<FarmDeliveries />} requiresFarmAdmin />
          }
        />
        <Route
          path="/permissions"
          element={
            <ProtectedRoute requiresAdmin children={<PermisionListPage />} />
          }
        />
        <Route
          path="/categories"
          element={<ProtectedRoute children={<CategoryPage />} requiresAdmin />}
        />

        <Route path="/*" element={<TransactionListPage />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </>
    );
  };

  return <Routes>{renderRoutes()}</Routes>;
};

export default Router;
