import styled from 'styled-components';
import { Form } from 'antd';

export const StyledLoginForm = styled(Form)`
  display: flex;
  min-height: 545px;
  background-color: ${(props) => props.theme.white};
  font-family: ${(props) => props.theme.fontText};
  border-radius: 6px;
  margin-right: 200px;
  width: 100%;
  max-width: 640px;

  .accept_terms {
    display: flex;
    align-items: center;
    gap: 5px;

    .link {
      font-size: 14px;
      line-height: 20px;
      color: ${(props) => props.theme.darkGrayishBlue};
    }
  }

  .ant-form-item {
    margin-bottom: 5px;

    .ant-form-item-control-input-content {
      input {
        height: 55px;
      }
    }

    .ant-input-suffix {
      height: 55px;
    }
  }

  .accept_terms .ant-form-item {
    margin-bottom: 0;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    padding: 40px 70px 33px 70px;
    max-width: 640px;
    width: 640px;

    .registration-title-wrapper.reset-pw {
      margin-bottom: 0;
      .title {
        margin-bottom: 18%;
      }
      .description {
        color: ${(props) => props.theme.black};
        font-weight: 500;
      }
    }
    .registration-title-wrapper {
      margin-bottom: 17px;
      .title {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 9px;
      }

      .description {
        margin: 0;
        font-size: 14px;
        line-height: 30px;
        color: ${(props) => props.theme.darkGrayishBlue};
      }
    }

    .registration-input-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;

      .fulname {
        display: flex;
        gap: 15px;
        justify-content: space-between;

        div {
          width: 100%;
        }
      }

      .ant-input::placeholder {
        color: ${(props) => props.theme.grayishBlue};
      }

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      .ant-input-affix-wrapper {
        padding: 0 11px;
      }

      .ant-checkbox-wrapper,
      .label {
        color: ${(props) => props.theme.darkGrayishBlue};
      }

      .email,
      .pw {
        font-size: 14px;
        line-height: 22px;
      }

      .pw-wrap {
        display: flex;
        gap: 15px;
      }
    }

    .reset-password {
      margin-top: 15px;
      font-size: 14px;
      color: ${(props) => props.theme.black};
      text-decoration: underline;
      text-align: center;
    }

    .registration-button-wrapper {
      display: flex;
      flex-direction: column;

      .cancel-back-to-landing-page {
        min-width: 241px;
        min-height: 55px;
        border: 1px solid ${(props) => props.theme.brand01};
        border-radius: 5px;
        margin-top: 10px;
      }

      .registration-buttons {
        display: flex;
        flex-direction: column;
      }

      .cstm-btn {
        margin-top: 10px;
        border-radius: 5px;
        height: 55px;
      }

      .submit {
        font-weight: 600;
        margin-top: 15px;
      }

      .google {
        border-color: ${(props) => props.theme.brand01};
        background-color: transparent;
        color: ${(props) => props.theme.brand01};
      }
    }
    .no-acc-reg {
      margin-top: 13px;
      color: ${(props) => props.theme.dustyBlue};
      font-size: 14px;
      line-height: 22px;
      text-align: center;

      .login_link {
        display: inline-block;
        height: 16px;
        margin-left: 5px;
        color: ${(props) => props.theme.vividBlue};
        font-weight: 600;
        border-bottom: 1px solid ${(props) => props.theme.vividBlue};
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  @media only screen and (max-width: 1100px) {
    margin-right: 0;
    .inputs {
      .registration-button-wrapper {
        height: 100%;
        justify-content: flex-start;
        flex-direction: column-reverse;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .inputs {
      padding: 34px 20px 20px 20px;
      .registration-title-wrapper {
        .title {
          text-align: center;
        }
        .description {
          text-align: center;
        }
      }

      .registration-button-wrapper {
        .no-acc-reg {
          margin-bottom: 32px;
        }
      }
    }
  }
`;
