import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form, message, UploadFile, UploadProps } from 'antd';
import { useIntl } from 'react-intl';
import { StyledNewUploader, StyledUploader } from './style';
import { getBase64 } from '../../../utils/productCardHelpers';
import { CustomUploadList } from './CustomUploadList';
import { options } from '../../../config/config';
import ImgCrop from 'antd-img-crop';

interface UploaderProps {
  onFileListChange?: Dispatch<any>;
  fileList: UploadFile[];
  multiple?: boolean;
  onValidate: (isValid: boolean) => void;
  setThumbUrls: React.Dispatch<React.SetStateAction<{ [p: string]: string }>>;
  thumbUrls: { [p: string]: string };
  handleRemove(file: UploadFile): void;
  handleEditBeforeUpload: (file: any) => Promise<boolean>;
  isCrop?: boolean;
  needThumb?: boolean;
  showActions?: boolean;
  isColumn?: boolean;
}

export const Uploader = (Props: UploaderProps) => {
  const {
    onFileListChange,
    fileList,
    multiple = true,
    thumbUrls,
    onValidate,
    setThumbUrls,
    handleRemove,
    handleEditBeforeUpload,
    isCrop = true,
    needThumb = true,
    showActions = true,
    isColumn,
  } = Props;

  const intl = useIntl();

  const allowedTypes = options.images.allowedFormats;
  const maxImages = multiple ? options.images.maxImages : 1;

  useEffect(() => {
    if (fileList.length === 0) {
      setThumbUrls({});
    }
  }, [fileList, setThumbUrls]);

  const renderAllowedTypes = useCallback(() => {
    const types = allowedTypes.map((type) => type.replace('image/', ''));
    return types.join(',');
  }, [allowedTypes]);

  const handleChange = useCallback(
    async (info: { fileList: UploadFile[] }) => {
      let newList = [...info.fileList];
      newList = newList.filter((file) => {
        if (!file.originFileObj) {
          return true;
        }
        const fileType = file.type;
        const isValidType = allowedTypes.includes(fileType || '');
        if (!isValidType) {
          message.error(
            `${intl.formatMessage({ id: 'images.allowed_types' })}${renderAllowedTypes()}`
          );
        }
        return isValidType;
      });

      if (maxImages && newList.length > maxImages) {
        message.warning(
          `${intl.formatMessage({ id: 'images.max_images' })}${maxImages}`
        );
        newList = newList.slice(0, maxImages);
      }

      let updatedThumbUrls: { [uid: string]: string } = {};
      await Promise.all(
        newList.map(async (file: UploadFile) => {
          if (!file.url && !file.thumbUrl && file.originFileObj) {
            updatedThumbUrls[file.uid] = await getBase64(
              file.originFileObj as File
            );
          } else if (file.thumbUrl) {
            updatedThumbUrls[file.uid] = file.thumbUrl;
          }
        })
      );

      onValidate(newList.length > 0);
      setThumbUrls((prev) => ({ ...prev, ...updatedThumbUrls }));
      if (onFileListChange) {
        onFileListChange(newList);
      }
    },
    [
      allowedTypes,
      intl,
      maxImages,
      onFileListChange,
      onValidate,
      renderAllowedTypes,
      setThumbUrls,
    ]
  );

  const props: UploadProps = useMemo(
    () => ({
      name: 'images',
      multiple: multiple,
      onChange: handleChange,
      fileList: fileList,
      listType: 'picture-card',
      showUploadList: false,
      onRemove: handleRemove,
      beforeUpload: () => false,
      accept: allowedTypes.join(','),
    }),
    [fileList, handleChange, handleRemove, multiple, allowedTypes]
  );

  const wrapWithCrop = (children: any) => {
    if (isCrop) {
      return (
        <ImgCrop rotationSlider showReset>
          {children}
        </ImgCrop>
      );
    } else {
      return children;
    }
  };

  const UploadList = useMemo(() => {
    return (
      <CustomUploadList
        fileList={fileList}
        thumbUrls={thumbUrls}
        onRemove={handleRemove}
        onEdit={handleEditBeforeUpload}
        isCrop={isCrop}
        needThumb={needThumb}
        showActions={showActions}
      />
    );
  }, [
    fileList,
    handleEditBeforeUpload,
    handleRemove,
    isCrop,
    needThumb,
    showActions,
    thumbUrls,
  ]);

  return (
    <StyledUploader>
      <Form.Item name="images">
        {wrapWithCrop(
          <StyledNewUploader {...props}>
            <p className="ant-upload-drag-icon">
              <img src={'/svgs/upload-image.svg'} alt="upload icon" />
            </p>
            <p className="ant-upload-text">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>{intl.formatMessage({ id: 'gallery.upload_file' })} </a>
              {intl.formatMessage({ id: 'gallery.or_here' })}
            </p>
          </StyledNewUploader>
        )}
      </Form.Item>
      {multiple && !isColumn ? UploadList : null}
    </StyledUploader>
  );
};
