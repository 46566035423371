import styled from 'styled-components';
import { Drawer } from 'antd';

export const StyledOrdersDrawer = styled(Drawer)`
  .content-invoice {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 150px;
  }

  .ant-drawer-header {
    padding: 0;
    min-height: 113px;

    .ant-drawer-header-title {
      display: flex;
      justify-content: right;
      padding-right: 20px;
    }
  }
`;
