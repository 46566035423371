import { StyledSearchAndFilter } from './style';
import { useIntl } from 'react-intl';
import { Icon, Search } from 'ui';
import { Button } from 'antd';
import useWindowScrollTop from 'utils/useWindowScrollTop';
import {
  useFilterDispatch,
  useFilterState,
} from 'public/context/FilterContext';
import useWindowSize from 'utils/useWindowSize';

interface Props {
  rootClassName?: string;
  isMenuOpen?: boolean;
  searchLabel?: string;
}

const SearchAndFilter = ({
  rootClassName = 'search-section-box',
  isMenuOpen = false,
  searchLabel,
}: Props) => {
  const intl = useIntl();
  const scrollPosition = useWindowScrollTop(300);
  const [_, innerWidth] = useWindowSize();
  const data = useFilterState();
  const dispatchFilter = useFilterDispatch();

  return innerWidth < 1200 ? (
    <StyledSearchAndFilter
      className={`${rootClassName} ${isMenuOpen && 'search-section-open'}`}
    >
      <div
        className={`search-section ${scrollPosition > 90 && 'search-section-fixed'}`}
      >
        {data.isFilter && (
          <Button
            form="filter-form"
            className="filter-open-btn"
            onClick={() => {
              data.openCloseDrawer();
              dispatchFilter({
                type: 'SAVE_PAYLOAD',
                payload: {
                  isOpenMobileFilter: true,
                },
              });
            }}
          >
            <Icon name={'filter-settings-icon'} />
            <span>{intl.formatMessage({ id: 'general.filter' })}</span>
          </Button>
        )}

        <Search
          placeholder={
            searchLabel
              ? searchLabel
              : intl.formatMessage({ id: 'mobile_header.search' })
          }
          prefix={<Icon name={'mobile-search-icon'} />}
        />
      </div>
    </StyledSearchAndFilter>
  ) : null;
};

export default SearchAndFilter;
