import { Space } from 'antd';
import {
  FarmProduct,
  ParcelLocation,
  SelectedDelivery,
} from 'public/context/CartContext';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FarmDeliveries } from 'types/FarmData';
import {
  Icon,
  Select,
  SelectOption,
  SelectOptionsValueString,
  SelectOptionsValueStringNumber,
  Spinner,
} from 'ui';
import useQueryApiClient from 'utils/useQueryApiClient';
import { StyledDelivery } from '../style';
import { FormInstance } from 'antd';

interface VenipakDeliveryProps {
  farmProduct: FarmProduct;
  selectedDelivery: FarmDeliveries;
  setSelectedDeliveryData: React.Dispatch<
    React.SetStateAction<SelectedDelivery[] | null>
  >;
  selectedDeliveryData: SelectedDelivery[] | null;
  deliveryForm: FormInstance<any>;
}

const VenipakDelivery = ({
  selectedDelivery,
  setSelectedDeliveryData,
  farmProduct,
  selectedDeliveryData,
  deliveryForm,
}: VenipakDeliveryProps) => {
  const intl = useIntl();
  const [selectedCityAddress, setSelectedCityAddress] = useState<string | null>(
    null
  );

  const {
    isLoading: parishLoading,
    data: cityData,
    refetch,
  } = useQueryApiClient({
    request: { url: 'api/v2/delivery/venipakPickupGetCities' },
  });

  const { isLoading: parcelLoading, data: parcelData } = useQueryApiClient({
    request: {
      url: `api/v2/delivery/venipakPickupGetLocations/${selectedCityAddress}`,
    },
    enabled: !!selectedCityAddress,
  });

  const handleCityChange = (city: string) => {
    setSelectedCityAddress(city);
    handleRefetch();
  };

  const handleRefetch = () => {
    deliveryForm.setFieldValue(`venipakLocation.${farmProduct.farm_id}`, null);
    refetch();
  };

  const handleParcelChange = (parcelName: string) => {
    if (!selectedCityAddress) return;

    const selected = parcelData?.find(
      (location: ParcelLocation) => location.name === parcelName
    );

    if (selected && selectedDelivery) {
      setSelectedDeliveryData([
        ...(selectedDeliveryData || []),
        {
          id: selected.id,
          selectedDeliveryId: selectedDelivery.id,
          city: selectedCityAddress,
          address: selected.address,
          firm_name: selectedDelivery?.type,
          name: selected.name,
          price: selectedDelivery.price,
        },
      ]);
    }
  };

  const optionsCities: SelectOptionsValueString[] = cityData?.map(
    (city: string) => ({
      label: city,
      value: city,
    })
  );

  const optionsParcels: SelectOptionsValueStringNumber[] = parcelData?.map(
    (location: ParcelLocation) => ({
      label: location.name,
      value: location.name,
    })
  );

  return (
    <StyledDelivery>
      <Spinner spinning={parishLoading}>
        <Space direction="vertical">
          {cityData && (
            <Select
              suffixIcon={<Icon name={'arrow-down-select-black'} />}
              validations="required"
              initialValue={
                farmProduct.selectedDelivery?.firm_name === 'delivery'
                  ? farmProduct.selectedDelivery?.city
                  : undefined
              }
              name={`venipakCity.${farmProduct.farm_id}`}
              size="middle"
              onChange={handleCityChange}
              children={
                optionsCities &&
                optionsCities.map((options, index) => (
                  <SelectOption value={options.value} key={`${index} - opt`}>
                    {options.label}
                  </SelectOption>
                ))
              }
            />
          )}

          <Spinner spinning={parcelLoading}>
            {(parcelData?.length > 0 || farmProduct.selectedDelivery) && (
              <Select
                suffixIcon={<Icon name={'arrow-down-select-black'} />}
                validations={'required'}
                size="middle"
                name={`venipakLocation.${farmProduct.farm_id}`}
                style={{ width: '100%' }}
                value={farmProduct.selectedDelivery?.name}
                initialValue={
                  farmProduct.selectedDelivery?.firm_name === 'delivery'
                    ? farmProduct.selectedDelivery?.name
                    : undefined
                }
                onChange={handleParcelChange}
                children={
                  optionsParcels &&
                  optionsParcels.map((options, index) => (
                    <SelectOption value={options.value} key={`${index} - opt`}>
                      {options.label}
                    </SelectOption>
                  ))
                }
              />
            )}
          </Spinner>
        </Space>
      </Spinner>
    </StyledDelivery>
  );
};

export default VenipakDelivery;
