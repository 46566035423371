import styled from 'styled-components';

export const StyledProfile = styled('div')`
  .content {
    font-family: ${(props) => props.theme.fontText};
    display: flex;
    min-width: 221px;
    min-height: 63px;
    gap: 18px;
    align-items: center;

    .info {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      max-height: 110px;
      max-width: 152px;

      .farm-name,
      .role {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        max-height: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .contact-name {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
`;
