import React, { useState } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import { Button, Tabs, Table, Dropdown, Input } from 'ui';
import Filter from 'admin/components/Filter';
import { StyledButtonList } from 'styles/buttonList';
import dayjs from 'dayjs';
import useQueryApiClient from '../../../../utils/useQueryApiClient';
import FileDownload from 'js-file-download';
import { MenuProps } from 'antd/es/menu';
import { Pagination } from '../../../../types/Pagination';
import { print } from '../../../../utils/print';
import copyToClipboard from '../../../../utils/copyToClipboard';
import EnumSelect from 'admin/Selects/EnumSelect';
import { StyledFarmUserListPage } from './style';
import FarmUserAction from '../../../../enums/farm-user';

interface FarmUsers {
  id: string;
  name: string;
  email: string;
  role: string;
  user: string;
  created_at: string | Date;
  updated_at: string | Date;
}

const FarmUserListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState({});
  const [paginationStatus, setPaginationStatus] = useState<Pagination>({});
  const [farmUserData, setFarmUserData] = useState<any>();
  const intl = useIntl();

  const { appendData: exportData } = useQueryApiClient({
    request: {
      url: `api/v2/users/farms/export`,
      data: {
        filter: advancedSearch,
      },
      multipart: true,
      method: 'POST',
    },
    onSuccess: (response, format) => {
      FileDownload(
        response,
        `${format}_${dayjs().format('YYYY-MM-DD')}.${format}`
      );
    },
  });

  const headers = [
    intl.formatMessage({ id: 'farm_users.user_id' }),
    intl.formatMessage({ id: 'farm_users.full_name' }),
    intl.formatMessage({ id: 'general.e-mail' }),
    intl.formatMessage({ id: 'general.role' }),
    intl.formatMessage({ id: 'general.created_at' }),
    intl.formatMessage({ id: 'general.updated_at' }),
  ].join('\t');

  const formatRow = (row: FarmUsers) => {
    return [
      row.id || '',
      row.name || '',
      row.email || '',
      row.role || '',
      row.user || '',
      row.created_at ? dayjs(row.created_at).format('DD.MM.YYYY') : '',
      row.updated_at ? dayjs(row.updated_at).format('DD.MM.YYYY') : '',
    ].join('\t');
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'farm_users.user_id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'farm_users.full_name' }),
      dataIndex: 'full_name',
    },
    {
      title: intl.formatMessage({ id: 'general.e-mail' }),
      dataIndex: 'email',
    },
    {
      title: intl.formatMessage({ id: 'general.role' }),
      dataIndex: 'role',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
  ];

  const items = [
    {
      key: 'farm_users',
      label: intl.formatMessage({ id: 'navigation.farm_users' }),
      children: (
        <StyledFarmUserListPage>
          <Filter
            onFinish={setAdvancedSearch}
            totalRecord={paginationStatus.total ?? 0}
          >
            <Input label={intl.formatMessage({ id: 'general.id' })} name="id" />
            <Input
              label={intl.formatMessage({ id: 'farm_users.full_name' })}
              name="full_name"
            />
            <Input
              label={intl.formatMessage({ id: 'general.e-mail' })}
              name="email"
            />
            <span className="user-roles">
              <EnumSelect
                label={intl.formatMessage({ id: 'general.role' })}
                name="role"
                code="UserRoles"
              />
            </span>
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />
          </Filter>
          <Table
            url="/api/v2/users/farms"
            columns={columns}
            filter={advancedSearch}
            paginnationStatus={(el: object) => {
              setPaginationStatus(el);
            }}
            saveData={(el: object) => {
              setFarmUserData(el);
            }}
          />
        </StyledFarmUserListPage>
      ),
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case FarmUserAction.COPY:
        copyToClipboard({
          data: farmUserData,
          headers: headers,
          formatRow: formatRow,
          intl: intl,
        });
        break;
      case FarmUserAction.XLSX:
      case FarmUserAction.CSV:
        exportData({ format: e.key, ...advancedSearch }, [], e.key);
        break;
      case FarmUserAction.PRINT:
        print(intl.formatMessage({ id: 'general.printing_header' }), 6);
        break;
    }
  };

  const dropdownItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'general.print' }),
      key: FarmUserAction.PRINT,
    },
    {
      label: intl.formatMessage({ id: 'general.copy' }),
      key: FarmUserAction.COPY,
    },
    {
      label: intl.formatMessage({ id: 'general.excel' }),
      key: FarmUserAction.XLSX,
    },
    {
      label: intl.formatMessage({ id: 'general.csv' }),
      key: FarmUserAction.CSV,
    },
  ];

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  };

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.farm_users' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Dropdown menu={menuProps}>
                <Button
                  label={intl.formatMessage({ id: 'general.export' })}
                  type="primary"
                />
              </Dropdown>
            </StyledButtonList>
          }
          items={items}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default FarmUserListPage;
