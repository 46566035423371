import React from 'react';
import CartWarning from '../CartWarning';
import { StyledCart, StyledCartWrapper } from './style';
import { FarmProduct } from 'public/context/CartContext';
import GroupedFarmCart from '../GroupedFarmCart/GroupedFarmCart';
import CartSummary from '../CartSummary/CartSummary';
import { useIntl } from 'react-intl';

interface CartProps {
  farmProducts: FarmProduct[];
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  isView?: boolean | undefined;
  priceTotal: number;
}

const Cart = ({
  farmProducts,
  setCurrent,
  current,
  isView: isView,
  priceTotal,
}: CartProps) => {
  const intl = useIntl();
  return (
    <StyledCartWrapper>
      <div className={'cart-page-title mobile'}>
        <h1>{intl.formatMessage({ id: 'general.basket' })}</h1>
      </div>
      <CartWarning />
      <StyledCart isView={isView}>
        <GroupedFarmCart farmProducts={farmProducts} isView={isView} />
        <CartSummary
          priceTotal={priceTotal}
          farmProducts={farmProducts}
          setCurrent={setCurrent}
          current={current}
          isView={isView}
        />
      </StyledCart>
    </StyledCartWrapper>
  );
};

export default Cart;
