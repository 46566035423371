import { styled } from 'styled-components'

export const StyledProcessPurchase = styled('div')`
  .form-summary-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10%;
    padding-top: 30px;

    .form-wrapper {
      width: 100%;

      .form-title {
        font-size: 24px;
        font-weight: 400;
        padding-bottom: 18px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .form-summary-wrapper {
      flex-direction: column;
      .form-wrapper {
        .form-title {
          margin-top: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .form-summary-wrapper {
      padding-top: 0;
      .form-wrapper {
        .form-title {
          margin-top: 10px;
          font-size: 18px;
          font-weight: 600;
          padding-bottom: 0;
        }
      }
    }
  }
`
