import { StyledMobileMenu } from './style';
import { useIntl } from 'react-intl';
import { Button, Icon } from 'ui';
import { useNavigate } from 'react-router-dom';
import { MobileMenuDrawer } from 'ui/Drawer/Drawers/MobileMenuDrawer';
import { useState } from 'react';
import { Menu } from 'antd';
import { MyProfileDrawer } from 'ui/Drawer/Drawers/MobileMyProfileDrawer';
import MobileProfielOptions from '../MobileProfielOptions/MobileProfielOptions';
import { useAuth } from 'hooks/useAuth';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useFilterDispatch, useFilterState } from 'public/context';
import MobileLogin from '../MobileLogin/MobileLogin';
import { MobileAuthDrawer as MobileLoginDrawer } from 'ui/Drawer/Drawers/MobileLoginDrawer';

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openMobileLogin, setOpenMobileLogin] = useState(false);
  const [selectedKey, setSelectedKey] = useState<null | string>(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { isOpenMobileFilter } = useFilterState();
  const dispatchFilter = useFilterDispatch();

  const { data: orderCount } = useQueryApiClient({
    request: {
      url: '/api/v2/user/order',
      data: {
        count: true,
      },
      disableOnMount: !user?.authenticated,
    },
  });

  const handleButtonClick = (key: string, action: () => void) => {
    if (key !== 'profile') setOpenProfile(false);

    setSelectedKey(key);
    action();
  };

  const items = [
    {
      key: 'home',
      label: intl.formatMessage({ id: 'breadcrumbs.home' }),
    },
    {
      key: 'main',
      label: intl.formatMessage({ id: 'navigation.main' }),
    },
    {
      key: 'current_events',
      label: intl.formatMessage({ id: 'navigation.current_events' }),
    },
    {
      key: 'contests',
      label: intl.formatMessage({ id: 'navigation.contests' }),
    },
    {
      key: 'recipes',
      label: intl.formatMessage({ id: 'navigation.recipes' }),
    },
    {
      key: 'events',
      label: intl.formatMessage({ id: 'navigation.events' }),
    },
    {
      key: 'contacts',
      label: intl.formatMessage({ id: 'navigation.contacts' }),
    },
    {
      key: 'sales',
      label: intl.formatMessage({ id: 'general.sales' }),
    },
    {
      key: 'news',
      label: intl.formatMessage({ id: 'general.news' }),
    },
  ];

  const addClassToHeader = () => {
    const header = document.getElementById('mobile-header-container');

    if (header) {
      if (!isOpenMobileFilter) {
        header.classList.add('filterOpen');
      } else {
        header.classList.remove('filterOpen');
      }
    }
  };

  const openCloseDrawer = () => {
    if (openProfile) {
      setOpenProfile(false);
      setIsMenuOpen(false);
      dispatchFilter({
        type: 'SAVE_PAYLOAD',
        payload: {
          isOpenMobileMenu: !false,
        },
      });
    } else {
      if (isOpenMobileFilter) {
        dispatchFilter({
          type: 'SAVE_PAYLOAD',
          payload: {
            isOpenMobileFilter: false,
          },
        });
        addClassToHeader();
      } else {
        dispatchFilter({
          type: 'SAVE_PAYLOAD',
          payload: {
            isOpenMobileMenu: !isMenuOpen,
          },
        });
        setIsMenuOpen((prev) => !prev);
      }
    }
  };

  const openCloseProfileDrawer = () => {
    setIsMenuOpen(false);
    dispatchFilter({
      type: 'SAVE_PAYLOAD',
      payload: {
        isOpenMobileMenu: false,
      },
    });
    setOpenProfile(!openProfile);
  };

  return (
    <>
      <StyledMobileMenu>
        <div className="mobile-menu-container">
          <div className="mobile-menu-content">
            <div
              className={`mobile-menu-item ${selectedKey === 'profile' ? 'selected' : ''}`}
            >
              <Button
                type="text"
                className={selectedKey === 'profile' ? 'selected' : ''}
                label={intl.formatMessage({ id: 'general.profile' })}
                icon={<Icon name="profile-icon" />}
                onClick={() =>
                  handleButtonClick(
                    'profile',
                    user?.authenticated
                      ? () => {
                          if (!isMenuOpen) {
                            setOpenProfile(true);
                          }
                        }
                      : () => setOpenMobileLogin(true)
                  )
                }
              />
            </div>

            <div
              className={`mobile-menu-item ${selectedKey === 'e-shop' ? 'selected' : ''}`}
            >
              <Button
                type="text"
                className={selectedKey === 'e-shop' ? 'selected' : ''}
                label={intl.formatMessage({ id: 'general.e-shop' })}
                icon={<Icon name="e-shop-icon" />}
                onClick={() =>
                  handleButtonClick('e-shop', () => navigate('products/all'))
                }
              />
            </div>
          </div>

          <div className="mobile-menu-filter">
            <Button
              type="text"
              icon={
                isOpenMobileFilter || openProfile || isMenuOpen ? (
                  <Icon
                    name="mobile-filter-close-button-icon"
                    type="filter-icon"
                  />
                ) : (
                  <Icon name="mobile-menu-open-icon" type="filter-icon" />
                )
              }
              onClick={() => openCloseDrawer()}
            />
          </div>

          <div className="mobile-menu-content">
            <div
              className={`mobile-menu-item manufacturers ${selectedKey === 'producers' ? 'selected' : ''}`}
            >
              <Button
                type="text"
                className={selectedKey === 'producers' ? 'selected' : ''}
                label={intl.formatMessage({ id: 'general.producers' })}
                icon={<Icon name="producers-icon" />}
                onClick={() =>
                  handleButtonClick('producers', () => navigate('farms/all'))
                }
              />
            </div>

            <div
              className={`mobile-menu-item ${selectedKey === 'cart' ? 'selected' : ''}`}
            >
              <Button
                type="text"
                className={selectedKey === 'cart' ? 'selected' : ''}
                label={intl.formatMessage({ id: 'general.basket' })}
                icon={<Icon name="cart-icon" />}
                onClick={() =>
                  handleButtonClick('cart', () => navigate('cart'))
                }
              />
            </div>
          </div>
        </div>

        <MobileMenuDrawer
          onClose={openCloseDrawer}
          open={isMenuOpen}
          closable={false}
          className={'mobile-menu'}
        >
          <Menu mode="vertical" defaultSelectedKeys={['2']} items={items} />
        </MobileMenuDrawer>

        <MyProfileDrawer
          closable={false}
          onClose={openCloseProfileDrawer}
          open={openProfile}
          className={'mobile-menu'}
          title={intl.formatMessage({ id: 'general.my_profile' })}
        >
          <MobileProfielOptions
            orderCount={orderCount}
            openCloseProfileDrawer={openCloseProfileDrawer}
          />
        </MyProfileDrawer>

        <MobileLoginDrawer
          onClose={() => setOpenMobileLogin(false)}
          open={openMobileLogin}
          className={'mobile-menu'}
        >
          <MobileLogin setOpenMobileLogin={setOpenMobileLogin} />
        </MobileLoginDrawer>
      </StyledMobileMenu>
    </>
  );
};

export default MobileMenu;
