import React, { CSSProperties } from 'react';
import { Form, Input as AntdInput } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import useFormValidation from '../../utils/useFormValidation';
import { Validations } from '../../interfaces';
import { FormItemLayout } from 'antd/es/form/Form';

export interface InputProps extends Validations {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  type?: string;
  size?: 'large' | 'middle' | 'small';
  value?: string | number;
  defaultValue?: string;
  maxLength?: number;
  allowClear?: boolean;
  label?: string;
  rules?: Rule[];
  name?: (string | number)[] | string | number;
  className?: string;
  validateTrigger?: string | string[];
  noStyle?: boolean;
  initialValue?: string;
  disabled?: boolean;
  addonAfter?: string | React.ReactNode;
  password?: boolean;
  style?: CSSProperties;
  dependencies?: string[];
  hidden?: boolean;
  readOnly?: any;
  layout?: FormItemLayout;
  onClick?: any;
}

export const Input = ({
  disabled = false,
  placeholder,
  name,
  required,
  prefix,
  suffix,
  type,
  onChange,
  size = 'large',
  value,
  onBlur,
  defaultValue,
  maxLength = 255,
  allowClear,
  label,
  rules,
  className,
  validateTrigger,
  noStyle,
  initialValue,
  addonAfter,
  validations,
  password,
  dependencies,
  hidden,
  layout,
  readOnly = false,
  onClick
}: InputProps) => {
  const { formValidations } = useFormValidation();

  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={validations ? formValidations(validations) : rules}
      className={className}
      validateTrigger={validateTrigger}
      noStyle={noStyle}
      dependencies={dependencies}
      hidden={hidden}
      layout={layout}
    >
      {password ? (
        <AntdInput.Password
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          prefix={prefix}
          suffix={suffix}
          type={type}
          onChange={onChange}
          size={size}
          value={value}
          onBlur={onBlur}
          defaultValue={defaultValue}
          maxLength={maxLength}
          allowClear={allowClear}
          addonAfter={addonAfter}
        />
      ) : (
        <AntdInput
          disabled={disabled}
          onClick={onClick}
          placeholder={placeholder}
          required={required}
          prefix={prefix}
          suffix={suffix}
          type={type}
          onChange={onChange}
          size={size}
          value={value}
          onBlur={onBlur}
          defaultValue={defaultValue}
          maxLength={maxLength}
          allowClear={allowClear}
          addonAfter={addonAfter}
          readOnly={readOnly}
        />
      )}
    </Form.Item>
  );
};
