import styled from 'styled-components';
import { Form } from 'antd';

export const StyledProfileForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${(props) => props.theme.white};
  border-radius: 6px;
  margin-right: 200px;
  width: 60%;
  font-size: 16px;
  font-weight: 400;

  .basic-info .ant-form-item-row {
    width: 425px;
  }

  .basic-info input {
    height: 45px;
  }
  
  .ant-input-affix-wrapper {
    height: 45px;
  }

  .change-password {
    width: 870px;
    height: 45px;
  }
  
  .change-password .ant-form-item-row {
    width: 870px;
    height: 45px;
  }

  .change-password-title {
    margin-top: 22px;
    font-size: 24px;
    line-height: 32px;
    color: ${(props) => props.theme.profileSubTitle};
  }

  .cstm-btn {
    width: 348px;
    height: 52px;
    border-radius: 45px !important;
    background: ${({ theme }) => theme.colorPrimary};
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  .registered_addresses {
    display: none;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 10px;

    .collum {
      display: flex;
      gap: 20px;
      justify-content: flex-start;
    }

    .cstm-btn {
      margin-top: 30px;
      border-radius: 5px;
      height: 52px;
      margin-bottom: 40px;
      float: right;
    }

    .title {
      font-weight: 600;
      font-size: 32px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 800px) {
    margin-right: 0px;
    width: 100%;

    .inputs {
      .collum {
        display: block;
      }
    }
  }
`;
