import DefaultLayout from '../../components/DefaultLayout';
import { useIntl } from 'react-intl';
import { StyledCartPage } from './style';
import { useCartState } from 'public/context/CartContext';
import { useEffect, useState } from 'react';
import { Icon } from 'ui';
import Cart from 'public/components/DefaultLayout/Cart';
import ProcessPurchase from 'public/components/DefaultLayout/ProcessPurchase';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StyledAntdCartSteps } from 'ui/Steps/style';

export const CartPage = () => {
  const intl = useIntl();
  const { farmProducts, priceTotal } = useCartState();
  const [current, setCurrent] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setCurrent(Number(tab));
    }
  }, [searchParams]);

  const steps = (
    <StyledAntdCartSteps
      type="navigation"
      current={current}
      items={[
        {
          style: { display: 'flex', justifyContent: 'flex-start' },
          title: intl.formatMessage({ id: 'general.basket' }),
        },
        {
          style: {
            display: 'flex',
            justifyContent: 'center',
          },
          title: intl.formatMessage({ id: 'general.process_your_purchase' }),
        },
        {
          style: { display: 'flex', justifyContent: 'flex-end' },
          title: intl.formatMessage({ id: 'general.order_receipt' }),
        },
      ]}
    />
  );

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        <StyledCartPage>
          <div
            className="back-to-catalog"
            onClick={() => navigate('/products/all')}
          >
            <Icon name={'arrow-left'} type={'any'} />
            <span>{intl.formatMessage({ id: 'general.back_to_catalog' })}</span>
          </div>
          <div className="container">
            <div className={'cart-page-title'}>
              <h1>{intl.formatMessage({ id: 'cart.your_cart_title' })}</h1>
            </div>
            {steps}
            {current == 0 && (
              <Cart
                priceTotal={priceTotal}
                farmProducts={farmProducts}
                setCurrent={setCurrent}
                current={current}
                isView={false}
              />
            )}
            {current == 1 && (
              <ProcessPurchase
                priceTotal={priceTotal}
                setCurrent={setCurrent}
                farmProducts={farmProducts}
                current={current}
                isView={false}
              />
            )}
            {current == 2 && (
              <Cart
                priceTotal={priceTotal}
                farmProducts={farmProducts}
                setCurrent={setCurrent}
                current={current}
                isView={true}
              />
            )}
          </div>
        </StyledCartPage>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};
