import React from 'react';
import { useIntl } from 'react-intl';

interface FieldProps {
  labelKey: string;
  value?: string | number | null;
}

export const Field = ({ labelKey, value }: FieldProps) => {
  const intl = useIntl();

  return (
    <div className="field">
      <div className="label">{intl.formatMessage({ id: labelKey })}: </div>
      <div className="value">{value}</div>
    </div>
  );
};
