import React, { useEffect, useRef, useState } from 'react'
import { CategoryData } from 'types/CategoryData'
import { StyledCategoryMenu } from './style'
import { RightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { routes } from 'config/config'
import RoutesPath from 'enums/routes'

interface CategoryMenuProps {
  categories: CategoryData[]
}

const CategoryMenu = ({ categories }: CategoryMenuProps) => {
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<CategoryData | null>(
    null
  )
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<CategoryData | null>(null)

  const menuRef = useRef<HTMLDivElement>(null)

  const handleMouseOverCategory = (category: CategoryData) => {
    setSelectedCategory(category)
    setSelectedSubCategory(null)
  }

  const handleMouseOverSubCategory = (subcategory: CategoryData) => {
    setSelectedSubCategory(subcategory)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setSelectedCategory(null)
      setSelectedSubCategory(null)
    }
  }

  const handleClickSubCategory = (value: any) => {
    if (!selectedCategory?.id || !selectedSubCategory?.id || !value?.id) return;

    navigate(RoutesPath.PRODUCTS_ALL, {
      state: {
        category: selectedCategory.id,
        subCategory: selectedSubCategory.id,
        subSubcategory: value.id,
      },
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const renderMenu = (categories: CategoryData[]) => {
    const count = (category: CategoryData) => category?.products_count ? `(${category?.products_count})` : '';
    return (
      <div className={'wrapper-menu'}>
        <div className={'menu'}>
          <div className={'category'}>
            <ul>
              {categories.map((category, index) => (
                <a href={category.route} key={category.id}>
                  <li
                    onMouseEnter={() => handleMouseOverCategory(category)}
                    className={
                      index === categories.length - 1 ? 'no-underline' : ''
                    }
                  >
                    {category.name} <span>{count(category)}</span>
                    {category.subcategories &&
                      !!category.subcategories.length && (
                        <RightOutlined className={'icon'} />
                      )}
                  </li>
                </a>
              ))}
            </ul>
          </div>
          {selectedCategory && selectedCategory.subcategories && selectedCategory.subcategories.length && (
            <div className={'subcategory'}>
              <ul>
                {selectedCategory.subcategories.map((subcategory, index) => (
                  <a href={subcategory.route} key={subcategory.id}>
                    <li
                      onMouseEnter={() =>
                        handleMouseOverSubCategory(subcategory)
                      }
                      className={
                        selectedCategory &&
                          selectedCategory.subcategories &&
                          index === selectedCategory.subcategories.length - 1
                          ? 'no-underline'
                          : ''
                      }
                    >
                      {subcategory.name} <span>{count(subcategory)}</span>
                      {subcategory.subcategories &&
                        !!subcategory.subcategories.length && (
                          <RightOutlined className={'icon'} />
                        )}
                    </li>
                  </a>
                ))}
              </ul>
            </div>
          )}
          {selectedSubCategory && selectedSubCategory.subcategories && selectedSubCategory.subcategories.length && (
            <div className={'subsubcategory'}>
              <ul>
                {selectedSubCategory.subcategories.map(
                  (subsubcategory, index) => (
                    <a href={subsubcategory.route} key={subsubcategory.id} onClick={() => handleClickSubCategory(subsubcategory)}>
                      <li
                        className={
                          selectedSubCategory &&
                            selectedSubCategory.subcategories &&
                            index === selectedSubCategory.subcategories.length - 1
                            ? 'no-underline'
                            : ''
                        }
                      >
                        {subsubcategory.name} <span>{count(subsubcategory)}</span>
                      </li>
                    </a>
                  )
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <StyledCategoryMenu ref={menuRef} itemProp={`${Math.ceil(categories?.length * 46.47)}`}>
      {renderMenu(categories)}
    </StyledCategoryMenu>
  )
}

export default CategoryMenu
