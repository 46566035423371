import { Spinner, Table } from 'ui';
import { ConfigProvider } from 'antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { OrderLayout } from './style';
import useQueryApiClient from 'utils/useQueryApiClient';
import FileDownload from 'js-file-download';
import { useNavigate } from 'react-router-dom';

interface MyOrdersResponse {
  id: number;
  order_id: string;
  farm_id: number;
}

export const MyOrders = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { appendData: pdfData, isLoading: pdfLoading } = useQueryApiClient({
    request: {
      url: '/api/v2/generate-pdf',
      method: 'POST',
      multipart: true,
    },
    onSuccess: (response) => {
      FileDownload(
        response,
        `${intl.formatMessage({ id: 'cart_summary.invoice' })} ${dayjs().format('YYYY-MM-DD')}.pdf`
      );
    },
    onError: (error) => {
      console.error('Error downloading PDF:', error);
    },
  });

  const columns = [
    {
      title: intl.formatMessage({ id: 'orders.order' }),
      dataIndex: 'number',
    },
    {
      title: intl.formatMessage({ id: 'general.date' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },

    {
      title: intl.formatMessage({ id: 'orders.statuss' }),
      dataIndex: 'status',
      render: (value: string) =>
        intl.formatMessage({ id: `enum.${value.toUpperCase()}` }),
    },
    {
      title: intl.formatMessage({ id: 'orders.summary' }),
      dataIndex: 'total',
      render: (value: string, row: any) =>
        `€ ${value} ${intl.formatMessage({ id: 'general.for' })} ${row.product_count} ` +
        (row.product_count > '1'
          ? intl.formatMessage({ id: 'orders.for_units' })
          : intl.formatMessage({ id: 'orders.for_unit' })),
    },
    {
      title: intl.formatMessage({ id: 'orders.actions' }),
      dataIndex: 'transactions',
      render: (values: MyOrdersResponse[], record: any) => (
        <>
          {record.status !== 'proccess' && (
            <>
              <span
                className={'view-order'}
                onClick={() =>
                  navigate(
                    `/user/order?order_id=${values.at(0)?.order_id}&date=${dayjs(record.created_at).format('DD.MM.YYYY')}`
                  )
                }
              >
                {intl.formatMessage({ id: 'orders.view' })}
              </span>
              <span
                className="order-download"
                onClick={() =>
                  values.map((value) =>
                    pdfData({
                      order_id: value.order_id,
                      farm_id: value.farm_id,
                    })
                  )
                }
              >
                {intl.formatMessage({ id: 'orders.download' })}
              </span>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <OrderLayout>
        <h1 className="title sub-title">
          {intl.formatMessage({ id: 'general.my_orders' })}
        </h1>
        <ConfigProvider
          theme={{
            token: {
              borderRadius: 0,
              colorBorderSecondary: 'none',
            },
          }}
        >
          <Table
            loading={pdfLoading}
            url="/api/v2/user/order"
            columns={columns}
            className="order-table"
          />
        </ConfigProvider>
      </OrderLayout>
    </>
  );
};
