import { StyledFooter } from './style';
import { useIntl } from 'react-intl';
import { Icon } from 'ui';

interface ContactContent {
  icon: string;
  content: string;
}

const contactContent: ContactContent[] = [
  { icon: 'bank-icon', content: 'footer.bank' },
  { icon: 'location-tick-icon', content: 'footer.location-tick' },
  { icon: 'call-calling-icon', content: 'footer.call-calling' },
  { icon: 'sms-tracking-icon', content: 'footer.sms-tracking' },
];

const DefaultFooter = () => {
  const intl = useIntl();
  const copyrightText = intl.formatMessage({ id: 'footer.copyright' });

  return (
    <StyledFooter>
      <div className="container-footer">
        <div className={'footer-contacts'}>
          <div className="footer-title">
            <p>{intl.formatMessage({ id: 'general.contacts' })}</p>
            <div className={'footer-icon-wrapper'}>
              <Icon name={'twitter'} type={'card'} />
              <Icon name={'youtube'} type={'card'} />
              <Icon name={'intagram'} type={'card'} />
            </div>
          </div>
          <div className={'footer-content-wrapper'}>
            {contactContent.slice(0, 2).map((content, index) => (
              <div className={'footer-content-container'} key={index}>
                <Icon
                  name={content.icon}
                  type={'avarage'}
                  className="contact-icon"
                />
                <div className={'footer-content'}>
                  <p>{intl.formatMessage({ id: content.content })}</p>
                </div>
              </div>
            ))}

            <div className="footer-content-container-right">
              {contactContent.slice(2, 4).map((content, index) => (
                <div className={'footer-content-container'} key={index}>
                  <Icon
                    name={content.icon}
                    type={'avarage'}
                    className="contact-icon"
                  />
                  <div className={'footer-content'}>
                    <p>{intl.formatMessage({ id: content.content })}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="footer-content-container-end">
              <div className={'footer-content'}>
                <p>{intl.formatMessage({ id: 'navigation.delivery' })}</p>
              </div>
              <div className={'footer-content'}>
                <p>{intl.formatMessage({ id: 'general.payment' })}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="desktop-footer-image-wrapper">
        <Icon name={'footer-desktop-print'} type={'footer'} />
        <Icon name={'footer-desktop-print'} type={'footer'} />
        <Icon name={'footer-desktop-print'} type={'footer'} />
      </div>
      <div className="footer-copyright">
        <div className="container">
          <p dangerouslySetInnerHTML={{ __html: copyrightText }} />
        </div>
      </div>
      <div className="mobile-footer-image-wrapper">
        <Icon name={'mobile-footer-print'} type={'footerMobile'} />
        <Icon name={'mobile-footer-print'} type={'footerMobile'} />
      </div>
    </StyledFooter>
  );
};

export default DefaultFooter;
