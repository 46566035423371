import React, { useEffect, useState } from 'react';
import { StyledAdressFinderWithoutModal } from '../style';
import AddressFinder from '../react-amk.min.js';
import { Form, FormInstance, message } from 'antd';
import { Input } from '../../Input';
import { CloseOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

type AMKResponse = {
  index: number;
  data: {
    address: string;
    code: number;
    dzvCode: number;
    dzvName: string;
    ielCode: number;
    ielName: string;
    irAdrese: boolean;
    nltCode: number;
    nltName: string;
    pilCode: number;
    pilName: string;
    typ: number;
    zipCode: string;
    novName: string;
    pagName: string;
  };
};

interface AddressFinderProps {
  form: FormInstance;
  disabled: boolean;
}

export const WithoutModalAddressFinder = ({
  form,
  disabled,
}: AddressFinderProps) => {
  const intl = useIntl();
  const AMK_ADRESS_SEARCH = `${window.runConfig.backendUrl}/api/v2/vraa/adress`;

  const addressValue = Form.useWatch('address', form);

  if (disabled) {
    return (
      <Input
        value={addressValue || ''}
        readOnly
        placeholder={intl.formatMessage({
          id: 'message.address_available_for_self',
        })}
        disabled
      />
    );
  }

  const handleSelectAddress = (event: AMKResponse) => {
    if (event.data?.code) {
      if (event.data?.irAdrese ?? false) {
        let finalAddress = `${event.data?.address}`;

        if (!!event.data?.zipCode) {
          finalAddress = `${event.data?.address}, ${event.data?.zipCode}`;
        }
        form.setFieldValue('address', finalAddress);
      } else {
        message.error(intl.formatMessage({ id: 'message.add_full_address' }));
      }
    }
  };

  const Suffix = () => {
    return <CloseOutlined onClick={handleClear} />;
  };

  if (disabled) {
    return (
      <Input
        placeholder={intl.formatMessage({
          id: 'message.address_available_for_self',
        })}
        disabled
      />
    );
  }

  const handleClear = () => {
    form.setFieldValue('address', null);
  };

  return (
    <StyledAdressFinderWithoutModal>
      {addressValue ? (
        <Input value={addressValue} readOnly suffix={<Suffix />} />
      ) : (
        <AddressFinder
          apiBaseEndpointUri={AMK_ADRESS_SEARCH}
          onItemSelect={handleSelectAddress}
          highlightMatches={false}
          defaultOpenAllRows={false}
          visibleColumnLabels={['address', 'selectButton']}
          visibleDataLabels={[]}
          showOnlyLowestLevelAddresses={true}
        />
      )}
    </StyledAdressFinderWithoutModal>
  );
};
