import React from 'react';
import { StyledRequirements } from './style';
import { useIntl } from 'react-intl';
import { options } from '../../../config/config';

export interface RequirementProps {
  maxSizeInMB?: number;
  allowedFormats?: string[];
  maxImageSize?: number | string;
  maxImages?: number;
  multiple?: boolean;
}

export const Requirements = ({
  maxSizeInMB = options.images.maxSizeInMB,
  allowedFormats = options.images.allowedFormats,
  maxImageSize = options.images.maxImageSize,
  maxImages = options.images.maxImages,
  multiple = true,
}: RequirementProps) => {
  const intl = useIntl();

  return (
    <StyledRequirements>
      <div className="reqs">
        {multiple && (
          <div className={'req'}>
            <div className={'intl'}>
              {intl.formatMessage({ id: 'gallery.requirement_max_images' })} -
            </div>
            <div>{maxImages}</div>
          </div>
        )}
        <div className={'req'}>
          <div className="intl">
            {intl.formatMessage({ id: 'gallery.requirement_max_size' })} -
          </div>
          <div>{maxSizeInMB}MB</div>
        </div>
        <div className={'req'}>
          <div className="intl">
            {intl.formatMessage({ id: 'gallery.requirement_allowed_formats' })}-
          </div>
          <div className="formats">
            {allowedFormats.map((format, index) => {
              const parts = format.split('/');
              return (
                <div key={index}>
                  {parts[1]}
                  {index !== allowedFormats.length - 1 ? ',' : ''}
                </div>
              );
            })}
          </div>
        </div>
        <div className={'req'}>
          <div className="intl">
            {intl.formatMessage({ id: 'gallery.requirement_image_size' })} -
          </div>
          <div>{maxImageSize}px</div>
        </div>
      </div>
    </StyledRequirements>
  );
};
