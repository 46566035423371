import { Dropdown, Icon } from '../../../ui';
import { useAuth } from '../../../hooks/useAuth';
import { StyledProfile } from './style';
import { MenuProps } from 'antd/es/menu';
import { useIntl } from 'react-intl';

export const Profile = () => {
  const { user, logout } = useAuth();
  const intl = useIntl();

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'logout':
        logout();
        break;
    }
  };

  const dropdownItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'general.logout' }),
      key: 'logout',
    },
  ];

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  };

  return (
    <StyledProfile>
      <Dropdown menu={menuProps}>
        <div className={'content'}>
          <div className={'profile-icon'}>
            <Icon name={'admin/admin-profile'} />
          </div>
          <div className={'info'}>
            <div className={'farm-name'}>{user?.farmName}</div>
            <div
              className={'contact-name'}
            >{`${user?.name} ${user?.surname}`}</div>
            <div className={'role'}>{user?.role}</div>
          </div>
          <div className={'dropdown'}>
            <Icon name={'admin/admin-profile-arrow-down'} />
          </div>
        </div>
      </Dropdown>
    </StyledProfile>
  );
};
