import styled from 'styled-components';
import { Drawer as AnteDrawer } from 'antd';

export const StyledDrawer = styled(AnteDrawer)`
  .ant-drawer-body {
    padding: 12px 40px !important;
  }

  .label-wrapper {
    border-top: 1px solid ${(props) => props.theme.silverGray};
    width: 100%;

    h4 {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .product-count-wrapper {
    position: relative;

    .productCount {
      display: flex;
      position: relative;
      align-items: center;
      gap: 15px;

      h1 {
        margin: 0;
      }
    }
  }

  .ant-select-arrowm,
  .anticon {
    color: ${(props) => props.theme.black};
  }

  .ant-select-item-option-content,
  .ant-select-selection-item {
    font-size: 16px;
  }
  .address-finder {
    min-width: 35px;
    margin-bottom: 24px;

    .data-grid {
      position: relative;
      z-index: 15;
      background-color: ${(props) => props.theme.white};
      border: 1px solid ${(props) => props.theme.silverGray};
      border-top: 0;
      border-bottom: 0;
    }
    .ant-form-item {
      margin: 0;
      max-height: 35px;
    }

    .ant-form-item-additional {
      position: absolute;
      border: 1px solid ${(props) => props.theme.red};
      top: 0;
      width: 787px;
      height: 42px;
      .ant-form-item-explain-error {
        position: absolute;
        top: 39px;
      }
    }
  }

  .label {
    font-size: 14px;
    color: ${(props) => props.theme.colorPrimary};
    padding-bottom: 4px;
  }

  .ant-tabs-nav-list {
    cursor: default;
  }

  .ant-tabs-nav::before {
    border-bottom: solid 1px ${(props) => props.theme.addProductUnderline};
  }

  .unit-value {
    display: flex;
    flex-direction: row;
  }

  .ant-drawer-header {
    min-height: 113px;
  }

  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .ant-drawer-close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      width: 22px;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    .ant-drawer-title {
      max-width: 300px;
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .ant-drawer-body {
    .preview {
      display: flex;
      flex-wrap: wrap;
      width: auto;
      height: 800px;
    }
  }

  .ant-col {
    padding-bottom: 0;
  }

  .ant-drawer-footer {
    border-top: 0;
    .btns {
      display: flex;
      justify-content: right;
      gap: 10px;

      .ant-btn {
        border-radius: 3px;
      }
    }
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  .add-edit-product-button {
    border-radius: 3px;
  }

  .add-edit-product-button.white {
    border: 1px solid ${(props) => props.theme.darkGrayishBlue};
  }
`;

export const StyledMansLauksDrawer = styled(AnteDrawer)`
  .ant-form-item {
    max-width: 180px;
  }

  .unit-value {
    display: flex;
    gap: 5px;
  }

  .ant-form-item-explain-error {
    width: 250px;
    font-size: 12px;
  }

  .storage-unit {
    display: flex !important;
    gap: 10px;
    .ant-form-item {
      width: 100px;
    }
  }

  .ant-drawer-header {
    min-height: 113px;
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: right;
  }
`;

export const StyledCartDrawerFooter = styled.div`
  .cart-warning-container {
    letter-spacing: 0.5px;
    padding: 14px 16px 14px 16px !important;
    font-weight: 300;
    font-size: 16px;
    font-family: ${(props) => props.theme.fontText};
  }

  .cart-drawer-sum-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    font-family: ${(props) => props.theme.fontText};

    border-top: 1px solid ${(props) => props.theme.borderBottom};
    margin-top: 10px;
    padding: 32px 0 25px 0;

    .cart-drawer-sum-title {
      font-weight: 400;

      p {
        margin: 0;
      }
    }

    .cart-drawer-sum {
      font-weight: 700;
    }
  }

  button {
    height: 52px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
  }
`;
export const StyledDeliveryDrawerFooter = styled.div`
  .delivery-drawer-btn.mobile {
    display: flex;
    flex-direction: row-reverse;
    background-color: transparent;
    height: 52px;
    width: 100%;
    border: 1px solid ${(props) => props.theme.darkRed};
    color: ${(props) => props.theme.darkRed};
    font-weight: 500;
  }

  .delivery-drawer-btn {
    display: flex;
    height: 52px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
  }
`;

export const MobileMenuStyledDrawer = styled(AnteDrawer)`
  .title,
  .text {
    margin: 0;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 14px;
    line-height: 14px;

    .bold-text {
      font-weight: 600;
    }
  }

  &.mobile-menu {
    background-color: ${(props) => props.theme.brand01} !important;
    box-shadow: 0px 4px 84px 0px ${(props) => props.theme.halfBlack};
  }

  .ant-menu-item {
    color: ${(props) => props.theme.white} !important;
  }

  .ant-drawer-body {
    padding: 20px;
  }

  @media only screen and (max-width: 1200px) {
    &.mobile-menu-drawer {
      position: relative;
      margin: 0 !important;
      padding: 0;
      display: block;
    }

    &.mobile-filter {
      padding-bottom: 135px !important;
      box-shadow: 0px 4px 84px 0px ${(props) => props.theme.halfBlack};
      height: 544px !important;

      @media only screen and (max-height: 824px) {
        height: calc(100vh - 119px);
      }
    }

    &.product-mobile-filter {
      height: 680px !important;

      @media only screen and (max-height: 840px) {
        height: calc(100vh - 199px) !important;
      }
    }

    &.mobile-menu {
      padding-bottom: 115px !important;
      height: 555px !important;

      .mobile-search-section-box {
        display: none;
      }

      @media only screen and (max-height: 950px) {
        height: 484px !important;
      }

      @media only screen and (max-height: 870px) {
        height: calc(100vh - 322px) !important;

        .mobile-search-section-box {
          display: block;
        }

        .search-section {
          position: fixed;
          top: 240px;
          left: 0;
        }
      }

      @media only screen and (max-height: 750px) {
        height: calc(100vh - 245px) !important;

        .mobile-search-section-box {
          display: block;
        }

        .search-section {
          top: 160px;
          width: calc(100vw - 30px);
        }
      }
    }

    .ant-menu-title-content {
      font-family: ${(props) => props.theme.fontText};
    }
  }
`;

export const MyProfileStyledDrawer = styled(AnteDrawer)`
  &.mobile-menu {
    margin-top: calc(100vh - 450px) !important;
  }
`;

export const MobileAuthStyledDrawer = styled(AnteDrawer)`
  box-shadow: ${(props) => props.theme.profileBoxShadow};
  font-family: ${(props) => props.theme.fontText};
  .ant-drawer-header {
    display: none;
  }
`;

export const StyledDeliveryDrawer = styled(AnteDrawer)`
  .ant-drawer-header {
    align-self: end;
    width: 100px;
    border-bottom: 0;
    position: absolute;

    svg {
      height: 30px;
      width: 30px;
    }
  }
`;

export const StyledCartDrawer = styled(AnteDrawer)`
  .farm-section {
    border-bottom: 1px solid ${(props) => props.theme.borderBottom};

    &:last-child {
      border-bottom: none;
    }

    .ant-card-bordered:last-child {
      border-bottom: none;
    }
  }
  .ant-drawer-body {
    padding: 0 22px 0 22px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .ant-drawer-title {
      font-size: 24px;
      font-family: ${(props) => props.theme.fontText};
      font-weight: 400;
      .ant-drawer-close {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
`;
