import styled from 'styled-components';

export const StyledAllProducts = styled('div')`
  background-color: ${({ theme }) => theme.whiteSmoke};

  .discount-items {
    display: none;
  }

  .banners {
    padding-bottom: 60px;
    background-color: ${({ theme }) => theme.white};

    .slick-slider {
      margin-bottom: 0;

      .banner-image-wrapper {
        padding: 0;
      }
    }

    .slick-dots-bottom {
      bottom: -20px;
    }

    .banner-wrapper {
      padding: 21px 0;
    }
  }
  .icon .ant-form-item {
    padding-bottom: 0;
  }

  .filter-by .ant-select-selection-item {
    display: none !important;
  }

  .banner-image-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .product-name-and-qmarks-container {
    .product-name {
      margin-top: 0 !important;
    }
  }

  .ant-pagination-item {
    margin-right: 0;
  }

  .ant-pagination-prev {
    margin-right: 24px;
  }

  .ant-pagination-next {
    margin-left: 24px;
  }

  .popular-categories {
    padding-bottom: 50px;
    padding-top: 125px;

    h1 {
      margin-bottom: 19px;
    }
  }

  .products-filters-container {
  }

  .products-filters-container {
    .category_name {
      display: none;
    }
    display: flex;
    flex-direction: row;
    column-gap: 50px;

    .ant-form {
      padding-right: 0;
    }

    .ant-select-selector {
      background-color: ${({ theme }) => theme.lightGrey} !important;
    }

    .ant-spin-nested-loading {
      width: 100%;
    }

    .products-wrapper {
      .products {
        display: flex;
        flex-wrap: wrap;
        padding-top: 51px;

        @media only screen and (max-width: 601px) {
          justify-content: center !important;
        }
      }

      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 77px 0 83px 0;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 0;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          font-family: ${({ theme }) => theme.fontText} !important;
        }
      }
    }
  }

  .banners {
    .banner_3 {
      padding-bottom: 0;
    }
  }

  .products-filters-container {
    .ant-select-selector {
      background-color: ${({ theme }) => theme.softShadowGray};
      color: ${({ theme }) => theme.colorText} !important;
      border: 1px solid ${({ theme }) => theme.softSlateBlue};
    }

    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.colorText} !important;
      font-size: 16px;
      line-height: 28px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .products-filters-container {
      .products-wrapper {
        padding-right: 0px !important;
        padding-top: 30px;
      }
    }
    .form-container {
      display: none !important;
    }
    .products-wrapper {
      padding-right: 0 !important;

      .products {
        padding: 0 !important;
      }
      .category_name {
        text-align: center;
        display: block !important;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  @media only screen and (max-width: 440px) {
    .products-container {
      background-color: ${({ theme }) => theme.inputGrey};
    }

    .popular-categories {
      padding-bottom: 0;
      padding-top: 0;
    }

    .mobile-product-category {
      padding: 20px 0 44px 0;

      .title {
        padding: 0 20px;

        h1 {
          margin-top: 0;
        }
      }

      .slick-dots-bottom {
        bottom: -22px !important;
      }
    }

    .discount-items {
      background-color: ${({ theme }) => theme.brightGrey};
    }

    .ant-pagination-prev {
      margin-inline-end: 6px !important;
    }

    .ant-pagination-next {
      margin-inline-start: 6px !important;
    }

    .discount-items {
      display: block;
      padding-top: 20px;

      .slick-dots {
        padding-top: 35px;
      }

      .view-all-items-button {
        margin-top: 10px;
        padding-bottom: 20px;
      }

      .custom-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-card-body .price-quality-marks .farm-name-container-mobile {
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .banners {
      padding-top: 25px;
      padding-bottom: 0px;
      background-color: ${({ theme }) => theme.brightGrey};

      .desktop-banner {
        display: block;
      }
      .slick-dots {
        display: none !important;
      }

      .banner-wrapper {
        padding-bottom: 0;
      }

      .desktop-banner:first-child {
        padding-top: 0;
      }
    }

    .products-filters-container {
      .products-wrapper {
        padding-top: 0;

        .products {
          display: flex;
          flex-wrap: wrap;
          justify-content: center !important;
          gap: 15px;

          .ant-card-body {
            gap: 5px;
          }

          .card-img {
            width: 100%;
            height: 100%;
            max-width: 153px;
          }

          .ant-image {
            height: 100%;
          }

          .ant-image-img {
            height: 100%;
            min-height: 136px;
          }

          .custom-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .farm-name-container-mobile {
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .price-quality-marks {
            margin-bottom: 0;
            gap: 5px;
          }

          .add-to-cart-container {
            padding-top: 5px;
          }

          .price-quality-marks
            .price-container-quality-marks-wraper
            .price-container {
            flex-direction: row;
            column-gap: 15px;
            flex-wrap: wrap;
          }
        }

        .category_name {
          text-align: start;
          padding: 0 20px;
        }

        .ant-spin-nested-loading {
          width: 100%;
        }

        .pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px 0 33px 0 !important;
        }
      }
    }

    .ant-pagination-prev {
      margin-right: 6px;
    }

    .ant-pagination-next {
      margin-left: 6px;
    }

    .product-slider {
      .ant-image-img {
        height: 100%;
        min-height: 136px;
      }

      .ant-card-body .custom-title p {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-card-body .price-quality-marks .farm-name-container-mobile {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-card-body
        .price-quality-marks
        .price-container-quality-marks-wraper {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }

      .ant-card-body {
        gap: 5px;
      }

      .price-quality-marks {
        gap: 5px;
      }

      .price-quality-marks {
        margin-bottom: 0;
      }

      .ant-card-body .add-to-cart-container .add-to-cart-icon .btn-add-to-cart {
        height: 30px;
      }

      .btn-add-to-cart .ant-btn {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }

  .products {
    gap: 33px;
  }

  .ant-card {
    margin: 0;
  }
`;
