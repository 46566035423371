import { StyledLoginPage } from './style';
import { Spinner } from '../../../ui';

import useQueryApiClient from 'utils/useQueryApiClient';
import { NewPasswordForm } from 'ui/Form/auth/NewPasswordForm';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

export const NewPassword = () => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { appendData } = useQueryApiClient({
    request: {
      url: `api/v2/new-password-reset`,
      method: 'POST',
    },
    onSuccess: (response) => {
      openNotification(response.message);
      setTimeout(() => {
        navigate('/');
      }, 5000);
    },
    onError: (error) => {
      openNotification(error.message);
    },
  });

  const openNotification = (message: string) => {
    notificationApi['info']({
      message: message,
      showProgress: true,
      pauseOnHover: true,
      duration: 5,
    });
  };

  return (
    <StyledLoginPage>
      {contextHolder}
      <div className={'content'}>
        <NewPasswordForm appendData={appendData} />
      </div>
    </StyledLoginPage>
  );
};
