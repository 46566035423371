import styled from 'styled-components';

export const StyledPage = styled.div`
  position: relative;
  bottom: 0;

  .footer {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 15px;
    width: 100%;
    height: 79px;
    background-color: ${(props) => props.theme.filterBackground};
    padding-right: 20px;
  }
`;
