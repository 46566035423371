import DefaultLayout from 'public/components/DefaultLayout';
import {
  useBannerState,
  useCategoryState,
  useProductState,
} from '../../context';
import Banner from '../../components/DefaultLayout/Banner';
import PopularProducts from '../../components/DefaultLayout/PopularProducts';
import DiscountItems from '../../components/DefaultLayout/DiscountItems/DiscountItems';
import PopularCategories from 'public/components/DefaultLayout/PopularCategories';
import MobileProductCategoriesCarousel from 'public/components/MobileLayout/MobileProductCategoriesCarousel/MobileProductCategoriesCarousel';
import { MobileMenuDrawer } from 'ui/Drawer/Drawers/MobileMenuDrawer';
import { StyledMobileFilterFooter } from 'public/components/MobileLayout/MobileMenu/MobileFilterFooter/style';
import MobileFilter from 'public/components/MobileLayout/MobileFilter/MobileFilter';
import { useIntl } from 'react-intl';
import { Button, Icon } from 'ui';
import { useCallback, useEffect, useState } from 'react';
import { FilterOptions } from 'types/FilterOptions';
import { Filter } from 'types/Filter';
import useQueryApiClient from 'utils/useQueryApiClient';
import SearchAndFilter from 'public/components/DefaultLayout/SearchAndFilter';
import { useFilterDispatch } from 'public/context/FilterContext';
import { TestPageWrapper } from './style';
import { BannerProps } from '../../../types/BannerData';

const TestPage = () => {
  const intl = useIntl();
  const { banners } = useBannerState();
  const { products, productsDiscounted } = useProductState();
  const { popularCategory } = useCategoryState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [productsCount, setProductsCount] = useState<number>(0);

  const [filter, setFilter] = useState<Filter>(() => {
    const savedFilter = localStorage.getItem('productsFilter');
    return savedFilter ? JSON.parse(savedFilter) : {};
  });

  const dispatchFilter = useFilterDispatch();

  const { appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/products',
      data: {
        page: 1,
        page_size: 1,
        ...filter,
      },
      disableOnMount: true,
    },
    onSuccess: (response) => {
      setFilterOptions(response.filters);
    },
  });

  useQueryApiClient({
    request: {
      url: '/api/v2/products/get/count',
    },
    onSuccess: (response) => setProductsCount(response),
  });

  useEffect(() => {
    if (isFilterOpen) return;

    appendData({
      filter: filter,
      page: 1,
      page_size: 1,
    });
  }, [filter]);

  const openCloseDrawer = useCallback(() => {
    setIsFilterOpen(!isFilterOpen);
  }, [isFilterOpen]);

  useEffect(() => {
    dispatchFilter({
      type: 'SAVE_PAYLOAD',
      payload: { isFilter: false, openCloseDrawer },
    });
  }, [dispatchFilter, openCloseDrawer]);

  const handleSubmitFilter = () => {
    localStorage.setItem('productsFilter', JSON.stringify(filter));
  };

  const banner_1_data = banners.data.find(
    (banner: BannerProps) => banner.type === 'banner_1'
  );
  const banner_2_data = banners.data.find(
    (banner: BannerProps) => banner.type === 'banner_2'
  );
  const banner_3_data = banners.data.find(
    (banner: BannerProps) => banner.type === 'banner_3'
  );
  const banner_4_data = banners.data.find(
    (banner: BannerProps) => banner.type === 'banner_4'
  );

  const drawer = () => {
    return (
      <MobileMenuDrawer
        title={intl.formatMessage({ id: 'product.filter' })}
        onClose={openCloseDrawer}
        open={isFilterOpen}
        className="mobile-filter"
        closable={false}
        footer={
          <StyledMobileFilterFooter>
            <Button
              label={intl.formatMessage({ id: 'general.select' })}
              icon={<Icon name={'mobile-filter-submit-arrow-icon'} />}
              onClick={handleSubmitFilter}
              href={'/products/all'}
            />
          </StyledMobileFilterFooter>
        }
      >
        <MobileFilter
          setFilter={setFilter}
          filter={filter}
          filterOptions={filterOptions}
        />
      </MobileMenuDrawer>
    );
  };

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        <TestPageWrapper>
          {banner_1_data && (
            <Banner
              banners={[banner_1_data]}
              className={banner_1_data.type}
              full
            />
          )}
          <SearchAndFilter />
          <PopularProducts products={products} />
          <MobileProductCategoriesCarousel popularCategory={popularCategory} />
          <PopularCategories categories={popularCategory} />
          <div className={'desktop-banner'}>
            {banner_3_data && (
              <Banner banners={[banner_3_data]} className={'desktop-banner'} />
            )}
          </div>
          <div className="discount-items">
            <DiscountItems products={productsDiscounted} slidesPerRow={1}>
              {banner_2_data && (
                <Banner
                  banners={[banner_2_data]}
                  className={'desktop-banner'}
                />
              )}
            </DiscountItems>
          </div>

          <div className="banners">
            {banner_3_data && (
              <Banner banners={[banner_3_data]} className={'mobile-banner'} />
            )}
            {banner_4_data && (
              <Banner
                banners={[banner_4_data]}
                className={'desktop-banner fourth-banner'}
              />
            )}
          </div>
          {drawer()}
        </TestPageWrapper>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default TestPage;
