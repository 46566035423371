import styled from 'styled-components';
import { Table } from '../../../../../ui';

export const StyledOrderItems = styled(Table)`
  .ant-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .ant-table-content {
      width: 845px;
      border: 1px solid ${(props) => props.theme.borderTable};
      border-radius: 10px;

      .ant-table-thead {
        max-height: 20px;
        border-bottom: 0;

        .ant-table-cell {
          padding: 10px 20px;
          font-weight: 400;
          color: ${(props) => props.theme.stepInactiveColor};
        }

        .ant-table-cell::before {
          background-color: transparent !important;
        }
      }
      .ant-table-tbody {
        border-top: 0;
        font-family: ${(props) => props.theme.fontText};
        font-size: 14px;

        .ant-table-cell {
          padding: 10px 20px;
          border: 0;
        }
      }
    }
  }
`;
